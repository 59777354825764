import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const NAME = 'sub_tours';

const INITIAL_STATE = /** @lends State.prototype */ {
	[NAME]: {
		attributes: null,
		data: null,
		loadError: null,
		locations: null,
		nextPage: null,
		op: null,
		scenes: null,
		site: null,
	},
};

const INITIAL_PARENT_STATE = {
	attributes: [],
	floorplan: null,
	image: null,
	krpano_tour: null,
	locations: [],
	scenes: [],
	site: null,
};

const updateState = (state, ob) => {
	return { ...state, [NAME]: { ...state[NAME], ...ob } };
};

const withInvalidation = (nextState, action) => {
	if (action.invalidateId && nextState[NAME] && nextState[NAME].data) {
		delete nextState[NAME].data[action.invalidateId];
	}
	return nextState;
};

const updateParentState = (nextState, action) => {
	const { next_page } = action;
	Object.keys(INITIAL_PARENT_STATE).forEach(key => {
		if (action.reset || !nextState[key]) {
			nextState[key] = INITIAL_PARENT_STATE[key];
		}
		if (!action[key]) {
			return;
		}
		nextState[key] = Array.isArray(action[key]) ? [...action[key]] : { ...action[key] };
	});
	if (nextState.scenes) {
		nextState.scenes = nextState.scenes.map(s => {
			return {
				...s,
				thumbnail: s.preview_image || s.image,
			};
		});
	}
	nextState.nextPage = next_page;
};

const helper = new ReduceHelper(NAME, 'id');

const gotData = (state, action) => {
	const nextState = helper.gotData(state, action);
	const sub_tours = nextState[NAME];
	updateParentState(sub_tours, action);
	return nextState;
};

const loadStart = (state, action) => {
	const nextState = updateState(state, {
		loadError: null,
	});

	if (action.invalidateAll) {
		nextState[NAME].data = null;
		nextState[NAME].site = null;
		nextState[NAME].image = null;
		nextState[NAME].floorplan = null;
		nextState[NAME].krpano_tour = null;
		nextState[NAME].attributes = null;
		nextState[NAME].locations = null;
		nextState[NAME].scenes = null;
		nextState[NAME].nextPage = null;
	}

	return withInvalidation(nextState, action);
};

const loadEnd = (state, action) => {
	return updateState(state, {
		loadError: action.error || null,
	});
};

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const opEnd = (state, action) => {
	return helper.withInvalidation(helper.opEnd(state, action), action);
};

const opReset = state => {
	return updateState(state, {
		op: null,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.SUB_TOURS_GOT_DATA]: gotData,
	[TYPES.SUB_TOURS_LOAD_START]: loadStart,
	[TYPES.SUB_TOURS_LOAD_END]: loadEnd,
	[TYPES.SUB_TOURS_OP_START]: opStart,
	[TYPES.SUB_TOURS_OP_END]: opEnd,
	[TYPES.SUB_TOURS_OP_RESET]: opReset,
	[TYPES.OP_RESET_ALL]: opReset,
	[TYPES.DATA_RESET_ALL]: helper.reset,
};
