import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cloneItems, deleteItems, moveItems } from '@/actions/folders';
import { closeLibraryContextMenu } from '@/actions/library_context';
import { CONTEXT_MENU, LIBRARY_CONTEXT_TYPE } from '@/types/library';
import Menu from '@/views/common/menu/Menu';
import FileContextMenu from '@/views/library/Files/FileContextMenu/FileContextMenu';
import FolderContextMenu from '@/views/library/Folders/FolderContextMenu/FolderContextMenu';

import ContextMenu from './ContextMenu';

const LIBRARY_CONTEXT_MENU = {
	[LIBRARY_CONTEXT_TYPE.FOLDER]: FolderContextMenu,
	[LIBRARY_CONTEXT_TYPE.FILE]: FileContextMenu,
};

export class LibraryContextMenu extends Component {
	render() {
		const {
			cloneItems,
			closeLibraryContextMenu,
			contextMenuProps,
			contextMenuType,
			deleteItems,
			isOpen,
			loadData,
			menuItems,
			moveItems,
			selectedItems,
		} = this.props;

		let LibraryMenu = LIBRARY_CONTEXT_MENU[contextMenuType];
		let items = menuItems ? menuItems[contextMenuType] : null;

		if (!isOpen) {
			return null;
		}

		if (isOpen && selectedItems && selectedItems.length > 1) {
			LibraryMenu = Menu;
			items = [
				{
					...CONTEXT_MENU.clone,
					action: () => {
						return cloneItems(selectedItems, null, contextMenuProps)
							.then(closeLibraryContextMenu)
							.then(loadData);
					},
				},
				{
					...CONTEXT_MENU.move,
					action: () => {
						return moveItems(selectedItems, null, contextMenuProps)
							.then(closeLibraryContextMenu)
							.then(loadData);
					},
				},
				{
					...CONTEXT_MENU.delete,
					action: () => {
						return deleteItems(selectedItems).then(closeLibraryContextMenu).then(loadData);
					},
				},
			];
		}

		return (
			<ContextMenu
				{...contextMenuProps}
				closeContextMenu={this.props.closeLibraryContextMenu}
				isOpen={isOpen}
			>
				{LibraryMenu ? <LibraryMenu {...contextMenuProps} menuItems={items} /> : null}
			</ContextMenu>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { contextMenuProps, contextMenuType } = state.libraryContextMenu;
	const isOpen =
		state.libraryContextMenu.isOpen &&
		!!contextMenuType &&
		props.namespace === contextMenuProps.namespace;

	return {
		contextMenuProps,
		contextMenuType,
		isOpen,
	};
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			cloneItems,
			closeLibraryContextMenu,
			deleteItems,
			moveItems,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(LibraryContextMenu);
