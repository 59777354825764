import { forEach, get, set } from 'lodash';

import { INDEXING_PROPERTY } from '@/types/data';

export function someChanged(state, nextState) {
	return Object.keys(nextState).some(key => {
		return state[key] !== nextState[key];
	});
}

export function createReducer(initialState, handlers) {
	return function reducer(state = initialState, action) {
		// eslint-disable-next-line no-prototype-builtins
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action);
		}
		return state;
	};
}

/**
 * @constructor
 * @param {NAMESPACE} namespace
 * @param {string | {idPath?: ID_PATH, dataPath?: DATA_PATH, container?: string}} [options]
 * @param {(record?: any, index?: number|string, collection?: any[]) => any} [mapper]
 * @template {string} NAMESPACE
 * @template {string} DATA_PATH
 * @template {string} ID_PATH
 * @template {any} S
 */
export default function ReduceHelper(namespace, options = null, mapper = null) {
	const thisHelper = this;
	const idPath =
		options && typeof options === 'object' ? options.idPath : /** @type {ID_PATH} */ (options);
	const dataPath =
		options && typeof options === 'object' ? options.dataPath : /** @type {DATA_PATH} */ ('data');
	const container = options && typeof options === 'object' ? options.container : 'hash';
	const isArray = container === 'array';

	this.namespace = namespace;
	this.idPath = idPath;
	this.dataPath = dataPath;
	this.container = container;
	if (mapper) {
		this.mapper = mapper.bind(this);
	}

	this.getNamespace = getNamespace;
	this.updateNamespace = updateNamespace;
	this.withInvalidation = withInvalidation;
	this.gotData = gotData;
	this.loadStart = loadStart;
	this.loadEnd = loadEnd;
	this.opStart = opStart;
	this.opEnd = opEnd;
	this.opReset = opReset;
	this.reset = reset;

	/**
	 * @typedef {{[x: string]: any}} Namespace
	 */
	/**
	 * @typedef {{[key in NAMESPACE]: S & {nextPage: Object} & {[key in DATA_PATH]: T[]}}} STATE
	 * @template T
	 */

	/**
	 * @param {_STATE} state
	 * @param {NAMESPACE} [_namespace]
	 * @returns {_STATE[NAMESPACE]}
	 * @template {STATE<OB>} _STATE
	 * @template {object} OB
	 */
	function getNamespace(state, _namespace = namespace) {
		return get(state, _namespace);
	}

	/**
	 * @param {_STATE} state
	 * @param {OB} ob
	 * @param {NAMESPACE} [_namespace]
	 * @returns {_STATE}
	 * @template {STATE<OB>} _STATE
	 * @template {object} OB
	 */
	function updateNamespace(state, ob, _namespace = namespace) {
		let nextNameSpace = getNamespace(state, _namespace);
		const nextState = { ...state };
		nextNameSpace = { _namespace, ...nextNameSpace, ...ob };
		return set(nextState, _namespace, nextNameSpace);
	}

	/**
	 * @param {_STATE} state
	 * @param {{ invalidateId?: keyof _STATE[NAMESPACE][DATA_PATH]; invalidateIds?: any; invalidateAll?: any; }} action
	 * @param {NAMESPACE} [_namespace]
	 * @template {STATE<OB>} _STATE
	 * @template {object} OB
	 */
	function withInvalidation(state, action, _namespace = namespace) {
		const { invalidateAll, invalidateId, invalidateIds } = action;
		const nextNameSpace = getNamespace(state);
		let data = nextNameSpace[dataPath];
		if (data && (invalidateId || invalidateIds)) {
			data = { ...data };

			if (invalidateId) {
				delete data[invalidateId];
			}

			if (invalidateIds) {
				invalidateIds.forEach(id => {
					delete data[id];
				});
			}
		}

		if (invalidateAll) {
			data = null;
		}

		return thisHelper.updateNamespace(state, { [dataPath]: data });
	}

	/**
	 *
	 * @param {_STATE} state
	 * @param {{[x: string]: any}} action
	 * @template {STATE<{[key in NAMESPACE]: {[key in DATA_PATH]: OB[]}}>} _STATE
	 * @template {{nextPage: any}} OB
	 */
	function gotData(state, action) {
		/** @type {any[] | object} */
		let data = isArray ? [] : {};
		const nextNameSpace = getNamespace(state);
		const { nextPage = nextNameSpace.nextPage, reset } = action;

		// if (isArray) {
		// 	const nextState = {
		// 		[dataPath]: action[dataPath],
		// 		nextPage,
		// 	};

		// 	if (mapper) {
		// 		nextState[dataPath] = action[dataPath].map(mapper);
		// 	}
		// 	return thisHelper.updateNamespace(state, nextState);
		// }

		if (!reset && nextNameSpace[dataPath]) {
			if (isArray) {
				/** @type {any[]} */
				data = [...data, ...nextNameSpace[dataPath]];
			} else {
				Object.assign(data, nextNameSpace[dataPath]);
			}
		}

		const indexOffset = Object.keys(data).length;
		forEach(
			action[dataPath],
			(/** @type {{[INDEXING_PROPERTY]: number}} */ record, index, collection) => {
				let id = idPath ? get(record, idPath) : index;
				if (isArray) {
					id = data.length;
				}

				// If data is empty, load sequentially, set INDEXING_PROPERTY to integers 0, 1, 2
				// If updating one or several records, keep the old index.
				record[INDEXING_PROPERTY] = data[id] ? data[id][INDEXING_PROPERTY] : index + indexOffset;

				if (thisHelper.mapper) {
					record = thisHelper.mapper(record, (isArray ? data.length : index) + indexOffset, data);
					if (record === null || id === null || id === undefined) {
						return;
					}
				}
				if (isArray && id >= data.length) {
					data.push(record);
					return;
				}
				data[id] = record;
			},
		);

		return thisHelper.updateNamespace(state, {
			[dataPath]: data,
			nextPage,
		});
	}

	function loadStart(state, action) {
		const { paginating = true } = action;
		const nextState = thisHelper.updateNamespace(state, {
			loadError: null,
			loading: true,
		});
		const nextNameSpace = getNamespace(nextState);

		if (paginating) {
			nextNameSpace.nextPage = { ...(nextNameSpace.nextPage || {}), loading: true };
		}
		if (isArray) {
			return nextState;
		}
		return thisHelper.withInvalidation(nextState, action);
	}

	function loadEnd(state, action) {
		const nextState = thisHelper.updateNamespace(state, {
			loadError: action.error || null,
			loading: false,
		});
		const nextNameSpace = getNamespace(nextState);
		if (nextNameSpace.nextPage) {
			nextNameSpace.nextPage.loading = false;
			if (Object.keys(nextNameSpace.nextPage).length === 1) {
				delete nextNameSpace.nextPage;
			}
		}
		return nextState;
	}

	function opStart(state) {
		return thisHelper.updateNamespace(state, {
			op: {
				progress: 0,
			},
		});
	}

	function opEnd(state, action) {
		const nextNameSpace = getNamespace(state);
		if (!nextNameSpace.op) {
			// No existing op, no change
			return state;
		}

		let op = null;
		if (action.error) {
			op = {
				...nextNameSpace.op,
				error: action.error,
				...(op || {}),
			};
		}

		if (action.loadError) {
			op = {
				...nextNameSpace.op,
				loadError: action.loadError,
				...(op || {}),
			};
		}

		return thisHelper.withInvalidation(thisHelper.updateNamespace(state, { op }), action);
	}

	function opReset(state) {
		return thisHelper.updateNamespace(state, {
			error: null,
			loadError: null,
			op: null,
		});
	}

	function reset(state, action) {
		let targets = action?.targets || null;
		if (!targets && action?.namespace) targets = [action.namespace];

		if (targets && !targets.includes(namespace)) {
			return state;
		}
		return thisHelper.updateNamespace(opReset(state), {
			[dataPath]: null,
		});
	}
}
