import { assign, enumize } from '@/lib/util';

import { SORT_DIRECTIONS, takeFromProps } from './data';
import { Criteria as SitesCriteria, SITE } from './sites';

/** @typedef {import('./sites').Site} Site */
/** @typedef {import('./sites').Image} Image */

const sub_tour = {
	id: 'id',
	locations: 'locations',
	parent_id: 'parent_id',
	scenes: 'scenes',
	site: 'site',
};

export const SUB_TOUR = sub_tour;

export class SubTour {
	constructor(source) {
		/** @type {number} */ this.id = null;
		/** @type {number} */ this.parent_id = null;
		/** @type {Site} */ this.site = null;
		/** @type {KrTour} */ this.krpano_tour = null;
		/** @type {SiteLocationWithDetails[]} */ this.locations = null;
		/** @type {SiteSceneWithDetails[]} */ this.scenes = null;

		Object.assign(this, source);
	}
}

export class SiteLocation {
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.site_id = undefined;
		/** @type {number} */ this.parent_id = undefined;
		/** @type {number} */ this.krpano_scene_group_id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {number} */ this.image_id = undefined;
		/** @type {number} */ this.lat = undefined;
		/** @type {number} */ this.lng = undefined;
		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = undefined;

		assign(this, this, source);
	}
}

export class SiteLocationWithDetails {
	constructor(source) {
		/** @type {number} */
		this.site_id = undefined;
		/** @type {number} */
		this.location_id = undefined;
		/** @type {number} */
		this.parent_id = undefined;

		/** @type {string} */
		this.image_key = undefined;
		/** @type {string} */
		this.preview_image_key = undefined;
		/** @type {string} */
		this.floorplan_image_key = undefined;

		/** @type {SiteLocation} */
		this.location = undefined;
		/** @type {SiteLocation} */
		this.parent = undefined;
		/** @type {Site} */
		this.site = undefined;

		/** @type {KrSceneGroup} */
		this.krpano_scene_group = undefined;

		/** @type {Image} */
		this.image = undefined;
		/** @type {Image} */
		this.preview_image = undefined;
		/** @type {Image} */
		this.floorplan = undefined;

		/** @type {any[]} */
		this.value_ids = undefined;

		assign(this, this, source);
	}
}

export const SITE_LOCATION = enumize(new SiteLocation());

export class SiteScene {
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.site_id = undefined;
		/** @type {number} */ this.scene_id = undefined;
		/** @type {number} */ this.location_id = undefined;
		/** @type {number} */ this.krpano_scene_id = undefined;
		/** @type {number} */ this.image_id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {number} */ this.lat = undefined;
		/** @type {number} */ this.lng = undefined;
		/** @type {number} */ this.sort = undefined;

		assign(this, this, source);
	}
}

export class SiteSceneWithDetails {
	constructor(source) {
		/** @type {number} */ this.scene_id = undefined;
		/** @type {number} */ this.krpano_scene_id = undefined;
		/** @type {number} */ this.site_id = undefined;
		/** @type {number} */ this.location_id = undefined;
		/** @type {string} */ this.sort = undefined;
		/** @type {SiteScene} */ this.scene = undefined;
		/** @type {KrScene} */ this.krpano_scene = undefined;
		/** @type {SiteLocation} */ this.location = undefined;
		/** @type {KrHotspot[]} */ this.hotspots = undefined;
		/** @type {Image} */ this.image = undefined;
		/** @type {Image} */ this.preview_image = undefined;
		/** @type {import('./tags').Tag[]} */ this.tags = undefined;

		assign(this, this, source);
	}
}

export const SITE_SCENE = enumize(new SiteScene());

export class KrScene {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.tour_id = undefined;
		/** @type {number} */ this.scene_group_id = undefined;
		/** @type {string} */ this.hash = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.title = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {string} */ this.source_dir = undefined;
		/** @type {number} */ this.image_id = undefined;
		/** @type {number} */ this.preview_image_id = undefined;

		/** @type {string} */ this.type = undefined;
		/** @type {string} */ this.projection = undefined;
		/** @type {number} */ this.level = undefined;
		/** @type {number} */ this.face_size = undefined;
		/** @type {number} */ this.face_width = undefined;
		/** @type {number} */ this.face_height = undefined;
		/** @type {number} */ this.tile_size = undefined;
		/** @type {string} */ this.url = undefined;

		/** @type {number} */ this.fov = undefined;
		/** @type {number} */ this.h_look = undefined;
		/** @type {number} */ this.v_look = undefined;

		/** @type {number} */ this.lat = undefined;
		/** @type {number} */ this.lng = undefined;

		/** @type {number} */ this.sort = undefined;

		/** @type {Date} */ this.processed_at = null;
		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

export class KrSceneWithDetails {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */
		this.tour_id = null;
		/** @type {number} */
		this.scene_id = null;
		/** @type {number} */
		this.sort = null;

		/** @type {KrScene} */
		this.scene = null;

		/** @type {KrTour} */
		this.tour = undefined;

		/** @type {KrSceneGroup} */
		this.scene_group = undefined;

		/** @type {KrHotspot[]} */
		this.hotspots = undefined;

		/** @type {Image} */
		this.image = undefined;

		/** @type {Image} */
		this.preview_image = undefined;

		/** @type {Image[]} */
		this.images = undefined;

		Object.assign(this, source);
	}
}

export class KrSceneGroup {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.tour_id = undefined;
		/** @type {number} */ this.start_scene_id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.title = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {number} */ this.preview_image_id = undefined;
		/** @type {number} */ this.floorplan_image_id = undefined;

		/** @type {number} */ this.lat = undefined;
		/** @type {number} */ this.lng = undefined;

		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

export class KrHotspot {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.scene_id = undefined;
		/** @type {number} */ this.tour_id = undefined;
		/** @type {number} */ this.target_id = undefined;
		/** @type {number} */ this.billboard_id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.title = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {string} */ this.tooltip = undefined;
		/** @type {string} */ this.style = undefined;
		/** @type {string} */ this.type = undefined;
		/** @type {string} */ this.source = undefined;
		/** @type {number} */ this.x = undefined;
		/** @type {number} */ this.y = undefined;

		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

export class KrTour {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {number} */ this.site_id = undefined;
		/** @type {number} */ this.version_id = undefined;
		/** @type {number} */ this.start_scene_id = undefined;
		/** @type {number} */ this.floorplan_image_id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.title = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {string} */ this.xml_path = undefined;

		/** @type {number} */ this.lat = undefined;
		/** @type {number} */ this.lng = undefined;

		/** @type {Date} */ this.processed_at = null;
		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

export class KrTourWithDetails {
	/** @param {object} [source] */
	constructor(source) {
		/** @type {number} */
		this.tour_id = undefined;
		/** @type {number} */
		this.site_id = undefined;
		/** @type {number} */
		this.version_id = undefined;

		/** @type {KrTour} */
		this.tour = null;

		/** @type {Image} */
		this.floorplan = null;

		/** @type {KrSceneGroup[]} */
		this.scene_groups = null;

		/** @type {KrSceneWithDetails[]} */
		this.scenes = null;

		/** @type {KrHotspot[]} */
		this.hotspots = null;

		Object.assign(this, source);
	}
}

export class Criteria extends SitesCriteria {}

export const DEFAULT_CRITERIA = new Criteria({
	details: [],
	filter: undefined,
	page_size: 50,
	sort_by: SITE.name,
	sort_direction: SORT_DIRECTIONS.asc,
});

//* *********************************************************************************************************************

export const TOOLS = {
	brochure_tool: 'brochure_tool',
	planning_tool: 'planning_tool',
	sales_tool: 'sales-tool',
	virtual_screens_tool: 'virtual_screens_tool',
};

export const STEPS = {
	choose_360_video: 'choose_360_video',
	choose_content: 'choose_content',
	choose_template: 'choose_template',
	content_order: 'content_order',
	create_brochure: 'create_brochure',
	pick_tour: 'pick_tour',
	upload_advert: 'upload_advert',
	virtual_screens: 'virtual_screens',
};

export const STEPS_LABELS = {
	choose_360_video: 'Pick 360 video',
	choose_content: 'Choose content',
	choose_template: 'Choose brochure template',
	content_order: 'Choose content order',
	create_brochure: 'Create brochure',
	pick_tour: 'Pick tour',
	upload_advert: 'Change virtual screens',
	virtual_screens: 'Change virtual screens',
};

export const FEATURE_STEPS = Object.keys(STEPS).map((name, index) => {
	const step = STEPS[name];
	const label = STEPS_LABELS[name];
	return {
		id: index + 1,
		label,
		name,
		step,
	};
});

export const TOOLS_DESCRIPTIONS = {
	[TOOLS.sales_tool]: 'Create a new tour from your existing tours, using Sales Tool',
	[TOOLS.planning_tool]: 'Create a new tour from your existing tours, using Planning Tool',
	[TOOLS.brochure_tool]: 'Create a new brochure for your existing tour, using Brochure Tool',
	[TOOLS.virtual_screens_tool]: 'Manage your virtual screen content, using Virtual Screens Tool',
};

export const TOOLS_STEPS = {
	[TOOLS.sales_tool]: [
		STEPS.pick_tour,
		STEPS.choose_content,
		STEPS.virtual_screens,
		STEPS.content_order,
	],
	[TOOLS.planning_tool]: [STEPS.pick_tour, STEPS.choose_content, STEPS.content_order],
	[TOOLS.brochure_tool]: [STEPS.pick_tour, STEPS.choose_template, STEPS.create_brochure],
	[TOOLS.virtual_screens_tool]: [STEPS.pick_tour, STEPS.virtual_screens],
};

export const SUB_TOUR_TABS = {
	attributes: 'attributes',
	geo: 'geo',
	locations: 'locations',
};

export const FLOOR_PLAN_EDITOR_MODES = {
	editor: 'editor',
	picker: 'picker',
};

export const LOCATION_MANAGER_MODES = {
	editor: 'editor',
	picker: 'picker',
};

export const EMPTY_LOCATION = {
	attributes: null,
	image: null,
	image_key: null,
	location: {
		description: null,
		image_id: null,
		name: null,
	},
	location_id: null,
	scenes: null,
	site: null,
	site_id: null,
	subsite_location: null,
	value_ids: null,
};

export const EMPTY_ATTRIBUTE = {
	attribute: {
		description: null,
		name: null,
	},
	attribute_id: null,
	site_id: null,
	values: null,
};

export class Bounds {
	constructor(bounds) {
		if (!bounds) {
			bounds = {
				lat: 0,
				lng: 0,
				maxLat: Number.NEGATIVE_INFINITY,
				maxLng: Number.NEGATIVE_INFINITY,
				minLat: Number.POSITIVE_INFINITY,
				minLng: Number.POSITIVE_INFINITY,
			};
		}

		this.maxLat = bounds.maxLat;
		this.maxLng = bounds.maxLng;
		this.minLat = bounds.minLat;
		this.minLng = bounds.minLng;
		this.lat = bounds.lat;
		this.lng = bounds.lng;

		this.getCenter = () => Bounds.getCenter(this);
	}

	extend(position) {
		if (!position || !Number.isFinite(position.lat) || !Number.isFinite(position.lng)) {
			return;
		}
		if (position.lat > this.maxLat) {
			this.maxLat = position.lat;
		}
		if (position.lng > this.maxLng) {
			this.maxLng = position.lng;
		}
		if (position.lat < this.minLat) {
			this.minLat = position.lat;
		}
		if (position.lng < this.minLng) {
			this.minLng = position.lng;
		}
	}

	static getCenter(bounds) {
		if (Array.isArray(bounds)) {
			bounds = Bounds.getBounds(bounds);
			return bounds.getCenter();
		}
		return {
			lat: bounds.lat,
			lng: bounds.lng,
		};
	}

	static getBounds(markers) {
		const bounds = new Bounds();
		markers.forEach((marker, index) => {
			const { position } = marker;
			bounds.lat = (bounds.lat * index + position.lat) / (index + 1);
			bounds.lng = (bounds.lng * index + position.lng) / (index + 1);
			bounds.extend(position);
		});

		return bounds;
	}
}

export const takeTool = takeFromProps('tool');
