import { cloneDeep } from 'lodash';

import * as api from '@/lib/api';
import { withType } from '@/lib/util';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { loadDevices } from './agorityDeviceManagement';
import { addToast, resetAllStates } from './application';
import { loadSite, siteLoaded } from './sites';

/**
 * @param {number} deviceId
 * @param {number} avatarId
 * @returns {SvActionCreator<any>}
 */
export const setDefaultAvatar = (deviceId, avatarId) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_AVATARS_OP_START));

	const { agorityDeviceManagement } = getState();
	const { data } = agorityDeviceManagement;

	const dwd = data.find(dwd => dwd.users_devices.id === Number(deviceId));
	const device = dwd.users_devices;

	return api.setEditedDevice(container.http, deviceId, { ...device, avatar_id: avatarId }).then(
		res => {
			dispatch(withType(TYPES.AGORITY_AVATARS_OP_END));
			dispatch(loadDevices());
			dispatch(resetAllStates());
			dispatch(addToast(new Toast(`Device avatar updated successfully`)));
			return res;
		},
		error => {
			dispatch(withType(TYPES.AGORITY_AVATARS_OP_END, { error }));
		},
	);
};

export const createOrUpdateAvatar =
	(deviceId, avatarId, payload) => (dispatch, getState, container) => {
		dispatch(withType(TYPES.AGORITY_AVATARS_OP_START));

		const promise = avatarId
			? api.updateAgorityAvatar(container.http, avatarId, payload)
			: api.createAgorityAvatar(container.http, deviceId, payload);

		return promise.then(
			res => {
				dispatch(withType(TYPES.AGORITY_AVATARS_OP_END));
				dispatch(resetAllStates());
				dispatch(loadSite(res.site_id));
				dispatch(addToast(new Toast(`Avatar ${avatarId ? 'updated' : 'created'} successfully`)));
				return res;
			},
			error => {
				dispatch(withType(TYPES.AGORITY_AVATARS_OP_END, { error }));
			},
		);
	};

export const avatarStatusUpdated = jobAttrs => (dispatch, getState, container) => {
	if (!jobAttrs?.data) {
		return;
	}
	const { sites } = getState();
	const { percent, site_id, status } = jobAttrs.data;
	let swd = sites.data[site_id];
	if (!swd) {
		return;
	}
	swd = cloneDeep(swd);
	if (!swd.site.metadata) {
		swd.site.metadata = {};
	}

	swd.site.metadata.avatar_status = status;
	swd.site.metadata.avatar_percent = percent;

	dispatch(siteLoaded(swd));
};
