import ENV from '@/env';
import * as api from '@/lib/api';
import * as routes from '@/lib/routes';
import { withType } from '@/lib/util';
import { ConnectedModal } from '@/types/connected_modal';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast } from './application';
import { addConnectedModal } from './connected_modal';

export const loadSites =
	(criteria, fromPage = null) =>
	(dispatch, getState, container) => {
		dispatch(
			withType(TYPES.BROCHURE_SITES_LOAD_START, {
				invalidateAll: !fromPage,
				paginating: !!fromPage,
			}),
		);

		return api.getSites(container.http, criteria, fromPage).then(
			res => {
				dispatch(
					withType(TYPES.BROCHURE_SITES_GOT_DATA, {
						data: res.data,
						nextPage: res.next_page,
					}),
				);
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END));
				return res.data;
			},
			error => {
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END, { error }));
			},
		);
	};

export const siteLoaded = swd => (dispatch, getState, container) => {
	dispatch(
		withType(TYPES.BROCHURE_SITES_GOT_DATA, {
			data: [swd].filter(Boolean),
			reset: false,
		}),
	);
	if (swd.tour) {
		dispatch(withType(TYPES.SUB_TOURS_GOT_DATA, { ...swd.tour }));
	}
	if (swd.billboards && swd.billboards.length) {
		dispatch(
			withType(TYPES.TOUR_BILLBOARDS_GOT_DATA, {
				data: swd.billboards,
				reset: false,
			}),
		);
	}
};

export const loadSite =
	(id, invalidate = true, criteria = null) =>
	(dispatch, getState, container) => {
		const startPayload = invalidate ? { invalidateId: id } : {};

		dispatch(withType(TYPES.BROCHURE_SITES_LOAD_START, startPayload));

		return api.getSite(container.http, id, criteria).then(
			swd => {
				dispatch(siteLoaded(swd));
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END));
			},
			error => {
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END, { error }));
			},
		);
	};
/**
 * @param {{
 * siteId?: number,
 * payload: object,
 * brochureId?: number,
 * brochureKey?: number,
 * cloning?: boolean,
 * onSuccess?: function
 * }} param0
 */
export const createOrUpdateBrochure =
	({ brochureId, brochureKey, cloning, onSuccess, payload, siteId }) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.BROCHURE_SITES_LOAD_START));
		dispatch(withType(TYPES.BROCHURE_SITES_OP_START));
		const promise = brochureId
			? api.updateBrochure(container.http, payload, siteId, brochureId)
			: api.createBrochure(container.http, payload, siteId);

		const opError = error => {
			dispatch(withType(TYPES.BROCHURE_SITES_OP_END, { error }));
		};

		return promise.then(res => {
			if (!res.data && res.record) {
				// TODO: compatibility mode
				const resolvedBrochureId = res.record.id;
				if (!resolvedBrochureId) {
					return dispatch(
						withType(TYPES.BROCHURE_SITES_OP_END, {
							error: new Error(`Operation has failed on the server`),
						}),
					);
				}
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END));

				const { parent_id, record, site, site_id, ...rest } = res;

				const newBrochure = {
					...rest,
					cover_image: record.cover_image_id,
					id: record.id,
					key: record.key,
					logo: record.logo_id,
					name: record.name,
					site_id: record.site_id || site_id,
					theme: record.theme,
				};

				// const {key} = payload;

				dispatch(withType(TYPES.BROCHURE_GOT_DATA, newBrochure));
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END));
				dispatch(withType(TYPES.BROCHURE_SITES_OP_END));

				// Changed, so the caller decides where to redirect to
				// if (!key) {
				// 	container.history.push(routes.kioskBrochure(record.key));
				// }
				// return;
			}

			dispatch(withType(TYPES.BROCHURE_SITES_OP_END));

			if (typeof onSuccess === 'function') {
				dispatch(onSuccess());
			}

			const brochure_notification_type_holder =
				ENV.custom_brochure_naming?.notification_type || 'Brochure';

			const toastMessage = cloning
				? `${brochure_notification_type_holder} successfully cloned`
				: brochureId
				? `${brochure_notification_type_holder} successfully updated!`
				: `${brochure_notification_type_holder} successfully created!`;

			dispatch(addToast(new Toast(toastMessage)));
			if (!brochureKey && res.id) {
				const { brochure } = res;
				container.history.push(routes.brochureFile(brochure.key));
			}
			return res;
		}, opError);
	};

export const deleteBrochure = (siteId, brochureId) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.BROCHURE_SITES_OP_START));

	const promise = api.deleteBrochure(container.http, siteId, brochureId);

	const opError = error => {
		dispatch(withType(TYPES.BROCHURE_SITES_OP_END, { error }));
	};

	return promise.then(res => {
		const { deleted } = res;
		if (!deleted) {
			return dispatch(
				withType(TYPES.BROCHURE_SITES_OP_END, {
					error: new Error(`Operation has failed on the server`),
				}),
			);
		}
		dispatch(loadSite(siteId));
		dispatch(addToast(new Toast('Brochure successfully deleted!')));
	}, opError);
};

export const loadBrochure = brochureKey => (dispatch, getState, container) => {
	dispatch(withType(TYPES.BROCHURE_SITES_LOAD_START, { reset: true }));

	const promise = api.getBrochure(container.http, brochureKey);

	const opError = error => {
		dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END, { error }));
	};
	return promise.then(res => {
		if (!res.id) {
			dispatch(siteLoaded(res));
			return dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END, { error: new Error('Not found') }));
		}
		const { brochure, cover_image, logo, parent_id, site, site_id, ...rest } = res;

		const newBrochure = {
			...rest,
			cover_image: cover_image && cover_image.id,
			id: brochure.id,
			key: brochure.key,
			logo: logo && logo.id,
			name: brochure.name,
			site_id,
			theme: brochure.theme,
		};

		dispatch(withType(TYPES.BROCHURE_GOT_DATA, newBrochure));
		dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END));
	}, opError);
};

export const cloneBrochure =
	({ additionalPayload, brochureKey, folderId, onSuccess }) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.BROCHURE_SITES_LOAD_START));
		const finalizeCloning = () => {
			const promise = api.getBrochure(container.http, brochureKey);

			const opError = error => {
				dispatch(withType(TYPES.BROCHURE_SITES_LOAD_END, { error }));
			};
			return promise.then(res => {
				if (!res.id) {
					dispatch(siteLoaded(res));
					return dispatch(
						withType(TYPES.BROCHURE_SITES_LOAD_END, { error: new Error('Not found') }),
					);
				}
				let {
					brochure,
					cover_image,
					documents,
					images,
					logo,
					parent_id,
					sections,
					site,
					site_id,
					...rest
				} = res;
				images = images.map(item => item.id);
				sections = sections.map(section => ({ ...section, id: null }));
				documents = documents.map(doc => doc.id);
				const payload = {
					...rest,
					cover_image: cover_image && cover_image.id,
					documents,
					folder_id: additionalPayload.folder_id,
					id: null,
					images,
					key: null,
					logo: logo && logo.id,
					name: additionalPayload.name,
					sections,
					site_id: null,
					theme: brochure.theme,
				};

				dispatch(createOrUpdateBrochure({ cloning: true, onSuccess, payload }));
			}, opError);
		};

		function modalCallback(folderId, name) {
			additionalPayload = {
				folder_id: folderId,
				name,
			};

			finalizeCloning();
		}

		if (!additionalPayload) {
			const modalProps = {
				onSubmit: modalCallback,
			};

			const modal = ConnectedModal.clone_site(modalProps);
			dispatch(addConnectedModal(modal));
		} else {
			additionalPayload = { ...additionalPayload };
			finalizeCloning();
		}
	};

export const createBrochureFromSection = section => (dispatch, getState, container) => {
	dispatch(withType(TYPES.BROCHURE_FROM_SECTION, section));
};
