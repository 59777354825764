import { Component, createRef } from 'react';

import spinnerIcon from '@/images/spiner.svg';

import './StretchableSpinner.scss';

const DEFAULT_MAX_SIZE = 200;

class StretchableSpinner extends Component {
	constructor(props) {
		super(props);
		this.myRef = createRef();
		this.state = {
			forceHeight: null,
			spinnerSize: 0,
		};
	}

	setSpinnerDimension = () => {
		const { current } = this.myRef;
		const maxSize = this.props.maxSize || DEFAULT_MAX_SIZE;
		const width = current && current.offsetWidth;
		const height = current && current.offsetHeight;
		const size = width >= height ? width / 10 : height / 10;
		const spinnerSize = Math.ceil(size / 10) * 5;
		const newState = {
			forceHeight: null,
			spinnerSize: Math.min(maxSize, spinnerSize),
		};

		if (this.props.pushHeight) {
			newState.forceHeight = Math.min(size, maxSize * 2);
		}

		this.setState(newState);
	};

	componentDidMount() {
		this.setSpinnerDimension();
	}

	render() {
		const { forceHeight, spinnerSize } = this.state;
		const { fixed, image, ...rest } = this.props;

		const heightStyle = forceHeight !== null ? { minHeight: forceHeight } : null;
		const fixedStyle = fixed ? { left: 0, position: 'fixed', top: 0 } : null;

		const style = { ...heightStyle, ...fixedStyle };

		const icon = image ? (
			<img alt="StretchableSpinner" height={spinnerSize} src={image} width={spinnerSize} />
		) : (
			<img alt="StretchableSpinner" height={spinnerSize} src={spinnerIcon} width={spinnerSize} />
		);

		return (
			<div ref={this.myRef} className="sv-StretchableSpinner" style={style} {...rest}>
				{icon}
			</div>
		);
	}
}

export function SmallStretchableSpinner(props) {
	return <StretchableSpinner {...props} maxSize={35} />;
}

export default StretchableSpinner;
