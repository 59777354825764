import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	connectedModals: {},
};

const addConnectedModal = (state, action) => {
	const connectedModals = { ...state.connectedModals };
	connectedModals[action.modal.id] = action.modal;
	return { ...state, connectedModals };
};

const dismissConnectedModal = (state, action) => {
	if (!state.connectedModals || !state.connectedModals[action.id]) {
		return state;
	}
	const connectedModals = { ...state.connectedModals };
	delete connectedModals[action.id];
	return { ...state, connectedModals };
};

// const dismissAllModals = (state, action) => {
// 	return Object.assign({}, state, INITIAL_STATE);
// };

export default {
	INITIAL_STATE,
	[TYPES.CONNECTED_MODAL_ADD]: addConnectedModal,
	[TYPES.CONNECTED_MODAL_DISMISS]: dismissConnectedModal,
	// [TYPES.NAVIGATE_TO_LOCATION]: dismissAllModals,
};
