import { uniqueId } from 'lodash';

import { assign } from '@/lib/util';

import { ModelBase } from './data';
import FA from './font_awesome';

/** @typedef {import('./sites').Site} Site */
/** @typedef {import('./library').Folder} Folder */
/** @typedef {import('./library').FolderWithDetails} FolderWithDetails */
/** @typedef {import('./library').PreparedDownload} PreparedDownload */
/** @typedef {{[originalId: number]: number}} IdMap */

export const BACKGROUND_JOB_STYLE = {
	danger: 'danger',
	info: 'info',
	success: 'success',
	warning: 'warning',
};

export const BACKGROUND_JOB_TYPES = {
	agority_process_avatar: 'agority:process_avatar',
	agority_upload_avatar: 'agority:upload_avatar',
	clone_item: 'clone_item',
	clone_items: 'clone_items',
	cloning: 'cloning',
	compress_items: 'compress_items',
	download: 'download',
	processing: 'processing',
	upload: 'upload',
};

export const BACKGROUND_JOB_TYPE_ICON = {
	[BACKGROUND_JOB_TYPES.upload]: FA.upload,
	[BACKGROUND_JOB_TYPES.download]: FA.download,
	[BACKGROUND_JOB_TYPES.compress_items]: FA.download,
	[BACKGROUND_JOB_TYPES.processing]: FA.cog,
	[BACKGROUND_JOB_TYPES.cloning]: FA.copy,
	[BACKGROUND_JOB_TYPES.clone_item]: FA.copy,
	[BACKGROUND_JOB_TYPES.clone_items]: FA.copy,
};

export const BACKGROUND_JOB_TYPE_LABEL = {
	[BACKGROUND_JOB_TYPES.upload]: 'Uploading',
	[BACKGROUND_JOB_TYPES.download]: 'Downloading',
	[BACKGROUND_JOB_TYPES.compress_items]: 'Compressing',
	[BACKGROUND_JOB_TYPES.processing]: 'Processing',
	[BACKGROUND_JOB_TYPES.cloning]: 'Cloning',
	[BACKGROUND_JOB_TYPES.clone_item]: 'Cloning',
	[BACKGROUND_JOB_TYPES.clone_items]: 'Cloning',
};

export const FINISHED_JOB_TYPE_LABEL = {
	[BACKGROUND_JOB_TYPES.upload]: 'Uploaded',
	[BACKGROUND_JOB_TYPES.download]: 'Compressed',
	[BACKGROUND_JOB_TYPES.compress_items]: 'Compressed',
	[BACKGROUND_JOB_TYPES.processing]: 'Processed',
	[BACKGROUND_JOB_TYPES.cloning]: 'Cloned',
	[BACKGROUND_JOB_TYPES.clone_item]: 'Cloned',
	[BACKGROUND_JOB_TYPES.clone_items]: 'Cloned',
};

export class AgendaJob extends ModelBase {
	constructor(source) {
		super();

		assign(source, this, source);

		/** @type {string} */
		this._id = source._id ? source._id : uniqueId();
		/** @type {string} */
		this.type = source.type || null;
		/** @type {number} */
		this.priority = source.priority || null;
		/** @type {Date} */
		this.nextRunAt = source.nextRunAt ? new Date(source.nextRunAt) : null;
		/** @type {Date} */
		this.lastModifiedBy = source.lastModifiedBy ? new Date(source.lastModifiedBy) : null;
		/** @type {Date} */
		this.lockedAt = source.lockedAt ? new Date(source.lockedAt) : null;
		/** @type {Date} */
		this.lastRunAt = source.lastRunAt ? new Date(source.lastRunAt) : null;
		/** @type {Date} */
		this.lastFinishedAt = source.lastFinishedAt ? new Date(source.lastFinishedAt) : null;
		/** @type {RunningJob} */
		this.data = source.data || null;
	}
}

export class RunningJob extends ModelBase {
	/** @param {Partial<RunningJob>} [source] */
	constructor(source) {
		super();

		assign(source, this, source);

		/** @type {string} */
		this.id = source.id ? source.id : uniqueId();
		/** @type {string} */
		this.job_title = source.job_title;
		/** @type {string} */
		this.job_type = source.job_type;
		/** @type {string} */
		this.record_type = source.record_type;
		/** @type {string} */
		this.icon = source.icon || BACKGROUND_JOB_TYPE_ICON[this.job_type] || FA.cog;
		/** @type {number} */
		this.percent = source.percent;
		/** @type {string} */
		this.text = source.text;
		/** @type {string} */
		this.status = source.status;
		/** @type {string} */
		this.location = source.location || null;
		/** @type {Error} */
		this.error = source.error || null;
		/** @type {Site | FolderWithDetails | PreparedDownload | Array<Site | FolderWithDetails | PreparedDownload>} */
		this.result = source.result || null;
		/** @type {boolean} */
		this.notified = source.notified || null;
		/** @type {Date} */
		this.started_at = source.started_at ? new Date(source.started_at) : null;
		/** @type {Date} */
		this.ended_at = source.ended_at ? new Date(source.ended_at) : null;

		/** @type {ItemsJob} */
		this.data = source.data || null;
	}
}

export class ItemJob extends ModelBase {
	constructor(source) {
		super();

		/** @type {string} */
		this.id = source.id || null;

		/** @type {number} */
		this.percent = source.percent || null;

		/** @type {string} */
		this.phase = source.phase || null;

		/** @type {string} */
		this.room = source.room || null;

		/** @type {"site" | "folder"} */
		this.record_type = source.record_type || null;

		/** @type {Error} */
		this.error = source.error || null;
	}
}

export class ItemsJob extends ItemJob {
	constructor(source) {
		super();

		/** @type {string} */
		this.id = source.id || null;

		/** @type {number} */
		this.session_id = source.session_id || null;

		/** @type {number} */
		this.user_id = source.user_id || null;

		/** @type {number} */
		this.folder_id = source.folder_id || null;

		/** @type {string} */
		this.filename = source.filename || null;

		/** @type {number} */
		this.percent = source.percent || null;

		/** @type {number} */
		this.step_size = source.step_size || null;

		/** @type {number} */
		this.current_step = source.current_step || null;

		/** @type {string} */
		this.phase = source.phase || null;

		/** @type {string} */
		this.room = source.room || null;

		/** @type {string} */
		this.job_title = source.job_title || null;

		/** @type {string} */
		this.job_type = source.job_type || null;

		/** @type {Error} */
		this.error = source.error || null;

		/** @type {number[]} */
		this.folders = source.folders || null;

		/** @type {number[]} */
		this.sites = source.sites || null;

		/** @type {{folders: number[], sites: number[]}} */
		this.snapshot = source.snapshot || null;

		/** @type {{folders: IdMap, sites: IdMap}} */
		this.complete = source.complete || null;

		/** @type {Array<Site | Folder> | PreparedDownload} */
		this.result = source.result || null;
	}
}

export class CloneItemsJob extends ModelBase {
	constructor(source) {
		super();

		/** @type {string} */
		this.id = source.id || null;

		/** @type {number} */
		this.session_id = source.session_id || null;

		/** @type {number} */
		this.user_id = source.user_id || null;

		/** @type {number} */
		this.folder_id = source.folder_id || null;

		/** @type {number} */
		this.percent = source.percent || null;

		/** @type {number} */
		this.step_size = source.step_size || null;

		/** @type {number} */
		this.current_step = source.current_step || null;

		/** @type {string} */
		this.phase = source.phase || null;

		/** @type {string} */
		this.room = source.room || null;

		/** @type {string} */
		this.job_title = source.job_title || null;

		/** @type {string} */
		this.job_type = source.job_type || null;

		/** @type {Error} */
		this.error = source.error || null;

		/** @type {number[]} */
		this.folders = source.folders || null;

		/** @type {number[]} */
		this.sites = source.sites || null;

		/** @type {{folders: number[], sites: number[]}} */
		this.snapshot = source.snapshot || null;

		/** @type {{folders: IdMap, sites: IdMap}} */
		this.complete = source.complete || null;

		/** @type {Array<Site | Folder>} */
		this.result = source.result || null;
	}
}

export const BACKGROUND_JOB_CONSTRUCTORS = {
	[BACKGROUND_JOB_TYPES.clone_item]: ItemJob,
	[BACKGROUND_JOB_TYPES.clone_items]: CloneItemsJob,
};
