import { Component } from 'react';
import { ErrorScreen } from '@spinview/shared-components';

import * as routes from '@/lib/routes';

const { NODE_ENV } = process.env;

let ErrorBoundary;

export class DevErrorBoundary extends Component {
	render() {
		return this.props.children;
	}
}

export class ProdErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			info: null,
		};
	}

	componentDidCatch(error, info) {
		this.setState({ error, info });
		if (window.rollbar && NODE_ENV === 'production') {
			window.rollbar.error(error, info);
		}
	}

	handleReset = () => {
		this.setState({ error: null, info: null });
	};

	renderError() {
		return (
			<ErrorScreen
				error={this.state.error || this.props.error}
				onDismiss={this.handleReset}
				onReturnHome={() => routes.goToAppLanding(true)}
			/>
		);
	}

	render() {
		if (this.state.error || this.props.error) {
			// You can render any custom fallback UI
			return this.renderError();
		}

		return this.props.children;
	}
}

if (NODE_ENV !== 'production') {
	ErrorBoundary = DevErrorBoundary;
} else {
	ErrorBoundary = ProdErrorBoundary;
}

export default ErrorBoundary;
