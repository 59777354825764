export const VARIANTS = {
	accent: 'accent',
	danger: 'danger',
	default: 'default',
	error: 'error',
	info: 'info',
	link: 'link',
	primary: 'primary',
	success: 'success',
	warning: 'warning',
};

export const BS_SIZES = {
	large: 'large',
	normal: 'normal',
	small: 'small',
	xsmall: 'xsmall',
};

export const USER_ROLES = {
	ADMIN: 'admin',
	MANAGER: 'manager',
};

// *********************************************************************************************************************

// From: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const ACCEPT_IMAGE = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg+xml': ['.svg'],
};

export const ACCEPT_ZIP = {
	'application/zip': ['.zip'],
};

export const ACCEPT_VIDEO = {
	'video/avi': ['.avi'],
	'video/mp4': ['.mp4'],
	'video/quicktime': ['.mov'],
};

export const ACCEPT_VIDEO_IMAGE_FILE = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/tiff': ['.tiff', '.tif'],
	'video/mp4': ['.mp4'],
};

export const ACCEPT_DOCUMENT = {
	'application/pdf': ['.pdf'],
	'image/png': ['.png'],
};

export const ACCEPT_IMAGE_TYPE_ARRAY = Object.keys(ACCEPT_IMAGE);
export const ACCEPT_VIDEO_TYPE_ARRAY = Object.keys(ACCEPT_VIDEO);
export const ACCEPT_DOCUMENT_TYPE_ARRAY = Object.keys(ACCEPT_DOCUMENT);

export const ACCEPT_ALL_TYPES = { ...ACCEPT_IMAGE, ...ACCEPT_VIDEO, ...ACCEPT_DOCUMENT };

export const ACCEPT_ZIP_EXTENSION = ['zip'];
export const ACCEPT_VIDEO_EXTENSION = ['avi', 'mp4', 'mov'];
export const ACCEPT_IMAGE_EXTENSION = ['jpeg', 'jpg', 'png', 'svg'];
export const ACCEPT_DOCUMENT_EXTENSION = ['pdf'];

export const MEDIA = {
	document: 'document',
	image: 'image',
	video: 'video',
};
export const MEDIA_TYPES = Object.keys(MEDIA);

export const MEDIA_EXTENSIONS = {
	[MEDIA.image]: ACCEPT_IMAGE_EXTENSION,
	[MEDIA.video]: ACCEPT_VIDEO_EXTENSION,
	[MEDIA.document]: ACCEPT_DOCUMENT_EXTENSION,
};

// *********************************************************************************************************************

export const URL_SCHEMAS = {
	http: 'http',
	https: 'https',
};

export const URL_SCHEMA_LABELS = {
	http: 'http',
	https: 'https',
};

// *********************************************************************************************************************

export const CURRENCY_SYMBOLS = {
	eur: '€',
	gbp: '£',
	usd: '$',
};

// *********************************************************************************************************************

export const validEmailRegularExpression = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const validPhoneRegularExpression = /[0-9]/g;
