import { createSelector } from 'reselect';

import { sortByIndexingProperty } from '@/types/data';

export const getBackgroundJobs = state =>
	state && state.backgroundJobs ? state.backgroundJobs.data : null;

export const getBackgroundJobsSelector = createSelector(getBackgroundJobs, sortByIndexingProperty);
export const currentJobProgress = createSelector(getBackgroundJobsSelector, jobs => {
	if (!jobs || !jobs.length) {
		return null;
	}

	let sumPercentage = 0.0;
	let currentPercentage = 0.0;

	const runningJobs = jobs.filter(p => {
		return (!p.errors || !p.errors.length) && !p.ended_at;
	});
	const num = runningJobs.length;
	runningJobs.forEach(p_item => {
		sumPercentage += p_item.percent * 100.0;
	});

	currentPercentage = num && Math.ceil((sumPercentage * 100.0) / (num * 100.0));
	return currentPercentage;
});
