export const getIoxConnectSensorList = async http => {
	return http.get('/iot/ioxconnect/sensors');
};

export const getIoxConnectSensorData = async (http, sensorId) => {
	return http.get(`/iot/ioxconnect/sensors/${sensorId}`);
};

export const getMorgenOneSensorData = async http => {
	return http.get('/iot/morgenone/sensors');
};
