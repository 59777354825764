export const INITIALIZED = 'INITIALIZED';
export const FATAL_ERROR = 'FATAL_ERROR';
export const SET_KIOSK_MODE = 'SET_KIOSK_MODE';
export const SET_KIOSK_ROOT = 'SET_KIOSK_ROOT';
export const SET_ASSET_TYPE_FILTER = 'SET_ASSET_TYPE_FILTER';
export const SET_KIOSK_TOP_NAV_TRANSPARENCY = 'SET_KIOSK_TOP_NAV_TRANSPARENCY';
export const SET_ROUTE_TITLE = 'SET_ROUTE_TITLE';
export const SET_CONTENT_BOUNDS = 'SET_CONTENT_BOUNDS';
export const REFRESHING = 'REFRESHING';
export const NAVIGATE_TO_LOCATION = 'NAVIGATE_TO_LOCATION';
export const OP_RESET_ALL = 'OP_RESET_ALL';
export const DATA_RESET_ALL = 'DATA_RESET_ALL';

export const APP_VERSION_UPDATE = 'APP_VERSION_UPDATE';
export const INITIALIZE_ROLLBAR_TRACKING = 'INITIALIZE_ROLLBAR_TRACKING';

export const AUTH_OP_RESET = 'AUTH_OP_RESET';
export const AUTH_GOT_SESSION = 'AUTH_GOT_SESSION';
export const AUTH_SET_REDIRECT = 'AUTH_SET_REDIRECT';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_END = 'LOGIN_END';
export const LOGOUT = 'LOGOUT';
export const SWITCH_COMPANY_START = 'SWITCH_COMPANY_START';
export const SWITCH_COMPANY_END = 'SWITCH_COMPANY_END';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_END = 'CHANGE_PASSWORD_END';
export const RESET_PASSWORD_REQUEST_START = 'RESET_PASSWORD_REQUEST_START';
export const RESET_PASSWORD_REQUEST_END = 'RESET_PASSWORD_REQUEST_END';
export const RESET_PASSWORD_EXECUTE_START = 'RESET_PASSWORD_EXECUTE_START';
export const RESET_PASSWORD_EXECUTE_END = 'RESET_PASSWORD_EXECUTE_END';
export const GENERATE_PASSWORD_REQUEST_START = 'GENERATE_PASSWORD_REQUEST_START';
export const GENERATE_PASSWORD_REQUEST_END = 'GENERATE_PASSWORD_REQUEST_END';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_END = 'REGISTER_USER_END';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const UPLOADS_RESUMABLE_FIELD = 'UPLOADS_RESUMABLE_FIELD';
export const UPLOADS_OP_START = 'UPLOADS_OP_START';
export const UPLOADS_OP_END = 'UPLOADS_OP_END';
export const UPLOADS_END = 'UPLOADS_END';
export const UPLOADS_FILE_ADD = 'UPLOADS_FILE_ADD';
export const UPLOADS_FILE_REMOVE = 'UPLOADS_FILE_REMOVE';
export const UPLOADS_RESET = 'UPLOADS_RESET';

export const SITES_GOT_DATA = 'SITES_GOT_DATA';
export const SITES_LOAD_START = 'SITES_LOAD_START';
export const SITES_LOAD_END = 'SITES_LOAD_END';
export const SITES_OP_START = 'SITES_OP_START';
export const SITES_OP_END = 'SITES_OP_END';
export const SITES_OP_RESET = 'SITES_OP_RESET';
export const SITES_VERSIONS_RESET = 'SITES_VERSIONS_RESET';
export const SITES_VERSIONS_GOT_DATA = 'SITES_VERSIONS_GOT_DATA';
export const SITES_BROCHURES_CREATED = 'SITES_BROCHURES_CREATED';
export const SITES_BROCHURES_FETCHED = 'SITES_BROCHURES_FETCHED';
export const SITES_BROCHURES_UPDATED = 'SITES_BROCHURES_UPDATED';
export const SITES_BROCHURES_DELETED = 'SITES_BROCHURES_DELETED';

export const BROCHURE_GOT_DATA = 'BROCHURE_GOT_DATA';
export const BROCHURE_FROM_SECTION = 'BROCHURE_FROM_SECTION';
export const BROCHURE_SITES_GOT_DATA = 'BROCHURE_SITES_GOT_DATA';
export const BROCHURE_SITES_LOAD_START = 'BROCHURE_SITES_LOAD_START';
export const BROCHURE_SITES_LOAD_END = 'BROCHURE_SITES_LOAD_END';
export const BROCHURE_SITES_OP_START = 'BROCHURE_SITES_OP_START';
export const BROCHURE_SITES_OP_END = 'BROCHURE_SITES_OP_END';
export const BROCHURE_SITES_OP_RESET = 'BROCHURE_SITES_OP_RESET';

export const FOLDERS_GOT_DATA = 'FOLDERS_GOT_DATA';
export const FOLDERS_LOAD_START = 'FOLDERS_LOAD_START';
export const FOLDERS_LOAD_END = 'FOLDERS_LOAD_END';
export const FOLDERS_OP_START = 'FOLDERS_OP_START';
export const FOLDERS_OP_END = 'FOLDERS_OP_END';
export const FOLDERS_OP_RESET = 'FOLDERS_OP_RESET';

export const UPDATE_FOLDERS = 'UPDATE_FOLDERS';
export const OPEN_LIBRARY_MODAL = 'OPEN_LIBRARY_MODAL';
export const CLOSE_LIBRARY_MODAL = 'CLOSE_LIBRARY_MODAL';
export const UPDATE_FOLDER_NAME = 'UPDATE_FOLDER_NAME';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const CLEAR_FOLDER_CONTENT = 'CLEAR_FOLDER_CONTENT';
export const UPDATE_FOLDER_PREVIEW = 'UPDATE_FOLDER_PREVIEW';
export const TOGGLE_LIBRARY_VIEW = 'TOGGLE_LIBRARY_VIEW';
export const TOGGLE_LIBRARY_SORT_VIEW = 'TOGGLE_LIBRARY_SORT_VIEW';

export const OPEN_LIBRARY_CONTEXT_MENU = 'OPEN_LIBRARY_CONTEXT_MENU';
export const CLOSE_LIBRARY_CONTEXT_MENU = 'CLOSE_LIBRARY_CONTEXT_MENU';

export const SITE_FILES_DOWNLOAD_START = 'SITE_FILES_DOWNLOAD_START';
export const SITE_FILES_DOWNLOAD_END = 'SITE_FILES_DOWNLOAD_END';

export const SITES_METADATA_GOT_DATA = 'SITES_METADATA_GOT_DATA';
export const SITES_METADATA_LOAD_START = 'SITES_METADATA_LOAD_START';
export const SITES_METADATA_LOAD_END = 'SITES_METADATA_LOAD_END';
export const SITES_METADATA_OP_START = 'SITES_METADATA_OP_START';
export const SITES_METADATA_OP_END = 'SITES_METADATA_OP_END';
export const SITES_METADATA_OP_RESET = 'SITES_METADATA_OP_RESET';

export const SUB_TOURS_GOT_DATA = 'SUB_TOURS_GOT_DATA';
export const SUB_TOURS_LOAD_START = 'SUB_TOURS_LOAD_START';
export const SUB_TOURS_LOAD_END = 'SUB_TOURS_LOAD_END';
export const SUB_TOURS_OP_START = 'SUB_TOURS_OP_START';
export const SUB_TOURS_OP_END = 'SUB_TOURS_OP_END';
export const SUB_TOURS_OP_RESET = 'SUB_TOURS_OP_RESET';
export const SUB_TOURS_SEND_MAILS_START = 'SUB_TOURS_SEND_MAILS_START';
export const SUB_TOURS_SEND_MAILS_END = 'SUB_TOURS_SEND_MAILS_END';
export const SUB_TOURS_SEND_MAILS_ERROR = 'SUB_TOURS_SEND_MAILS_ERROR';

export const ENQUIRIES_GOT_DATA = 'ENQUIRIES_GOT_DATA';
export const ENQUIRIES_LOAD_START = 'ENQUIRIES_LOAD_START';
export const ENQUIRIES_LOAD_END = 'ENQUIRIES_LOAD_END';
export const ENQUIRIES_OP_START = 'ENQUIRIES_OP_START';
export const ENQUIRIES_OP_END = 'ENQUIRIES_OP_END';
export const ENQUIRIES_OP_RESET = 'ENQUIRIES_OP_RESET';
export const ENQUIRIES_MESSAGES_LOAD_START = 'ENQUIRIES_MESSAGES_LOAD_START';
export const ENQUIRIES_MESSAGES_LOAD_END = 'ENQUIRIES_MESSAGES_LOAD_END';
export const ENQUIRIES_MESSAGE_SEND_START = 'ENQUIRIES_MESSAGE_SEND_START';
export const ENQUIRIES_MESSAGE_SEND_END = 'ENQUIRIES_MESSAGE_SEND_END';
export const ENQUIRIES_ASSETS_LOAD_START = 'ENQUIRIES_ASSETS_LOAD_START';
export const ENQUIRIES_ASSETS_LOAD_END = 'ENQUIRIES_ASSETS_LOAD_END';
export const ENQUIRIES_ASSET_DOWNLOAD_START = 'ENQUIRIES_ASSET_DOWNLOAD_START';
export const ENQUIRIES_ASSET_DOWNLOAD_END = 'ENQUIRIES_ASSET_DOWNLOAD_END';
export const ENQUIRIES_GOT_STANDARD_TOUR = 'ENQUIRIES_GOT_STANDARD_TOUR';
export const ENQUIRIES_GOT_BESPOKE_TOUR = 'ENQUIRIES_GOT_BESPOKE_TOUR';
export const ENQUIRIES_GOT_TOUR = 'ENQUIRIES_GOT_TOUR';
export const ENQUIRIES_SINGLE_IMPORT = 'ENQUIRIES_SINGLE_IMPORT';
export const ENQUIRIES_EMPTY_IMPORTED_ENQUIRIES_LIST = 'ENQUIRIES_EMPTY_IMPORTED_ENQUIRIES_LIST';
export const ENQUIRIES_FAILED_ENQUIRY_IMPORT = 'ENQUIRIES_FAILED_ENQUIRY_IMPORT';

export const COMPANIES_GOT_DATA = 'COMPANIES_GOT_DATA';
export const COMPANIES_LOAD_START = 'COMPANIES_LOAD_START';
export const COMPANIES_LOAD_END = 'COMPANIES_LOAD_END';
export const COMPANIES_OP_START = 'COMPANIES_OP_START';
export const COMPANIES_OP_END = 'COMPANIES_OP_END';
export const COMPANIES_OP_RESET = 'COMPANIES_OP_RESET';

export const PRODUCTS_GOT_DATA = 'PRODUCTS_GOT_DATA';
export const PRODUCTS_LOAD_START = 'PRODUCTS_LOAD_START';
export const PRODUCTS_LOAD_END = 'PRODUCTS_LOAD_END';
export const PRODUCTS_OP_START = 'PRODUCTS_OP_START';
export const PRODUCTS_OP_END = 'PRODUCTS_OP_END';
export const PRODUCTS_OP_RESET = 'PRODUCTS_OP_RESET';

export const TOUR_BILLBOARDS_GOT_DATA = 'TOUR_BILLBOARDS_GOT_DATA';
export const TOUR_BILLBOARDS_LOAD_START = 'TOUR_BILLBOARDS_LOAD_START';
export const TOUR_BILLBOARDS_LOAD_END = 'TOUR_BILLBOARDS_LOAD_END';
export const TOUR_BILLBOARD_OP_START = 'TOUR_BILLBOARD_OP_START';
export const TOUR_BILLBOARD_OP_END = 'TOUR_BILLBOARD_OP_END';
export const TOUR_BILLBOARD_OP_RESET = 'TOUR_BILLBOARD_OP_RESET';

export const BILLBOARDS_GOT_DATA = 'BILLBOARDS_GOT_DATA';
export const BILLBOARDS_LOAD_START = 'BILLBOARDS_LOAD_START';
export const BILLBOARDS_LOAD_END = 'BILLBOARDS_LOAD_END';
export const BILLBOARDS_OP_START = 'BILLBOARDS_OP_START';
export const BILLBOARDS_OP_END = 'BILLBOARDS_OP_END';
export const BILLBOARDS_OP_RESET = 'BILLBOARDS_OP_RESET';

export const INSIGHTS_OP_START = 'INSIGHTS_OP_START';
export const INSIGHTS_OP_END = 'INSIGHTS_OP_END';
export const INSIGHTS_GOT_DATA = 'INSIGHTS_GOT_DATA';
export const INSIGHTS_RESET = 'INSIGHTS_RESET';
export const INSIGHTS_GOT_TMP_CRITERIA = 'INSIGHTS_GOT_TMP_CRITERIA';

export const INSIGHTS_CITIES_OP_START = 'INSIGHTS_CITIES_OP_START';
export const INSIGHTS_CITIES_OP_END = 'INSIGHTS_CITIES_OP_END';
export const INSIGHTS_CITIES_GOT_DATA = 'INSIGHTS_CITIES_GOT_DATA';
export const INSIGHTS_CITIES_RESET = 'INSIGHTS_CITIES_RESET';

export const TRACKER_OP_START = 'TRACKER_OP_START';
export const TRACKER_OP_END = 'TRACKER_OP_END';
export const TRACKER_ANNOUNCE = 'TRACKER_ANNOUNCE';
export const TRACKER_EVENT = 'TRACKER_EVENT';
export const TRACKER_GOT_DATA = 'TRACKER_GOT_DATA';

export const USERS_GOT_DATA = 'USERS_GOT_DATA';
export const USERS_LOAD_START = 'USERS_LOAD_START';
export const USERS_LOAD_END = 'USERS_LOAD_END';
export const USERS_OP_START = 'USERS_OP_START';
export const USERS_OP_END = 'USERS_OP_END';
export const USERS_OP_RESET = 'USERS_OP_RESET';

export const USER_CONTACTS_GOT_DATA = 'USER_CONTACTS_GOT_DATA';
export const USER_CONTACTS_LOAD_START = 'USER_CONTACTS_LOAD_START';
export const USER_CONTACTS_LOAD_END = 'USER_CONTACTS_LOAD_END';
export const USER_CONTACTS_OP_START = 'USER_CONTACTS_OP_START';
export const USER_CONTACTS_OP_END = 'USER_CONTACTS_OP_END';
export const USER_CONTACTS_OP_RESET = 'USER_CONTACTS_OP_RESET';

export const TOAST_ADD = 'TOAST_ADD';
export const TOAST_DISMISS = 'TOAST_DISMISS';

export const FILE_EXPLORER_LOAD_START = 'FILE_EXPLORER_LOAD_START';
export const FILE_EXPLORER_GOT_DATA = 'FILE_EXPLORER_GOT_DATA';
export const FILE_EXPLORER_LOAD_END = 'FILE_EXPLORER_LOAD_END';
export const FILE_EXPLORER_FILE_DELETED = 'FILE_EXPLORER_FILE_DELETED';
export const FILE_EXPLORER_PATH_CHANGED = 'FILE_EXPLORER_PATH_CHANGED';
export const FILE_EXPLORER_FILE_RENAMED = 'FILE_EXPLORER_FILE_RENAMED';
export const FILE_EXPLORER_FILE_EDITED = 'FILE_EXPLORER_FILE_EDITED';
export const FILE_EXPLORER_FILE_LOADED = 'FILE_EXPLORER_FILE_LOADED';

export const SOCKET_SUBSCRIBED = 'SOCKET_SUBSCRIBED';
export const SOCKET_UNSUBSCRIBED = 'SOCKET_UNSUBSCRIBED';
export const SOCKET_SUBSCRIBE_ERROR = 'SOCKET_SUBSCRIBE_ERROR';
export const SOCKET_UNSUBSCRIBE_ERROR = 'SOCKET_UNSUBSCRIBE_ERROR';

export const KIOSK_VISUAL_STATS_LEGEND_LOAD_START = 'KIOSK_VISUAL_STATS_LEGEND_LOAD_START';
export const KIOSK_VISUAL_STATS_LEGEND_LOAD_END = 'KIOSK_VISUAL_STATS_LEGEND_LOAD_END';
export const KIOSK_VISUAL_STATS_LEGEND_GOT_DATA = 'KIOSK_VISUAL_STATS_LEGEND_GOT_DATA';

export const KIOSK_VISUAL_STATS_JOURNEY_LOAD_START = 'KIOSK_VISUAL_STATS_JOURNEY_LOAD_START';
export const KIOSK_VISUAL_STATS_JOURNEY_LOAD_END = 'KIOSK_VISUAL_STATS_JOURNEY_LOAD_END';
export const KIOSK_VISUAL_STATS_JOURNEY_GOT_DATA = 'KIOSK_VISUAL_STATS_JOURNEY_GOT_DATA';

export const KIOSK_VISUAL_STATS_OP_START = 'KIOSK_VISUAL_STATS_OP_START';
export const KIOSK_VISUAL_STATS_OP_END = 'KIOSK_VISUAL_STATS_OP_END';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

export const FLASH_STORAGE_SET = 'FLASH_STORAGE_SET';
export const FLASH_STORAGE_CLEAR = 'FLASH_STORAGE_CLEAR';

export const LOCAL_STORAGE_SET = 'LOCAL_STORAGE_SET';
export const LOCAL_STORAGE_REMOVE = 'LOCAL_STORAGE_REMOVE';
export const LOCAL_STORAGE_READ = 'LOCAL_STORAGE_READ';
export const LOCAL_STORAGE_CLEAR = 'LOCAL_STORAGE_CLEAR';

export const BACKGROUND_JOBS_LOAD_START = 'BACKGROUND_JOBS_LOAD_START';
export const BACKGROUND_JOBS_GOT_DATA = 'BACKGROUND_JOBS_GOT_DATA';
export const BACKGROUND_JOBS_LOAD_END = 'BACKGROUND_JOBS_LOAD_END';
export const BACKGROUND_JOBS_OP_START = 'BACKGROUND_JOBS_OP_START';
export const BACKGROUND_JOBS_OP_END = 'BACKGROUND_JOBS_OP_END';
export const BACKGROUND_JOBS_OP_RESET = 'BACKGROUND_JOBS_OP_RESET';
export const SET_OR_UPDATE_BACKGROUND_JOB = 'SET_OR_UPDATE_BACKGROUND_JOB';
export const DELETE_BACKGROUND_JOB = 'DELETE_BACKGROUND_JOB';

export const GET_APP_SETTINGS_START = 'GET_APP_SETTINGS_START';
export const GET_APP_SETTINGS_END = 'GET_APP_SETTINGS_END';
export const GET_RAW_APP_SETTINGS_START = 'GET_RAW_APP_SETTINGS_START';
export const GET_RAW_APP_SETTINGS_END = 'GET_RAW_APP_SETTINGS_END';
export const GET_APP_SETTINGS_ERROR = 'GET_APP_SETTINGS_ERROR';
export const SET_APP_SETTINGS_START = 'SET_APP_SETTINGS_START';
export const SET_APP_SETTINGS_END = 'SET_APP_SETTINGS_END';
export const SET_APP_SETTINGS_ERROR = 'SET_APP_SETTINGS_ERROR';
export const APP_SETTINGS_SET_LANDING_IMAGE = 'APP_SETTINGS_SET_LANDING_IMAGE';

export const CREATE_BROCHURE = 'CREATE_BROCHURE';

export const CONFIRM_BOX = 'CONFIRM_BOX';

export const DEVICE_GOT_DATA = 'DEVICE_GOT_DATA';
export const DEVICE_MASTER_KEY = 'DEVICE_MASTER_KEY';
export const DEVICE_NEW_DETAIL = 'DEVICE_NEW_DETAIL';
export const AGORITY_DEVICE_OP_START = 'AGORITY_DEVICE_OP_START';
export const AGORITY_DEVICE_OP_END = 'AGORITY_DEVICE_OP_END';
export const AGORITY_DEVICE_OP_RESET = 'AGORITY_DEVICE_OP_RESET';
export const AGORITY_DEVICE_LOAD_START = 'AGORITY_DEVICE_LOAD_START';
export const AGORITY_DEVICE_LOAD_END = 'AGORITY_DEVICE_LOAD_END';
export const DEVICE_OWNER_LIST = 'DEVICE_OWNER_LIST';
export const CUSTOMER_DEVICE_OWNER_LIST = 'CUSTOMER_DEVICE_OWNER_LIST';
export const OPEN_DEVICE_MODAL = 'OPEN_DEVICE_MODAL';
export const CLOSE_DEVICE_MODAL = 'CLOSE_DEVICE_MODAL';

export const AGORITY_AVATARS_OP_START = 'AGORITY_AVATARS_OP_START';
export const AGORITY_AVATARS_OP_END = 'AGORITY_AVATARS_OP_END';
export const AGORITY_AVATARS_OP_RESET = 'AGORITY_AVATARS_OP_RESET';
export const AGORITY_AVATARS_LOAD_START = 'AGORITY_AVATARS_LOAD_START';
export const AGORITY_AVATARS_LOAD_END = 'AGORITY_AVATARS_LOAD_END';
export const AGORITY_AVATARS_GOT_DATA = 'AGORITY_AVATARS_GOT_DATA';

export const AGORITY_OP_START = 'AGORITY_OP_START';
export const AGORITY_OP_END = 'AGORITY_OP_END';
export const AGORITY_OP_RESET = 'AGORITY_OP_RESET';
export const AGORITY_LOAD_START = 'AGORITY_LOAD_START';
export const AGORITY_LOAD_END = 'AGORITY_LOAD_END';
export const AGORITY_SELECTED_DEVICES = 'AGORITY_SELECTED_DEVICES';
export const AGORITY_SELECTED_CONTENT = 'AGORITY_SELECTED_CONTENT';
export const AGORITY_SURVEY_DATA = 'AGORITY_SURVEY_DATA';
export const AGORITY_SURVEY_RESULTS_DATA = 'AGORITY_SURVEY_RESULTS_DATA';
export const OPEN_CLOSE_LIBRARY_MODAL = 'OPEN_CLOSE_LIBRARY_MODAL';

export const CONNECTED_MODAL_ADD = 'CONNECTED_MODAL_ADD';
export const CONNECTED_MODAL_DISMISS = 'CONNECTED_MODAL_DISMISS';

export const VISION_LOAD_START = 'VISION_LOAD_START';
export const VISION_LOAD_END = 'VISION_LOAD_END';
export const VISION_GOT_DATA = 'VISION_GOT_DATA';
export const VISION_OP_START = 'VISION_OP_START';
export const VISION_OP_END = 'VISION_OP_END';
export const VISION_OP_RESET = 'VISION_OP_RESET';
export const VISION_RESET = 'VISION_RESET';
export const VISION_VIEWER_SETTINGS = 'VISION_VIEWER_SETTINGS';
export const VISION_STORE_CAMERA_PLACEMENT = 'VISION_STORE_CAMERA_PLACEMENT';

export const PROPERTY_SCANS_LOAD_START = 'PROPERTY_SCANS_LOAD_START';
export const PROPERTY_SCANS_LOAD_END = 'PROPERTY_SCANS_LOAD_END';
export const PROPERTY_SCANS_GOT_DATA = 'PROPERTY_SCANS_GOT_DATA';
export const PROPERTY_SCANS_OP_START = 'PROPERTY_SCANS_OP_START';
export const PROPERTY_SCANS_OP_END = 'PROPERTY_SCANS_OP_END';
export const PROPERTY_SCANS_OP_RESET = 'PROPERTY_SCANS_OP_RESET';
