import * as api from '@/lib/api';
import * as routes from '@/lib/routes';
import { withType } from '@/lib/util';
import { getUploadEndPoint } from '@/selectors/sites';
import { ConnectedModal } from '@/types/connected_modal';
import { LIBRARY_NAMESPACE, LIBRARY_TYPE } from '@/types/library';
import { Criteria, DETAILED_CRITERIA, SITE_LABELS, SITE_TYPES } from '@/types/sites';
import { Toast, TOAST_TYPES } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast, dismissToast, refresh } from './application';
import { updateJobProgress } from './background_jobs';
import { addConnectedModal } from './connected_modal';
import { createOrUpdateFolder, loadFolder } from './folders';
import { loadAsset } from './vision';

function getStartPayload(fromPage) {
	return {
		invalidateAll: !fromPage,
		paginating: !!fromPage,
	};
}

export const fetchSites =
	(criteria, ensureId = null, withErrorToast = false) =>
	(dispatch, getState, container) => {
		criteria = new Criteria(criteria);
		if (criteria.page_size === undefined) {
			criteria.page_size = 100;
		}

		if (ensureId) {
			criteria.filter_exclude_id = ensureId;
		}

		let promise = api.getSites(container.http, criteria).then(queryResult => {
			// TODO: This pattern keeps repeating. Normalize it on front or back

			const hasEnsuredId =
				!ensureId || (queryResult.data && queryResult.data.some(swd => swd.site.id === ensureId));

			if (hasEnsuredId) {
				return queryResult.data;
			}

			return api.getSite(container.http, ensureId).then(swd => {
				return [swd].concat(queryResult.data);
			});
		});

		if (withErrorToast) {
			promise = promise.catch(error => {
				dispatch(addToast(Toast.error(error, `Failed to lookup sites`)));
			});
		}

		return promise;
	};

export const fetchSite =
	(siteId, withErrorToast = false) =>
	(dispatch, getState, container) => {
		const errorHandler = withErrorToast
			? error => dispatch(addToast(Toast.error(error, `Failed to fetch site ${siteId}-s`)))
			: null;

		// TODO: This should go into backend?
		const promiseQueue = [
			api.getSite(container.http, siteId),
			api.getSiteImages(container.http, siteId),
			api.getMetadata(container.http, siteId),
		];

		return Promise.all(promiseQueue).then(results => {
			const swd = results[0];
			swd.images = results[1];
			swd.metadata = results[2] && results[2].data;
			return swd;
		}, errorHandler);
	};

export const fetchSitesWithDetails =
	(siteIds, criteria, withErrorToast = false) =>
	(dispatch, getState, container) => {
		const errorHandler = withErrorToast
			? error => dispatch(addToast(Toast.error(error, `Failed to fetch sites`)))
			: null;

		// TODO: This should go into backend?
		let promise = siteIds.length
			? siteIds.map(item =>
					api.getSite(container.http, item, criteria).then(queryResult => {
						// TODO: This pattern keeps repeating. Normalize it on front or back
						return queryResult;
					}),
			  )
			: [];

		if (promise.length) {
			promise = Promise.all(promise).then(item => {
				return item;
			}, errorHandler);
		}

		return promise;
	};

export const loadSites =
	(
		criteria,
		fromPage = null,
		params = { invalidateAll: true, namespace: LIBRARY_NAMESPACE.view },
	) =>
	(dispatch, getState, container) => {
		if (typeof criteria !== 'object') {
			criteria = new Criteria({ id: criteria });
		}
		if (typeof params !== 'object') {
			params = { namespace: LIBRARY_NAMESPACE.view };
		}
		const namespace = params.namespace || LIBRARY_NAMESPACE.view;
		const { invalidateAll = true } = params;

		dispatch(
			withType(TYPES.SITES_LOAD_START, {
				...getStartPayload(fromPage),
				invalidateAll,
				namespace,
			}),
		);

		return api.getSites(container.http, criteria, fromPage).then(
			res => {
				dispatch(
					withType(TYPES.SITES_GOT_DATA, {
						data: res.data,
						namespace,
						nextPage: res.next_page,
					}),
				);
				dispatch(withType(TYPES.SITES_LOAD_END, { namespace }));
				return res.data;
			},
			error => {
				dispatch(withType(TYPES.SITES_LOAD_END, { error, namespace }));
			},
		);
	};

export const siteLoaded = swd => (dispatch, getState, container) => {
	dispatch(
		withType(TYPES.SITES_GOT_DATA, {
			data: [swd],
			reset: false,
		}),
	);
	if (swd.tour) {
		dispatch(withType(TYPES.SUB_TOURS_GOT_DATA, { ...swd.tour }));
	}
	if (swd.billboards && swd.billboards.length) {
		dispatch(
			withType(TYPES.TOUR_BILLBOARDS_GOT_DATA, {
				data: swd.billboards,
				reset: false,
			}),
		);
	}
};

export const loadSite =
	(id, invalidate = true, criteria = null, isReturn) =>
	(dispatch, getState, container) => {
		const startPayload = invalidate ? { invalidateId: id } : {};

		dispatch(withType(TYPES.SITES_LOAD_START, startPayload));

		return api.getSite(container.http, id, criteria).then(
			swd => {
				dispatch(siteLoaded(swd));
				dispatch(withType(TYPES.SITES_LOAD_END));
				if (isReturn) {
					return swd;
				}
			},
			error => {
				dispatch(withType(TYPES.SITES_LOAD_END, { error }));
			},
		);
	};

/**
 * Get prepared download for given sites
 * @param {number[]} siteIds
 */
export const prepareSitesFilesDownload = siteIds => (dispatch, getState, container) => {
	const preparingToast = new Toast(
		`Preparing download for sites ${siteIds.join(', ')}. Please wait...`,
		TOAST_TYPES.info,
	);
	return api.prepareSitesFilesDownload(container.http, siteIds).then(
		pd => {
			dispatch(withType(TYPES.SITE_FILES_DOWNLOAD_END, { siteIds }));

			const url = api.preparedDownloadUrl(pd.id);
			window.location.href = url;
			dispatch(dismissToast(preparingToast.id));
			dispatch(addToast(`Your download for sites ${siteIds.join(', ')} will begin shortly`));
		},
		error => {
			dispatch(withType(TYPES.SITE_FILES_DOWNLOAD_END, { siteIds }));
			dispatch(
				addToast(Toast.error(error, `Failed to prepare files download for ${siteIds.join(', ')}`)),
			);
		},
	);
};

/**
 * Get prepared download for given file paths for a particular site
 * @param siteId
 * @param paths
 */
export const prepareSiteFilesDownload =
	(siteId, versionId, paths) => (dispatch, getState, container) => {
		dispatch(withType(TYPES.SITE_FILES_DOWNLOAD_START, { siteId }));

		const preparingToast = new Toast(
			`Preparing download for site ${siteId}. Please wait...`,
			TOAST_TYPES.info,
		);
		dispatch(addToast(preparingToast));
		return api.prepareSiteFilesDownload(container.http, siteId, versionId, paths).then(
			pd => {
				dispatch(withType(TYPES.SITE_FILES_DOWNLOAD_END, { siteId }));

				const url = api.preparedDownloadUrl(pd.id);
				window.location.href = url;
				dispatch(dismissToast(preparingToast.id));
				dispatch(addToast(`Your download for site ${siteId} will begin shortly`));
			},
			error => {
				dispatch(withType(TYPES.SITE_FILES_DOWNLOAD_END, { siteId }));
				dispatch(addToast(Toast.error(error, `Failed to prepare files download for ${siteId}`)));
			},
		);
	};

export const updateSiteBrochure = (siteId, payload) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_OP_START));

	const opError = error => {
		dispatch(withType(TYPES.SITES_OP_END, { error }));
	};

	const promise = api.updateSite(container.http, siteId, payload);

	return promise.then(res => {
		const resolvedSiteId = res.record.id;
		if (!resolvedSiteId) {
			return dispatch(
				withType(TYPES.SITES_OP_END, {
					error: new Error(`Operation has failed on the server`),
				}),
			);
		}

		const opCompleted = () => {
			dispatch(withType(TYPES.SITES_OP_END, { invalidateId: false }));
			dispatch(loadSite(resolvedSiteId, false));

			dispatch(addToast(new Toast('Brochure info was changed!')));
		};

		return opCompleted();
	}, opError);
};

export const createOrUpdateSite =
	(siteId, payload, images = undefined, isSubtour = false) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const promise = siteId
			? api.updateSite(container.http, siteId, payload)
			: api.createSite(container.http, payload);

		const opError = error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
		};

		return promise.then(res => {
			const resolvedSiteId = res.record.id;
			if (!resolvedSiteId) {
				return dispatch(
					withType(TYPES.SITES_OP_END, {
						error: new Error(`Operation has failed on the server`),
					}),
				);
			}

			const opCompleted = () => {
				return api.getSite(container.http, resolvedSiteId, DETAILED_CRITERIA).then(swd => {
					dispatch(siteLoaded(swd));
					dispatch(withType(TYPES.SITES_OP_END));
					// When creating a new site with uploader, go to the files tab
					const redirectUrl =
						!siteId && payload.type !== SITE_TYPES.image
							? routes.siteFiles(resolvedSiteId)
							: routes.siteOverview(resolvedSiteId);

					!siteId && !isSubtour && container.history.push(redirectUrl);

					dispatch(addToast(new Toast('Your changes were saved!')));
				});
			};

			if (!images) {
				return opCompleted();
			}

			return api
				.updateSiteImages(container.http, resolvedSiteId, images)
				.then(opCompleted, opError);
		}, opError);
	};

export const cloneSite =
	(siteId, additionalPayload = null, toFolderId = null) =>
	(dispatch, getState, { history, http }) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const finalizeCloning = () => {
			// Handle socket subscription
			const payload = {
				...additionalPayload,
				siteId,
			};

			return api.cloneSite(http, siteId, payload).then(
				res => {
					dispatch(withType(TYPES.SITES_OP_END));
					dispatch(updateJobProgress(res));
					dispatch(refresh());
				},
				error => {
					dispatch(refresh());
					dispatch(withType(TYPES.SITES_OP_END, { error }));
				},
			);
		};

		function modalCallback(folderId, name) {
			additionalPayload = {
				folder_id: folderId,
				name,
			};
			finalizeCloning();
		}

		if (!additionalPayload) {
			const modalProps = {
				folderId: toFolderId,
				onSubmit: modalCallback,
			};

			const modal = ConnectedModal.clone_site(modalProps);
			dispatch(addConnectedModal(modal));
		} else {
			additionalPayload = { ...additionalPayload };
			finalizeCloning();
		}
	};

export const deleteSite =
	(siteId, name, folderId) =>
	(dispatch, getState, { history, http }) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const finalizeDelete = () => {
			return api.deleteSite(http, siteId).then(
				res => {
					if (!res.deleted) {
						return dispatch(
							withType(TYPES.SITES_OP_END, {
								error: new Error(`Delete operation has failed on the server`),
							}),
						);
					}

					dispatch(withType(TYPES.SITES_OP_END, { invalidateId: siteId }));
					const siteType = res.record && (res.record.type || res.record.media_format);
					const sucessMessage = SITE_LABELS[siteType]
						? `${SITE_LABELS[siteType]} ${siteId} was successfully deleted`
						: 'Successfully deleted';

					if (folderId) {
						history.push(routes.folderView(folderId, routes.LIBRARY_FOLDERS));
					}

					dispatch(addToast(new Toast(sucessMessage)));
					return res;
				},
				error => {
					dispatch(withType(TYPES.SITES_OP_END, { error }));
				},
			);
		};

		const modalProps = {
			content: `Are you sure you want to delete ${name || 'this file'}?`,
			onSubmit: finalizeDelete,
		};

		const modal = ConnectedModal.delete_site(modalProps);
		dispatch(addConnectedModal(modal));
	};

export const moveSites =
	(folderId, parentId, payload, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const { namespace } = params;
		const opError = error => {
			dispatch(withType(TYPES.SITES_OP_END, { error, namespace }));
		};

		return api.moveSites(container.http, folderId, payload).then(res => {
			if (res.error) {
				return dispatch(
					withType(TYPES.SITES_OP_END, {
						error: new Error(`Operation has failed on the server`),
						namespace,
					}),
				);
			}

			const opCompleted = () => {
				dispatch(withType(TYPES.SITES_OP_END, { namespace }));
				dispatch(addToast(new Toast('Sites successfully moved!')));
				dispatch(refresh());
			};

			return opCompleted();
		}, opError);
	};

export const deleteSites =
	(payload, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const { namespace } = params;
		const opError = error => {
			dispatch(withType(TYPES.SITES_OP_END, { error, namespace }));
		};

		return api.deleteSites(container.http, payload).then(res => {
			if (res.error) {
				return dispatch(
					withType(TYPES.SITES_OP_END, {
						error: new Error(`Operation has failed on the server`),
						namespace,
					}),
				);
			}

			const opCompleted = () => {
				dispatch(withType(TYPES.SITES_OP_END, { namespace }));
				dispatch(addToast(new Toast('Sites successfully deleted!')));
			};

			return opCompleted();
		}, opError);
	};

export const loadSiteFiles = (siteId, versionId, subPath) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.FILE_EXPLORER_LOAD_START, { siteId }));
	const identifier = getUploadEndPoint(siteId, versionId); // TODO to api
	return api.getSiteFiles(container.http, siteId, versionId, subPath).then(
		res => {
			dispatch(
				withType(TYPES.FILE_EXPLORER_GOT_DATA, {
					data: res,
					identifier,
					reset: true,
					subPath,
					versionId,
				}),
			);
			dispatch(withType(TYPES.FILE_EXPLORER_LOAD_END));
		},
		error => {
			dispatch(withType(TYPES.FILE_EXPLORER_LOAD_END, { error }));
		},
	);
};

export const publishVersion = versionId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_OP_START));
	return api.setSiteVersionAsProduction(container.http, versionId).then(
		res => {
			dispatch(withType(TYPES.SITES_OP_END));
			return res;
		},
		error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
		},
	);
};

export const newVersion =
	(siteId, payload, setAsStatsVersion = false) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_VERSIONS_RESET, { siteId }));
		dispatch(withType(TYPES.SITES_LOAD_START));
		return api
			.newSiteVersion(container.http, siteId, payload)
			.then(res => {
				if (!setAsStatsVersion) {
					return res;
				}

				const versionId = res.record.id;
				if (!versionId) {
					throw new Error(`Missing version id for the newly created version (${res})`);
				}

				return api.setSiteVersionAsStats(container.http, versionId).then(() => {
					// Reload site because we changed stats_version_id
					// TODO: Why just not reload the entire page, so much cleaner! Investigate.
					dispatch(loadSite(siteId, true));
					return res;
				});
			})
			.then(
				res => {
					dispatch(withType(TYPES.SITES_LOAD_END));
					dispatch(loadSiteVersions(siteId));
					return res;
				},
				error => {
					dispatch(withType(TYPES.SITES_LOAD_END, { error }));
				},
			);
	};

export const cloneVersion = (siteId, versionId, payload) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_VERSIONS_RESET, { siteId }));
	dispatch(withType(TYPES.SITES_LOAD_START));
	return api.cloneSiteVersion(container.http, siteId, versionId, payload).then(
		res => {
			dispatch(withType(TYPES.SITES_LOAD_END));
			dispatch(loadSiteVersions(siteId));
			return res;
		},
		error => {
			dispatch(withType(TYPES.SITES_LOAD_END, { error }));
		},
	);
};

export const updateVersion = (versionId, payload) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_LOAD_START));
	return api.updateSiteVersion(container.http, versionId, payload).then(
		res => {
			dispatch(withType(TYPES.SITES_LOAD_END));
			dispatch(addToast(new Toast('Site version successfully updated!')));
			return res;
		},
		error => {
			return dispatch(addToast(new Toast(error, TOAST_TYPES.error)));
		},
	);
};

export const loadSiteVersions = siteId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_LOAD_START));

	if (getState().sites.siteVersions.siteId !== siteId) {
		dispatch(withType(TYPES.SITES_VERSIONS_RESET, { siteId }));
	}

	return api.getSiteVersions(container.http, siteId).then(
		data => {
			dispatch(
				withType(TYPES.SITES_VERSIONS_GOT_DATA, {
					data,
					siteId,
				}),
			);
			dispatch(withType(TYPES.SITES_LOAD_END));
		},
		error => {
			dispatch(withType(TYPES.SITES_LOAD_END, { error }));
		},
	);
};

export const deleteVersion = (siteId, versionId) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_VERSIONS_RESET, { siteId }));
	dispatch(withType(TYPES.SITES_LOAD_START));

	return api.deleteSiteVersion(container.http, siteId, versionId).then(
		res => {
			// Reload site because we changed stats_version_id
			// TODO: Why just not reload the entire page, so much cleaner! Investigate.
			dispatch(withType(TYPES.SITES_LOAD_END));
			dispatch(loadSiteVersions(siteId));

			return res;
		},
		error => {
			dispatch(withType(TYPES.SITES_LOAD_END, { error }));
		},
	);
};

export const parseVersion = (siteId, versionId) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_OP_START));

	return api.parseSiteVersion(container.http, siteId, versionId).then(
		res => {
			dispatch(withType(TYPES.SITES_OP_END));
			return res;
		},
		error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
		},
	);
};

export const importBillboards = (siteId, versionId, subPath) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_OP_START));

	return api.importBillboards(container.http, siteId, versionId, subPath).then(
		res => {
			dispatch(withType(TYPES.SITES_OP_END));
			return res;
		},
		error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
		},
	);
};

export const resetOpError = () => {
	return withType(TYPES.SITES_OP_RESET);
};

export const deleteBrochure = (siteId, brochureId) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SITES_OP_START));

	const promise = api.deleteBrochure(container.http, siteId, brochureId);

	const criteria = new Criteria(DETAILED_CRITERIA);

	criteria.id = siteId;

	const opError = error => {
		dispatch(withType(TYPES.SITES_OP_END, { error }));
	};

	return promise.then(res => {
		const { deleted } = res;
		if (!deleted) {
			return dispatch(
				withType(TYPES.SITES_OP_END, {
					error: new Error(`Operation has failed on the server`),
				}),
			);
		}
		dispatch(loadSite(siteId, null, criteria));
		dispatch(addToast(new Toast('Brochure successfully deleted!')));
		dispatch(refresh());
	}, opError);
};

export const moveSite =
	(id, type, folderId, params) =>
	(dispatch, getState, { history, http }) => {
		const finalizeMoving = (toId, name) => {
			const payloadKey = type === LIBRARY_TYPE.FOLDER ? 'parent_id' : 'folder_id';
			const payload = {
				name,
				[payloadKey]: toId,
			};

			return type === LIBRARY_TYPE.FOLDER
				? dispatch(createOrUpdateFolder(id, payload))
				: dispatch(createOrUpdateSite(id, payload));
		};

		function modalCallback(folderId, name) {
			return finalizeMoving(folderId, name).then(() => {
				const refreshMethod =
					type === LIBRARY_TYPE.FOLDER
						? loadFolder(folderId, {}, params || { silent: true })
						: loadSite(id, false);
				return dispatch(refreshMethod);
			});
		}

		const modalProps = {
			displayFileNameEditor: !id,
			displayLibrary: !!id,
			folderId,
			onSubmit: modalCallback,
		};

		const modal = id ? ConnectedModal.move_site(modalProps) : ConnectedModal.save(modalProps);
		dispatch(addConnectedModal(modal));
	};

export const rename =
	(id, name, type, folderId) =>
	(dispatch, getState, { history, http }) => {
		const finalize = (toId, name) => {
			const payloadKey = type === LIBRARY_TYPE.FOLDER ? 'parent_id' : 'folder_id';
			const payload = {
				name,
				[payloadKey]: toId,
			};
			if (folderId === toId && type === LIBRARY_TYPE.FOLDER) {
				delete payload[payloadKey];
			}
			return type === LIBRARY_TYPE.FOLDER
				? dispatch(createOrUpdateFolder(id, payload))
				: dispatch(createOrUpdateSite(id, payload));
		};

		function modalCallback(folderId, name) {
			return finalize(folderId, name).then(() => {
				const refreshMethod =
					type === LIBRARY_TYPE.FOLDER ? loadFolder(id, {}, { silent: true }) : loadSite(id, false);
				return dispatch(refreshMethod);
			});
		}

		const modalProps = {
			displayFileNameEditor: true,
			displayLibrary: false,
			fileName: name,
			folderId,
			onSubmit: modalCallback,
			submitLabel: `Save`,
			title: `Rename ${name}`,
		};

		const modal = ConnectedModal.move_site(modalProps);
		dispatch(addConnectedModal(modal));
	};

export const reset =
	namespace =>
	(dispatch, getState, { history, http }) => {
		dispatch(withType(TYPES.DATA_RESET_ALL, { namespace }));
	};

// Tagging related stuff

export const createOrUpdateTag =
	(payload, assetId = null) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const promise = !payload.id
			? api.createTag(container.http, payload)
			: api.editTag(container.http, payload);

		const opError = error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
			throw error;
		};

		return promise.then(res => {
			dispatch(withType(TYPES.SITES_OP_END));
			if (assetId) {
				dispatch(loadAsset(assetId));
			}
			return res;
		}, opError);
	};

export const deleteTag =
	(payload, assetId = null) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.SITES_OP_START));

		const promise = api.deleteTag(container.http, payload);

		const opError = error => {
			dispatch(withType(TYPES.SITES_OP_END, { error }));
			throw error;
		};

		return promise.then(res => {
			const opCompleted = () => {
				const swd = res;
				dispatch(withType(TYPES.SITES_OP_END));

				if (assetId) {
					dispatch(loadAsset(assetId));
				}

				return swd;
			};

			return opCompleted();
		}, opError);
	};
