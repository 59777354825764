import { Component } from 'react';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import FA from '@/types/font_awesome';
import { LIBRARY_TYPE } from '@/types/library';
import Folder from '@/views/library/Folders/Folder';
import GridView from '@/views/sites/GridView';
import TablePagination from '@/views/widgets/tables/TablePagination';

import File from './File';

import './Files.scss';

const CLASS = 'sv-Files';
const HEADER_CLASS = `${CLASS}-header`;

class Files extends Component {
	render() {
		const {
			createHref,
			criteria,
			itemClassName,
			items,
			nextPage,
			onClick,
			onContextMenu,
			onDoubleClick,
			onLoadMore,
			onSelection,
			renderItem,
			sectionName,
			selectable,
			selectedItems,
			selecting,
		} = this.props;

		const headerClosed = (
			<div className={classNames(HEADER_CLASS, `${HEADER_CLASS}-acord`)}>
				<div className={`${CLASS}-name`}>{sectionName}</div>
				<div className={`${HEADER_CLASS}-trigger`}>
					<FontAwesomeIcon icon={FA.chevron_right} />
				</div>
			</div>
		);

		const headerOpen = (
			<div className={HEADER_CLASS}>
				<div className={`${CLASS}-name`}>{sectionName}</div>
				<div className={`${HEADER_CLASS}-trigger`}>
					<FontAwesomeIcon icon={FA.chevron_down} />
				</div>
			</div>
		);

		return (
			<div className={CLASS}>
				<Collapsible
					open
					className={`${CLASS}-acord`}
					trigger={headerClosed}
					triggerWhenOpen={headerOpen}
				>
					<GridView
						className={`${CLASS}-grid`}
						createHref={createHref}
						items={items}
						selectable={selectable}
						selectedItems={selectedItems}
						selecting={selecting}
						onItemClick={onClick}
						onItemContextMenu={onContextMenu}
						onItemDoubleClick={onDoubleClick}
						onSelection={onSelection}
					>
						{items.map((item, index) => {
							const isFolder = !item.site;
							const itemType = isFolder ? LIBRARY_TYPE.FOLDER : LIBRARY_TYPE.FILE;
							const record = isFolder ? item.folder : item.site;
							const isSelected = selectedItems && selectedItems.includes(item.key);

							const className = classNames(
								item.disabled ? `${itemClassName}-disabled` : itemClassName,
							);
							const Component = isFolder ? Folder : File;

							const childProps = {
								className,
								createHref,
								criteria,
								disabled: item.disabled,
								id: record.id,
								index,
								isSelected,
								item,
								record,
								type: itemType,
							};

							if (typeof renderItem === 'function') {
								return renderItem(childProps);
							}

							return <Component key={item.key} {...childProps} />;
						})}
					</GridView>
					<TablePagination nextPage={nextPage} onLoadMore={onLoadMore} />
				</Collapsible>
			</div>
		);
	}
}

export default Files;
