import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';
import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';

import './LargeErrorBox.scss';

const { NODE_ENV } = process.env;

function ErrorStack({ stack }) {
	const lines = Array.isArray(stack) ? stack : String(stack).split('\n');
	return (
		<div className="center-block text-left">
			<ul style={{ listStyle: 'none' }}>
				{lines.map((line, i) => {
					return <li key={i}>{line}</li>;
				})}
			</ul>
		</div>
	);
}

function LargeErrorBox({ error, message, onDismiss }) {
	const errorStatus = error && error.status ? <h4>Error {error.status}</h4> : null;

	const errorMessage = error && error.message ? <p>{error.message}</p> : null;
	let stack;
	if (error) {
		stack = error.info
			? [error.info.stack, error.info.componentStack].filter(Boolean).join('\n')
			: error.stack;
	}
	const errorStack = stack ? <ErrorStack stack={stack} /> : null;

	let dismissButton = null;
	if (onDismiss) {
		dismissButton = (
			<IconButton
				className="sv-LargeErrorBox-dismiss"
				icon={FA.times}
				size="sm"
				variant="danger"
				onClick={onDismiss}
			/>
		);
	}

	return (
		<div className={classes('sv-LargeErrorBox alert alert-danger')}>
			{dismissButton}
			<div className="sv-LargeErrorBox-content">
				<FontAwesomeIcon icon={FA.exclamation_triangle} />
				<h3>{message}</h3>
				{errorStatus}
				{errorMessage}
				{NODE_ENV === 'development' ? errorStack : null}
			</div>
		</div>
	);
}

LargeErrorBox.propTypes = {
	error: PropTypes.object,
	message: PropTypes.string,
	onDismiss: PropTypes.func,
};

export default LargeErrorBox;
