import { siteOverview } from '@/lib/routes';
import { withType } from '@/lib/util';
import {
	BACKGROUND_JOB_TYPE_ICON,
	BACKGROUND_JOB_TYPES,
	RunningJob,
} from '@/types/background_jobs';
import { SITE_LABELS } from '@/types/sites';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast } from './application';
import { gotAssets } from './file_explorer';
import { unsubscribe } from './sockets';

/** @typedef {import('flowjs').Flow & {onProgress: () => any}} Flow */

export const setOrUpdateUploadJob =
	(data, endpoint = null, percent = null) =>
	(dispatch, getState, container) => {
		const { backgroundJobs, uploads } = getState();
		const uploadTarget = uploads && uploads[endpoint];
		const files = uploadTarget ? Object.values(uploadTarget.files) : [];

		if (!data) {
			data = {};
		}
		if (!percent) {
			percent = data.progress;
		}

		let { id, job_title, location, room, ...rest } = data;
		job_title = job_title || id;
		const job_type = BACKGROUND_JOB_TYPES.upload;
		const icon = BACKGROUND_JOB_TYPE_ICON[job_type];
		if (data.record && SITE_LABELS[data.record.type]) {
			location = siteOverview(data.record.id);
			job_title = `${SITE_LABELS[data.record.type]} | ${data.record.name}`;
		}
		if (!job_title && files.length) {
			job_title = files[0].name;
		}
		if (!job_title && backgroundJobs.data && backgroundJobs.data[endpoint]) {
			const job = backgroundJobs.data[endpoint];
			data = Object.assign(data, job);
			job_title = data.job_title;
		}

		const runningJob = new RunningJob({
			_id: endpoint,
			...rest,
			data: {
				...data,
				icon,
				job_title,
				job_type,
				location,
				percent,
			},
		}).clean();

		dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job: runningJob }));
	};

/**
 * @param {string} endpoint
 * @param {Flow} resumableField
 */
export const newResumableField =
	(endpoint, resumableField, payload = null) =>
	(dispatch, getState, container) => {
		resumableField.onProgress = () =>
			dispatch(setOrUpdateUploadJob(payload, endpoint, resumableField.progress()));
		resumableField.on('progress', resumableField.onProgress);

		dispatch(
			withType(TYPES.UPLOADS_RESUMABLE_FIELD, {
				key: endpoint,
				resumableField,
			}),
		);
	};

export const addFileUpload = (endpoint, file) => (dispatch, getState, container) => {
	return dispatch(
		withType(TYPES.UPLOADS_FILE_ADD, {
			file,
			key: endpoint,
		}),
	);
};

export const removeFileUpload = (endpoint, file) => (dispatch, getState, container) => {
	dispatch(
		withType(TYPES.UPLOADS_FILE_REMOVE, {
			identifier: file.uniqueIdentifier || file,
			key: endpoint,
		}),
	);

	const { uploads } = getState();
	const uploadTarget = uploads && uploads[endpoint];

	if (!uploadTarget || Object.keys(uploadTarget.files).length === 0) {
		dispatch(finalizeJob(endpoint));
	}
};

export const endUpload =
	(endpoint, wasEmpty = false) =>
	(dispatch, getState, container) => {
		dispatch(
			withType(TYPES.UPLOADS_END, {
				key: endpoint,
			}),
		);
		!wasEmpty && dispatch(finalizeJob(endpoint));
	};

export const finalizeJob = (endpoint, data) => dispatch =>
	dispatch(setOrUpdateUploadJob(data, endpoint, 1));

export const assetStatus = event => (dispatch, getState, container) => {
	const { uploads } = getState();
	const { endpoint, identifier, status, ...data } = event.data;
	const uploadTarget = uploads && uploads[endpoint];

	if (!uploadTarget) {
		dispatch(finalizeJob(endpoint, data));
		return;
	}

	const { files, resumableField } = uploadTarget;
	const file = files[identifier];
	let progress;

	if (file) {
		file.status = status;
	}
	if (resumableField) {
		resumableField.fire('progress');
		progress = resumableField.progress();
	}
	setOrUpdateUploadJob(data, endpoint, progress);
};

export const assetProcessed = event => (dispatch, getState, container) => {
	const { fileExplorer, uploads } = getState();
	const { endpoint, error, files, identifier, room, status, subPath, ...rest } = event.data;

	const uploadTarget = uploads && uploads[endpoint];

	if (fileExplorer && fileExplorer.identifier === endpoint) {
		for (const file in fileExplorer.files) {
			for (const uFile in files) {
				if (fileExplorer.files[file].name === files[uFile].name) {
					dispatch(addToast(new Toast(`${files[uFile].name} has been overwritten!`)));
				}
			}
		}
		dispatch(gotAssets(endpoint, subPath, files));
	}

	if (!uploadTarget || !uploadTarget.files[identifier]) {
		dispatch(finalizeJob(endpoint, rest));
		return;
	}

	const { resumableField } = uploadTarget;
	const file = uploadTarget.files[identifier];
	file.status = null;

	if (!resumableField) {
		dispatch(finalizeJob(endpoint, rest));
		return;
	}

	if (error) {
		resumableField.fire('fileError', file, error);
	} else {
		resumableField.fire('fileSuccess', file);
	}

	if (!resumableField.files.length) {
		if (resumableField.isProcessing) {
			dispatch(finalizeJob(endpoint, rest));
		}
		resumableField.isProcessing = false;
		resumableField.fire('complete');

		dispatch(unsubscribe(room));
	}
};
