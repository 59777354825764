import * as L from 'leaflet';

import 'leaflet-layerindex';
import 'leaflet.markercluster';

// import PolylineOffset from './polylineoffset';

// import {GLPoints} from './glify';

/**
 * Add simple iterators to LatLng and LatLngBounds, so we can use
 * [...latLng] = [0.2697826027870178, 4.644009579198617]
 * [...bounds] = [[0.2697826027870178, 4.644009579198617], [0.2697826027870178, 4.644009579198617]]
 */

if (!L.LatLng.prototype[Symbol.iterator]) {
	L.LatLng.prototype[Symbol.iterator] = function* () {
		yield this.lat;
		yield this.lng;
	};
}

if (!L.LatLngBounds.prototype[Symbol.iterator]) {
	L.LatLngBounds.prototype[Symbol.iterator] = function* () {
		yield [...this.getSouthWest()];
		yield [...this.getNorthEast()];
	};
}

L.Map.include(L.LayerIndexMixin);

L.Map.include({
	getMetersPerPixel() {
		const map = this;
		const centerLatLng = map.getCenter(); // get map center
		const pointC = map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
		const pointX = L.point(pointC.x + 10, pointC.y); // add 10 pixels to x

		// convert containerpoints to latlng's
		const latLngX = map.containerPointToLatLng(pointX);
		return centerLatLng.distanceTo(latLngX) / 10; // calculate distance between c and x (latitude)
	},
});
