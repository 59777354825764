import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	propertyScans: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
	},
};

const helper = new ReduceHelper('propertyScans', 'id', record => {
	record.floor_count = record.floors?.length;
	record.station_count = record.floors?.reduce(
		(prev, current) => prev + (current.stations?.length ?? 0),
		0,
	);
	record.target_count = record.floors?.reduce(
		(prev, current) => prev + (current.targets?.length ?? 0),
		0,
	);
	return record;
});

export default {
	INITIAL_STATE,
	[TYPES.PROPERTY_SCANS_GOT_DATA]: helper.gotData,
	[TYPES.PROPERTY_SCANS_LOAD_START]: helper.loadStart,
	[TYPES.PROPERTY_SCANS_LOAD_END]: helper.loadEnd,
	[TYPES.PROPERTY_SCANS_OP_START]: helper.opStart,
	[TYPES.PROPERTY_SCANS_OP_END]: helper.opEnd,
	[TYPES.PROPERTY_SCANS_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
	[TYPES.REFRESHING]: helper.reset,
};
