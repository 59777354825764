import { withType } from '@/lib/util';

import * as TYPES from './action_types';

export const openLibraryContextMenu = (contextMenuType, contextMenuProps) =>
	withType(TYPES.OPEN_LIBRARY_CONTEXT_MENU, {
		contextMenuProps,
		contextMenuType,
	});

export const closeLibraryContextMenu = () => withType(TYPES.CLOSE_LIBRARY_CONTEXT_MENU);
