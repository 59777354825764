import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from './Breadcrumb';

import './Breadcrumbs.scss';

/**
 * @typedef {Object} BreadcrumbsProps
 * @property {any[]} [breadcrumbs]
 * @property {string} [linkPath]
 * @property {string} [separator]
 * @property {boolean} [includeRoot]
 * @property {boolean} [interactiveTail]
 * @property {string} [rootPathName]
 * @property {any} [onClick]
 */

/**
 * @param {BreadcrumbsProps} props
 */
function Breadcrumbs(props) {
	let { breadcrumbs, includeRoot, interactiveTail, linkPath, rootPathName, separator } = props;

	linkPath = linkPath || 'path';
	const separateBy = <span className="separator">{separator || '>'}</span>;
	const rootBreadcrumb = {
		key: 'root',
		[linkPath]: null,
		name: rootPathName,
	};

	if (typeof breadcrumbs === 'string') {
		breadcrumbs = [{ name: breadcrumbs }];
	}
	[...breadcrumbs] = breadcrumbs ? Object.values(breadcrumbs) : [];

	includeRoot && breadcrumbs.unshift(rootBreadcrumb);

	const tail = breadcrumbs.pop();
	if (interactiveTail !== false && tail) {
		interactiveTail = interactiveTail || tail.linkPath || tail.pathPrefix || tail.onClick;
	}

	if (interactiveTail) {
		breadcrumbs.push(tail);
	}

	const items = breadcrumbs.map(breadcrumb => {
		let { id, key } = breadcrumb;
		if (id) {
			key = id;
		}
		return (
			<Fragment key={key}>
				<Breadcrumb {...props} breadcrumb={breadcrumb} />
				{breadcrumb !== tail ? separateBy : null}
			</Fragment>
		);
	});
	return (
		<div className="sv-Breadcrumbs">
			{items}
			{!interactiveTail && tail ? <span>{tail.name}</span> : null}
		</div>
	);
}

Breadcrumbs.propTypes = {
	breadcrumbs: PropTypes.array,
	component: PropTypes.element,
	includeRoot: PropTypes.bool,
	interactiveTail: PropTypes.bool,
	linkPath: PropTypes.string,
	pathPrefix: PropTypes.string,
	rootPathName: PropTypes.string,
	separator: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
};

export default Breadcrumbs;
