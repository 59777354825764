import PropTypes from 'prop-types';

import useHistory from '@/hooks/useHistory';
import * as routes from '@/lib/routes';
import { companyLabel } from '@/selectors/companies';
import Highlighted from '@/views/widgets/presentation/Highlighted';

function CompanyLink({ company, highlight }) {
	const history = useHistory();

	if (!company) {
		return <span className="sv-CompanyLink text-muted">N/A</span>;
	}

	let content = companyLabel(company);
	if (highlight) {
		content = <Highlighted match={highlight}>{content}</Highlighted>;
	}

	return (
		<a
			className="sv-CompanyLink"
			href={history.createHref({ pathname: routes.companyDetails(company.id) })}
			title={companyLabel(company)}
		>
			{content}
		</a>
	);
}

CompanyLink.propTypes = {
	company: PropTypes.object,
	highlight: PropTypes.string,
};

export default CompanyLink;
