import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = {
	libraryContextMenu: {
		contextMenuProps: null,
		contextMenuType: null,
		isOpen: false,
	},
};

const openLibraryContextMenu = (state, { contextMenuProps, contextMenuType }) => {
	return {
		...state,
		libraryContextMenu: {
			contextMenuProps,
			contextMenuType,
			isOpen: true,
		},
	};
};

const closeLibraryContextMenu = state => {
	if (!state.libraryContextMenu || !state.libraryContextMenu.contextMenuType) {
		return state;
	}
	return {
		...state,
		libraryContextMenu: {
			contextMenuProps: null,
			contextMenuType: null,
			isOpen: false,
		},
	};
};

export default {
	INITIAL_STATE,
	[TYPES.OPEN_LIBRARY_CONTEXT_MENU]: openLibraryContextMenu,
	[TYPES.CLOSE_LIBRARY_CONTEXT_MENU]: closeLibraryContextMenu,
	[TYPES.DATA_RESET_ALL]: closeLibraryContextMenu,
	[TYPES.OP_RESET_ALL]: closeLibraryContextMenu,
};
