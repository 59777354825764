import * as TYPES from '@/actions/action_types';
import { LIBRARY_NAMESPACE, LIBRARY_VIEW_SORT_TYPES, LIBRARY_VIEW_TYPES } from '@/types/library';

import ReduceHelper from './reduce_helper';

const NAMESPACE = 'folders';
const MODAL_NAMESPACE = `library_modal`;
const FOLDERS_DATA = 'data';
const BREADCRUMBS_DATA = 'breadcrumbs';

const INITIAL_STATE = {
	[MODAL_NAMESPACE]: {
		[BREADCRUMBS_DATA]: {},
		[FOLDERS_DATA]: null,
		// site or folder
		error: null,

		folderId: null,
		hideDisabledContent: LIBRARY_VIEW_SORT_TYPES.sorted,
		libraryView: LIBRARY_VIEW_TYPES.list,
		loadError: null,
		loading: null,
		nextPage: null,
		op: null,
		targetItem: null,
	},
	[NAMESPACE]: {
		[BREADCRUMBS_DATA]: {},
		[FOLDERS_DATA]: null,
		error: null,
		hideDisabledContent: LIBRARY_VIEW_SORT_TYPES.sorted,
		libraryView: LIBRARY_VIEW_TYPES.list,
		loadError: null,
		loading: null,
		nextPage: null,
		op: null,
	},
};

const mapper = fwd => {
	if (fwd && fwd.folder && typeof fwd.folder.metadata === 'string') {
		try {
			fwd.folder.metadata = JSON.parse(fwd.folder.metadata);
		} catch (e) {
			console.error(e); // eslint-disable-line no-console
		}
	}
	return fwd;
};

const libraryHelper = new ReduceHelper(NAMESPACE, 'folder.id', mapper);
const modalHelper = new ReduceHelper(MODAL_NAMESPACE, 'folder.id', mapper);
const libraryBreadcrumbsHelper = new ReduceHelper(NAMESPACE, {
	dataPath: BREADCRUMBS_DATA,
	idPath: 'folder.id',
});
const modalBreadcrumbsHelper = new ReduceHelper(MODAL_NAMESPACE, {
	dataPath: BREADCRUMBS_DATA,
	idPath: 'folder.id',
});

const helpers = {
	[LIBRARY_NAMESPACE.view]: [libraryBreadcrumbsHelper, libraryHelper],
	[LIBRARY_NAMESPACE.modal]: [modalBreadcrumbsHelper, modalHelper],
};

const resetHandlers = [
	libraryHelper,
	libraryBreadcrumbsHelper,
	modalHelper,
	modalBreadcrumbsHelper,
].map(helper => nameSpaceHandler('reset', helper));

export default {
	INITIAL_STATE,

	[TYPES.FOLDERS_GOT_DATA]: nameSpaceHandler('gotData'),
	[TYPES.FOLDERS_LOAD_START]: nameSpaceHandler('loadStart'),
	[TYPES.FOLDERS_LOAD_END]: nameSpaceHandler('loadEnd'),
	[TYPES.FOLDERS_OP_START]: nameSpaceHandler('opStart'),
	[TYPES.FOLDERS_OP_END]: nameSpaceHandler('opEnd'),
	[TYPES.FOLDERS_OP_RESET]: nameSpaceHandler('opReset'),
	[TYPES.OP_RESET_ALL]: [
		libraryHelper,
		libraryBreadcrumbsHelper,
		modalHelper,
		modalBreadcrumbsHelper,
	].map(helper => nameSpaceHandler('opReset', helper)),

	[TYPES.DELETE_FOLDER_SUCCESS]: deleteFolder,
	[TYPES.CLEAR_FOLDER_CONTENT]: clearFolderContent,
	[TYPES.TOGGLE_LIBRARY_VIEW]: nameSpaceHandler('updateNamespace'),
	[TYPES.TOGGLE_LIBRARY_SORT_VIEW]: nameSpaceHandler('updateNamespace'),
	// *: Universal state reset action, for eg. cleaning up after leaving a site section
	[TYPES.DATA_RESET_ALL]: resetHandlers,
	[TYPES.SWITCH_COMPANY_END]: resetHandlers,
};

const invalidate = (state, action, helper = libraryHelper) => {
	return helper.withInvalidation(state, action);
};
const _gotData = (state, action, helper = libraryHelper) => {
	return helper.gotData(invalidate(state, action, helper), action);
};
const updateNamespace = (state, action, helper = libraryHelper) => {
	return helper.updateNamespace(invalidate(state, action, helper), action);
};

/** @typedef {ReduceHelper<"folders"|"library_modal", string, string, any>} HelperType */
/**
 * @param {string} method
 * @param {HelperType|HelperType[]} useHelper
 */
function nameSpaceHandler(method, useHelper = libraryHelper) {
	return (state, action) => {
		const helper = action.namespace ? helpers[action.namespace] : useHelper;
		if (!helper) {
			return state;
		}
		if (Array.isArray(helper)) {
			return helper.reduce((state, helper) => helper[method](state, action), state);
		}
		return helper[method](state, action);
	};
}

function deleteFolder(state, { folderId }) {
	return _gotData(state, { data: [], invalidateId: folderId });
}

function clearFolderContent(state, action) {
	const { namespace = NAMESPACE } = action || {};
	state = _gotData(state, INITIAL_STATE);
	return updateNamespace(state, {
		...INITIAL_STATE[namespace],
		libraryView: state[namespace].libraryView,
	});
}
