import PropTypes from 'prop-types';

function BreadcrumbCustom({ Component, breadcrumb, linkPath, onClick, pathPrefix, ...props }) {
	const handleClick = e => {
		onClick && onClick(e, breadcrumb.id);
	};
	return (
		<Component
			breadcrumb={breadcrumb}
			linkPath={linkPath}
			pathPrefix={pathPrefix}
			onClick={handleClick}
		/>
	);
}

BreadcrumbCustom.propTypes = {
	Component: PropTypes.element.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	linkPath: PropTypes.string,
	pathPrefix: PropTypes.string,
};

export default BreadcrumbCustom;
