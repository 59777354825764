import { log } from '@/lib/logger';

import agorityContentManagement from './agorityContentManagement';
import agorityDeviceManagement from './agorityDeviceManagement';
import application from './application';
import auth from './auth';
import background_jobs from './background_jobs';
import billboards from './billboards';
import brochures from './brochures';
import companies from './companies';
import connected_modal from './connected_modal';
import enquiries from './enquiries';
import file_explorer from './file_explorer';
import flash_storage from './flash_storage';
import kiosk_visual_stats from './kiosk_visual_stats';
import library from './library';
import library_context from './library_context';
import local_storage from './local_storage';
import metadata from './metadata';
import products from './products';
import propertyScans from './propertyScans';
import reports from './reports';
import reports_cities from './reports_cities';
import sites from './sites';
import sockets from './sockets';
import sub_tours from './sub_tours';
import tracker from './tracker';
import uploads from './uploads';
import user_contacts from './user_contacts';
import users from './users';
import vision from './vision';

const ALL_HANDLERS = [
	application,
	auth,
	background_jobs,
	enquiries,
	companies,
	products,
	billboards,
	brochures,
	sites,
	reports,
	reports_cities,
	users,
	user_contacts,
	file_explorer,
	uploads,
	sockets,
	kiosk_visual_stats,
	metadata,
	sub_tours,
	flash_storage,
	local_storage,
	agorityDeviceManagement,
	library,
	library_context,
	agorityContentManagement,
	connected_modal,
	tracker,
	vision,
	propertyScans,
];

/** @typedef {
		InitialState
 * } State */

const InitialState = {
	...application.INITIAL_STATE,
	...auth.INITIAL_STATE,
	...background_jobs.INITIAL_STATE,
	...enquiries.INITIAL_STATE,
	...companies.INITIAL_STATE,
	...products.INITIAL_STATE,
	...billboards.INITIAL_STATE,
	...brochures.INITIAL_STATE,
	...sites.INITIAL_STATE,
	...reports.INITIAL_STATE,
	...reports_cities.INITIAL_STATE,
	...users.INITIAL_STATE,
	...user_contacts.INITIAL_STATE,
	...file_explorer.INITIAL_STATE,
	...uploads.INITIAL_STATE,
	...sockets.INITIAL_STATE,
	...kiosk_visual_stats.INITIAL_STATE,
	...metadata.INITIAL_STATE,
	...sub_tours.INITIAL_STATE,
	...flash_storage.INITIAL_STATE,
	...local_storage.INITIAL_STATE,
	...agorityDeviceManagement.INITIAL_STATE,
	...library.INITIAL_STATE,
	...library_context.INITIAL_STATE,
	...agorityContentManagement.INITIAL_STATE,
	...connected_modal.INITIAL_STATE,
	...tracker.INITIAL_STATE,
	...vision.INITIAL_STATE,
	...propertyScans.INITIAL_STATE,
};

export const INITIAL_STATE = InitialState;

const HANDLERS = {};

const addReducer = (existingReducer, reducer) => {
	if (existingReducer) {
		// Compose with existing reducer
		return (state, type) => {
			return reducer(existingReducer(state, type), type);
		};
	}
	return reducer;
};

ALL_HANDLERS.forEach(handlers => {
	if (handlers.INITIAL_STATE) {
		Object.assign(INITIAL_STATE, handlers.INITIAL_STATE);
		delete handlers.INITIAL_STATE;
	}

	Object.entries(handlers).forEach(([name, reducers]) => {
		if (!Array.isArray(reducers)) {
			reducers = [reducers];
		}
		HANDLERS[name] = reducers.reduce((agg, reducer) => {
			return addReducer(agg, reducer);
		}, HANDLERS[name]);
	});
});

/**
 *
 * @param {INITIAL_STATE} state
 * @param {Action<any>} action
 * @returns
 */
function rootReducer(state = INITIAL_STATE, action = { type: null }) {
	const handler = HANDLERS[action.type];
	if (!handler) {
		return state;
	}

	log('reducer', `[${action.type || 'unknown'}]`, action);
	return handler(state, action);
}

export default rootReducer;
