import { generatePath } from 'react-router-dom';

import { SITE_TYPES } from '@/types/sites';

import { getParams } from './PatternUtils';

export { getParams };

/** @typedef {import('../types/sites').Site} Site */
//* *********************************************************************************************************************

export const ROOT = '/';
export const NOT_FOUND = '/404';

export const AUTH_LOGIN = '/login';
export const AUTH_RESET_PASSWORD_REQUEST = '/login/reset';
export const AUTH_RESET_PASSWORD_EXECUTE = '/login/reset/:token';
export const AUTH_REGISTER = '/login/register';

//* *********************************************************************************************************************

/**
 * If given location pathname is under "/library/sites", parse out site id and type. Returned as Site hash.
 * @param {string} pathname
 * @returns {Partial<Site>}
 */
export function getSiteFromLocation(pathname) {
	const match = SITE_PATHNAME_REGEX.exec(pathname);
	if (!match) {
		return null;
	}

	// if (!SITE_TYPES[match[1]]) {
	// 	throw new Error(`Invalid site type: ${match[1]}`);
	// }

	return {
		// type: match[1],
		id: Number(match[1]) || null,
	};
}

//* *********************************************************************************************************************

export const APP_ROOT = '/';
export const APP_SUBSECTION = `${APP_ROOT}:section`;

export const APP_LANDING = `${APP_ROOT}landing`;
export const goToAppLanding = (reload = false) => {
	window.location.replace(`#${APP_LANDING}`);

	if (reload) {
		window.location.reload();
	}
};

export const VIEWER_ROOT = `${APP_ROOT}viewer`;
export const VIEWER_ASSET = `${VIEWER_ROOT}/asset/:assetId`;
export const VIEWER_ASSET_SUBSECTION = `${VIEWER_ASSET}/:section?`;
export const VIEWER_ASSET_360_SECTION_TAG = `${VIEWER_ASSET}/360/:thumbnailId?/tags/:tagId?`;
export const VIEWER_ASSET_FLOORPLAN_SECTION_TAG = `${VIEWER_ASSET}/floorplan/:floorplanId?/tags/:tagIdFloorplan?`;
export const VIEWER_ASSET_FLOORPLAN_SECTION = `${VIEWER_ASSET}/floorplan/:floorplanId?`;
export const VIEWER_ASSET_SUBSECTION_TAG = `${VIEWER_ASSET}/:section?/tags/:tagId?`;

export const VAGON = `${APP_ROOT}vagon`;
export const VAGON_STREAM = `${APP_ROOT}vagon/:streamId?`;

export const APP_TOOLS = `${APP_ROOT}tools`;

// Planner Tool
export const PLANNER_ROOT = `${APP_TOOLS}/planner`;
export const PLANNER_SCAN_NEW = `${PLANNER_ROOT}/scan/new/:floorIndex?`;
export const PLANNER_SCAN_EDIT = `${PLANNER_ROOT}/scan/:scanId/:floorIndex?`;

// Brochure Tool
export const BROCHURE_ROOT = `${APP_TOOLS}/brochures`;
export const BROCHURE = `${BROCHURE_ROOT}/:brochureKey?`;
export const BROCHURE_NEW = `${BROCHURE_ROOT}/new/:siteId?`;
export const BROCHURE_EDIT = `${BROCHURE_ROOT}/edit/:brochureKey`;

// Tagview Tool
export const TAGVIEW_ROOT = `${APP_TOOLS}/tags`;
export const TAGVIEW_SITE_TAG = `${TAGVIEW_ROOT}/:siteId?/:tagId?`;
export const TAGVIEW_NEW = `${TAGVIEW_ROOT}/new`;
export const TAGVIEW_EDIT = `${TAGVIEW_ROOT}/edit/:siteId?`;
export const TAGVIEW_FINAL = `${TAGVIEW_ROOT}/edit/:siteId?/final`;

export const IOT = `${APP_ROOT}iot`;
export const IFRAME_ADDON = `${APP_ROOT}iframe-addon/:index?`;

export const ADD_ASSET = `${APP_ROOT}add-asset/:folderId?`;
export const EDIT_ASSET = `${APP_ROOT}edit-asset/:assetId/:origin?`;

export const TAG_MANAGEMENT = `${APP_ROOT}tags/`;
export const TAG_MANAGEMENT_WITH_TAG = `${APP_ROOT}tags/:tagId?`;

//* *********************************************************************************************************************

export const LIBRARY_ROOT = `${APP_ROOT}library`;
export const LIBRARY_FOLDERS = LIBRARY_ROOT;
export const LIBRARY_FOLDER = `${LIBRARY_FOLDERS}/:folderId?`;
export const LIBRARY_SITES = `${LIBRARY_ROOT}/sites`;
export const LIBRARY_SITE = `${LIBRARY_SITES}/:siteId?`;
export const LIBRARY_ORPHANS = `${LIBRARY_FOLDERS}/orphans`;
export const librarySection = section => `${LIBRARY_ROOT}/${section}`;
export const folderView = (folderId, basePath = null) => {
	if (basePath) {
		return `${basePath}/${folderId}`;
	}
	return `${LIBRARY_FOLDERS}/${folderId}`;
};

//* *********************************************************************************************************************

const SITE_PATHNAME_REGEX = /^\/(?:library\/)?sites\/?([0-9]*)/i;

export const SITES = '/sites';
export const SITES_TYPE = `${SITES}/:type`;
export const sites = type => `${SITES}/${type}`;

export const SITES_TOURS = sites(SITE_TYPES.tour);
export const SITES_MASTER_TOURS = sites(SITE_TYPES.master_tour);
export const SITES_VIDEOS = sites(SITE_TYPES.video);
export const SITES_IMAGES = sites(SITE_TYPES.image);
export const SITES_OBJECT_360 = sites(SITE_TYPES.object_360);
export const SITES_UNITY = sites(SITE_TYPES.unity);
export const SITES_OTHER = sites(SITE_TYPES.other);

export const SITE_NEW = `${SITES}/new/:type`;
export const siteNew = type => `${SITES}/new/${type}`;

const siteTabFn =
	tab =>
	(siteId, basePath = null) => {
		if (basePath) {
			return `${basePath}/${siteId}/${tab}`;
		}
		return `${LIBRARY_SITES}/${siteId}/${tab}`;
	};

export const SITE = `${LIBRARY_SITES}/:siteId`;

export const SITE_OVERVIEW = `${SITE}/overview`;
export const siteOverview = siteTabFn('overview');

export const SITE_BROCHURES = `${SITE}/brochures`;
export const siteBrochures = siteTabFn('brochures');

export const SITE_PANOS = `${SITE}/panos/:versionId?`;
export const sitePanos = siteTabFn('panos');
export const siteVersionPanos = (siteId, versionId) =>
	`${sitePanos(siteId)}${versionId ? `/${versionId}` : ''}`;

export const SITE_FILES = `${SITE}/versions/:versionId?`;
export const siteFiles = siteTabFn('versions');
export const siteVersionFiles = (siteId, versionId) =>
	`${siteFiles(siteId)}${versionId ? `/${versionId}` : ''}`;

export const SITE_INSIGHTS = `${SITE}/insights`;

export const SITE_INSIGHTS_TRAFFIC = `${SITE_INSIGHTS}/traffic`;
export const SITE_INSIGHTS_GEO = `${SITE_INSIGHTS}/geo`;
export const SITE_INSIGHTS_DEVICE = `${SITE_INSIGHTS}/device`;
export const SITE_INSIGHTS_BROWSER = `${SITE_INSIGHTS}/browser`;
export const SITE_INSIGHTS_OS = `${SITE_INSIGHTS}/os`;
export const SITE_INSIGHTS_REFERRAL = `${SITE_INSIGHTS}/referral`;
export const SITE_INSIGHTS_VISUAL = `${SITE_INSIGHTS}/visual`;
export const SITE_INSIGHTS_HEATMAP = `${SITE_INSIGHTS}/heatmap`;

export const SITE_BILLBOARDS = `${SITE}/billboards`;
export const siteBillboards = siteTabFn('billboards');

export const SITE_SUBTOURS = `${SITE}/tours`;
export const siteSubtours = siteTabFn('tours');

export const SITE_BILLBOARD_NEW = `${SITE}/billboards/new`;
export const siteBillboardNew = siteId => `${siteBillboards(siteId)}/new`;

export const SITE_BILLBOARD_EDIT = `${SITE}/billboards/:billboardId`;
export const siteBillboardEdit = (siteId, billboardId) =>
	`${siteBillboards(siteId)}/${billboardId}`;

export const SITE_PROJECT_FILE = `${SITE}/project-file`;
export const siteProjectFile = siteTabFn('project-file');

//* *********************************************************************************************************************

// Custom contact route
export const CUSTOM_CONTACT = `${APP_ROOT}custom_contact/:tool`;
export const customContactTool = tool => `${APP_ROOT}custom_contact/${tool}`;
export const CUSTOM_CONTACT_LIBRARY = customContactTool('library');
export const CUSTOM_CONTACT_BROCHURE_VIEW = customContactTool('brochure_tool');
export const CUSTOM_CONTACT_TAGS = customContactTool('tags');
export const CUSTOM_CONTACT_VIRTUAL_SCREEN_MANAGER = customContactTool('virtual_screen_manager');
export const CUSTOM_CONTACT_VISION = customContactTool('viewer');
export const CUSTOM_CONTACT_RAIL = customContactTool('rail');
export const CUSTOM_CONTACT_SALES_VIEW = customContactTool('sales-tool');
export const CUSTOM_CONTACT_PLANNING_VIEW = customContactTool('planning_tool');
export const CUSTOM_CONTACT_AR_APP = customContactTool('ar_app');
export const CUSTOM_CONTACT_AGORITY = customContactTool('agority');
export const CUSTOM_CONTACT_AUDIENCE = customContactTool('audience');
export const CUSTOM_CONTACT_PRODUCT = customContactTool('product');
export const CUSTOM_CONTACT_EYE = customContactTool('eye');
export const CUSTOM_CONTACT_HEATMAPS = customContactTool('heatmaps');
export const CUSTOM_CONTACT_ROUTE = customContactTool('route');
export const CUSTOM_CONTACT_PUBLISH = customContactTool('publish');
export const CUSTOM_CONTACT_SHOP = customContactTool('shop');
export const CUSTOM_CONTACT_IOT = customContactTool('iot');
export const CUSTOM_CONTACT_PLANNER = customContactTool('planner');
export const CUSTOM_CONTACT_ADD_ASSET = customContactTool('add_asset');
export const CUSTOM_CONTACT_IFRAME_ADDONS = customContactTool('iframe_addons');
export const CUSTOM_CONTACT_TAG_MANAGEMENT = customContactTool('tag_management');
//

export const TOOLS_ROOT = `${APP_ROOT}`;
export const TOOL = `${TOOLS_ROOT}tools/:tool`;
export const TOOL_FOLDERS = `${TOOL}/folders`;
export const TOOLS_ORPHANS = `${TOOL}/folders/orphans`;
export const TOOL_FOLDER = `${TOOL}/folders/:folderId?`;
export const toolPage = tool => `${TOOLS_ROOT}/tools/${tool}`;

export const SALES_TOOLS = toolPage(':tool(sales-tool|planning-tool)');

export const SALES_TOOL = `${APP_TOOLS}/sales-tool`;
export const SALES_TOOL_FOLDERS = `${APP_TOOLS}/sales-tool/folders`;
export const PLANNING_TOOL = toolPage('planning-tool');
export const BROCHURE_TOOL = toolPage('brochure-tool');
export const REPORTS_TOOL = `/reports`; // same as brochure

export const VIRTUAL_SCREENS_MANAGER_TOOL = `${APP_TOOLS}/virtual-screen-manager`;
export const VIRTUAL_SCREENS_TOOL = toolPage('virtual-screens-tool');

// Brochure routes

export const SITE_BROCHURE_NEW = `${BROCHURE_TOOL}/theme/new/:siteId?`;
export const SITE_BROCHURE_EDIT = `${BROCHURE_TOOL}/brochure/:brochureKey`;
export const newBrochure = siteId => `${BROCHURE_TOOL}/theme/new${siteId ? `/${siteId}` : ''}`;
export const editBrochure = brochureKey => `${BROCHURE_TOOL}/brochure/${brochureKey}`;

export const SITE_REPORT_NEW = SITE_BROCHURE_NEW.replace(BROCHURE_TOOL, REPORTS_TOOL);
export const SITE_REPORT_EDIT = SITE_BROCHURE_EDIT.replace(BROCHURE_TOOL, REPORTS_TOOL);
export const newReport = siteId => `${REPORTS_TOOL}/theme/new${siteId ? `/${siteId}` : ''}`;
export const editReport = brochureKey => `${REPORTS_TOOL}/brochure/${brochureKey}`;

export const SITE_TOOL = `${APP_ROOT}tools/:tool/:siteId`;
export const SITE_TOOL_TAB = `${APP_ROOT}tools/:tool/:siteId/:tab`;
export const siteTool = (tool, siteId) => `${APP_ROOT}tools/${tool}/${siteId}`;
export const siteToolFn = tool => siteId => siteTool(tool, siteId);
export const siteToolTab = (tool, siteId, tab) => `${siteTool(tool, siteId)}/${tab}`;
export const siteToolTabFn = tab => (tool, siteId) => siteToolTab(tool, siteId, tab);

export const foldersTool = tool => `${toolPage(tool)}/folders`;
export const folderTool = tool => folderId =>
	`${toolPage(tool)}/folders${folderId ? `/${folderId}` : ''}`;
export const fileTool = tool => fileId => siteTool(tool, fileId);

export const tourVirtualScreensView = siteToolFn('virtual_screens_tool');
export const tourPlanningToolView = siteToolFn('planning_tool');
export const tourSalesToolView = siteToolFn('sales-tool');
export const tourBrochureToolView = siteToolFn('brochure_tool');

export const SITE_TOOL_NEW = `${SITE_TOOL}/new/:step?`;
export const siteToolNew = siteToolTabFn('new');

export const SITE_TOOL_EDIT = `${SITE_TOOL}/edit/:id/:step?`;
export const siteToolEdit = siteToolTabFn('edit');

export const SITE_SALES_TOOL = `${SALES_TOOL}/:siteId`;
export const SITE_PLANNING_TOOL = `${PLANNING_TOOL}/:siteId`;
export const SITE_BROCHURE_TOOL = `${BROCHURE_TOOL}/:siteId`;
export const SITE_VIRTUAL_SCREENS_TOOL = `${VIRTUAL_SCREENS_TOOL}/:siteId/:step?`;
export const siteVirtualScreensTool = (siteId, step) =>
	`${VIRTUAL_SCREENS_TOOL}/${siteId}${step ? `/${step}` : ''}`;

export const SALES_TOOL_TAB = `${SITE_SALES_TOOL}/:tab`;
export const PLANNING_TOOL_TAB = `${SITE_PLANNING_TOOL}/:tab`;
export const BROCHURE_TOOL_TAB = `${SITE_BROCHURE_TOOL}/:tab`;
export const VIRTUAL_SCREENS_TOOL_TAB = `${SITE_VIRTUAL_SCREENS_TOOL}/:tab`;

export const SALES_TOOL_ATTRIBUTES = `${SITE_TOOL}/attributes`;
export const salesToolAttributes = siteToolTabFn('attributes');
export const SALES_TOOL_LOCATIONS = `${SITE_TOOL}/locations`;
export const salesToolLocations = siteToolTabFn('locations');
export const SALES_TOOL_GEO_EDIT = `${SITE_TOOL}/geo`;
export const salesToolGeoEdit = siteToolTabFn('geo');

export const SALES_TOOL_LOCATION_NEW = `${SITE_TOOL}/locations/new`;
export const salesToolLocationNew = (tool, siteId) => `${salesToolLocations(tool, siteId)}/new`;
export const SALES_TOOL_ATTRIBUTE_NEW = `${SITE_TOOL}/attributes/new`;
export const salesToolAttributeNew = (tool, siteId) => `${salesToolAttributes(tool, siteId)}/new`;

export const SALES_TOOL_LOCATION_EDIT = `${SITE_TOOL}/locations/edit/:locationId`;
export const salesToolLocationEdit = (tool, siteId, locationId) =>
	`${salesToolLocations(tool, siteId)}/edit/${locationId}`;

export const SALES_TOOL_ATTRIBUTE_EDIT = `${SITE_TOOL}/attributes/edit/:attributeId`;
export const salesToolAttributeEdit = (tool, siteId, attributeId) =>
	`${salesToolAttributes(tool, siteId)}/edit/${attributeId}`;

export const SUB_TOUR = `${SITE_TOOL}/edit/:tourId/:step?`;
export const SALES_TOOL_SUB_TOUR = `${SITE_SALES_TOOL}/edit/:tourId/:step?`;
export const PLANNING_TOOL_SUB_TOUR = `${SITE_PLANNING_TOOL}/edit/:tourId/:step?`;
export const VIRTUAL_SCREENS_TOOL_SUB_TOUR = `${SITE_VIRTUAL_SCREENS_TOOL}/edit/:tourId/:step?`;
export const BROCHURE_TOOL_SUB_TOUR = `${SITE_BROCHURE_TOOL}/edit/:tourId/:step?`;

export const subTour = (tool, siteId, tourId, step = null) =>
	siteToolTab(tool, siteId, `edit/${tourId}`) + (step ? `/${step}` : '');

export const SUB_TOUR_L = `${SITE_TOOL}/edit/:tourId/:step/l/:locationId`;
export const subTourL = (tool, siteId, tourId, locationId, step = null) =>
	`${subTour(tool, siteId, tourId, step)}/l/${locationId}`;
export const subTourTab = (tool, siteId, tourId, tab) =>
	`${subTour(tool, siteId, `edit/${tourId}`)}/${tab}`;

export const SUB_TOUR_NEW = `${SITE_TOOL}/new/:step?`;
export const SALES_TOOL_SUB_TOUR_NEW = `${SITE_SALES_TOOL}/new/:step?`;
export const PLANNING_TOOL_SUB_TOUR_NEW = `${SITE_PLANNING_TOOL}/new/:step?`;
export const VIRTUAL_SCREENS_TOOL_SUB_TOUR_NEW = `${SITE_VIRTUAL_SCREENS_TOOL}/new/:step?`;
export const BROCHURE_TOOL_SUB_TOUR_NEW = `${SITE_BROCHURE_TOOL}/new/:step?`;

export const subTourNew = (tool, siteId, step = null) =>
	siteToolTab(tool, siteId, 'new') + (step ? `/${step}` : '');
export const SUB_TOUR_NEW_L = `${SITE_TOOL}/new/:step/l/:locationId`;
export const subTourNewL = (tool, siteId, locationId, step = 1) =>
	`${subTourNew(tool, siteId, step)}/l/${locationId}`;

export const SUB_TOUR_DETAILS = `${SUB_TOUR}/details`;
export const subTourDetails = (tool, siteId, tourId) => subTourTab(tool, siteId, tourId, 'details');

export const SUB_TOUR_EDIT = `${SUB_TOUR}/edit`;
export const subTourEdit = (tool, siteId, tourId) => subTourTab(tool, siteId, tourId, 'edit');

export const SUB_TOUR_GEO_EDIT = `${SUB_TOUR}/geo`;
export const subTourSubGeoEdit = (tool, siteId, tourId) => subTourTab(tool, siteId, tourId, 'geo');

export const SUB_TOUR_LOCATION_EDIT = `${SUB_TOUR}/l/:locationId`;
export const subTourLocationEdit = (tool, siteId, tourId, locationId) =>
	`${subTourTab(tool, siteId, tourId, 'l')}/${locationId}`;

export const SUB_TOUR_LOCATIONS = `${SUB_TOUR}/l`;
export const subTourLocations = (tool, siteId, tourId) => subTourTab(tool, siteId, tourId, 'l');

export const SUB_TOUR_LOCATION_NEW = `${SUB_TOUR_LOCATIONS}/new`;
export const subTourLocationNew = (tool, siteId, tourId) =>
	`${subTourLocations(tool, siteId, tourId)}/new`;

export const SUB_TOUR_BILLBOARDS = `${SUB_TOUR}/billboards`;
export const subTourBillboards = (siteId, tourId) =>
	subTourTab('sales-tool', siteId, tourId, 'billboards');

export const SUB_TOUR_BILLBOARDS_EDIT = `${SUB_TOUR_BILLBOARDS}/:billboardId`;
export const subTourBillboardsEdit = (siteId, tourId, billboardId) =>
	`${subTourBillboards(siteId, tourId)}/${billboardId}`;

//* *********************************************************************************************************************

export const ENQUIRIES = `${APP_ROOT}enquiries`;

export const ENQUIRY_FORM_EDIT = `${ENQUIRIES}/form/:enquiryId`;
export const enquiryFormEdit = enquiryId => `${ENQUIRIES}/form/${enquiryId}`;

export const ENQUIRY_DETAILS = `${ENQUIRIES}/:enquiryId`;
export const enquiryDetails = enquiryId => `${ENQUIRIES}/${enquiryId}`;

export const ENQUIRY_MESSAGES = `${ENQUIRY_DETAILS}/messages`;
export const enquiryMessages = enquiryId => `${enquiryDetails(enquiryId)}/messages`;

//* *********************************************************************************************************************

export const COMPANIES = `${APP_ROOT}companies`;
export const COMPANY_NEW = `${APP_ROOT}companies/new`;

export const COMPANY_DETAILS = `${COMPANIES}/:companyId`;
export const companyDetails = companyId => `${COMPANIES}/${companyId}`;

export const COMPANY_USERS = `${COMPANIES}/:companyId/users`;
export const companyUsers = companyId => `${COMPANIES}/${companyId}/users`;

export const COMPANY_USER_DETAILS = `${COMPANY_USERS}/:userId`;
export const companyUserDetails = (companyId, userId) =>
	`${COMPANIES}/${companyId}/users/${userId}`;

export const COMPANY_USERS_NEW = `${COMPANY_USERS}/new`;
export const companyUsersNew = companyId => `${companyUsers(companyId)}/new`;

export const COMPANY_USER_EDIT = `${COMPANY_USER_DETAILS}/edit`;
export const companyUserEdit = (companyId, userId) =>
	`${companyUserDetails(companyId, userId)}/edit`;

export const COMPANY_USER_EDIT_SETTINGS = `${COMPANY_USER_DETAILS}/settings`;
export const companyUserEditSettings = (companyId, userId) =>
	`${companyUserDetails(companyId, userId)}/settings`;

export const COMPANY_EDIT = `${COMPANY_DETAILS}/edit`;
export const companyEdit = companyId => `${companyDetails(companyId)}/edit`;

//* *********************************************************************************************************************

export const USER_ACCOUNT = `${APP_ROOT}account`;
export const userAccount = () => USER_ACCOUNT;
export const USER_ACCOUNT_SETTINGS = `${USER_ACCOUNT}/settings`;
export const USER_SOCIAL_ACCOUNTS = `${USER_ACCOUNT}/social`;
export const USER_ACCOUNT_CONTACTS = `${USER_ACCOUNT}/contacts`;
export const USER_SECURITY = `${USER_ACCOUNT}/security`;

export const USERS = `${APP_ROOT}users`;
export const USER_EDIT_SETTINGS = `${USERS}/:userId/settings`;
export const userEditSettings = userId => `${USERS}/${userId}/settings`;
export const USER_EDIT = `${USERS}/:userId`;
export const userEdit = userId => `${USERS}/${userId}`;

export const USERS_NEW = `${USERS}/new`;

//* *********************************************************************************************************************

export const INSIGHTS = '/insights';

export const ADMIN_REPORTS = `${APP_ROOT}reports`;
export const ADMIN_CUSTOMER_REPORTS = `${ADMIN_REPORTS}/customers`;

export const ADMIN_SITES_REPORTS = `${ADMIN_REPORTS}/sites`;

export const INSIGHTS_PRODUCT = `${INSIGHTS}/publish_insights`;
export const INSIGHTS_VISUAL = `${INSIGHTS}/visual`;
export const INSIGHTS_BIOMETRICS = `${INSIGHTS}/biometrics`;
export const INSIGHTS_EYE_TRACKING = `${INSIGHTS}/eye_tracking`;
export const INSIGHTS_HEATMAPS = `${APP_TOOLS}/heatmaps`;
export const INSIGHTS_HEATMAPS_SITE = `${INSIGHTS_HEATMAPS}/:siteId?`;
export const insightsHeatmaps = siteId => `${INSIGHTS_HEATMAPS}/${siteId}`;

export const AUDIENCE = `${APP_TOOLS}/audience`;
export const AUDIENCE_TRAFFIC = `${AUDIENCE}/traffic`;
export const AUDIENCE_GEO = `${AUDIENCE}/geo`;
export const AUDIENCE_DEVICE = `${AUDIENCE}/device`;
export const AUDIENCE_BROWSER = `${AUDIENCE}/browser`;
export const AUDIENCE_OS = `${AUDIENCE}/os`;
export const AUDIENCE_REFERRAL = `${AUDIENCE}/referral`;

//* *********************************************************************************************************************

export const ADMIN = `${APP_ROOT}admin`;

export const ADMIN_IMPORT = `${ADMIN}/import`;
export const ADMIN_IMPORT_ORDERS = `${ADMIN_IMPORT}/orders`;

export const ADMIN_PLATFORM_SETTINGS = `${ADMIN}/platform`;
export const ADMIN_EMAIL_NOTIFICATIONS = `${ADMIN}/emails`;
export const ADMIN_TUTORIAL_SETTINGS = `${ADMIN}/tutorials`;
export const ADMIN_BROCHURE_SETTINGS = `${ADMIN}/brochure_settings`;

//* *********************************************************************************************************************

// export const DEFAULT_LOGGED_IN = HOME;
export const DEFAULT_LOGGED_IN = APP_ROOT;
export const DEFAULT_LOGGED_OUT = AUTH_LOGIN;

//* *********************************************************************************************************************

export const isKioskRoute = pathname => {
	return pathname && pathname.indexOf() === 0;
};

export const KIOSK = '/kiosk';
export const DEPRECATED_ROUTES_PREFIX = `${KIOSK}/vision`;

export const KIOSK_AUTH_LOGIN = '/kiosk/login';

export const KIOSK_SITE = '/kiosk/sites/:siteId';
export const KIOSK_SITE_VISUAL_STATS = '/kiosk/sites/:siteId/stats/visual';
export const kioskSiteVisualStats = siteId => `/kiosk/sites/${siteId}/stats/visual`;

export const KIOSK_ENQUIRY_UPLOADER = '/kiosk/enquiries/uploader/:enquiryHash';
export const kioskEnquiryUploader = enquiryHash => `/kiosk/enquiries/uploader/${enquiryHash}`;

export const KIOSK_DEFAULT_LOGGED_IN = KIOSK;
export const KIOSK_DEFAULT_LOGGED_OUT = KIOSK_AUTH_LOGIN;

export const BROCHURE_FILE = `${APP_ROOT}brochures/:brochureKey`;
export const BROCHURES_ROOT = `${APP_ROOT}brochures`;
export const KIOSK_SITE_BROCHURE_NEW = `${BROCHURES_ROOT}/:theme/new/:siteId?`;
export const brochureFile = brochureKey => `${APP_ROOT}brochures/${brochureKey}`;
export const kioskNewBrochure = (theme, siteId) =>
	`${BROCHURES_ROOT}/${theme}/new${siteId ? `/${siteId}` : ''}`;

/** ***************************************************************************** */

// Apps routes

export const APPS = '/apps';
export const APPS_AR_APP = `${APPS}/ar_app`;
export const APPS_AR_APP_MODELS = `${APPS_AR_APP}/models`;
export const APPS_AR_APP_TAGS = `${APPS_AR_APP}/tags`;

/** ***************************************************************************** */

export const AGORITY_APP = `${APP_TOOLS}/agority`;
export const AGORITY_DEVICE = `${AGORITY_APP}/:deviceId?`;
export const AGORITY_AVATARS = `${AGORITY_APP}/avatars/:deviceId?/:avatarId?`;
export const AGORITY_CONTENT = `${AGORITY_APP}/content`;

export const AGORITY_CONTENT_FOLDER = `${AGORITY_CONTENT}/folders/:folderId?`;
export const AGORITY_NOTES = `${AGORITY_CONTENT}/notes`;
export const AGORITY_RECORDINGS = `${AGORITY_CONTENT}/recordings`;
export const AGORITY_SELFIES = `${AGORITY_CONTENT}/selfies`;
export const AGORITY_SURVEYS = `${AGORITY_CONTENT}/surveys`;

export const AGORITY_SURVEYS_NEW = `${AGORITY_SURVEYS}/create`;
export const AGORITY_SURVEYS_RESULTS = `${AGORITY_SURVEYS}/results`;
export const AGORITY_SURVEYS_DETAILS = `${AGORITY_SURVEYS}/:surveyId`;
export const AGORITY_SURVEYS_EDIT = `${AGORITY_SURVEYS}/:surveyId/edit`;

export const agorityDeviceDetails = deviceId => generatePath(AGORITY_DEVICE, { deviceId });
export const agorityAvatars = deviceId => generatePath(AGORITY_AVATARS, { deviceId });
export const agorityContent = () => AGORITY_CONTENT;
export const agorityNotes = () => AGORITY_NOTES;
export const agorityRecordings = () => AGORITY_RECORDINGS;
export const agoritySelfies = () => AGORITY_SELFIES;
export const agoritySurveys = () => AGORITY_SURVEYS;
export const agoritySurvey = surveyId => generatePath(AGORITY_SURVEYS_DETAILS, { surveyId });
export const agoritySurveyEdit = surveyId => `${agoritySurvey(surveyId)}/edit`;
export const agoritySurveyResults = surveyId => `${agoritySurveys()}/results`;

export const folderViewAgorityUpload = folderId =>
	`${agorityContent()}/folders${folderId ? `/${folderId}` : ''}`;
