import ENV from '@/env';
import { makeImageQueryString, makeImageUrl } from '@/lib/api';

const { visuals } = ENV;
const {
	max_lightbox_width,
	preview_image_max_height,
	preview_image_max_width,
	site_thumbnail_height_small,
	site_thumbnail_width_small,
} = visuals;

export const THUMB_QUERY_STRING = makeImageQueryString(
	site_thumbnail_width_small,
	site_thumbnail_height_small,
	true,
);

/**
 * @mixin
 */
const image = {
	created_at: 'created_at',
	directory: 'directory',
	format: 'format',
	id: 'id',
	is_360: 'is_360',
	key: 'key',
	original_name: 'original_name',
};

export const IMAGE = image;

/**
 * @mixes image
 */
export function Image(image) {
	image && Object.assign(this, image);
}

export const FORMATS = {
	jpeg: 'jpeg',
	jpg: 'jpg',
	png: 'png',
};

/**
 * @param {{key?: string, url?: string, width?: number, height?: number}} image
 * @param {{width?: number, height?: number, max?: boolean}} options
 */
export const createImageSource = (
	image,
	options = { height: undefined, max: true, width: undefined },
) => {
	let { key, url } = image;
	if (key && !url) {
		url = makeImageUrl(key);
	}

	const { max } = options;
	const width =
		options.width === undefined ? Math.min(image.width, max_lightbox_width) : options.width;
	const height =
		options.height === undefined
			? Math.max(Math.min(image.height, preview_image_max_width), preview_image_max_height)
			: options.height;

	return {
		source: {
			fullscreen: url,
			regular: url + makeImageQueryString(width, height, max),
			thumbnail: url + THUMB_QUERY_STRING,
		},
	};
};
