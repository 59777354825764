import Popover from 'react-bootstrap/Popover';
import PropTypes from 'prop-types';

import './FlavoredPopover.scss';

function FlavoredPopover({ children, className = '', flavor = 'default', ...props }) {
	className = `${className || ''} sv-FlavoredPopover sv-FlavoredPopover-${flavor}`;

	return (
		<Popover className={className} {...props}>
			{children}
		</Popover>
	);
}

FlavoredPopover.propTypes = {
	flavor: PropTypes.string,
};

FlavoredPopover.defaultProps = {
	flavor: 'default',
};

export default FlavoredPopover;
