import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const NAME = 'sites_metadata';

const INITIAL_STATE = /** @lends State.prototype */ {
	[NAME]: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
	},
};

const updateState = (state, ob) => {
	return { ...state, [NAME]: { ...state[NAME], ...ob } };
};

const withInvalidation = (nextState, action) => {
	if (action.invalidateId && nextState[NAME] && nextState[NAME].data) {
		delete nextState[NAME].data[action.invalidateId];
	}
	return nextState;
};

const helper = new ReduceHelper(NAME, 'tour.id');

const gotData = (state, action) => {
	const nextState = helper.gotData(state, action);
	nextState[NAME].siteType = action.siteType;
	return nextState;
};

const loadStart = (state, action) => {
	const nextState = updateState(state, {
		loadError: null,
	});

	if (action.invalidateAll) {
		nextState[NAME].data = null;
	}

	return withInvalidation(nextState, action);
};

const loadEnd = (state, action) => {
	return updateState(state, {
		loadError: action.error || null,
	});
};

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const opEnd = (state, action) => {
	return helper.withInvalidation(helper.opEnd(state, action), action);
};

const opReset = state => {
	return updateState(state, {
		op: null,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.SITES_METADATA_GOT_DATA]: gotData,
	[TYPES.SITES_METADATA_LOAD_START]: loadStart,
	[TYPES.SITES_METADATA_LOAD_END]: loadEnd,
	[TYPES.SITES_METADATA_OP_START]: opStart,
	[TYPES.SITES_METADATA_OP_END]: opEnd,
	[TYPES.SITES_METADATA_OP_RESET]: opReset,
	[TYPES.OP_RESET_ALL]: opReset,
};
