import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	users: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
		params: null,
	},
};

const helper = new ReduceHelper('users', 'user.id');

export default {
	INITIAL_STATE,
	[TYPES.USERS_GOT_DATA]: helper.gotData,
	[TYPES.USERS_LOAD_START]: helper.loadStart,
	[TYPES.USERS_LOAD_END]: helper.loadEnd,
	[TYPES.USERS_OP_START]: helper.opStart,
	[TYPES.USERS_OP_END]: helper.opEnd,
	[TYPES.USERS_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
};
