import { assign } from '@/lib/util';

import { Criteria, SORT_DIRECTIONS } from './data';
import { IMAGE } from './images';

const company = {
	address: 'address',
	allow_customers: 'allow_customers',
	allow_orders: 'allow_orders',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	email: 'email',
	id: 'id',
	iframe_addons: 'iframe_addons',
	invoice_address: 'invoice_address',
	iot_dashboard_url: 'iot_dashboard_url',
	logo_id: 'logo_id',
	name: 'name',
	notes: 'notes',
	parent_id: 'parent_id',
	phone: 'phone',
	reference: 'reference',
	updated_at: 'updated_at',
	vat: 'vat',
};

export const COMPANY = company;

export class Company {
	constructor(source) {
		/** @type {number} */ this.id = null;
		/** @type {number} */ this.parent_id = null;
		/** @type {number} */ this.logo_id = null;
		/** @type {string} */ this.name = null;
		/** @type {string} */ this.reference = null;
		/** @type {string} */ this.phone = null;
		/** @type {string} */ this.email = null;
		/** @type {string} */ this.vat = null;
		/** @type {string} */ this.address = null;
		/** @type {string} */ this.invoice_address = null;
		/** @type {string} */ this.notes = null;
		/** @type {boolean} */ this.allow_customers = null;
		/** @type {boolean} */ this.allow_orders = null;
		/** @type {Date} */ this.created_at = null;
		/** @type {Date} */ this.updated_at = null;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

/**
 * @lends CompanyWithDetails.prototype
 * */
const cwd = {
	company: 'company',
	logo: 'logo',
	parent: 'parent',
};

export const CWD = cwd;

export class CompanyWithDetails {
	constructor(source) {
		/** @type {Company} */ this.company = null;
		/** @type {Company} */ this.parent = null;
		/** @type {Image} */ this.logo = null;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

export class CompanyCriteria extends Criteria {
	constructor(source) {
		super();

		this.parent = undefined;
		this.only_assigned = undefined;
		this.descendants_of = undefined;

		assign(this, this, source);

		if (typeof this.parent !== 'undefined' && this.parent >= 1) {
			this.parent = Number(this.parent);
		}
	}

	toQuery(fromPage) {
		const query = super.toQuery(fromPage);

		if (this.only_assigned) {
			query['only-assigned'] = 'true';
		}

		if (this.parent === null) {
			query['child-of'] = 'null';
		}

		if (this.descendants_of) {
			query['descendants-of'] = this.descendants_of;
		}

		return query;
	}
}

// *********************************************************************************************************************

export const LOOKUP_PROPS = [
	`company.${COMPANY.name}`,
	`company.${COMPANY.reference}`,
	`company.${COMPANY.parent_id}`,
	`logo.${IMAGE.key}`,
];

export const DEFAULT_CRITERIA = new CompanyCriteria({
	filter: '',
	parent: undefined,
	sort_by: `company.${COMPANY.id}`,
	sort_direction: SORT_DIRECTIONS.desc,
});
