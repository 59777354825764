import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';

import './TablePagination.scss';

const CLASS = 'sv-TablePagination';

const wrap = content => {
	return <div className={CLASS}>{content}</div>;
};

/**
 * @return {null|object}
 */
function TablePagination({ nextPage, onLoadMore }) {
	if (!nextPage) {
		return null;
	}

	if (nextPage.loading) {
		return wrap(
			<IconButton disabled spin className={`${CLASS}-load-more`} icon={FA.cog}>
				Loading...
			</IconButton>,
		);
	}

	const handleLoadMoreClick = () => onLoadMore(nextPage);

	return wrap(
		<IconButton
			secondary
			className={`${CLASS}-load-more`}
			variant="accent"
			onClick={handleLoadMoreClick}
		>
			Load more
		</IconButton>,
	);
}

TablePagination.propTypes = {
	nextPage: PropTypes.object,
	onLoadMore: PropTypes.func,
};

export default TablePagination;
