import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	reports_cities: {
		data: null,
		nextPage: null,
		op: null,
		signature: null,
	},
};

const helper = new ReduceHelper('reports_cities');

const reset = state => {
	return helper.updateNamespace(state, INITIAL_STATE.reports_cities);
};

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const gotData = (state, action) => {
	const nextState = helper.gotData(state, action);
	nextState.reports_cities.signature = action.signature;
	return nextState;
};

export default {
	INITIAL_STATE,
	[TYPES.INSIGHTS_CITIES_OP_START]: opStart,
	[TYPES.INSIGHTS_CITIES_OP_END]: helper.opEnd,
	[TYPES.INSIGHTS_CITIES_GOT_DATA]: gotData,
	[TYPES.INSIGHTS_CITIES_RESET]: reset,
};
