import { Children, Component } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import PropTypes from 'prop-types';

import { VARIANTS } from '@/types/consts';

import FlavoredPopover from './FlavoredPopover';

import './ErrorPopover.scss';

const MIN_LIFETIME = 500;

class ErrorPopover extends Component {
	static propTypes = {
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		onHide: PropTypes.func,
		placement: PropTypes.string,
		title: PropTypes.string,
	};

	static defaultProps = {
		title: 'Error',
	};

	constructor(props) {
		super(props);

		this.state = {
			dismissed: false,
			lastShownAt: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.error !== this.props.error) {
			this.setState({
				dismissed: false,
				lastShownAt: new Date(),
			});
		}
	}
	// componentWillReceiveProps(nextProps) {
	// 	if (this.props.error !== nextProps.error) {
	// 		this.setState({
	// 			dismissed: false,
	// 			lastShownAt: new Date(),
	// 		});
	// 	}
	// }

	onHide() {
		if (this.props.onHide) {
			this.props.onHide();
		}

		if (this.state.lastShownAt && new Date() - this.state.lastShownAt < MIN_LIFETIME) {
			// Sometimes popover closes too fast, capturing the mouse click that caused the error
			// We will wait at last MIN_LIFETIME before we accept closing the error
			return;
		}

		this.setState({
			dismissed: true,
		});
	}

	render() {
		const { className, error, placement, title } = this.props;

		let errorMessage;
		if (error) {
			errorMessage = error.message || error;
			if (error.status) {
				errorMessage += ` (status code: ${error.status})`;
			}
		}

		const show = !!error && !this.state.dismissed;

		const child = Children.only(this.props.children);
		const wrapperClassName = `sv-ErrorPopover-wrapper ${className || ''}`;
		return (
			<div ref={wrapper => (this.wrapper = wrapper)} className={wrapperClassName}>
				<Overlay
					rootClose
					placement={placement}
					show={show}
					target={this.wrapper}
					onHide={() => this.onHide()}
				>
					<FlavoredPopover
						className="sv-ErrorPopover"
						flavor={VARIANTS.danger}
						id="error-popover"
						title={title}
					>
						{errorMessage}
					</FlavoredPopover>
				</Overlay>
				{child}
			</div>
		);
	}
}

export default ErrorPopover;
