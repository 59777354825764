import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	reports: {
		criteria: null,
		data: null,
		nextPage: null,
		op: null,
		signature: null,
	},
};

const helper = new ReduceHelper('reports');

const reset = state => {
	return helper.updateNamespace(state, INITIAL_STATE.reports);
};

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const gotData = (state, action) => {
	const nextState = helper.gotData(state, action);
	nextState.reports.signature = action.signature;
	return nextState;
};

const gotTmpCriteria = (state, action) => {
	const nextState = helper.gotData(state, action);
	nextState.reports.criteria = action.criteria;
	return nextState;
};

export default {
	INITIAL_STATE,
	[TYPES.INSIGHTS_OP_START]: opStart,
	[TYPES.INSIGHTS_OP_END]: helper.opEnd,
	[TYPES.INSIGHTS_GOT_DATA]: gotData,
	[TYPES.INSIGHTS_RESET]: reset,
	[TYPES.INSIGHTS_GOT_TMP_CRITERIA]: gotTmpCriteria,
};
