import { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import FA from '@/types/font_awesome';
import CheckboxStyled from '@/views/widgets/input/CheckboxStyled';

import Menu from './Menu';

import './MenuItem.scss';

const CLASS = 'sv-MenuItem';

const PLACEMENTS = {
	left: 'left',
	right: 'right',
};

let clientWidth = null;

class MenuItem extends Component {
	state = {
		isOpen: false,
		placement: this.props.placement,
	};

	constructor(props) {
		super(props);
		this.menuItem = createRef();
	}

	handleOpen = () => this.setState({ isOpen: true });

	handleClose = () => this.setState({ isOpen: false });

	handleClick = e => {
		const { action, value, valuePath, ...rest } = this.props;
		return action(e, valuePath, value, { ...rest });
	};

	getValue = () => {
		const { item, valuePath } = this.props;
		return get(item, valuePath);
	};

	renderItem() {
		const { title, type, value } = this.props;

		if (!type) {
			return title;
		}

		const isChecked = value === this.getValue() || undefined;
		return (
			<CheckboxStyled checked={isChecked} onChange={this.handleClick} onClick={this.handleClick}>
				{title}
			</CheckboxStyled>
		);
	}

	// componentDidMount = () => {
	// 	if (this.menuItem.current) {

	// 		clientWidth = document.documentElement.clientWidth;
	// 		const elPosition = this.menuItem.current.getBoundingClientRect();
	// 		let newPlacement = PLACEMENTS.right;

	// 		((clientWidth - elPosition.x) < 300) ? newPlacement = PLACEMENTS.left : newPlacement;
	// 		this.setState({placement: newPlacement});
	// 	}
	// };

	componentDidUpdate() {
		if (!clientWidth) {
			return;
		}
		clientWidth = document.documentElement.clientWidth;
		const elPosition = this.menuItem.current.getBoundingClientRect();
		let newPlacement = PLACEMENTS.right;

		if (clientWidth - elPosition.x < 300) {
			newPlacement = PLACEMENTS.left;
		}

		this.setState({ placement: newPlacement });
	}

	render() {
		const { children, disabled, divider, title } = this.props;
		let { placement } = this.props;

		if (!PLACEMENTS[placement]) {
			placement = PLACEMENTS.right;
		}

		if (disabled) {
			return (
				<div className={`${CLASS} ${CLASS}-disabled`}>
					<div className={`${CLASS}-item`}>{title}</div>

					{divider && <hr />}
				</div>
			);
		}

		if (children) {
			return (
				<div
					key={title}
					ref={this.menuItem}
					className={CLASS}
					onMouseEnter={this.handleOpen}
					onMouseLeave={this.handleClose}
				>
					<div className={`${CLASS}-item`}>
						{title}

						<FontAwesomeIcon className={`${CLASS}-arrow`} icon={FA.chevron_right} />
					</div>

					{this.state.isOpen && (
						<div className={`${CLASS}-menu-wrapper-${placement}` || this.state.placement}>
							<Menu menuItems={children} />
						</div>
					)}

					{divider && <hr />}
				</div>
			);
		}

		return (
			<div className={CLASS}>
				<div className={`${CLASS}-item`} onClick={this.handleClick}>
					{this.renderItem()}
				</div>

				{divider && <hr />}
			</div>
		);
	}
}

export default MenuItem;
