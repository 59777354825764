import { Component, createRef } from 'react';
import ReactSelect, { Async, AsyncCreatable, Creatable } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import 'setimmediate';

import './Select.scss';

const CLASS = 'sv-Select';

const stringOrNode = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);
const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
function isOptionUnique(ref) {
	const { labelKey, option, options, valueKey } = ref;
	if (!options || !options.length) {
		return true;
	}

	return (
		options.filter(existingOption => {
			return (
				existingOption[labelKey] === option[labelKey] ||
				existingOption[valueKey] === option[valueKey]
			);
		}).length === 0
	);
}

class Select extends Component {
	/**
	 * @type {ReactSelect.propTypes}
	 */
	static propTypes = {
		// From react-select prop types
		'aria-describedby': PropTypes.string,

		// html id(s) of element(s) that should be used to describe this input (for assistive tech)
		'aria-label': PropTypes.string,

		// aria label (for assistive tech)
		'aria-labelledby': PropTypes.string,

		// html id of an element that should be used as the label (for assistive tech)
		arrowRenderer: PropTypes.func,

		async: PropTypes.bool,

		// create the drop-down caret element
		autoBlur: PropTypes.bool,

		// automatically blur the component when an option is selected
		autoFocus: PropTypes.bool,

		// autofocus the component on mount
		autofocus: PropTypes.bool,

		// deprecated; use autoFocus instead
		autosize: PropTypes.bool,

		// whether to enable autosizing or not
		backspaceRemoves: PropTypes.bool,

		// whether backspace removes an item if there is no text input
		backspaceToRemoveMessage: PropTypes.string,

		className: PropTypes.string,
		// message to use for screenreaders to press backspace to remove the current item - {label} is replaced with the item label
		clearAllText: stringOrNode,
		// title for the "clear" control when multi: true
		clearRenderer: PropTypes.func,
		// create clearable x element
		clearValueText: stringOrNode,
		// title for the "clear" control
		clearable: PropTypes.bool,
		// should it be possible to reset value
		closeOnSelect: PropTypes.bool,
		// whether to close the menu when a value is selected
		deleteRemoves: PropTypes.bool,
		// whether delete removes an item if there is no text input
		delimiter: PropTypes.string,
		// delimiter to use to join multiple values for the hidden field value
		disabled: PropTypes.bool,
		// whether the Select is disabled or not
		escapeClearsValue: PropTypes.bool,
		// whether escape clears the value when the menu is closed
		filterOption: PropTypes.func,
		// method to filter a single option (option, filterString)
		filterOptions: PropTypes.any,
		// boolean to enable default filtering or function to filter the options array ([options], filterString, [values])
		id: PropTypes.string,
		// html id to set on the input element for accessibility or tests
		ignoreAccents: PropTypes.bool,
		// whether to strip diacritics when filtering
		ignoreCase: PropTypes.bool,
		// whether to perform case-insensitive filtering
		inputProps: PropTypes.object,
		// custom attributes for the Input
		inputRenderer: PropTypes.func,
		// returns a custom input component
		instanceId: PropTypes.string,
		// set the components instanceId
		isLoading: PropTypes.bool,
		// whether the Select is loading externally or not (such as options being loaded)
		joinValues: PropTypes.bool,
		// joins multiple values into a single form field with the delimiter (legacy mode)
		labelKey: PropTypes.string,
		// path of the label value in option objects
		matchPos: PropTypes.string,
		// (any|start) match the start or entire string when filtering
		matchProp: PropTypes.string,
		// (any|label|value) which option property to filter on
		menuBuffer: PropTypes.number,
		// optional buffer (in px) between the bottom of the viewport and the bottom of the menu
		menuContainerStyle: PropTypes.object,
		// optional style to apply to the menu container
		menuRenderer: PropTypes.func,
		// renders a custom menu with options
		menuStyle: PropTypes.object,
		// optional style to apply to the menu
		multi: PropTypes.bool,
		// multi-value input
		name: PropTypes.string,
		// generates a hidden <input /> tag with this field name for html forms
		noResultsText: stringOrNode,
		// placeholder displayed when there are no matching search results
		onBlur: PropTypes.func,
		// onBlur handler: function (event) {}
		onBlurResetsInput: PropTypes.bool,
		// whether input is cleared on blur
		onChange: PropTypes.func,
		// onChange handler: function (newValue) {}
		onClose: PropTypes.func,
		// fires when the menu is closed
		onCloseResetsInput: PropTypes.bool,
		// whether input is cleared when menu is closed through the arrow
		onFocus: PropTypes.func,
		// onFocus handler: function (event) {}
		onInputChange: PropTypes.func,
		// onInputChange handler: function (inputValue) {}
		onInputKeyDown: PropTypes.func,
		// input keyDown handler: function (event) {}
		onMenuScrollToBottom: PropTypes.func,
		// fires when the menu is scrolled to the bottom; can be used to paginate options
		onOpen: PropTypes.func,
		// fires when the menu is open
		onSelectResetsInput: PropTypes.bool,
		// whether input is cleared on select (works only for multiselect)
		onValueClick: PropTypes.func,
		// onClick handler for value labels: function (value, event) {}
		openOnClick: PropTypes.bool,
		// boolean to control opening the menu when the control is clicked
		openOnFocus: PropTypes.bool,
		// always open options menu on focus
		optionClassName: PropTypes.string,
		// additional class(es) to apply to the <Option /> elements
		optionComponent: PropTypes.func,
		// option component to render in dropdown
		optionRenderer: PropTypes.func,
		// optionRenderer: function (option) {}
		options: PropTypes.array,
		// array of options
		pageSize: PropTypes.number,
		// number of entries to page when using page up/down keys
		placeholder: stringOrNode,
		// field placeholder, displayed when there's no value
		removeSelected: PropTypes.bool,
		// whether the selected option is removed from the dropdown on multi selects
		required: PropTypes.bool,
		// applies HTML5 required attribute when needed
		resetValue: PropTypes.any,
		// value to use when you clear the control
		rtl: PropTypes.bool,
		// set to true in order to use react-select in right-to-left direction
		scrollMenuIntoView: PropTypes.bool,
		// boolean to enable the viewport to shift so that the full menu fully visible when engaged
		searchable: PropTypes.bool,
		// whether to enable searching feature or not
		simpleValue: PropTypes.bool,
		size: PropTypes.string, // pass the value to onChange as a simple value (legacy pre 1.0 mode), defaults to false
		style: PropTypes.object, // optional style to apply to the control
		tabIndex: stringOrNumber, // optional tab index of the control
		tabSelectsValue: PropTypes.bool, // whether to treat tabbing out while focused to be value selection
		trimFilter: PropTypes.bool, // whether to trim whitespace around filter value
		value: PropTypes.any, // initial field value
		valueComponent: PropTypes.func, // value component to render
		valueKey: PropTypes.string, // path of the label value in option objects
		valueRenderer: PropTypes.func, // valueRenderer: function (option) {}
		wrapperStyle: PropTypes.object, // optional style to apply to the component wrapper
	};

	constructor(props) {
		super(props);

		this.el = createRef();
	}

	loadOptions() {
		if (this.el.current) {
			return this.el.current.loadOptions.apply(this.el.current, arguments);
		}
	}

	getInputValue() {
		if (!this.el.current) {
			return null;
		}

		return this.el.current.inputValue && this.el.current.inputValue;
	}

	handleInputBlur = event => {
		const { delimiter = ',', onChange, simpleValue, value = [] } = this.props;
		// const val = this.getInputValue();
		const val = event.target.value;
		if (!onChange || !val) {
			return;
		}
		if (simpleValue) {
			onChange([...value, val].join(delimiter));
		}
		onChange([...value, { label: val, value: val }]);
	};

	handleChange = (...args) => {
		const { onChange } = this.props;
		if (!onChange) {
			return;
		}
		onChange(...args);
		console.log(this.el.current);
		console.log(this.el.current.select);
		console.log(this.el.current.setInputValue);
		if (!this.el.current || !this.el.current.select) {
			setImmediate(() => this.el.current.select.setInputValue(''));
			return;
		}
		this.el.current.select.setInputValue('');
	};

	render() {
		let { className, onChange, size, value, ...props } = this.props;

		className = classNames(className, CLASS, { [`${CLASS}-${size}`]: !!size });

		const creatable = props.allowCreate || props.creatable;
		let Class;
		if (props.autoBlur === undefined) {
			props.autoBlur = false;
		}
		if (creatable) {
			props.isOptionUnique =
				props.isOptionUnique === undefined ? isOptionUnique : props.isOptionUnique;
			// if (props.isOptionUnique === isOptionUnique && props.simpleValue) {
			// 	delete props.isOptionUnique;
			// }
			Class = props.async ? AsyncCreatable : Creatable;
		} else {
			Class = props.async ? Async : ReactSelect;
		}

		if (creatable && props.multi && props.onBlur === undefined) {
			props.onBlurResetsInput =
				props.onBlurResetsInput === undefined ? true : props.onBlurResetsInput;
			props.onSelectResetsInput =
				props.onSelectResetsInput === undefined ? false : props.onSelectResetsInput;
			props.onCloseResetsInput =
				props.onCloseResetsInput === undefined ? false : props.onCloseResetsInput;
			props.onBlur = this.handleInputBlur;
			props.onChange = this.handleChange;
		}

		delete props.async;
		if (props.multi && Array.isArray(value)) {
			value = value.map(v => {
				if (typeof v === 'string') {
					return { label: v, value: v };
				}
				return v;
			});
		}

		return (
			<Class ref={this.el} className={className} value={value} onChange={onChange} {...props} />
		);
	}
}

export default Select;
