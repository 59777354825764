import * as api from '@/lib/api';
import { withType } from '@/lib/util';
import { Toast, TOAST_TYPES } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast } from './application';

/**
 * @param {DeviceCriteria} criteria
 * @param {PaginationInfo} fromPage Used for pagination
 */

export const loadDevices =
	(criteria, fromPage = null) =>
	(dispatch, getState, container) => {
		dispatch(
			withType(TYPES.AGORITY_DEVICE_LOAD_START, {
				invalidateAll: !fromPage,
			}),
		);
		return api.getAgorityDeviceList(container.http, criteria, fromPage).then(
			queryResult => {
				dispatch(
					withType(TYPES.DEVICE_GOT_DATA, {
						data: queryResult,
					}),
				);
				dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
			},
			error => {
				dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
			},
		);
	};

export const masterKeyGet = () => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.getMasterKey(container.http).then(
		queryResult => {
			dispatch(
				withType(TYPES.DEVICE_MASTER_KEY, {
					data: queryResult.master_key,
				}),
			);
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
		},
	);
};

export const setNewDeviceInfo =
	(id, name, owner, userId, image) => (dispatch, getState, container) => {
		return dispatch(
			withType(TYPES.DEVICE_NEW_DETAIL, {
				data: { id, image, name, owner, userId },
			}),
		);
	};

export const saveNewDeviceInfo = json => (dispatch, getState, container) => {
	const payload = {};
	payload.key = json.id;
	payload.device_name = json.name;
	payload.device_owner = json.owner;
	payload.user_id = json.userId;
	return api.addDeviceById(container.http, payload).then(
		queryResult => {
			dispatch(addToast(new Toast(`Device added successfully`)));
			dispatch(loadDevices());

			dispatch(closeDeviceModal());
		},
		error => {
			dispatch(addToast(new Toast(error.message, TOAST_TYPES.danger)));

			dispatch(closeDeviceModal());
		},
	);
};

export const deleteUserSelectedDevice = deviceId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.deleteDevice(container.http, deviceId).then(
		queryResult => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
			dispatch(loadDevices());
			dispatch(addToast(new Toast(`Device deleted successfully`)));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
		},
	);
};

export const changeDeviceStatus = (deviceId, status) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.setDeviceStatus(container.http, deviceId, status).then(
		queryResult => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
			dispatch(loadDevices());
			dispatch(addToast(new Toast(`Device status saved successfully`)));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
		},
	);
};

export const editedDeviceSet = field => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.setEditedDevice(container.http, field.id, field).then(
		queryResult => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
			dispatch(loadDevices());
			dispatch(closeDeviceModal());
			dispatch(addToast(new Toast(`Device saved successfully`)));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
			dispatch(addToast(new Toast(error.message, TOAST_TYPES.danger)));
		},
	);
};

export const getOwnerList = companyId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.getOwnerList(container.http, companyId).then(
		queryResult => {
			dispatch(
				withType(TYPES.DEVICE_OWNER_LIST, {
					data: queryResult,
				}),
			);
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
		},
	);
};

export const getCustomerOwnerList = userId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_START));
	return api.getUser(container.http, userId).then(
		queryResult => {
			dispatch(
				withType(TYPES.CUSTOMER_DEVICE_OWNER_LIST, {
					data: queryResult,
				}),
			);
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END));
		},
		error => {
			dispatch(withType(TYPES.AGORITY_DEVICE_LOAD_END, { error }));
		},
	);
};

export const deviceModalControl = operation => withType(TYPES.OPEN_DEVICE_MODAL, { operation });

export const closeDeviceModal = () => withType(TYPES.CLOSE_DEVICE_MODAL);
