import * as ReactRedux from 'react-redux';
import { createBrowserHistory } from 'history';
import cookie from 'js-cookie';
import moment from 'moment';

import * as RouteHooks from './hooks/route-hooks';
import * as http from './lib/http';
import { log } from './lib/logger';
import * as routes from './lib/routes';
import { Sockets } from './lib/sockets';
import { getLocationWithQuery } from './lib/util';
import ENV from './env';
import configureStore from './redux_store';

/**
 * @typedef {import('react-redux').TypedUseSelectorHook<T>} TypedUseSelectorHook
 * @template T
 */

export class Container {
	constructor(values) {
		/** @type {import('./lib/http')} */
		this.http = null;

		/** @type {import('js-cookie').Cookies} */
		this.cookie = null;

		/** @type {import('history').History} */
		this.history = null;

		/** @type {SvStore} */
		this.store = null;

		/** @type {import('./hooks/route-hooks')} */
		this.route_hooks = null;

		/** @type {Sockets} */
		this.socket = null;

		values && Object.assign(this, values);

		if (!this.route_hooks) {
			this.route_hooks = RouteHooks;
		}

		// if (this.history) {
		// 	let origCreateHref = this.history.createHref;
		// 	history.createHref = location => {
		// 		if (typeof location === 'string') {
		// 			return origCreateHref({pathname: location});
		// 		}
		// 		return origCreateHref(location);
		// 	};
		// 	history.createPath = location => {
		// 		if (typeof location === 'string') {
		// 			return createPath({pathname: location});
		// 		}
		// 		return createPath(location);
		// 	};
		// }
	}
}

/**
 * @type {Container}
 */
export const container = new Container({
	cookie,
	history: createBrowserHistory({ basename: `${window.location.pathname}#` }),
	http,
	socket: null,
	store: null,
});

const store = configureStore(container);

container.store = store;

container.socket = new Sockets(store);
// @ts-ignore
window.socket = container.socket;

if (ENV.log.enabled && ENV.log.contexts && ENV.log.contexts.store) {
	store.subscribe(() => {
		log('store', store.getState());
	});
}

const location = getLocationWithQuery(container.history.location);
// @ts-ignore
const debugMode = Object.prototype.hasOwnProperty.call(location.query || {}, 'debug');

if (ENV.dev || debugMode) {
	// @ts-ignore
	window.container = container;
	// @ts-ignore
	window.store = store;
	// @ts-ignore
	window.socket = container.socket;
	// @ts-ignore
	window.routes = routes;
	// @ts-ignore
	window.moment = moment;
	// @ts-ignore
	window._ENV = ENV;
}

/** @type {TypedUseSelectorHook<PartialState>} */
export const { useSelector } = ReactRedux;
export const useDispatch = () => container.store.dispatch;
export const useStore = () => container.store;

/**
 * Singleton alert!
 */
export default container;
