import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import ENV from '@/env';
import { convertDateToHumanReadable } from '@/lib/util';
import { getUserWithDetailsSelector } from '@/selectors/users';
import { TIME_ZONES } from '@/types/datetime';

/**
 * @return {null|Component}
 */

class FormattedDate extends Component {
	static propTypes = {
		date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		humanize: PropTypes.bool,
		withTime: PropTypes.bool,
	};

	static defaultProps = {
		datetime_format: ENV.date_format.locale,
		humanize: false,
		timezone: ENV.date_format.timezone,
		withTime: false,
	};

	render() {
		const { date, datetime_format, humanize, timezone, withTime } = this.props;
		const timezoneCode = TIME_ZONES.find(item => item.value === Number(timezone));

		if (!date) {
			return null;
		}

		let m;

		try {
			m = moment(date).locale(datetime_format).tz(timezoneCode.label);
			if (!m.isValid()) {
				return null;
			}
		} catch (_) {
			return null;
		}

		const content = humanize
			? convertDateToHumanReadable(m)
			: withTime
			? m.format('LLLL')
			: m.format('L');

		return (
			<time dateTime={m.toString()} title={m.format('LLL')}>
				{content}
			</time>
		);
	}
}

const mapStateToProps = (/** State */ state, props) => {
	const uwd = getUserWithDetailsSelector(state, props);
	const { timedate_format, timezone } = uwd
		? uwd.user
		: {
				timedate_format: ENV.date_format.locale,
				timezone: ENV.date_format.timezone,
		  };

	return {
		datetime_format: timedate_format || ENV.date_format.locale,
		timezone,
	};
};

export default connect(mapStateToProps, null)(FormattedDate);
