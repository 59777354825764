import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withEnter } from '@/lib/util';
import { takeFolderId } from '@/selectors/folders';
import FA from '@/types/font_awesome';
import { DEFAULT_CRITERIA, LIBRARY_COLUMN_HEADER, LIBRARY_NAMESPACE } from '@/types/library';
import LibraryView from '@/views/library/LibraryView';
import TextInput from '@/views/widgets/forms/TextInput';
import IconButton from '@/views/widgets/IconButton';

import './SaveAsModal.scss';

const CLASS = 'sv-SaveAsModal';
const COLLAPSIBLE_CLASS = `${CLASS}-collapsible`;

const DISABLED_COLUMNS = [
	LIBRARY_COLUMN_HEADER.url,
	LIBRARY_COLUMN_HEADER.owner,
	LIBRARY_COLUMN_HEADER.type,
	LIBRARY_COLUMN_HEADER.modified,
	LIBRARY_COLUMN_HEADER.fileSize,
];
class SaveAsModal extends Component {
	static defaultProps = {
		collapsed: undefined,
		displayFileNameEditor: true,
		displayLibrary: true,
		folderId: undefined,
		inputLabel: 'Name of content',
		inputPlaceholder: 'Write name here',
		plain: false,
		submitLabel: 'Save',
	};

	constructor(props) {
		super(props);

		this.state = {
			criteria: DEFAULT_CRITERIA.clone(),
			fileName: props.fileName || undefined,
			folderId: props.folderId,
		};
	}

	componentDidMount() {
		const { criteria, folderId = null } = this.props;
		this.handleCriteriaChanged(criteria);
		this.setState({ folderId });
	}

	handleFolderClick = (fwd, e) => {
		e.preventDefault();
		this.setState({ folderId: fwd.id || fwd.folder.id });
	};

	handleCriteriaChanged = newCriteria => {
		const { criteria } = this.state;
		if (!newCriteria || criteria.equals(newCriteria)) {
			return null;
		}
		this.setState({ criteria: newCriteria });
	};

	handleFileNameChanged = val => this.setState({ fileName: val });

	handleSubmit = () => {
		const { fileName, folderId } = this.state;
		const { onSubmit } = this.props;

		onSubmit(folderId, fileName);
	};

	renderModalHeader = () => {
		const { onClose, title } = this.props;
		if (!title) {
			return null;
		}

		return (
			<Modal.Header className={`${CLASS}-header`}>
				<div className={`${CLASS}-header-title`}>{title}</div>

				<div className={`${CLASS}-header-close`}>
					<IconButton icon={FA.close} onClick={onClose} />
				</div>
			</Modal.Header>
		);
	};

	collapsibleClosed() {
		return (
			<div className={COLLAPSIBLE_CLASS}>
				<div className={`${CLASS}-name`}>
					Location: <FontAwesomeIcon icon={FA.chevron_right} />
				</div>
			</div>
		);
	}

	collapsibleOpen() {
		return (
			<div className={COLLAPSIBLE_CLASS}>
				<div className={`${CLASS}-name`}>
					Location <FontAwesomeIcon icon={FA.chevron_down} />
				</div>
			</div>
		);
	}

	renderLibraryView() {
		const { criteria, folderId } = this.state;
		return (
			<LibraryView
				disableFiles
				disableFilter
				displayNewFolder
				createFileHref={null}
				createFolderHref={null}
				disableActions={!folderId}
				disabledColumns={DISABLED_COLUMNS}
				displaySort={false}
				displayUpload={false}
				folderId={folderId}
				foldersCriteria={criteria}
				namespace={LIBRARY_NAMESPACE.modal}
				selectable={false}
				onContextMenu={null}
				onCriteriaChanged={this.handleCriteriaChanged}
				onFolderClick={this.handleFolderClick}
			/>
		);
	}

	renderModalBody = () => {
		const { collapsed, displayFileNameEditor, displayLibrary, inputLabel, inputPlaceholder } =
			this.props;

		const { fileName } = this.state;

		return (
			<Modal.Body className={`${CLASS}-body`}>
				{displayLibrary && collapsed !== undefined ? (
					<Collapsible
						className={`${CLASS}-acord`}
						open={collapsed}
						trigger={this.collapsibleClosed()}
						triggerWhenOpen={this.collapsibleOpen()}
					>
						{this.renderLibraryView()}
					</Collapsible>
				) : displayLibrary ? (
					this.renderLibraryView()
				) : null}

				{displayFileNameEditor && (
					<TextInput
						label={inputLabel}
						placeholder={inputPlaceholder}
						value={fileName}
						onChange={this.handleFileNameChanged}
						onKeyDown={withEnter(this.handleSubmit)}
					/>
				)}
			</Modal.Body>
		);
	};

	renderModalFooter = () => {
		const { displayFileNameEditor, onClose, submitLabel } = this.props;

		const { fileName, folderId } = this.state;
		const disabled = !folderId || (displayFileNameEditor && fileName === '');

		return (
			<Modal.Footer className={`${CLASS}-buttonWrapper`}>
				<IconButton secondary onClick={onClose}>
					Cancel
				</IconButton>
				<IconButton disabled={disabled} onClick={this.handleSubmit}>
					{submitLabel}
				</IconButton>
			</Modal.Footer>
		);
	};

	render() {
		const { plain } = this.props;
		const plainClassName = plain ? `${CLASS}-plain` : '';

		return (
			<div className={`${CLASS} ${plainClassName}`}>
				{this.renderModalHeader()}
				{this.renderModalBody()}
				{this.renderModalFooter()}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		folderId: takeFolderId(state, props),
	};
};

export default connect(mapStateToProps, null)(SaveAsModal);
