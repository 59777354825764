import { Component, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';
import TextInput from '@/views/widgets/forms/TextInput';

import './TextInputWithIcon.scss';

const CLASS = 'sv-TextInputWithIcon';

class TextInputWithIcon extends Component {
	/**
	 * @type {typeof TextInput.propTypes}
	 */
	static propTypes = {
		...TextInput.propTypes,
		beforeElement: PropTypes.any,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		hasError: PropTypes.bool,
		helpBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		icon: PropTypes.any,
		id: PropTypes.string,
		initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		onChange: PropTypes.func,
		onIconClick: PropTypes.func,
		onKeyDown: PropTypes.func,
		optional: PropTypes.bool,
		placeholder: PropTypes.string,
		rows: PropTypes.number,
		showFeedbackIcon: PropTypes.bool,
		size: PropTypes.string,
		type: PropTypes.string,
		validator: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	};

	setInputValue(value) {
		let { input } = this;
		if (!input) {
			return;
		}
		if (typeof input.getInstance === 'function') {
			input = input.getInstance();
		}
		input.setInputValue && input.setInputValue(value);
	}

	renderIcon() {
		let { icon } = this.props;
		if (!icon) {
			return null;
		}

		if (typeof icon === 'function') {
			icon = icon();
		}
		if (typeof icon === 'string' && FA[icon]) {
			icon = <FontAwesomeIcon icon={FA[icon]} />;
		}

		if (typeof icon === 'object' && !isValidElement(icon)) {
			icon = <FontAwesomeIcon icon={icon} />;
		}
		return (
			<div className={`${CLASS}-icon`} onClick={this.props.onIconClick}>
				{icon}
			</div>
		);
	}

	render() {
		const { children, className, icon, onIconClick, type, ...props } = this.props;

		return (
			<TextInput
				ref={input => (this.input = input)}
				beforeElement={this.renderIcon()}
				className={`${className || ''} ${CLASS}`}
				type={type || 'input'}
				{...props}
			>
				{children}
			</TextInput>
		);
	}
}

export default TextInputWithIcon;
