import { forwardRef, useEffect, useMemo } from 'react';
import { FeatureGroup, MapContainer, ScaleControl } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L, { DivIcon, latLng, latLngBounds, Point, Polyline } from 'leaflet';

import './FloorplanSketcher.scss';

const CLASS = 'sv-FloorplanSketcher';

const DEFAULT_CENTER = latLng(0, 0);

const LINE_WEIGHT = 10;

const EDIT_OPTIONS = {
	selectedPathOptions: {
		maintainColor: true,
	},
};

const DRAW_OPTIONS = {
	circle: false,
	circlemarker: false,
	marker: false,
	polygon: {
		allowIntersection: false,
		shapeOptions: {
			color: 'black',
			fill: false,
			opacity: 1,
			weight: LINE_WEIGHT,
		},
	},
	polyline: {
		allowIntersection: false,
		icon: new DivIcon({
			className: 'leaflet-div-icon leaflet-editing-icon',
			iconSize: new Point(8, 8),
		}),
		shapeOptions: {
			color: 'black',
			guidelineDistance: 10,
			opacity: 1,
			weight: LINE_WEIGHT,
		},
	},
	rectangle: {
		shapeOptions: {
			color: 'black',
			fill: false,
			opacity: 1,
			weight: LINE_WEIGHT,
		},
	},
};

/** @typedef {import('react').RefObject<import('react-leaflet').FeatureGroup>} FeatureGroupRef */
/** @typedef {import('react').Ref<import('react-leaflet').Map>} MapRef */

/**
 * @typedef {Object} FloorplanSketcherProps
 * @property {FeatureGroupRef} fgRef
 * @property {any} [zoomPanOptions]
 * @property {any} [lines]
 * @property {any} [onChange]
 * @property {any} [onCreated]
 * @property {any} [onDrawStop]
 * @property {any} [onEditStop]
 */

/**
 *
 * @param {FloorplanSketcherProps & Partial<import('react-leaflet').MapProps>} param0
 * @param {MapRef} ref
 */
export function FloorplanSketcher(
	{
		bounds,
		className = null,
		fgRef = null,
		lines,
		maxZoom = 14,
		minZoom = 4,
		onChange = null,
		zoom = 9,
		zoomPanOptions = {
			animate: true,
			duration: 0.25,
			easeLinearity: 0.25,
			noMoveStart: false,
		},
		// onCreated = null,
		// onDrawStop = null,
		// onEditStop = null,
		...props
	},
	ref,
) {
	/**
	 * onEdited			hook to leaflet-draw's draw:edited event
	 * onCreated		hook to leaflet-draw's draw:created event
	 * onDeleted		hook to leaflet-draw's draw:deleted event
	 * onMounted		hook to leaflet-draw's draw:mounted event
	 * onEditStart		hook to leaflet-draw's draw:editstart event
	 * onEditStop		hook to leaflet-draw's draw:editstop event
	 * onDeleteStart	hook to leaflet-draw's draw:deletestart event
	 * onDeleteStop		hook to leaflet-draw's draw:deletestop event
	 * onDrawStart		hook to leaflet-draw's draw:drawstart event
	 * onDrawStop		hook to leaflet-draw's draw:drawstop event
	 * onDrawVertex		hook to leaflet-draw's draw:drawvertex event
	 * onEditMove		hook to leaflet-draw's draw:editmove event
	 * onEditResize		hook to leaflet-draw's draw:editresize event
	 * onEditVertex		hook to leaflet-draw's draw:editvertex event
	 */

	// const [fgBounds, setFgBounds] = useState(null);

	useEffect(() => {
		// window.sketcherRef = ref;
		// window.fgRef = fgRef;

		requestAnimationFrame(() => {
			const featureGroup = fgRef?.current?.leafletElement;
			// window.sketcherMap = sketcherRef?.current?.leafletElement;
			// window.featureGroup = featureGroup;

			if (!featureGroup || !lines) return;

			featureGroup.clearLayers();
			for (const lineString of lines) {
				const polyLine = new Polyline(lineString, DRAW_OPTIONS.polyline.shapeOptions);
				featureGroup.addLayer(polyLine);
			}
			// const bounds = latLngBounds(featureGroup.getBounds());
			// setFgBounds(bounds);
		});
	}, [ref, fgRef, lines]);

	const initialBounds = useMemo(() => (bounds ? latLngBounds(bounds) : null), [bounds]);
	// const changedHandler = useCallback((...args) => {
	// 	const featureGroup = fgRef.current.leafletElement;
	// 	const bounds = latLngBounds(featureGroup.getBounds());
	// 	setFgBounds(bounds);
	// }, [fgRef]);

	// const drawStopHandler = useCallback((...args) => {
	// 	changedHandler(...args);
	// 	onDrawStop?.(...args);
	// }, [onDrawStop, changedHandler]);
	// const editStopHandler = useCallback((...args) => {
	// 	changedHandler(...args);
	// 	onEditStop?.(...args);
	// }, [onEditStop, changedHandler]);

	return (
		<div className={CLASS}>
			<MapContainer
				ref={ref}
				preferCanvas
				attributionControl={false}
				bounceAtZoomLimits={false}
				bounds={initialBounds}
				center={DEFAULT_CENTER}
				crs={L.CRS.Simple}
				maxZoom={maxZoom}
				minZoom={minZoom}
				sleep={false}
				tap={false}
				zoom={zoom}
				zoomPanOptions={zoomPanOptions}
			>
				<ScaleControl />
				{/* {fgBounds ? <Rectangle bounds={fgBounds} fillColor="#00A49A"/> : null} */}
				<FeatureGroup ref={fgRef}>
					<EditControl draw={DRAW_OPTIONS} edit={EDIT_OPTIONS} position="topleft" {...props} />
				</FeatureGroup>
			</MapContainer>
		</div>
	);
}

export default forwardRef(FloorplanSketcher);
