import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';

import './Label.scss';

function Label({
	children = null,
	className = null,
	mandatory = null,
	optional = null,
	...passProps
}) {
	className = classes(className, 'sv-Label', mandatory && 'sv-Label-mandatory');

	const mandatoryIndicator = mandatory && <span className="sv-Label-mandatory-indicator">*</span>;
	const optionalIndicator = optional && (
		<span className="sv-Label-optional-indicator">(optional)</span>
	);

	return (
		<Form.Label {...passProps} className={className}>
			{children}
			{mandatoryIndicator}
			{optionalIndicator}
		</Form.Label>
	);
}

Label.propTypes = {
	className: PropTypes.string,
	htmlFor: PropTypes.string,
	mandatory: PropTypes.bool,
	optional: PropTypes.bool,
};

export default Label;
