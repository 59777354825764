import * as api from '@/lib/api';
import { withType } from '@/lib/util';
import { Toast } from '@/types/toast';
import { Criteria } from '@/types/vision';

import * as TYPES from './action_types';
import { addToast, addToastWarning } from './application';

/**
 * @param {Partial<VisionState['viewer']>} settings
 * @returns {ThunkAction<any>}
 */
export const setViewerSettings = settings => dispatch =>
	dispatch(withType(TYPES.VISION_VIEWER_SETTINGS, settings));

export const storeCameraPlacement = (cameraPlacement, cameraContext) => dispatch =>
	dispatch(withType(TYPES.VISION_STORE_CAMERA_PLACEMENT, { cameraContext, cameraPlacement }));

/**
 * @param {any} type
 * @returns {ThunkAction<any>}
 */
export const setAssetTypeFilter = type => dispatch =>
	dispatch(withType(TYPES.SET_ASSET_TYPE_FILTER, { assetType: type }));

/**
 * @param {boolean} [fromPage]
 */
function getStartPayload(fromPage) {
	return {
		invalidateAll: !fromPage,
		loading: true,
		paginating: !!fromPage,
	};
}

/**
 * @param {Criteria} [criteria]
 * @returns {ThunkAction<Promise<any>>}
 */
export const loadAssets =
	(criteria, fromPage = null, params = {}) =>
	(dispatch, getState, container) => {
		if (typeof criteria !== 'object') {
			criteria = new Criteria({ id: criteria });
		}
		if (typeof params !== 'object') {
			params = {};
		}

		dispatch(withType(TYPES.VISION_LOAD_START, getStartPayload(fromPage)));

		return api.getVisionAssets(container.http, criteria, fromPage).then(
			res => {
				dispatch(
					withType(TYPES.VISION_GOT_DATA, {
						data: res.data,
						nextPage: res.next_page,
					}),
				);
				dispatch(withType(TYPES.VISION_LOAD_END, {}));
				return res.data;
			},
			error => {
				dispatch(withType(TYPES.VISION_LOAD_END, { error }));
			},
		);
	};

/**
 * @returns {ThunkAction<Promise<any>>}
 */
export const loadAsset = assetId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.VISION_LOAD_START, {}));

	return api.getVisionAsset(container.http, assetId).then(
		res => {
			dispatch(
				withType(TYPES.VISION_GOT_DATA, {
					data: [res],
				}),
			);
			dispatch(withType(TYPES.VISION_LOAD_END, {}));
			return res;
		},
		error => {
			dispatch(withType(TYPES.VISION_LOAD_END, { error }));
		},
	);
};

/**
 * @param {number} id
 * @param {Partial<AssetVariant>} payload
 * @returns {ThunkAction<Promise<any>>}
 */
export const saveVariant =
	(id, payload, showSuccessToast = false) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.VISION_OP_START));

		const isNew = !id;
		const promise = id
			? api.updateAssetVariant(container.http, id, payload)
			: api.createAssetVariant(container.http, payload);

		return promise.then(
			res => {
				id = res.record.id;
				if (!id) {
					throw new Error('Unexpected response');
				}
				dispatch(withType(TYPES.VISION_OP_END));

				if (showSuccessToast) {
					dispatch(
						addToast(new Toast(`Asset variant ${isNew ? 'created' : 'updated'} successfully`)),
					);
				}
				dispatch(loadAsset(res.record.asset_id));
			},
			error => {
				dispatch(addToastWarning(error.message));
				dispatch(withType(TYPES.VISION_OP_END, { error }));
			},
		);
	};

export const uploadVariantToIon = id => {
	const payload = {
		cesium_ion_asset_id: null,
		cesium_ion_asset_status: null,
	};
	return saveVariant(id, payload);
};
/**
 * @param {number} id
 * @returns {ThunkAction<Promise<any>>}
 */
export const downloadVariantFromIon = id => (dispatch, getState, container) => {
	return api.downloadAssetVariantFromIon(container.http, id).then(
		() => dispatch(addToast(new Toast(`Asset variant downloaded successfully`))),
		error => dispatch(addToastWarning(error.message)),
	);
};

export const resetOpError = () => {
	return withType(TYPES.VISION_OP_RESET);
};
