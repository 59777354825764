import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = {
	enquiries: {
		assets: {},
		bespoke: null,
		data: null,
		loadError: null,
		messages: {},
		nextPage: null,
		op: null,
		params: null,
		preparingAssetDownload: {},
		standard: null,
		uploader: null,
		uploads: {},
	},
};

const helper = new ReduceHelper('enquiries', 'enquiry.id');

const messagesLoadStart = state => {
	return helper.opStart(state);
};

const messagesLoadEnd = (state, action) => {
	const messagesObject = {
		messages: {},
	};
	messagesObject.messages[action.enquiryId] = action.messages;
	messagesObject.op = null;
	return helper.updateNamespace(state, messagesObject);
};

const assetsLoadStart = state => {
	return helper.opStart(state);
};

const assetsLoadEnd = (state, action) => {
	const assetsObject = {
		assets: {},
	};
	assetsObject.assets[action.enquiryId] = action.assets;
	assetsObject.op = null;
	return helper.updateNamespace(state, assetsObject);
};

const assetsDownloadStart = (state, action) => {
	return helper.updateNamespace(state, {
		preparingAssetDownload: {
			...state.enquiries.preparingAssetDownload,
			[action.enquiryId]: new Date(),
		},
	});
};

const assetsDownloadEnd = (state, action) => {
	return helper.updateNamespace(state, {
		preparingAssetDownload: {
			...state.enquiries.preparingAssetDownload,
			[action.enquiryId]: null,
		},
	});
};

const gotStandardTour = (state, action) => {
	return helper.updateNamespace(state, {
		standard: action.data,
	});
};

const gotBespokeTour = (state, action) => {
	return helper.updateNamespace(state, {
		bespoke: action.data,
	});
};

const gotProductTour = (state, action) => {
	return helper.updateNamespace(state, {
		[action.orderType]: action.data,
	});
};

const gotImportedOrder = (state, action) => {
	if (!state.enquiries.importedEnquiries) {
		return helper.updateNamespace(state, {
			importedEnquiries: [],
		});
	}

	const improtedEnquiries = state.enquiries.importedEnquiries;
	action.enquiry.index = improtedEnquiries.length;
	const enquiries = [...improtedEnquiries, action.order];
	return helper.updateNamespace(state, {
		importedEnquiries: enquiries,
	});
};

const gotEnquiryImportError = (state, action) => {
	if (!state.enquiries.importEnquiryErrors) {
		helper.updateNamespace(state, {
			importEnquiryErrors: [],
		});
	}

	const { importEnquiryErrors } = state.enquiries;
	action.enquiry.index = importEnquiryErrors.length;
	const errors = [...importEnquiryErrors, { error: action.error, order: action.order }];
	return helper.updateNamespace(state, {
		importEnquiryErrors: errors,
	});
};

const emptyImportedEnquiriesList = state => {
	return helper.updateNamespace(state, {
		importEnquiryErrors: [],
		importedEnquiries: [],
	});
};

export default {
	INITIAL_STATE,
	[TYPES.ENQUIRIES_GOT_DATA]: helper.gotData,
	[TYPES.ENQUIRIES_LOAD_START]: helper.loadStart,
	[TYPES.ENQUIRIES_LOAD_END]: helper.loadEnd,
	[TYPES.ENQUIRIES_OP_START]: helper.opStart,
	[TYPES.ENQUIRIES_OP_END]: helper.opEnd,
	[TYPES.ENQUIRIES_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
	[TYPES.ENQUIRIES_MESSAGES_LOAD_START]: messagesLoadStart,
	[TYPES.ENQUIRIES_MESSAGES_LOAD_END]: messagesLoadEnd,
	[TYPES.ENQUIRIES_MESSAGE_SEND_START]: helper.opStart,
	[TYPES.ENQUIRIES_MESSAGE_SEND_END]: helper.opEnd,
	[TYPES.ENQUIRIES_ASSETS_LOAD_START]: assetsLoadStart,
	[TYPES.ENQUIRIES_ASSETS_LOAD_END]: assetsLoadEnd,
	[TYPES.ENQUIRIES_ASSET_DOWNLOAD_START]: assetsDownloadStart,
	[TYPES.ENQUIRIES_ASSET_DOWNLOAD_END]: assetsDownloadEnd,
	[TYPES.ENQUIRIES_GOT_STANDARD_TOUR]: gotStandardTour,
	[TYPES.ENQUIRIES_GOT_BESPOKE_TOUR]: gotBespokeTour,
	[TYPES.ENQUIRIES_GOT_TOUR]: gotProductTour,
	[TYPES.ENQUIRIES_SINGLE_IMPORT]: gotImportedOrder,
	[TYPES.ENQUIRIES_EMPTY_IMPORTED_ENQUIRIES_LIST]: emptyImportedEnquiriesList,
	[TYPES.ENQUIRIES_FAILED_ENQUIRY_IMPORT]: gotEnquiryImportError,
};
