import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';

import container from '@/container';
import { RequestHandlerProvider } from '@/contexts/request-handler.context';
import reactQueryClient from '@/react-query.config';
import { AppRouter } from '@/routes/AppRouter';
import BootingScreen from '@/views/app/BootingScreen';

function Root() {
	return (
		<Provider store={container.store}>
			<RequestHandlerProvider>
				<QueryClientProvider client={reactQueryClient}>
					<BootingScreen>
						<AppRouter />
					</BootingScreen>
				</QueryClientProvider>
			</RequestHandlerProvider>
		</Provider>
	);
}

export default Root;
