import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { MEDIA_FORMATS_NONE_VALUE, SITE_ICONS, SITE_ICONS_ALT, SITE_TYPES } from '@/types/sites';

function SiteIcon({ alt = false, className = null, siteType, ...rest }) {
	if (!siteType || siteType === MEDIA_FORMATS_NONE_VALUE) {
		siteType = SITE_TYPES.other;
	}
	const icon = alt ? SITE_ICONS_ALT[siteType] : SITE_ICONS[siteType];
	className = classNames('sv-SiteIcon', className);
	return <FontAwesomeIcon className={className} icon={icon} {...rest} />;
}

SiteIcon.propTypes = {
	alt: PropTypes.bool,
	siteType: PropTypes.string.isRequired,
};

export default SiteIcon;
