import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleLibraryView } from '@/actions/folders';
import { getLibraryState } from '@/selectors/folders';
import FA from '@/types/font_awesome';
import { LIBRARY_VIEW_TYPES, LIBRARY_VIEW_TYPES_FLIPPED } from '@/types/library';

function LibraryToggleView({ libraryView, onClick }) {
	const icon = libraryView === LIBRARY_VIEW_TYPES.grid ? FA.list_ul : FA.th;
	return (
		<div className="fa sv-LibraryView" onClick={onClick}>
			<FontAwesomeIcon icon={icon} />
		</div>
	);
}

const mapStateToProps = (state, props) => {
	const { libraryView } = getLibraryState(state, props);

	return {
		libraryView,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	toggleLibraryView: libraryView => dispatch(toggleLibraryView(libraryView, props.namespace)),
});

const mergeProps = ({ libraryView }, { toggleLibraryView }) => {
	return {
		libraryView,
		onClick: () => toggleLibraryView(LIBRARY_VIEW_TYPES_FLIPPED[libraryView]),
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LibraryToggleView);
