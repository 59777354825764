import PropTypes from 'prop-types';

import { classes } from '@/lib/util';

function TextSpan({
	children,
	className,
	danger,
	info,
	italic,
	muted,
	paragraph,
	success,
	warning,
}) {
	if (muted + success + info + danger > 1) {
		throw new Error(
			`You can only set one visual state. muted=${muted} success=${success} info=${info} danger=${danger} warning=${warning}`,
		);
	}

	const state =
		(muted && 'muted') ||
		(success && 'success') ||
		(info && 'info') ||
		(warning && 'warning') ||
		(danger && 'danger') ||
		null;

	className = classes(
		className,
		'sv-TextSpan',
		state && `sv-TextSpan-${state}`,
		state && `text-${state}`,
	);

	let result = italic ? (
		<i className={className}>{children}</i>
	) : (
		<span className={className}>{children}</span>
	);

	if (paragraph) {
		result = <p>{result}</p>;
	}

	return result;
}

TextSpan.propTypes = {
	className: PropTypes.string,
	danger: PropTypes.bool,
	info: PropTypes.bool,
	italic: PropTypes.bool,
	muted: PropTypes.bool,
	paragraph: PropTypes.bool,
	success: PropTypes.bool,
};

export default TextSpan;
