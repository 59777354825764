import ENV from '@/env';

// Tab List Creator

export const createTabList = (tabs, names, routeCreator) => {
	const tabList = Object.keys(tabs).map(tab => {
		return {
			id: tabs[tab],
			name: names[tab],
			route: routeCreator(tabs[tab]),
		};
	});

	return tabList;
};

// Tool Tabs

export const TOOLS_TABS = {
	brochure_tool: 'brochure_tool',
	planning_tool: 'planning_tool',
	sales_tool: 'sales-tool',
	virtual_screens_tool: 'virtual_screens_tool',
};

export const TOOLS_TABS_NAMES = {
	[TOOLS_TABS.sales_tool]: 'Sales View',
	[TOOLS_TABS.brochure_tool]: ENV.custom_brochure_naming?.route_name || 'Brochure View',
};
