import ReactDOM from 'react-dom/client';

import { overrideEnv } from './env';

import './fonts/sv-icons/styles.css';
import './styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const startApplication = async () => {
	await overrideEnv();

	// files are imported here instead of at the top level to prevent
	// any part of the app to load before the ENV overrides have been applied
	const container = require('./container');
	const { initialize } = require('./actions/application');
	require('./reselect-tools');

	container.default.store.dispatch(initialize());
};

await startApplication();

require('./init');

const Root = require('./Root').default;

root.render(
	// TODO: We need to turn this ON at certain point after fixing the issues: Cesium Viewer, redux, etc.
	// <React.StrictMode>
	<Root />,
	// </React.StrictMode>
);

// TODO: We should turn this ON: https://create-react-app.dev/docs/measuring-performance/
// reportWebVitals();
