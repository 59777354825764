import { isValidElement } from 'react';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';
import FA from '@/types/font_awesome';

import Label from './forms/Label';

import './IconButton.scss';

export const toFa = (icon, spin, ...rest) => {
	if (isValidElement(icon)) {
		return icon;
	}
	if (typeof icon === 'string' && FA[icon]) {
		icon = FA[icon];
	}
	return <FontAwesomeIcon key={icon} icon={icon} spin={spin} {...rest} />;
};

function IconButton({
	children = null,
	className = null,
	component = null,
	danger = false,
	disabled = false,
	icon = null,
	isDropdown = false,
	label = null,
	loading = false,
	secondary = false,
	spin = false,
	...props
}) {
	if (loading) {
		icon = FA.cog;
		disabled = spin = true;
	}

	if (icon) {
		icon = toFa(icon, spin);
	}

	className = classes(
		' sv-IconButton',
		!children && 'sv-IconButton-with-content',
		secondary && 'sv-IconButton-secondary',
		disabled && 'sv-IconButton-disabled',
		danger && 'sv-IconButton-danger',
		props.href && 'sv-IconButton-link',
		isDropdown && 'sv-IconButton-dropdown-Container',
		className,
	);

	if (typeof children !== 'object') {
		// wrap string or number
		children = <span>{children}</span>;
	}

	if (isDropdown) {
		const CLASS = 'sv-IconButton-dropdown';
		return (
			<div className={CLASS}>
				{label ? <Label>{label}</Label> : null}
				<DropdownButton
					align="end"
					disabled={disabled}
					id={undefined}
					title={undefined}
					variant={className}
					{...props}
				>
					{children}
				</DropdownButton>
			</div>
		);
	}

	const Component = component || Button;

	return (
		<Component className={className} disabled={disabled || loading} {...props}>
			{icon || null}
			{children}
		</Component>
	);
}

const IconPropShapeType = PropTypes.shape({
	icon: PropTypes.any,
	iconName: PropTypes.string,
	prefix: PropTypes.string,
});

IconButton.propTypes = {
	active: PropTypes.bool,
	block: PropTypes.bool,
	className: PropTypes.string,
	component: PropTypes.elementType,
	danger: PropTypes.bool,
	disabled: PropTypes.bool,
	href: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, IconPropShapeType]),
	isDropdown: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	secondary: PropTypes.bool,
	spin: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'reset', 'submit']),
};
export default IconButton;
