import { Component, createRef } from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { uploadImages } from '@/actions/application';
import ENV from '@/env';
import { makeImageUrl } from '@/lib/api';
import { dataUrlToBlob } from '@/lib/util';
import FA from '@/types/font_awesome';
import Label from '@/views/widgets/forms/Label';
import IconButton from '@/views/widgets/IconButton';
import ImageUploaderBox from '@/views/widgets/images/ImageUploaderBox';

import './ImageCropModal.scss';

const CLASS = 'sv-ImageCrop';

class ImageCropModal extends Component {
	static defaultProps = {
		allowCrop: true,
		allowUpload: false,
		aspectRatio: 1 / 1,
		imageWidth: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			image: null,
		};

		this.cropperRef = createRef();
	}

	onSave = () => {
		const records = [];
		const { onSubmit, upload } = this.props;
		const blobAvatar = dataUrlToBlob(this.cropperRef.current.getCroppedCanvas().toDataURL());
		const payload = [blobAvatar];

		return upload(payload)
			.then(res => {
				res.forEach(record => {
					records.push({ ...record, type: 'image' });
				});
			})
			.then(() => {
				onSubmit(records[0]);
			});
	};

	renderModalHeader = () => {
		const { onClose } = this.props;
		const header = 'Edit image';
		return (
			<Modal.Header>
				<Modal.Title>{header}</Modal.Title>
				<IconButton icon={FA.close} onClick={onClose} />
			</Modal.Header>
		);
	};

	onImageChanged = newImage => {
		this.setState({
			image: newImage,
		});
	};

	renderModalBody = () => {
		const { aspectRatio, image, imageWidth } = this.props;
		const newAvatar =
			(image && makeImageUrl(image.key, imageWidth)) ||
			(this.state.image && makeImageUrl(this.state.image.key, imageWidth));

		const upload = image || this.state.image;

		return (
			<Modal.Body>
				<FormGroup className="form-group">
					{upload ? (
						<Cropper
							ref={this.cropperRef}
							center
							cropBoxResizable
							highlight
							responsive
							scalable
							aspectRatio={aspectRatio}
							background={false}
							cropBoxMovable={false}
							dragMode="move"
							guides={false}
							minContainerHeight={300}
							minContainerWidth={300}
							minCropBoxHeight={256}
							minCropBoxWidth={256}
							src={newAvatar}
							style={{
								height: 300,
								width: 300,
							}}
						/>
					) : (
						<ImageUploaderBox
							centerImage
							isPreview
							disableClick={false}
							image={this.state.image}
							imageHeight={ENV.visuals.site_thumbnail_width}
							imageWidth={ENV.visuals.site_thumbnail_width}
							overlayIcon={FA.user}
							onImageChanged={this.onImageChanged}
						/>
					)}
				</FormGroup>
				{upload ? (
					<FormGroup className="form-group">
						<Label>
							<FontAwesomeIcon icon={FA.arrows} />
							Press and hold mouse to move image.
						</Label>
						<Label>
							<FontAwesomeIcon icon={FA.search_plus} />
							<FontAwesomeIcon icon={FA.search_minus} />
							Scroll inside canvas to zoom in/out the image.
						</Label>
					</FormGroup>
				) : null}
			</Modal.Body>
		);
	};

	renderModalFooter = () => {
		const { onClose } = this.props;
		return (
			<Modal.Footer>
				<IconButton secondary onClick={onClose}>
					Cancel
				</IconButton>
				<IconButton onClick={this.onSave}>Save</IconButton>
			</Modal.Footer>
		);
	};

	render() {
		return (
			<div className={CLASS}>
				{this.renderModalHeader()}
				{this.renderModalBody()}
				{this.renderModalFooter()}
			</div>
		);
	}
}
const mapDispatchToProps = dispatch => ({
	upload: files => dispatch(uploadImages(files)),
});

export default connect(null, mapDispatchToProps)(ImageCropModal);
