import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';

import './TablePlain.scss';

function TablePlain({
	after,
	bordered = true,
	children,
	className = null,
	condensed = true,
	striped = true,
}) {
	className = classes('sv-TablePlain', className);
	return (
		<div className={className}>
			<Table bordered={bordered} condensed={condensed} striped={striped}>
				{children}
			</Table>
			{after}
		</div>
	);
}

TablePlain.propTypes = {
	after: PropTypes.element,
	bordered: PropTypes.bool,
	striped: PropTypes.bool,
};

export default TablePlain;
