export const getResumableField = (state, endpoint) => {
	const target = state.uploads[endpoint];
	if (!target) {
		return null;
	}

	return target.resumableField;
};

export const getFiles = (state, endpoint) => {
	const target = state.uploads[endpoint];
	if (!target) {
		return [];
	}

	return Object.keys(target.files).map(key => target.files[key]);
};
