import { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import * as lodash from 'lodash';
import PropTypes from 'prop-types';

import { Criteria } from '@/types/data';
import { REPORT_CRITERIA, TIME_FRAME_LABELS, TIME_FRAMES } from '@/types/reports';
import Select from '@/views/widgets/input/Select';

const timeFrameOptions = lodash.map(TIME_FRAMES, key => {
	return {
		label: TIME_FRAME_LABELS[key],
		value: key,
	};
});

const MENU_HEIGHT_PX = 252; // To make it nicer

const CLASS = 'sv-ReportTimeFramePicker';

class ReportTimeFramePicker extends Component {
	static propTypes = {
		className: PropTypes.string,
		criteria: PropTypes.instanceOf(Criteria).isRequired,
		displayLabel: PropTypes.bool,
		label: PropTypes.string,
		onCriteriaChanged: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
	};

	static defaultProps = {
		displayLabel: true,
		label: 'Time label',
		placeholder: 'Select...',
	};

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange(option) {
		const updatedCriteria = this.props.criteria.update({
			[REPORT_CRITERIA.time_frame]: option ? option.value : null,
		});

		this.props.onCriteriaChanged(updatedCriteria);
	}

	render() {
		const {
			/** @type ReportCriteria */ className,
			criteria,
			displayLabel,
			label,
			onCriteriaChanged,
			placeholder,
			...props
		} = this.props;

		const finalClassName = `${className || ''} ${CLASS}`;
		const selectId = `${CLASS}_select`;

		return (
			<FormGroup className="finalClassName form-group" controlId={selectId} {...props}>
				{displayLabel && <Form.Label className="fw-bold">{label}</Form.Label>}

				<Select
					clearable
					openOnFocus
					inputProps={{ id: selectId }}
					menuContainerStyle={{ maxHeight: `${MENU_HEIGHT_PX + 2}px` }}
					menuStyle={{ maxHeight: `${MENU_HEIGHT_PX}px` }}
					options={timeFrameOptions}
					placeholder={placeholder}
					value={criteria.time_frame}
					onChange={this.onChange}
				/>
			</FormGroup>
		);
	}
}

export default ReportTimeFramePicker;
