import { updateJobProgress } from '@/actions/background_jobs';
import { siteLoaded } from '@/actions/sites';
import { assetProcessed, assetStatus } from '@/actions/uploads';
import ENV from '@/env';
import { EMIT, JOB_EVENTS, SITE_EVENTS, UPLOADER_EVENTS } from '@/types/sockets';

import logger from './logger';

/**
 * @typedef {object} Socket
 * @property {number} [readyState]
 * @property {{[x: string]: number}} [states]
 * @property {() => void} [open]
 * @property {() => void} [end]
 * @property {(callback?: (arg: Socket) => void) => Promise<void | Socket>} [onceOpen]
 * @property {(event: string, handler: () => void) => Socket} [on]
 * @property {(event: string, handler: () => void) => Socket} [once]
 * @property {(event: string, data: any, handler: () => void) => Socket} [send]
 * @property {(event: string, ...args: any | {(...args: any): void}) => Socket} [emit]
 * @property {(event: string, handler: () => void) => Socket} [removeListener]
 */

/**
 * @param {SvStore} store
 */
export function Sockets(store) {
	/** @typedef {import('primus')} Primus */
	/** @type {typeof Primus["Socket"] & Socket} */
	// @ts-ignore
	const { Primus } = window;

	if (!Primus) {
		logger.error('Primus is not defined, websockets support disabled');
		return;
	}

	// eslint-disable-next-line no-useless-escape
	const match = /^((?:[a-z]+:\/\/)?[^\/]+)/i.exec(ENV.api.base_url);
	if (!match) {
		throw new Error(`Invalid base URL: ${ENV.api.base_url}`);
	}

	const thisSockets = this;
	let socketUrl = match[1];

	/** @type {import('primus').SocketOptions} */
	let options = {
		manual: true,
		reconnect: {
			max: 1000 * 60 * 10, // Number: The max delay before we try to reconnect.
			min: 500, // Number: The minimum delay before we try reconnect.
			retries: Infinity, // Number: How many times we should try to reconnect.
		},
	};

	/** @type {Socket} */
	let _socket = new Primus(socketUrl, options);

	const listeners = {
		[UPLOADER_EVENTS.UPLOADS_ASSETS_PROCESSED]: asset => store.dispatch(assetProcessed(asset)),
		[UPLOADER_EVENTS.UPLOADS_ASSETS_STATUS]: asset => store.dispatch(assetStatus(asset)),
		[SITE_EVENTS.SITE_EDITED]: data => store.dispatch(siteLoaded(data)),
		[JOB_EVENTS.JOB_PROGRESS]: data => store.dispatch(updateJobProgress(data)),
	};

	Object.assign(
		thisSockets,
		/** @lends Sockets.prototype */ {
			_socket,
			emit,
			listeners,
			once,
			open,
			subscribe,
			unsubscribe,
		},
	);

	function open(url = socketUrl, opts = options) {
		// logger.log('sockets', 'open', url, opts);

		options = { ...options, ...opts };
		if (!url) {
			url = socketUrl;
		}

		if (_socket && _socket.readyState !== Primus.CLOSED) {
			_socket.once('end', () => open(url, opts));
			Object.keys(listeners).forEach(eventName => {
				_socket.removeListener(eventName, listeners[eventName]);
			});
			_socket.end();
			return;
		}

		if (url !== socketUrl) {
			socketUrl = url;
			thisSockets._socket = _socket = new Primus(url, options);

			// Primus readyStates, used internally to set the correct ready state.
			_socket.states = {
				// We're opening the connection.
				CLOSED: 2,
				// No active connection.
				OPEN: 3,
				OPENING: 1, // The connection is open.
			};

			// _socket.on('data', data => {
			// 	logger.log(
			// 		'sockets',
			// 		`data <== ${
			// 			data ? JSON.stringify(data) : ''
			// 		}`,
			// 	);
			// });
		}

		_socket.onceOpen = callback => {
			return new Promise(resolve => {
				if (_socket.readyState === Primus.OPEN) {
					return resolve(true);
				}
				return _socket.once('open', function onceOpen() {
					resolve(true);
				});
			}).then(() => {
				return typeof callback === 'function' ? callback(_socket) : null;
			});
		};

		if (_socket && _socket.readyState === Primus.CLOSED) {
			_socket.open();
			_socket.onceOpen(onReconnect);
		}

		// _socket.once('open', onReconnect);
		return _socket;
	}

	function onReconnect() {
		// logger.log('sockets', 'onReconnect');

		const { socketSubscriptions } = store.getState();
		Object.keys(listeners).forEach(eventName => {
			_socket.removeListener(eventName, listeners[eventName]);
			_socket.on(eventName, listeners[eventName]);
		});
		if (!socketSubscriptions || !socketSubscriptions.data) {
			return;
		}
		const rooms = Object.keys(socketSubscriptions.data);
		rooms.forEach(room => subscribe(room));
	}

	/**
	 * Listen for an {event} from server
	 * @param {string} room
	 * @param {string} event
	 * @param {(...args: any[]) => void} callback
	 */
	function once(room, event, callback) {
		if (room && typeof room === 'string') {
			const { socketSubscriptions } = store.getState();
			let alreadySubscribed;
			if (socketSubscriptions) {
				const { data } = socketSubscriptions;
				alreadySubscribed = data && data[room];
			}
			if (alreadySubscribed) {
				return once(null, event, callback);
			}

			return subscribe(room, err => {
				if (err) {
					return callback(err);
				}
				return once(null, event, (...args) => {
					unsubscribe(room);
					return callback(...args);
				});
			});
		}

		if (!event || typeof event !== 'string') {
			return;
		}
		if (typeof callback !== 'function') {
			return;
		}

		_socket.once(event, (...args) => {
			logIncoming(null, event, ...args);
			return callback(...args);
		});
	}

	/**
	 * Send {event} {data} to server
	 * @param {string} event
	 * @param {...any} args
	 * @returns
	 */
	function emit(event, ...args) {
		// function emit(event, data, callback) {
		const data = args;
		let callback;

		if (data.length && typeof data[data.length - 1] === 'function') {
			callback = data.pop();
		}
		logOutgoing(event, data);

		return _socket.send(event, data, function (err, response) {
			const args = [...arguments];
			if (err) {
				logIncoming(err, event, data, response);
			} else {
				logIncoming(null, event, data, response);
			}

			callback && callback(...args);
		});
	}

	function subscribe(room, callback) {
		emit(EMIT.SUBSCRIBE, room, callback);
	}

	function unsubscribe(room, callback) {
		emit(EMIT.UNSUBSCRIBE, room, callback);
	}

	function logOutgoing(event, data, ...params) {
		logger.log('sockets', `${event} ==> ${data ? JSON.stringify(data) : ''}`, ...params);
	}

	function logIncoming(err, event, data, ...params) {
		let method = 'log';
		const args = ['sockets'];
		if (err) {
			method = 'error';
			args.push(err);
		}
		args.push(`${event} <== ${data ? JSON.stringify(data) : ''}`);

		logger[method](...args, ...params);
	}
}
