/* eslint-disable max-classes-per-file */

import { Component, createRef } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationBanner } from '@spinview/shared-components';
import { capitalize, isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { navigateToQuery, resetAllOps, setRouteTitle } from '@/actions/application';
import { cloneBrochure } from '@/actions/brochures';
import { addConnectedModal as addModal } from '@/actions/connected_modal';
import { cloneItems, deleteItems, downloadItems, loadFolder, moveItems } from '@/actions/folders';
import { closeLibraryContextMenu, openLibraryContextMenu } from '@/actions/library_context';
import { cloneSite, loadSites, reset } from '@/actions/sites';
import { brochureFile, folderView, getParams, LIBRARY_FOLDERS, siteOverview } from '@/lib/routes';
import { loadFiles, scrollToTop } from '@/lib/util';
import {
	allowSiteSeeOrphans,
	allowSiteSeeOwnership,
	getLoggedInCompanyId,
	isBasicUser,
} from '@/selectors/auth';
import {
	foldersWithDetailsSelector,
	getCriteria as getLibraryCriteria,
	getFolderSelector,
	getLibraryState,
	libraryBreadCrumbsSelector,
	searchedFoldersWithDetailsSelector,
	takeFolderId,
	takeFolderRoutePattern,
	takePath,
	takePathPrefix,
	takeShowOrphans,
	takeSiteRoutePattern,
	weAreInsideVisionAssetFolder,
} from '@/selectors/folders';
import { getState as getSitesState } from '@/selectors/sites';
import {
	filesSelector,
	getCriteria as getSubTourCriteria,
	searchedFilesSelector,
} from '@/selectors/sub_tours';
import { getResumableField } from '@/selectors/uploads';
import { takeFromProps } from '@/types/data';
import FA from '@/types/font_awesome';
import { LIBRARY_ACTIONS, LIBRARY_NAMESPACE, LIBRARY_VIEW_TYPES } from '@/types/library';
import {
	PERMITTED_FILE_TYPES_IN_FOLDERS,
	PERMITTED_FILE_TYPES_IN_FOLDERS_MESSAGES,
	VISION_ASSET_VARIANT_FOLDER_TYPES,
} from '@/types/library_folders';
import { Criteria as SiteCriteria, SITE_TYPES } from '@/types/sites';
import { TOOLS_TABS_NAMES } from '@/types/tabs';
import Breadcrumbs from '@/views/common/Breadcrumbs/Breadcrumbs';
import ErrorBoundary from '@/views/widgets/ErrorBoundary';
import UploaderResumable from '@/views/widgets/files/UploaderResumable';
import ErrorBox from '@/views/widgets/forms/ErrorBox';
import IconButton from '@/views/widgets/IconButton';
import PageContainer from '@/views/widgets/layout/PageContainer';
import StretchableSpinner from '@/views/widgets/StretchableSpinner';

import LibraryContextMenu from './ContextMenu/LibraryContextMenu';
import LibraryActionBar from './LibraryActionBar/LibraryActionBar';
import LibraryGridView from './LibraryGridView';
import LibraryListView from './LibraryListView';

import './LibraryView.scss';

const CLASS = 'sv-LibraryView';

const endpoint = '/sites/upload';

const allowDisableProps = [
	'actions',
	'filter',
	'newFolder',
	'companies',
	'folders',
	'files',
	'uploads',
];
const allowDisplayProps = [
	'filter',
	'viewToggle',
	'newFolder',
	'upload',
	'selectcheckbox',
	'help',
	'HelpToggle',
];
const magicPropsByPrefix = {
	disable: allowDisableProps,
	display: allowDisplayProps,
};

const prefixedPropsGetter = function (prefix) {
	const propsByPrefix = magicPropsByPrefix[prefix];
	if (!propsByPrefix) {
		return null;
	}
	const getters = propsByPrefix.reduce((agg, key) => {
		const propKey = `${prefix}${capitalize(key)}`;
		agg[key] = [propKey, takeFromProps(propKey)];
		return agg;
	}, {});

	const massGetter = (...args) => {
		return propsByPrefix.reduce((agg, key) => {
			const [propKey, getter] = getters[key];
			agg[propKey] = getter(...args);
			return agg;
		}, {});
	};
	return massGetter;
};

const disabledPropsSelector = prefixedPropsGetter('disable');
const displayPropsSelector = prefixedPropsGetter('display');

export class Library extends Component {
	render() {
		return (
			<PageContainer className="sv-Library">
				<LibraryViewWithState {...this.props} />
			</PageContainer>
		);
	}
}

export class LibraryView extends Component {
	constructor(props) {
		super(props);

		// if (props.selectedItems || props.onSelection) {
		// 	return;
		// }
		this.state = {
			foldersCache: {},
			lastSelectionIndex: null,
			selectedItems: [],
			selecting: false,
			sitesCache: {},
		};
	}

	componentDidMount() {
		const {
			error,
			foldersLoading,
			foldersOp,
			loading,
			op,
			sitesLoading,
			sitesOp,
			// folders,
			// files,
		} = this.props;

		if (error || loading || op) {
			return;
		}

		if (!foldersLoading || foldersOp) {
			this.loadFolder();
		}

		if (!sitesLoading || sitesOp) {
			this.loadSites();
		}
	}

	componentDidUpdate(prevProps) {
		const {
			criteria,
			disableFiles,
			disableFolders,
			error,
			files,
			folderId,
			folders,
			foldersCriteria,
			foldersLoading,
			foldersOp,
			fwd,
			isRoot,
			loading,
			namespace,
			pathPrefix,
			reset,
			sitesCriteria,
			sitesLoading,
			sitesOp,
		} = this.props;

		if (fwd && (!prevProps.fwd || fwd.folder.name !== prevProps.fwd.folder.name)) {
			this.updateTitle();
		}

		if (folderId !== prevProps.folderId || pathPrefix !== prevProps.pathPrefix) {
			return this.loadData(true);
		}

		if (error || loading) {
			return;
		}

		if (folderId !== prevProps.folderId && folderId === 'orphans' && folders) {
			return reset(namespace);
		}

		if (!foldersLoading && !foldersOp && folders === null && !disableFolders) {
			return this.loadData();
		}
		if (
			!sitesLoading &&
			!sitesOp &&
			files === null &&
			!disableFiles &&
			(!isRoot || criteria?.filter)
		) {
			return this.loadData();
		}

		const foldersDiff = foldersCriteria && !foldersCriteria.equals(prevProps.foldersCriteria);
		const sitesDiff = sitesCriteria && !sitesCriteria.equals(prevProps.sitesCriteria);

		if (foldersDiff) {
			this.loadFolder(foldersCriteria);
			this.resetSelection();
		}
		if (sitesDiff) {
			this.loadSites(sitesCriteria);
			this.resetSelection();
		}

		const filesLen = files ? files.length : 0;
		const prevFilesLen = prevProps.files ? prevProps.files.length : 0;

		if (filesLen !== prevFilesLen) {
			this.resetSelection();
		}
	}

	updateTitle(props) {
		props = props || this.props;
		const { fwd, route } = props;
		if (!fwd || !route || route.name !== 'Library') {
			return;
		}
		return this.props.setRouteTitle('Library', fwd.folder.name);
	}

	loadData = omitFilter => {
		let { foldersCriteria, sitesCriteria } = this.props;
		if (omitFilter) {
			if (sitesCriteria && sitesCriteria.filter) {
				sitesCriteria = sitesCriteria.clone();
				delete sitesCriteria.filter;
			}
			if (foldersCriteria && foldersCriteria.filter) {
				foldersCriteria = foldersCriteria.clone();
				delete foldersCriteria.filter;
			}
			this.resetSelection();
		}
		this.loadSites(sitesCriteria);
		this.loadFolder(foldersCriteria);
	};

	loadMore = nextPage => {
		const {
			foldersCriteria,
			foldersNextPage,

			sitesCriteria,
			sitesNextPage,
		} = this.props;

		if (nextPage === sitesNextPage) {
			return this.loadSites(sitesCriteria, nextPage, false);
		}
		if (nextPage === foldersNextPage) {
			return this.loadFolder(foldersCriteria, nextPage, false);
		}
	};

	loadSites(criteria, nextPage = false, invalidateAll = true) {
		const {
			disableFiles,
			disableFolders,
			loadSites,
			loggedInCompanyId,
			match: { params },
			namespace,
			sitesCriteria,
		} = this.props;
		criteria = criteria || sitesCriteria;
		if (!criteria || disableFiles) {
			return null;
		}
		if (!disableFolders && !criteria.folder_id && criteria.folder_id !== null && !criteria.filter) {
			return null;
		}
		if (params.siteId) {
			criteria = criteria.update({ filter_exclude_id: params.siteId });
		}

		if (criteria.filter !== undefined) {
			criteria = criteria.update({ folder_id: undefined });
		}

		return loadSites(criteria, nextPage, { invalidateAll, namespace }).then(() => {
			this.setState({
				sitesCache: { ...this.state.sitesCache, [criteria.folder_id]: true },
			});
		});
	}

	loadFolder(criteria, nextPage = false, invalidateAll = true) {
		const { disableCompanies, disableFolders, foldersCriteria, loadFolder, namespace } = this.props;
		criteria = criteria || foldersCriteria;
		if (!criteria || disableFolders) {
			return null;
		}
		if (!criteria.folder_id && disableCompanies) {
			return null;
		}
		if (criteria.filter !== undefined) {
			criteria = criteria.update({ folder_id: undefined });
		}

		return loadFolder(criteria, nextPage, { invalidateAll, namespace }).then(() => {
			this.setState({
				foldersCache: { ...this.state.foldersCache, [criteria.folder_id]: true },
			});
		});
	}

	handleCriteriaChanged = newCriteria => {
		const { navigateToQuery, onCriteriaChanged } = this.props;
		if (newCriteria.filter === '') {
			newCriteria.filter = undefined;
		}
		if (newCriteria.filter !== undefined) {
			newCriteria.folder_id = undefined;
		}

		if (onCriteriaChanged) {
			return onCriteriaChanged(newCriteria);
		}

		return navigateToQuery(newCriteria);
	};

	moveItems = () => {
		const { folderId, moveItems, namespace } = this.props;
		const selectedItems = this.getSelectedItems();

		const moveProps = { folderId, namespace };
		if (folderId === 'orphans') {
			delete moveProps.folderId;
		}
		return moveItems(selectedItems, null, moveProps).then(result => {
			if (result === false) return null;
			this.handleSelectAllToggle(null, false);
			return this.loadData(true);
		});
	};

	reloadSites = () => {
		const { criteria, folderId } = this.props;
		criteria.folder_id = folderId;
		return loadSites(criteria);
	};

	cloneItems = () => {
		const {
			cloneBrochure,
			cloneItems,
			cloneSite,
			disableFiles,
			disableFolders,
			files,
			folderId,
			folders,
			namespace,
		} = this.props;

		const selectedItems = this.getSelectedItems();
		if (selectedItems.length === 1) {
			const [key] = selectedItems;
			const item = [
				...((!disableFolders && folders.filter(fwd => !fwd.isCompany)) || []),
				...((!disableFiles && files) || []),
			].find(item => item.key === key);
			this.handleSelectAllToggle(null, false);
			if (item.type === SITE_TYPES.brochure && item.brochure) {
				return cloneBrochure({
					brochureKey: item.brochure.key,
					folderId,
					onSuccess: this.reloadSites,
				});
			}
			if (item.site) {
				return cloneSite(item.site.id);
			}
		}

		const cloneProps = { folderId, namespace };
		return cloneItems(selectedItems, null, cloneProps).then(result => {
			if (result === false) return null;
			this.handleSelectAllToggle(null, false);
			return this.loadData(true);
		});
	};

	deleteItems = () => {
		const { deleteItems, folderId, namespace } = this.props;
		const selectedItems = this.getSelectedItems();

		return deleteItems(selectedItems, folderId, { namespace }).then(result => {
			if (result === false) return null;
			this.handleSelectAllToggle(null, false);
			return this.loadData(true);
		});
	};

	downloadItems = () => {
		const { downloadItems, namespace } = this.props;
		const selectedItems = this.getSelectedItems();

		return downloadItems(selectedItems, null, { namespace }).then(result => {
			if (result === false) return null;
			this.handleSelectAllToggle(null, false);
			return this.loadData(true);
		});
	};

	/**
	 *
	 * @param {{key: string}} item
	 * @param {MouseEvent} [e]
	 * @returns
	 */
	handleSelection = (item, e) => {
		const { disableFiles, disableFolders, files, folders, onSelection } = this.props;
		let { lastSelectionIndex } = this.state;
		let selectedItems = this.getSelectedItems();
		const filtered = [...selectedItems].filter(key => key !== item.key);
		const shouldAdd = filtered.length === selectedItems.length;

		const allItems = [
			...((!disableFolders && folders.filter(fwd => !fwd.isCompany)) || []),
			...((!disableFiles && files) || []),
		].map(item => item.key);
		const itemIndex = allItems.indexOf(item.key);

		if (lastSelectionIndex !== -1 && e && e.shiftKey) {
			const startIndex = Math.min(itemIndex, lastSelectionIndex);
			const endIndex = Math.max(itemIndex, lastSelectionIndex);
			for (let i = startIndex; i <= endIndex; i++) {
				const index = selectedItems.indexOf(allItems[i]);
				if (!shouldAdd && index !== -1) {
					selectedItems.splice(index, 1);
				}
				if (shouldAdd && index === -1) {
					selectedItems.push(allItems[i]);
				}
			}
		} else {
			selectedItems = shouldAdd ? [...filtered, item.key] : filtered;
		}

		const len = selectedItems.length;

		lastSelectionIndex = itemIndex;
		if (onSelection) {
			onSelection(selectedItems, e);
		}
		if (e && e.defaultPrevented) {
			return false;
		}
		e && e.preventDefault();
		e && e.stopPropagation();
		this.setState({
			lastSelectionIndex,
			selectedItems,
			selecting: !!len,
		});
	};

	handleSelectAllToggle = (e, forceState) => {
		const { closeLibraryContextMenu, disableFiles, disableFolders, files, folders, onSelection } =
			this.props;
		let selectedItems = this.getSelectedItems();

		closeLibraryContextMenu();

		const allItems = [
			...((!disableFiles && files) || []),
			...((!disableFolders && folders.filter(fwd => !fwd.isCompany)) || []),
		].map(item => item.key);

		if (forceState === false || selectedItems.length === allItems.length) {
			selectedItems = [];
		}
		if (forceState === true) {
			selectedItems = allItems;
		}
		if (onSelection) {
			onSelection(selectedItems, e);
		}
		if (e && e.defaultPrevented) {
			return false;
		}
		const len = selectedItems.length;
		this.setState({
			lastSelectionIndex: len ? len - 1 : null,
			selectedItems,
			selecting: !!len,
		});
	};

	resetSelection = () => {
		this.setState({
			lastSelectionIndex: null,
			selectedItems: [],
			selecting: false,
		});
	};

	uploadCompleted = (endpoint, identifier) => {
		const ownIdentifier = this.getUploadIdentifier();
		if (endpoint !== this.props.endpoint || identifier !== ownIdentifier) {
			return;
		}
		this.props.reset(this.props.namespace);
		this.loadData(false);
	};

	getSelectedItems = () => {
		return this.props.selectedItems || (this.state && this.state.selectedItems);
	};

	getIsSelecting = () => {
		const { selectable } = this.props;
		if (!selectable) {
			return false;
		}
		const selectedItems = this.getSelectedItems();
		const selecting = this.props.selecting || (this.state && this.state.selecting);
		return selecting || (selectedItems && selectedItems.length > 0);
	};

	getUploadIdentifier = () => {
		const { location, namespace } = this.props;
		let { pathname } = location;
		if (pathname.charAt(pathname.length - 1) === '/') {
			pathname = pathname.substring(0, pathname.length - 1);
		}
		return `${pathname}:${namespace || LIBRARY_NAMESPACE.view}`;
	};

	render() {
		return (
			<ControlledLibraryView
				{...this.props}
				identifier={this.getUploadIdentifier()}
				loadData={this.loadData}
				loadMore={this.loadMore}
				selectedItems={this.getSelectedItems()}
				selecting={this.getIsSelecting()}
				onCloneItems={this.cloneItems}
				onCriteriaChanged={this.handleCriteriaChanged}
				onDeleteItems={this.deleteItems}
				onDownloadItems={this.downloadItems}
				onMoveItems={this.moveItems}
				onSelectAllToggle={this.props.selectable ? this.handleSelectAllToggle : undefined}
				onSelection={this.props.selectable ? this.handleSelection : undefined}
				onUploadComplete={this.uploadCompleted}
			/>
		);
	}
}

export class ControlledLibraryView extends Component {
	constructor(props) {
		super(props);

		this.dropzone = createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		const { disableRedirect, folderId, folders, history, loading } = this.props;

		if (loading) {
			return null;
		}

		const singleCompanyFwd =
			folders && folders.length === 1 && folders[0].folder.parent_id === null && folders[0];
		if (
			!disableRedirect &&
			!folderId &&
			!prevProps.folderId &&
			singleCompanyFwd &&
			singleCompanyFwd.folder
		) {
			const path = this.createFolderHref(singleCompanyFwd);
			const clickHandlerResult = this.handleBreadcrumbClicked(
				singleCompanyFwd.folder,
				new MouseEvent('click'),
			);
			if (
				clickHandlerResult === false ||
				(clickHandlerResult && clickHandlerResult.defaultPrevented)
			) {
				return;
			}
			if (path) {
				return history.replace(path);
			}
		}
	}

	openContextMenu = (type, props = {}) => {
		let { folderId, namespace } = this.props;
		props = props || {};
		folderId = folderId || props.folderId;
		const contextMenuProps = { ...props, folderId, namespace };
		if (folderId === 'orphans') {
			delete contextMenuProps.folderId;
		}
		this.props.openLibraryContextMenu(type, contextMenuProps);
	};

	handleUploadClick = () => {
		const { current } = this.dropzone;
		current && current.open();
	};

	handleDropFiles = acceptedFiles => {
		loadFiles(acceptedFiles);
		this.props.resumableField.addFiles(acceptedFiles);
		scrollToTop();
	};

	handleBreadcrumbClicked = (folder, e) => {
		const fwd = {
			folder,
			id: folder ? folder.id : null,
			parent_id: folder ? folder.parent_id : null,
		};

		const { onBreadCrumbClick, onFolderClick } = this.props;

		if (onBreadCrumbClick) {
			onBreadCrumbClick(fwd, e);
			return e || false;
		}
		if (onFolderClick) {
			onFolderClick(fwd, e);
			return e || false;
		}
	};

	createFolderHref = fwd => {
		const { createFolderHref, folderRoutePattern, location, match } = this.props;
		const { params = {} } = match;

		if (typeof createFolderHref !== 'function' && createFolderHref !== undefined) {
			return null;
		}
		if (createFolderHref) {
			return createFolderHref(fwd.folder.id);
		}
		if (folderRoutePattern) {
			const computedParams = getParams(folderRoutePattern, location.pathname);
			const pattern = generatePath(folderRoutePattern, {
				...params,
				...computedParams,
				folderId: fwd.folder.id,
			});
			return pattern.replace(/\?$/, '');
		}
		return folderView(fwd.folder.id);
	};

	createFileHref = swd => {
		const { createFileHref, location, match, siteRoutePattern } = this.props;
		const { params = {} } = match;

		if (typeof createFileHref !== 'function' && createFileHref !== undefined) {
			return null;
		}
		if (createFileHref) {
			return createFileHref(swd.site.id);
		}
		if (swd.site.type === SITE_TYPES.brochure && swd.brochure) {
			return brochureFile(swd.brochure.key);
		}
		if (siteRoutePattern) {
			const computedParams = getParams(siteRoutePattern, location.pathname);
			const pattern = generatePath(siteRoutePattern, {
				...params,
				...computedParams,
				siteId: swd.site.id,
			});
			return pattern.replace(/\?$/, '');
		}
		return siteOverview(swd.site.id);
	};

	renderUploader = () => {
		const { endpoint, folderId, identifier, onUploadComplete } = this.props;
		const query = {
			endpoint,
			folder_id: folderId,
			shouldUnzip: true,
		};

		return (
			<UploaderResumable
				endpoint={endpoint}
				identifier={identifier}
				query={query}
				onComplete={onUploadComplete}
			/>
		);
	};

	renderView() {
		let {
			disableCompanies,
			disableFiles,
			disableFolders,
			files,
			folderId,
			folders,
			foldersCriteria,
			isBasicUser,
			libraryView,
			loading,
			sitesCriteria,
			...rest
		} = this.props;

		const View = libraryView === LIBRARY_VIEW_TYPES.grid ? LibraryGridView : LibraryListView;

		if (isEmpty(folders) && isEmpty(files)) {
			return loading ? <StretchableSpinner /> : <h6>No content available</h6>;
		}

		if (disableCompanies) {
			folders = folders ? folders.filter(fwd => !fwd.isCompany) : folders;
			foldersCriteria = null;
		}

		if (disableFolders) {
			folders = null;
			foldersCriteria = null;
		}

		if (disableFiles) {
			files = null;
			sitesCriteria = null;
		}

		const foldersLength = folders ? folders.filter(fwd => !fwd.isCompany).length : 0;
		const filesLength = files ? files.length : 0;
		const itemsLen = foldersLength + filesLength;
		const selectedLen = this.props.selectedItems && this.props.selectedItems.length;
		const allSelected = itemsLen === selectedLen;
		const disabledColumns = [];
		if (isBasicUser) {
			disabledColumns.push('url');
		}
		if (foldersCriteria.filter === undefined) {
			disabledColumns.push('asset');
		}

		return (
			<>
				{libraryView === LIBRARY_VIEW_TYPES.grid && folderId && (
					<>
						<FontAwesomeIcon
							icon={allSelected ? FA.check_square : FA.square_empty}
							onClick={e => this.props.onSelectAllToggle(e, !allSelected)}
						/>
						<div className="inline_cbx_title">Select All</div>
					</>
				)}
				<View
					onContextMenu={this.openContextMenu}
					{...rest}
					createFileHref={this.createFileHref}
					createFolderHref={this.createFolderHref}
					disableCompanies={disableCompanies}
					disableFiles={disableFiles}
					disableFolders={disableFolders}
					disabledColumns={disabledColumns}
					files={files}
					folderId={folderId}
					folders={folders}
					foldersCriteria={foldersCriteria}
					itemClassName="sv-LibraryItem"
					sitesCriteria={sitesCriteria}
				/>
			</>
		);
	}

	renderSelectionActionButtons() {
		const {
			disableActions,
			folders,
			onCloneItems,
			onDeleteItems,
			onDownloadItems,
			onMoveItems,
			selectable,
			selectedItems,
		} = this.props;
		if (disableActions === true || !selectable || !selectedItems || !selectedItems.length) {
			return null;
		}

		const selectionHasVisionAssetVariantFolder = folders
			?.filter(folder => selectedItems.includes(folder.key))
			.some(folder => Object.keys(VISION_ASSET_VARIANT_FOLDER_TYPES).includes(folder.type));

		const disabledActions = Array.isArray(disableActions) ? disableActions : [];
		const moveDisabled =
			selectionHasVisionAssetVariantFolder || disabledActions.includes(LIBRARY_ACTIONS.move);
		const cloneDisabled =
			selectionHasVisionAssetVariantFolder || disabledActions.includes(LIBRARY_ACTIONS.clone);
		const deleteDisabled =
			selectionHasVisionAssetVariantFolder || disabledActions.includes(LIBRARY_ACTIONS.delete);
		const downloadDisabled = disabledActions.includes(LIBRARY_ACTIONS.download);
		if ([moveDisabled, cloneDisabled, deleteDisabled, downloadDisabled].every(Boolean)) {
			return null;
		}

		return (
			<div className={`${CLASS}-controls-fileControls`}>
				<span>{selectedItems.length} items selected</span>
				<div className={`${CLASS}-controls-fileControls-buttonWrapper`}>
					{moveDisabled ? null : <IconButton onClick={onMoveItems}>Move to</IconButton>}
					{cloneDisabled ? null : <IconButton onClick={onCloneItems}>Clone to</IconButton>}
					{/* {downloadDisabled ? null : <IconButton onClick={onDownloadItems}>Download</IconButton>} */}
					{deleteDisabled ? null : (
						<IconButton secondary onClick={onDeleteItems}>
							Delete
						</IconButton>
					)}
				</div>
			</div>
		);
	}

	render() {
		const {
			contextMenuItems,
			criteria,
			disableActions,
			disableBreadcrumbs,
			disableFilter,

			disableUploads,
			displayFilter,
			displayHelp,
			displayHelpToggle,

			displayLibrarySearch,
			displayNewFolder,
			displaySort,

			displayUpload,

			displayViewToggle,

			error,
			folderId,
			fwd,
			libraryLeftChild,
			libraryView,
			loadData,
			loading,
			namespace,
			onCriteriaChanged,
			onFolderClick,
			pathPrefix,
			resetAllOps,

			resumableField,
			route,
			selectedItems,
			sitesCriteria,
			tool,
		} = this.props;

		const filter = criteria && criteria.filter;
		const breadcrumbs = filter ? `Search Results` : this.props.breadcrumbs;
		let isToolActive;
		let routeName = this.props.rootPathName || (route && route.name) || 'Library';
		if (tool) {
			routeName = TOOLS_TABS_NAMES[tool];
			isToolActive = !!tool;
		}

		const disableNewAsset =
			this.props.disableNewAsset || !breadcrumbs || this.props.weAreInsideVisionAssetFolder;

		const permittedFileTypesForUpload = PERMITTED_FILE_TYPES_IN_FOLDERS[fwd?.folder?.type] || '';

		const permittedFileTypesMessage =
			PERMITTED_FILE_TYPES_IN_FOLDERS_MESSAGES[fwd?.folder?.type] || '';

		const disableNewFolder = this.props.disableNewFolder || !folderId;

		return (
			<div className={CLASS}>
				<ErrorBoundary>
					{error && <ErrorBox dismiss={resetAllOps} error={error} />}
					{loading ? null : (
						<LibraryContextMenu
							folderId={folderId}
							loadData={loadData}
							menuItems={contextMenuItems}
							namespace={namespace}
							selectedItems={selectedItems}
						/>
					)}
					{disableUploads ? null : this.renderUploader()}
					{displayFilter && (
						<LibraryActionBar
							criteria={sitesCriteria}
							disableActions={disableActions}
							disableFilter={disableFilter}
							disableNewAsset={disableNewAsset}
							disableNewFolder={disableNewFolder}
							displayEnabledToggle={isToolActive}
							displayHelp={displayHelp}
							displayHelpToggle={isToolActive || displayHelpToggle}
							displayLibrarySearch={displayLibrarySearch}
							displayNewFolder={displayNewFolder}
							displaySort={displaySort}
							displayUpload={displayUpload}
							displayViewToggle={displayViewToggle}
							folderId={folderId}
							libraryLeftChild={libraryLeftChild}
							libraryView={libraryView}
							namespace={namespace}
							onCriteriaChanged={onCriteriaChanged}
							onUploadClick={this.handleUploadClick}
						/>
					)}
					<div className={`${CLASS}-controls`}>
						{!disableBreadcrumbs && (
							<Breadcrumbs
								breadcrumbs={breadcrumbs}
								includeRoot={!filter}
								linkPath="id"
								pathPrefix={pathPrefix || LIBRARY_FOLDERS}
								rootPathName={routeName}
								onClick={onFolderClick ? this.handleBreadcrumbClicked : undefined}
							/>
						)}
						{this.renderSelectionActionButtons()}
					</div>

					{permittedFileTypesMessage && (
						<NotificationBanner
							permanent
							reducedIntensity
							notificationId={1}
							severity="info"
							text={permittedFileTypesMessage}
						/>
					)}

					{disableUploads ? (
						this.renderView()
					) : (
						<Dropzone
							ref={this.dropzone}
							noClick
							accept={permittedFileTypesForUpload}
							disabled={!resumableField}
							useFsAccessApi={false}
							onDrop={this.handleDropFiles}
						>
							{({ getInputProps, getRootProps }) => (
								<section {...getRootProps()} className={`${CLASS}-dropzone`}>
									{this.renderView()}
									<input {...getInputProps()} />
									<div className={`${CLASS}-drop-overlay`}>
										<FontAwesomeIcon icon={FA.cloud_upload} size="2x" />
									</div>
								</section>
							)}
						</Dropzone>
					)}
				</ErrorBoundary>
			</div>
		);
	}
}

LibraryView.propTypes = {
	breadcrumbs: PropTypes.array,

	createFileHref: PropTypes.func,
	createFolderHref: PropTypes.func,

	criteria: PropTypes.object,
	disableActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	disableBreadcrumbs: PropTypes.bool,

	disableCompanies: PropTypes.bool,
	disableFiles: PropTypes.bool,
	disableFilter: PropTypes.bool,
	disableFolders: PropTypes.bool,
	disableNewFolder: PropTypes.bool,

	disableUploads: PropTypes.bool,
	displayCheckbox: PropTypes.bool,
	displayFilter: PropTypes.bool,
	displayHelpToggle: PropTypes.bool,

	displayLibrarySearch: PropTypes.bool,
	displayNewFolder: PropTypes.bool,

	displaySort: PropTypes.bool,
	displayUpload: PropTypes.bool,
	displayViewToggle: PropTypes.bool,
	files: PropTypes.array,

	folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	folders: PropTypes.array,
	foldersCriteria: PropTypes.object,
	foldersNextPage: PropTypes.object,

	libraryView: PropTypes.string,
	loadFolder: PropTypes.func,
	loadSites: PropTypes.func,
	loading: PropTypes.bool,
	onCriteriaChanged: PropTypes.func,
	onFileClick: PropTypes.func,
	onFolderClick: PropTypes.func,
	pathPrefix: PropTypes.string,

	selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	sitesCriteria: PropTypes.object,

	sitesNextPage: PropTypes.object,
};

LibraryView.defaultProps = {
	disableActions: false,
	disableBreadcrumbs: false,
	disableCompanies: false,
	disableFiles: false,
	disableFilter: false,
	disableFolders: false,
	disableNewFolder: false,
	disableUploads: false,

	displayCheckbox: true,
	displayFilter: true,
	displayLibrarySearch: true,
	displayNewFolder: true,
	displaySort: true,
	displayUpload: true,
	displayViewToggle: true,

	listViewActions: true,
	selectable: true,
	selecting: false,
};

const mapStateToProps = (state, props) => {
	const { namespace, route } = props;
	let folderId = takeFolderId(route, props);
	if (folderId === undefined) {
		folderId = takeFolderId(state, props);
	}
	const allowSeeOrphans = allowSiteSeeOrphans(state);
	const showOrphans = takeShowOrphans(route, props) && allowSeeOrphans;

	const libraryState = getLibraryState(state, props);
	const sitesState = getSitesState(state, props);
	const foldersLoading = libraryState.loading;
	const sitesLoading = sitesState.loading;
	const foldersCriteria = getLibraryCriteria(state, props);
	const sitesCriteria = getSubTourCriteria(state, props);

	const { libraryView } = libraryState;

	const foldersNextPage = libraryState.nextPage;
	const sitesNextPage = sitesState.nextPage;

	const criteria = new SiteCriteria(sitesCriteria || foldersCriteria);

	let disabledProps = disabledPropsSelector(props, route);
	const displayProps = displayPropsSelector(props, route);
	const path = takePath(props, route);
	const pathPrefix = takePathPrefix(props, route);
	const folderRoutePattern = takeFolderRoutePattern(props, route);
	const siteRoutePattern = takeSiteRoutePattern(props, route);
	if (folderId === 'orphans') {
		disabledProps.disableFolders = true;
		disabledProps.disableCompanies = true;
	}

	if (!folderId) {
		disabledProps.disableFiles = true;
	}

	if (sitesCriteria.filter !== undefined) {
		disabledProps.disableFiles = false;
	}

	const filtering = ['filter', 'media_format', 'type', 'owner_id', 'country'].some(
		field => criteria[field],
	);

	if ((pathPrefix || path) && pathPrefix === path && !filtering) {
		disabledProps = {
			...disabledProps,
			disableActions: true,
			disableFiles: true,
		};
	}

	const loadError = libraryState.loadError || sitesState.loadError;
	const error =
		loadError ||
		[libraryState.op, sitesState.op].find(op => (op ? op.error || op.loadError : false));
	const nsEndPoint = `${endpoint}?${namespace || LIBRARY_NAMESPACE.view}`;

	const files =
		sitesCriteria.filter !== undefined
			? searchedFilesSelector(state, props)
			: filesSelector(state, props);

	const folders =
		foldersCriteria.filter !== undefined
			? searchedFoldersWithDetailsSelector(state, props)
			: foldersWithDetailsSelector(state, props);

	const sortFoldersByVisionTypeFirst = folders => {
		return folders.sort((a, b) => {
			if (a.type !== null && b.type === null) {
				return -1;
			}
			if (a.type === null && b.type !== null) {
				return 1;
			}
			return 0;
		});
	};

	return {
		allowSeeOrphans,
		breadcrumbs: libraryBreadCrumbsSelector(state, props),
		criteria,
		endpoint: nsEndPoint,
		error,
		files,
		folderId,
		folderRoutePattern,
		folders: sortFoldersByVisionTypeFirst(folders ?? []),
		foldersCriteria,
		foldersLoading,
		foldersNextPage,
		foldersOp: libraryState.op,
		fwd: getFolderSelector(state, props),
		libraryView,
		loadError,
		loading: foldersLoading || sitesLoading,
		pathPrefix,

		route,
		showOrphans,
		siteRoutePattern,
		sitesCriteria,
		sitesLoading,

		sitesNextPage,
		sitesOp: sitesState.op,
		weAreInsideVisionAssetFolder: weAreInsideVisionAssetFolder(state, props),

		...disabledProps,
		...displayProps,

		allowSeeOwnership: allowSiteSeeOwnership(state),
		isBasicUser: isBasicUser(state),
		loggedInCompanyId: getLoggedInCompanyId(state, props),
		resumableField: disabledProps.disableUploads ? null : getResumableField(state, nsEndPoint),
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addModal,
			cloneBrochure,
			cloneItems,
			cloneSite,
			closeLibraryContextMenu,
			deleteItems,
			downloadItems,
			loadFolder,
			loadSites,
			moveItems,
			navigateToQuery,
			openLibraryContextMenu,
			reset,
			resetAllOps,
			setRouteTitle,
		},
		dispatch,
	);

const mergeProps = (state, actions, props) => {
	const ret = merge({}, state, actions, props);
	const { showOrphans } = state;
	let { folderId, foldersCriteria, sitesCriteria } = ret;

	foldersCriteria = foldersCriteria && foldersCriteria.clone();
	sitesCriteria = sitesCriteria && sitesCriteria.clone();

	if (Object.prototype.hasOwnProperty.call(ret, 'folderId')) {
		if (foldersCriteria) foldersCriteria.folder_id = folderId;
		if (sitesCriteria) sitesCriteria.folder_id = folderId;
	}

	if (foldersCriteria.filter !== undefined) {
		foldersCriteria.folder_id = undefined;
	}

	if (sitesCriteria.filter !== undefined) {
		sitesCriteria.folder_id = undefined;
	}

	return {
		...ret,
		foldersCriteria,
		showOrphans,
		sitesCriteria,
	};
};

const LibraryViewWithState = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)(withRouter(LibraryView));

export default LibraryViewWithState;
