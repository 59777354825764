import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	products: {
		data: null,
		loadError: null,
		opError: null,
		params: null,
	},
};

const updateProducts = (state, ob) => {
	return { ...state, products: { ...state.products, ...ob } };
};

const gotData = (state, action) => {
	const data = (!action.reset && state.products.data) || {};

	action.data.forEach(twp => {
		if (twp.subproducts) {
			const subproducts = {};
			twp.subproducts.forEach(subproduct => {
				subproducts[subproduct.child_id] = subproduct;
			});
			twp.subproducts = subproducts;
		}

		data[twp.product.id] = twp;
	});

	return updateProducts(state, {
		data,
	});
};

const loadStart = (state, action) => {
	const newState = {
		loadError: null,
	};
	if (action.params !== undefined) {
		newState.params = action.params;
	}
	return updateProducts(state, newState);
};

const loadEnd = (state, action) => {
	return updateProducts(state, {
		loadError: action.error || null,
	});
};

const opStart = (state, action) => {
	return updateProducts(state, {
		opError: null,
	});
};

const opEnd = (state, action) => {
	const nextState = updateProducts(state, {
		opError: action.error || null,
	});

	if (action.invalidateId && nextState.products.data) {
		delete nextState.products.data[action.invalidateId];
	}

	return nextState;
};

const opReset = state => {
	return updateProducts(state, {
		opError: null,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.PRODUCTS_GOT_DATA]: gotData,
	[TYPES.PRODUCTS_LOAD_START]: loadStart,
	[TYPES.PRODUCTS_LOAD_END]: loadEnd,
	[TYPES.PRODUCTS_OP_START]: opStart,
	[TYPES.PRODUCTS_OP_END]: opEnd,
	[TYPES.PRODUCTS_OP_RESET]: opReset,
	[TYPES.OP_RESET_ALL]: opReset,
};
