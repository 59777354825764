import filesize from 'file-size';
import { get } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ENV from '@/env';
import { makeAttachmentUrl } from '@/lib/api';
import { makeSiteUrlOrNull } from '@/lib/asset_router';
import * as routes from '@/lib/routes';
import { createDeepEqualitySelector } from '@/lib/util';
import { makeGetter, sortByIndexingProperty, takeFromProps } from '@/types/data';
import { LIBRARY_NAMESPACE } from '@/types/library';
import { VisualStatsCriteria } from '@/types/reports';
import { Criteria, DEFAULT_CRITERIA, DETAILED_CRITERIA } from '@/types/sites';

import { takeQuery } from './application';
import { getData as getCompaniesData } from './companies';
import { getBreadCrumbs, getData as getFoldersData } from './folders';

const getSitesState = makeGetter('sites');
const getModalState = makeGetter('sites.modal');
const dataGetter = makeGetter('data');
export const takeNamespace = takeFromProps('namespace');

export function getState(state, props) {
	const namespace = takeNamespace(state, props);
	const sites = getSitesState(state);

	if (namespace === LIBRARY_NAMESPACE.view) {
		return sites;
	}
	if (namespace === LIBRARY_NAMESPACE.modal) {
		return getModalState(state);
	}
	return sites;
}

export const getData = (state, props) => dataGetter(getState(state, props));

export const takeSiteId = takeFromProps('siteId');
export const takeSiteVersionId = takeFromProps('versionId');
export const takeTourId = takeFromProps('tourId');
export const takeOwnerId = takeFromProps('owner_id');
export const getSiteSelector = createSelector(getData, takeSiteId, get);
export const getChildSiteSelector = createSelector(getData, takeTourId, get);

export function getSiteWithDetails(swd, companies, folders) {
	if (!swd) {
		return swd;
	}
	swd = { ...swd, key: `s-${swd.site.id}` };
	if (swd.owner && swd.folder) {
		return swd;
	}
	const cwd = companies && companies[swd.site.owner_id];
	if (!swd.owner && cwd) {
		swd = { ...swd, owner: cwd.company };
	}
	const fwd = folders && folders[swd.site.folder_id];
	if (!swd.folder && fwd) {
		swd = { ...swd, folder: fwd.folder };
	}
	if (swd.tags) {
		swd.tags.forEach(tag => {
			tag.tagAttachments?.forEach(tagAttachment => {
				if (tagAttachment.attachment) {
					const { attachment } = tagAttachment;
					tagAttachment.url = makeAttachmentUrl(
						tagAttachment.type,
						attachment.key,
						attachment.width,
						attachment.height,
					);
				}
			});
		});
	}
	if (swd.tags && swd.tour?.scenes) {
		for (const scene of swd.tour.scenes) {
			if (scene.image) {
				scene.tags = swd.tags.filter(tag => tag.image_id === scene.image.id);
			}
		}
	}
	return swd;
}

/** @type {import('../custom').ParametricSelector<any, any, import('../types/sites').SiteWithDetails>} */
export const getSiteWithDetailsSelector = createSelector(
	getSiteSelector,
	getCompaniesData,
	getFoldersData,
	getSiteWithDetails,
);
export const getChildSiteWithDetailsSelector = createSelector(
	getChildSiteSelector,
	getCompaniesData,
	getFoldersData,
	getSiteWithDetails,
);

export const getSiteRecords = createSelector(getData, swds => {
	return swds ? Object.values(swds).map(swd => swd.site) : null;
});

export const takeSiteBreadcrumbs = createSelector(
	getSiteWithDetailsSelector,
	makeGetter('breadcrumbs'),
);
export const takeSiteFolderId = createSelector(
	getSiteWithDetailsSelector,
	makeGetter('site.folder_id'),
);

export const siteBreadcrumbsSelector = createSelector(
	takeSiteBreadcrumbs,
	takeSiteFolderId,
	getBreadCrumbs,
);

export const getCriteria = createSelector(
	takeQuery,
	takeFromProps('criteria'),
	(query, criteria) => {
		if (criteria) {
			query = null;
		}
		return new Criteria({ ...DEFAULT_CRITERIA, ...query, ...criteria });
	},
);

export const getDetailedCriteria = createSelector(
	takeFromProps('details'),
	takeFromProps('criteria'),
	(details, criteria) => {
		return new Criteria({ ...DETAILED_CRITERIA, ...criteria, details });
	},
);

export const getSortedSitesWithDetailsSelector = createSelector(getData, sortByIndexingProperty);

export function getSitesWithDetails(swds, ...rest) {
	if (!swds) {
		return swds;
	}
	return swds.map(swd => getSiteWithDetails(swd, ...rest));
}

/** @type {import('../custom').ParametricSelector<any, any, import('../types/sites').SiteWithDetails[]>} */
export const getSitesWithDetailsSelector = createSelector(
	getSortedSitesWithDetailsSelector,
	getCompaniesData,
	getFoldersData,
	getSitesWithDetails,
);

export function getCompanySitesWithDetails(ownerId, swds) {
	if (!swds) {
		return swds;
	}

	return swds.filter(swd => swd.site.owner_id === ownerId);
}

export const getOwnerIdFromCriteriaSelector = createSelector(
	getDetailedCriteria,
	takeOwnerId,
	(criteria, ownerId) => ownerId || takeOwnerId(criteria),
);

export const getCompanySitesWithDetailsSelector = createSelector(
	getOwnerIdFromCriteriaSelector,
	getSitesWithDetailsSelector,
	getCompanySitesWithDetails,
);

export function getSitesReport(swds) {
	const headers = [
		{ key: 'id', label: 'Id' },
		{ key: 'name', label: 'Name' },
		{ key: 'ownerName', label: 'Owner' },
		{ key: 'username', label: 'Created by' },
		{ key: 'url', label: 'URL' },
		{ key: 'folderRoute', label: 'Path' },
		{ key: 'type', label: 'Type' },
		{ key: 'created_at', label: 'Created' },
		{ key: 'updated_at', label: 'Modified' },
		{ key: 'status', label: 'Deleted' },
		{ key: 'sizeOfFile', label: 'Size' },
	];

	const data = [];
	if (!swds) {
		return swds;
	}

	swds.forEach(swd => {
		const { owner, site, user } = swd;
		const { created_at, deleted_at, folder_id, id, name, size, type, updated_at, url_path } = site;
		const ownerName = owner && owner.name;
		const { username } = user || '';

		const url = makeSiteUrlOrNull(url_path);
		const folderRoute = folder_id ? routes.folderView(folder_id) : routes.LIBRARY_FOLDERS;
		const sizeOfFile = size ? filesize(size).human('jedec') : 'N/A';

		const status = deleted_at ? moment(deleted_at).format(ENV.date_format.short) : 'Live';

		data.push({
			created_at,
			folderRoute,
			id,
			name,
			ownerName,
			sizeOfFile,
			status,
			type,
			updated_at,
			url,
			username,
		});
	});

	return { data, headers };
}

export const getSitesReportSelector = createSelector(
	getCompanySitesWithDetailsSelector,
	getSitesReport,
);

const UPLOAD_KEY_PREFIX = 'UPLOAD_SITE_';
export const siteUploadKey = siteId => UPLOAD_KEY_PREFIX + (siteId || 'NEW');

// TODO to api
export const getUploadEndPoint = (siteId, versionId) => `/sites/${siteId}/upload/${versionId}`;

// *********************************************************************************************************************
// Site Stats Kiosk

export const getVisualStatsCriteria = createDeepEqualitySelector(
	(_, props) => props.location.query,
	query => {
		return new VisualStatsCriteria(query);
	},
);

/**
 * Returns true if backend is preparing download for given site id
 * @param {State} state
 * @param siteId
 */
export const isPreparingSiteFilesDownload = (state, siteId) =>
	!!state.sites.preparingSiteFilesDownload[siteId];
