import { uniqueId } from 'lodash';

import ContentPickerModal from '@/views/library/modal/operations/ContentPickerModal';
import SaveAsModal from '@/views/library/modal/operations/SaveAsModal';
import SaveToModal from '@/views/library/modal/operations/SaveToModal';
import AssetInfoModal from '@/views/widgets/connected_modals/AssetInfoModal';
import BasicModal from '@/views/widgets/connected_modals/BasicModal';
import ImageCropModal from '@/views/widgets/connected_modals/ImageCropModal';
import SketchModal from '@/views/widgets/connected_modals/SketchModal';

export const CONNECTED_MODAL_TYPES = {
	asset_info: 'asset_info',
	basic: 'basic',
	image_crop: 'image_crop',
	pick: 'pick',
	save: 'save',
	save_to: 'save_to',
	sketch: 'sketch',
};

export const CONNECTED_MODAL_COMPONENTS = {
	[CONNECTED_MODAL_TYPES.save]: SaveAsModal,
	[CONNECTED_MODAL_TYPES.save_to]: SaveToModal,
	[CONNECTED_MODAL_TYPES.basic]: BasicModal,
	[CONNECTED_MODAL_TYPES.pick]: ContentPickerModal,
	[CONNECTED_MODAL_TYPES.image_crop]: ImageCropModal,
	[CONNECTED_MODAL_TYPES.sketch]: SketchModal,
	[CONNECTED_MODAL_TYPES.asset_info]: AssetInfoModal,
};

export class ConnectedModal {
	constructor(type, modalProps) {
		this.id = uniqueId();
		this.type = type;
		this.props = {
			...modalProps,
		};
	}
}

/**
 * Base modals
 * Desc: These modals are the base modal types used to create any type of
 * other needed modals
 */

ConnectedModal.save = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.save, props);
};

ConnectedModal.save_to = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.save_to, props);
};

ConnectedModal.pick = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.pick, props);
};

ConnectedModal.basic = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.basic, props);
};

ConnectedModal.image_crop = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.image_crop, props);
};

ConnectedModal.asset_info = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.asset_info, props);
};

ConnectedModal.sketch = props => {
	return new ConnectedModal(CONNECTED_MODAL_TYPES.sketch, props);
};

/**
 * Derived Library Modals
 * Desc: These modals are derived from base modals
 * to be used for library operations
 */

ConnectedModal.new_site = props => {
	const defaultProps = {
		inputLabel: 'New name',
		inputPlaceholder: 'Write new name here...',
		submitLabel: 'Save',
		title: 'Save content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.save(combinedProps);
};

ConnectedModal.clone_site = props => {
	const defaultProps = {
		inputLabel: 'New name',
		inputPlaceholder: 'Write new name here...',
		submitLabel: 'Clone',
		title: 'Clone content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.save(combinedProps);
};

ConnectedModal.clone = props => {
	const defaultProps = {
		submitLabel: 'Clone',
		title: 'Clone content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.save_to(combinedProps);
};

ConnectedModal.delete_site = props => {
	const defaultProps = {
		confirmLabel: 'Delete',
		title: 'Delete content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.basic(combinedProps);
};

ConnectedModal.move_site = props => {
	const defaultProps = {
		displayFileNameEditor: false,
		folderId: undefined,
		submitLabel: 'Move',
		title: 'Move content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.save(combinedProps);
};

ConnectedModal.pick_site = props => {
	const defaultProps = {
		folderId: undefined,
		submitLabel: 'Select',
		title: 'Select content',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.pick(combinedProps);
};

ConnectedModal.pick_folder = props => {
	const defaultProps = {
		disableFiles: true,
		folderId: undefined,
		pickType: 'folder',
		submitLabel: 'Select',
		title: 'Select folder',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.pick(combinedProps);
};

ConnectedModal.createAsset = props => {
	const defaultProps = {
		displayFileNameEditor: true,
		displayLibrary: false,
		inputLabel: '',
		inputPlaceholder: 'Asset Name',
		submitLabel: 'Create',
		title: 'Create Asset',
	};

	const combinedProps = { ...defaultProps, ...props };
	return ConnectedModal.save(combinedProps);
};
