export const FOLDER_TYPES = {
	360: '360',
	aerial: 'aerial',
	bim: 'bim',
	documents: 'documents',
	floorplans: 'floorplans',
	models: 'models',
	orthophotos: 'orthophotos',
	pointclouds: 'pointclouds',
	reports: 'reports',
	tours: 'tours',
	vision_asset: 'vision_asset',
};

export const FOLDER_LABELS = {
	[FOLDER_TYPES[360]]: '360',
	[FOLDER_TYPES.aerial]: 'Aerial',
	[FOLDER_TYPES.vision_asset]: 'Vision Asset',
	[FOLDER_TYPES.pointclouds]: 'Pointclouds',
	[FOLDER_TYPES.models]: '3D Models',
	[FOLDER_TYPES.bim]: 'BIM',
	[FOLDER_TYPES.tours]: '360 Tours',
	[FOLDER_TYPES.orthophotos]: 'Ortophotos',
	[FOLDER_TYPES.floorplans]: 'Floor Plans',
	[FOLDER_TYPES.reports]: 'Reports',
	[FOLDER_TYPES.documents]: 'Documents',
};

export const LIBRARY_FOLDER_ICONS = {
	[FOLDER_TYPES[360]]: 'panorama',
	[FOLDER_TYPES.aerial]: 'drone',
	[FOLDER_TYPES.vision_asset]: 'gem_o',
	[FOLDER_TYPES.pointclouds]: 'point_cloud',
	[FOLDER_TYPES.models]: 'cube',
	[FOLDER_TYPES.bim]: 'bim',
	[FOLDER_TYPES.tours]: 'street_view_o',
	[FOLDER_TYPES.orthophotos]: 'image',
	[FOLDER_TYPES.floorplans]: 'floor_plan',
	[FOLDER_TYPES.reports]: 'pie_chart_o',
	[FOLDER_TYPES.documents]: 'files_o',
};

export const VISION_ASSET_VARIANT_FOLDER_TYPES = {
	[FOLDER_TYPES.aerial]: FOLDER_TYPES.aerial,
	[FOLDER_TYPES.pointclouds]: FOLDER_TYPES.pointclouds,
	[FOLDER_TYPES.models]: FOLDER_TYPES.models,
	[FOLDER_TYPES.bim]: FOLDER_TYPES.bim,
	[FOLDER_TYPES.tours]: FOLDER_TYPES.tours,
	[FOLDER_TYPES.orthophotos]: FOLDER_TYPES.orthophotos,
	[FOLDER_TYPES.floorplans]: FOLDER_TYPES.floorplans,
	[FOLDER_TYPES.reports]: FOLDER_TYPES.reports,
	[FOLDER_TYPES.documents]: FOLDER_TYPES.documents,
	[FOLDER_TYPES[360]]: FOLDER_TYPES[360],
};

export const PERMITTED_FILE_TYPES_IN_FOLDERS = {
	[FOLDER_TYPES.floorplans]: {
		'image/jpeg': ['.jpeg', '.jpg'],
		'image/png': ['.png'],
	},
	[FOLDER_TYPES.aerial]: {
		'image/jpeg': ['.jpeg', '.jpg'],
	},
};

export const PERMITTED_FILE_TYPES_IN_FOLDERS_MESSAGES = {
	[FOLDER_TYPES.floorplans]: 'You can only upload PNG or JPG in this folder',
};
