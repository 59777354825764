import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { SORT_DIRECTIONS } from '@/types/data';
import FA from '@/types/font_awesome';

import './ColumnSortIndicator.scss';

const SORT_ICONS = {
	[SORT_DIRECTIONS.asc]: FA.caret_up,
	[SORT_DIRECTIONS.desc]: FA.caret_down,
};

const sort = direction => (
	<FontAwesomeIcon className={`sv-ColumnSortIndicator-${direction}`} icon={SORT_ICONS[direction]} />
);

function ColumnSortIndicator({ direction }) {
	let asc = null;
	let desc = null;
	if (!direction || direction === SORT_DIRECTIONS.asc) {
		asc = sort(SORT_DIRECTIONS.asc);
	}
	if (!direction || direction === SORT_DIRECTIONS.desc) {
		desc = sort(SORT_DIRECTIONS.desc);
	}
	return (
		<span className="sv-ColumnSortIndicator">
			{asc}
			{desc}
		</span>
	);
}

ColumnSortIndicator.propTypes = {
	direction: PropTypes.oneOf(Object.keys(SORT_DIRECTIONS)),
};

export default ColumnSortIndicator;
