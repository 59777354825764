import { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';

import { addConnectedModal } from '@/actions/connected_modal';
import { cloneItems, createOrUpdateFolder, deleteFolder } from '@/actions/folders';
import { closeLibraryContextMenu } from '@/actions/library_context';
import { moveSite, rename } from '@/actions/sites';
import container from '@/container';
import { getVisionAssetByFolderId } from '@/lib/api';
import * as routes from '@/lib/routes';
import { ConnectedModal } from '@/types/connected_modal';
import { FOLDER_CONTEXT_MENU, LIBRARY_TYPE } from '@/types/library';
import { FOLDER_TYPES, VISION_ASSET_VARIANT_FOLDER_TYPES } from '@/types/library_folders';
import Menu from '@/views/common/menu/Menu';

class FolderContextMenu extends Component {
	handleOpen = () => {
		const { folderPath, history } = this.props;
		this.props.closeLibraryContextMenu();
		history.push(folderPath);
	};

	handleClick = (e, valuePath, value) => {
		const { item } = this.props;
		const record = item.folder || item;
		const payload = { ...item, folder: { ...record } };
		payload.folder.metadata = { ...record.metadata };

		value = value === get(payload, valuePath) ? false : value;
		set(payload, valuePath, value);
		return this.props.createOrUpdateFolder(record.id, payload.folder || payload);
	};

	handleEditAsset = async folderId => {
		const { history } = this.props;
		const visionAsset = await getVisionAssetByFolderId(container.http, folderId);
		history.push(generatePath(routes.EDIT_ASSET, { assetId: visionAsset.id, origin: 'library' }));
	};

	createContextMenu = () => {
		const { folderId, item, menuItems, namespace } = this.props;
		const record = item.folder || item;
		const { changeCover, clone, deleteItem, editAssetInfo, move, open, rename, ...rest } =
			menuItems;
		const cloneProps = { folderId, namespace };

		const isVisionAssetFolder = item.type === FOLDER_TYPES.vision_asset;

		const isVisionAssetVariantFolder = Object.keys(VISION_ASSET_VARIANT_FOLDER_TYPES).includes(
			item.type,
		);

		return [
			{
				...open,
				action: this.handleOpen,
			},
			!isVisionAssetVariantFolder && {
				...move,
				action: () => this.props.moveFolder(record.id, folderId),
			},
			{
				...rename,
				action: () => this.props.rename(record.id, record.name, folderId || record.id),
				divider: true,
			},
			!isVisionAssetVariantFolder && {
				...clone,
				action: () => this.props.cloneItems([`f-${record.id}`], cloneProps),
			},
			!isVisionAssetVariantFolder && {
				...deleteItem,
				action: () => this.props.deleteFolder(record.id, record.name),
				divider: true,
			},
			{
				...changeCover,
				action: this.changeCoverImage,
			},
			isVisionAssetFolder && {
				...editAssetInfo,
				action: () => this.handleEditAsset(item.id),
			},
			...Object.values(rest),
		]
			.filter(Boolean)
			.map(v => ({ ...v, item }));
	};

	changeCoverImage = () => {
		const { addModal, createOrUpdateFolder, item } = this.props;
		const modalProps = {
			aspectRatio: 16 / 9,
			onClose: () => {},
			onSubmit: image => createOrUpdateFolder(item.id, { image_id: image && image.id }),
		};

		const modal = ConnectedModal.image_crop(modalProps);
		addModal(modal);
	};

	render() {
		const { item, placement } = this.props;

		if (!item) {
			return null;
		}

		let skip = this.props.skip || {};
		if (Array.isArray(skip)) {
			skip = skip.reduce((agg, key) => {
				agg[key] = key;
				return agg;
			}, {});
		}

		let menuItems = this.createContextMenu();
		menuItems = menuItems.map(item => {
			let { name, shouldRender } = item;
			if (shouldRender === undefined) {
				shouldRender = true;
			}

			return {
				...item,
				shouldRender: shouldRender && !skip[name],
			};
		});

		return <Menu menuItems={menuItems} placement={placement} />;
	}
}

FolderContextMenu.propTypes = {
	folderPath: PropTypes.string,
	history: PropTypes.object,
	item: PropTypes.object,
	position: PropTypes.object,
};

const mapStateToProps = (state, props) => {
	const menuItems = props.menuItems
		? { ...FOLDER_CONTEXT_MENU, ...props.menuItems }
		: FOLDER_CONTEXT_MENU;
	return {
		menuItems,
	};
};

const mapDispatchToProps = dispatch => ({
	addModal: modal => dispatch(addConnectedModal(modal)),
	cloneItems: (items, params) => dispatch(cloneItems(items, null, params)),
	closeLibraryContextMenu: () => dispatch(closeLibraryContextMenu()),
	createOrUpdateFolder: (folderId, payload) => dispatch(createOrUpdateFolder(folderId, payload)),
	deleteFolder: (id, name) => dispatch(deleteFolder(id, name)),
	moveFolder: (id, folderId) => dispatch(moveSite(id, LIBRARY_TYPE.FOLDER, folderId)),
	rename: (id, name, folderId) => dispatch(rename(id, name, LIBRARY_TYPE.FOLDER, folderId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FolderContextMenu));
