import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	userContacts: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
	},
};

const helper = new ReduceHelper('userContacts', 'id');

export default {
	INITIAL_STATE,
	[TYPES.USER_CONTACTS_GOT_DATA]: helper.gotData,
	[TYPES.USER_CONTACTS_LOAD_START]: helper.loadStart,
	[TYPES.USER_CONTACTS_LOAD_END]: helper.loadEnd,
	[TYPES.USER_CONTACTS_OP_START]: helper.opStart,
	[TYPES.USER_CONTACTS_OP_END]: helper.opEnd,
	[TYPES.USER_CONTACTS_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
};
