import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	auth: {
		/** Current operation */
		op: null,

		/** When auth redirect happens (eg. you are not logged in), we save the route info here, so we can send you back */
		redirect: null,

		/* Current register operation */
		registerOp: null,

		/**
		 * Session object loaded from API
		 * @type {import('../types/auth').Session}
		 * */
		session: null,

		/**
		 * User with details for currently loaded session.
		 * @type {import('../types/users').UserWithDetails}
		 */
		uwd: null,
	},
};

const updateAuth = (state, ob) => {
	return { ...state, auth: { ...state.auth, ...ob } };
};

const authOpStart = (state, action) => {
	return updateAuth(state, {
		op: {
			currentOp: action?.currentOp,
			error: null,
		},
	});
};

const authOpEnd = (state, action) => {
	const op = action.error
		? {
				current: null,
				error: action.error,
		  }
		: null;

	return updateAuth(state, { op });
};

const loginStart = state => {
	const nextState = authOpStart(state);
	nextState.auth.session = null;
	return nextState;
};

const logout = state => {
	return updateAuth(state, {
		session: null,
		uwd: null,
	});
};

const gotSession = (state, seswd) => {
	return updateAuth(state, {
		session: seswd.session,
		uwd: seswd.uwd,
	});
};

const setAuthRedirect = (state, action) => {
	return updateAuth(state, {
		redirect: action.payload,
	});
};

const updateAuthUser = (state, action) => {
	return updateAuth(state, {
		uwd: {
			...action,
		},
	});
};

const registerUserStart = (state, action) => {
	return updateAuth(state, {
		registerOp: {
			...action,
		},
	});
};

const registerUserEnd = (state, action) => {
	return updateAuth(state, {
		registerOp: {
			...action,
		},
	});
};

const registerUserError = (state, action) => {
	return updateAuth(state, {
		registerOp: {
			...action,
		},
	});
};

export default {
	INITIAL_STATE,
	[TYPES.AUTH_OP_RESET]: authOpEnd,
	[TYPES.OP_RESET_ALL]: authOpEnd,
	[TYPES.AUTH_GOT_SESSION]: gotSession,
	[TYPES.AUTH_SET_REDIRECT]: setAuthRedirect,
	[TYPES.LOGIN_START]: loginStart,
	[TYPES.LOGIN_END]: authOpEnd,
	[TYPES.LOGOUT]: logout,
	[TYPES.SWITCH_COMPANY_START]: authOpStart,
	[TYPES.SWITCH_COMPANY_END]: authOpEnd,
	[TYPES.CHANGE_PASSWORD_START]: authOpStart,
	[TYPES.CHANGE_PASSWORD_END]: authOpEnd,
	[TYPES.RESET_PASSWORD_REQUEST_START]: authOpStart,
	[TYPES.RESET_PASSWORD_REQUEST_END]: authOpEnd,
	[TYPES.RESET_PASSWORD_EXECUTE_START]: authOpStart,
	[TYPES.RESET_PASSWORD_EXECUTE_END]: authOpEnd,
	[TYPES.GENERATE_PASSWORD_REQUEST_START]: authOpStart,
	[TYPES.GENERATE_PASSWORD_REQUEST_END]: authOpEnd,
	[TYPES.UPDATE_AUTH_USER]: updateAuthUser,
	[TYPES.REGISTER_USER_START]: registerUserStart,
	[TYPES.REGISTER_USER_END]: registerUserEnd,
	[TYPES.REGISTER_USER_ERROR]: registerUserError,
};
