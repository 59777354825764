import { cloneElement, isValidElement, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
	Criteria,
	SORT_DIRECTIONS,
	SORT_DIRECTIONS_FLIP,
	TABLE_COLUMN_ALIGN,
	TABLE_COLUMN_SIZING,
	TableColumn,
} from '@/types/data';
import FA from '@/types/font_awesome';

import ColumnSortIndicator from './ColumnSortIndicator';

import './TableDataHead.scss';

export function TableDataHeadColumn({
	align = null,
	background = null,
	caretPosition = TableDataHead.CARET_POSITIONS.right,
	children = null,
	criteria = null,
	onCriteriaChanged = null,
	sizing = null,
	sort_path = null,
	sortable = null,
	stretchColumnStyle = null,
	title = null,
	...rest
}) {
	const sortBy = criteria && criteria.sort_by;
	const sortDirection = criteria && criteria.sort_direction;
	const isSortColumn = sortBy === sort_path;
	const allClassNames = [];
	let icon = null;
	let onClick = null;
	let sortIndicator = null;
	let styleOb = {};

	if (sizing === TABLE_COLUMN_SIZING.stretch) {
		if (stretchColumnStyle.width) {
			styleOb = stretchColumnStyle;
		} else {
			allClassNames.push('stretch');
		}
	} else if (sizing === TABLE_COLUMN_SIZING.shrink) {
		allClassNames.push('shrink');
	}

	if (align === TABLE_COLUMN_ALIGN.center) {
		allClassNames.push('text-center');
	} else if (align === TABLE_COLUMN_ALIGN.right) {
		allClassNames.push('text-right');
	}

	if (sort_path && sortable !== false) {
		allClassNames.push('sv-TableDataHead-sortable');
	}
	if (caretPosition && sortable !== false) {
		allClassNames.push(`sv-TableDataHead-caret-${caretPosition}`);
	}

	if (onCriteriaChanged && sort_path) {
		onClick = e => {
			const newCriteria = criteria.update({
				sort_by: sort_path,
			});
			if (isSortColumn) {
				newCriteria.sort_direction =
					SORT_DIRECTIONS_FLIP[newCriteria.sort_direction] || SORT_DIRECTIONS.asc;
			}
			onCriteriaChanged(newCriteria);
			e.preventDefault();
		};
	}

	if (icon && typeof icon === 'string') {
		icon = <FontAwesomeIcon icon={icon} />;
	}

	if (sort_path) {
		sortIndicator = <ColumnSortIndicator direction={isSortColumn ? sortDirection : null} />;
	}

	if (background) {
		styleOb = { ...styleOb, background };
	}

	return (
		<th className={classNames(allClassNames)} style={styleOb} onClick={onClick} {...rest}>
			{icon}
			{children}
			{title !== undefined && title !== null ? ` ${title}` : null}
			{sortIndicator}
		</th>
	);
}

function TableDataHead({
	background = null,
	caretPosition = TableDataHead.CARET_POSITIONS.right,
	columns = null,
	criteria = null,
	displayCheckbox,
	itemsLen,
	onCriteriaChanged = null,
	onSelectAllToggle,
	onSelection,
	selectedItems,
}) {
	const columnElements = useMemo(() => {
		const stretchColumns = columns?.reduce(
			(count, tableColumn) =>
				tableColumn.sizing === TABLE_COLUMN_SIZING.stretch ? count + 1 : count,
			0,
		);
		const stretchColumnStyle = stretchColumns > 1 ? { width: `${100 / stretchColumns}%` } : {};
		let elements =
			columns?.map((tableColumn, index) => {
				const key = `column_${index}`;
				const columnProps = {
					background,
					caretPosition,
					criteria,
					key,
					onCriteriaChanged,
					stretchColumnStyle,
				};

				if (isValidElement(tableColumn)) {
					return cloneElement(tableColumn, columnProps);
				}
				return (
					<TableDataHeadColumn key={key} {...columnProps} {...tableColumn}>
						{isValidElement(tableColumn.icon) ? tableColumn.icon : null}
					</TableDataHeadColumn>
				);
			}) || [];

		if (displayCheckbox && onSelection) {
			const selectedLen = selectedItems && selectedItems.length;
			const allSelected = itemsLen === selectedLen;
			const toggleAllClickHandler = e => onSelectAllToggle(e, !allSelected);
			const SEL_COL = (
				<TableDataHeadColumn
					name="select"
					sizing={TABLE_COLUMN_SIZING.shrink}
					onClick={toggleAllClickHandler}
				>
					<FontAwesomeIcon icon={allSelected ? FA.check_square : FA.square_empty} />
				</TableDataHeadColumn>
			);
			elements = [SEL_COL, ...elements];
		}

		return elements;
	}, [
		background,
		caretPosition,
		columns,
		criteria,

		displayCheckbox,
		itemsLen,
		onCriteriaChanged,

		onSelectAllToggle,
		onSelection,
		selectedItems,
	]);

	if (!columns) {
		return null;
	}

	return (
		<thead className="sv-TableDataHead">
			<tr>{columnElements}</tr>
		</thead>
	);
}

TableDataHead.propTypes = {
	caretPosition: PropTypes.string,
	columns: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.instanceOf(TableColumn), PropTypes.element]),
	),
	criteria: PropTypes.instanceOf(Criteria),
	onCriteriaChanged: PropTypes.func,
};

TableDataHead.CARET_POSITIONS = {
	left: 'left',
	right: 'right',
};

export default TableDataHead;
