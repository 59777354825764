import PropTypes from 'prop-types';

import './TableSingleCell.scss';

function TableSingleCell({ children, header = false }) {
	const cell = header ? <th colSpan={100}>{children}</th> : <td colSpan={100}>{children}</td>;
	return <tr className="sv-TableSingleCell">{cell}</tr>;
}

TableSingleCell.propTypes = {
	header: PropTypes.bool,
};

export default TableSingleCell;
