import { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '@/views/widgets/ErrorBoundary';

import MenuItem from './MenuItem';

import './Menu.scss';

const CLASS = 'sv-Menu';
class Menu extends Component {
	static propTypes = {
		menuItems: PropTypes.arrayOf(
			PropTypes.shape({
				action: PropTypes.func,
				children: PropTypes.array,
				divider: PropTypes.bool,
				placement: PropTypes.string,
				shouldRender: PropTypes.bool,
				title: PropTypes.string,
			}),
		),
	};

	getMenuItems() {
		const { menuItems } = this.props;
		if (menuItems && !Array.isArray(menuItems)) {
			return Object.values(menuItems);
		}
		return menuItems;
	}

	render() {
		const { placement } = this.props;

		return (
			<ErrorBoundary>
				<div className={CLASS}>
					{this.getMenuItems().map((item, index) =>
						item.shouldRender === false ? null : (
							<MenuItem key={index} placement={placement} {...item} />
						),
					)}
				</div>
			</ErrorBoundary>
		);
	}
}

export default Menu;
