import { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';

import './ErrorBox.scss';

const CLASS = 'sv-ErrorBox';

class ErrorBox extends Component {
	static propTypes = {
		dismiss: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		error: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			dismissed: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error) {
			this.setState({
				dismissed: false,
			});
		}
	}
	// componentWillReceiveProps(nextProps) {
	// 	if (nextProps.error !== this.props.error) {
	// 		this.setState({
	// 			dismissed: false,
	// 		});
	// 	}
	// }

	onDismiss() {
		const { dismiss } = this.props;
		if (typeof dismiss === 'function' && dismiss() === false) {
			return;
		}

		this.setState({
			dismissed: true,
		});
	}

	renderErrorList(errors) {
		const items = [];

		Object.entries(errors).forEach(([key, err]) => {
			if (!Array.isArray(err)) {
				err = [err];
			}

			err.forEach((msg, index) => {
				if (msg && msg.error) {
					msg = msg.error;
				}
				items.push(<li key={`err_${key}_${index}`}>{msg}</li>);
			});
		});

		return <ul className="sv-ErrorBox-errors">{items}</ul>;
	}

	render() {
		if (this.state.dismissed) {
			return null;
		}

		let { dismiss, error } = this.props;
		if (error.error) {
			error = error.error;
		}

		const onDismiss = (dismiss && this.onDismiss.bind(this)) || null;
		const title = error.message;

		const errors = (error.inner_error && error.inner_error.errors) || error.errors || null;
		const details = errors && this.renderErrorList(errors);

		return (
			<Alert className={CLASS} variant="danger" onDismiss={onDismiss}>
				<div className={`${CLASS}-message`}>
					<FontAwesomeIcon icon={FA.exclamation_triangle} />
					{title}
				</div>
				<div className={`${CLASS}-details`}>{details}</div>
			</Alert>
		);
	}
}

export default ErrorBox;
