import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = {
	socketSubscriptions: {
		data: null,
	},
};

const helper = new ReduceHelper('socketSubscriptions', 'room');

const gotData = (state, action) => {
	const data = action.data.map(room => ({ room }));
	const nextState = helper.gotData(state, { ...action, data });
	return nextState;
};

export default {
	INITIAL_STATE,
	[TYPES.SOCKET_SUBSCRIBED]: gotData,
	[TYPES.SOCKET_UNSUBSCRIBED]: gotData,
};
