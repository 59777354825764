import ENV from '@/env';

import { makeImageQueryString } from './api';
import { joinUrlPath } from './util';

const DEFAULT_SCHEMA = ENV.asset_router.default_schema;
const BASE = ENV.asset_router.hostname;

const baseUrl = (urlSchema = DEFAULT_SCHEMA) => {
	urlSchema = urlSchema || DEFAULT_SCHEMA;
	return `${urlSchema}://${BASE}`;
};

export const assetRouterBaseUrl = baseUrl;

export const makeSiteUrl = (sitePath, urlSchema = DEFAULT_SCHEMA) => {
	return joinUrlPath(baseUrl(urlSchema), sitePath);
};

export const makeSiteUrlOrNull = (sitePath, urlSchema = DEFAULT_SCHEMA) => {
	if (!sitePath) {
		return null;
	}
	return makeSiteUrl(sitePath, urlSchema);
};

export const makeSiteVersionUrl = (sitePath, versionTag, urlSchema = DEFAULT_SCHEMA) => {
	if (!sitePath) {
		return null;
	}
	return joinUrlPath(makeSiteUrl(sitePath, urlSchema), '/~versions/', versionTag);
};

export const makeStatsUrl = (sitePath, urlSchema = DEFAULT_SCHEMA) => {
	if (!sitePath) {
		return null;
	}
	return joinUrlPath(makeSiteUrl(sitePath, urlSchema), '/~stats/');
};

export const makeSiteImageUrl = (
	sitePath,
	imageTag,
	width = null,
	height = null,
	max = null,
	urlSchema = DEFAULT_SCHEMA,
) => {
	if (!sitePath) {
		return null;
	}
	const url =
		joinUrlPath(makeSiteUrl(sitePath, urlSchema), '/~images/', imageTag) +
		makeImageQueryString(width, height, max);
	return url;
};
