/* eslint-disable no-prototype-builtins */
import { get, zipObject } from 'lodash';
import moment from 'moment';

import ENV from '@/env';
import {
	assign,
	enumize,
	getDefaultTimeOrdinalsLowerBound,
	getTimeOrdinals,
	numberify,
	safeMoment,
	toTimeOrdinalString,
} from '@/lib/util';

import { Criteria, SORT_DIRECTIONS, TABLE_COLUMN_SIZING, TableColumn } from './data';
import FA from './font_awesome';

export const HEATMAP_COLOR_LABELS = {
	blue: 'blue',
	green: 'green',
	red: 'red',
	yellow: 'yellow',
};

export const HEATMAP_COLORS = {
	[HEATMAP_COLOR_LABELS.blue]: 'rgb(0,0,255)',
	[HEATMAP_COLOR_LABELS.green]: 'rgb(0,255,0)',
	[HEATMAP_COLOR_LABELS.yellow]: 'yellow',
	[HEATMAP_COLOR_LABELS.red]: 'rgb(255,0,0)',
};

export const HEATMAP_COLOR_RATIO = {
	[HEATMAP_COLOR_LABELS.blue]: 0.25,
	[HEATMAP_COLOR_LABELS.green]: 0.55,
	[HEATMAP_COLOR_LABELS.yellow]: 0.85,
	[HEATMAP_COLOR_LABELS.red]: 1,
};

export const REPORT_SECTIONS = {
	audience: 'audience',
	management: 'management',
};

export const REPORT_SECTION_NAMES = {
	audience: 'Audience reports',
	management: 'Management reports',
};

export const MANAGEMENT_REPORTS = {
	// customer_reports: 'customer_reports',
	content: 'content',

	orders: 'orders',
};

export const AUDIENCE_REPORTS = {
	browser: 'browser',
	device: 'device',
	geo: 'geo',
	heatmap: 'heatmap',
	os: 'os',
	platform: 'platform',
	referral: 'referral',
	traffic: 'traffic',
	visual: 'visual',
};

export const REPORT_NAMES = {
	audience: {
		browser: 'Browser',
		device: 'Device',
		geo: 'Geo',
		heatmap: 'Heatmaps',
		os: 'OS',
		platform: 'Platform',
		referral: 'Referral',
		traffic: 'Traffic',
		visual: 'Visual',
	},
	management: {
		// customer_reports: 'Customer Reports',
		content: 'Content',

		orders: 'Orders',
	},
};

export const REPORT_ICONS = {
	audience: {
		browser: FA.internet_explorer,
		device: FA.mobile_phone,
		geo: FA.globe,
		heatmap: FA.image,
		os: FA.windows,
		platform: FA.laptop,
		referral: FA.bullhorn,
		traffic: FA.subway,
		visual: FA.slideshare,
	},
	management: {
		orders: FA.shopping_cart,
	},
};

export const REPORT_TYPES = {
	summary: 'summary',
	timeline: 'timeline',
};

export const REPORT_TYPE_LABELS = {
	summary: 'Summary',
	timeline: 'Timeline',
};

export const REPORT_TYPE_ICONS = {
	summary: FA.balance_scale,
	timeline: FA.long_arrow_right,
};

export const REPORT_IDS = {
	browser: {
		chart: ENV.reports.browser.chart,
		table: ENV.reports.browser.table,
	},
	device: {
		chart: ENV.reports.device.chart,
		table: ENV.reports.device.table,
	},
	geo: {
		chart: ENV.reports.geo.chart,
		table: ENV.reports.geo.table,
	},
	os: {
		chart: ENV.reports.os.chart,
		table: ENV.reports.os.table,
	},
	platform: {
		chart: ENV.reports.platform.chart,
		table: ENV.reports.platform.table,
	},
	referral: {
		chart: ENV.reports.referral.chart,
		table: ENV.reports.referral.table,
	},
	traffic: {
		chart: ENV.reports.traffic.chart,
		table: ENV.reports.traffic.table,
	},
};

export const TIME_RESOLUTIONS = {
	day: 'day',
	hour: 'hour',
	month: 'month',
	year: 'year',
};

export const TIME_RESOLUTION_FORMATS = {
	day: 'YYYY/MM/DD',
	hour: 'YYYY/MM/DD HH[h]',
	month: 'YYYY/MM',
	year: 'YYYY',
};

export const TIME_RESOLUTION_LABELS = {
	day: 'Day',
	hour: 'Hour',
	month: 'Month',
	year: 'Year',
};

export const REPORT_EXPORT_FORMATS = {
	csv: 'csv',
	tsv: 'tsv',
	zip: 'zip',
};

export const REPORT_DISPLAY_FORMATS = {
	chart: 'chart',
	table: 'table',
};

export const REPORT_FORMAT_LABELS = {
	chart: 'Chart',
	csv: 'Comma-separated values (.csv)',
	table: 'Table',
	tsv: 'Tab-separated values (.tsv)',
	zip: 'ZIP (.zip)',
};

export const REPORT_CHART_TYPES = {
	bar: 'bar',
	geo_map: 'geo_map',
	horizontal_bar: 'horizontal_bar',
	line: 'line',
	pie: 'pie',
};

export const REPORT_CHART_TYPE_LABELS = {
	bar: 'Bar',
	geo: 'Geo map',
	horizontal_bar: 'Horizontal bar',
	line: 'Line',
	pie: 'Pie',
};

export const REPORT_FORMAT_ICONS = {
	chart: FA.pie_chart,
	csv: FA.file_text_o,
	table: FA.table,
	tsv: FA.file_text_o,
	zip: FA.file_text_o,
};

export const AUDIENCE_VIEW_MODES = {
	any: 'any',
	vr: 'vr',
	web: 'web',
};

export const AUDIENCE_VIEW_MODE_LABELS = {
	any: 'Combined',
	vr: 'VR mode',
	web: 'Web mode',
};

export const TIME_FRAMES = {
	all_time: 'all_time',
	custom: 'custom',
	last_7_days: 'last_7_days',
	prev_month: 'prev_month',
	prev_six_months: 'prev_six_months',
	prev_year: 'prev_year',
	this_month: 'this_month',
	today: 'today',
	yesterday: 'yesterday',
};

export const TIME_FRAME_LABELS = {
	all_time: 'All time',
	custom: 'Choose Date',
	last_7_days: 'Last 7 days',
	prev_month: 'Last month',
	prev_six_months: 'Last 6 months',
	prev_year: 'Last year',
	this_month: 'This month',
	today: 'Today',
	yesterday: 'Yesterday',
};

export const TIME_FRAME_GETTERS = {
	all_time: {},
	last_7_days: {
		from: () => moment().subtract(7, 'days').startOf('day').toDate(),
	},
	prev_month: {
		from: () => moment().subtract(1, 'months').startOf('month').toDate(),
		to: () => moment().startOf('month').toDate(),
	},
	prev_six_months: {
		from: () => moment().subtract(6, 'months').startOf('month').toDate(),
		to: () => moment().startOf('month').toDate(),
	},
	prev_year: {
		from: () => moment().subtract(12, 'months').startOf('month').toDate(),
		to: () => moment().startOf('month').toDate(),
	},
	this_month: {
		from: () => moment().startOf('month').toDate(),
	},
	today: {
		from: () => moment().startOf('day').toDate(),
	},
	yesterday: {
		from: () => moment().subtract(1, 'day').startOf('day').toDate(),
		to: () => moment().startOf('day').toDate(),
	},
};

export const CONTENT_REPORT_COLUMNS = [
	{ label: 'Id', value: 'id' },
	{ label: 'Name', value: 'name' },
	{ label: 'Owner', value: 'owner' },
	{ label: 'Created by', value: 'user' },
	{ label: 'URL', value: 'url' },
	{ label: 'Path', value: 'path' },
	{ label: 'Type', value: 'type' },
	{ label: 'Created', value: 'created' },
	{ label: 'Modified', value: 'modified' },
	{ label: 'Deleted', value: 'deleted' },
	{ label: 'Size', value: 'size' },
];

export const CONTENT_REPORT_COLUMN_HEADER = {
	created: 'Created',
	deleted: 'Deleted',
	id: 'Id',
	modified: 'Modified',
	name: 'Name',
	owner: 'Owner',
	path: 'Path',
	size: 'Size',
	type: 'Type',
	url: 'URL',
	user: 'Created by',
};

export const CONTENT_REPORT_TABLE_COLUMNS = [
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.id],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Id',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.name],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Name',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.owner],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Owner',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.user],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Created by',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.url],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'URL',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.path],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Path',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.type],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Type',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.created],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Created',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.modified],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Modified',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.deleted],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Deleted',
	}),
	new TableColumn({
		name: [CONTENT_REPORT_COLUMN_HEADER.size],
		sizing: TABLE_COLUMN_SIZING.shrink,
		title: 'Size',
	}),
];

export const MAX_CHART_LABELS = 10;

// *********************************************************************************************************************

export const formatDateToResolution = (date, resolution) => {
	const m = safeMoment(date);
	if (!m) {
		return null;
	}
	return m.format(TIME_RESOLUTION_FORMATS[resolution]);
};

// *********************************************************************************************************************

class TimelineData {
	constructor(datasets, xLabels, yLabels) {
		this.datasets = datasets || {};
		this.xLabels = xLabels || [];
		this.yLabels = yLabels || [];
	}
}

/**
 * Converts server-provided data into a timeline suitable data
 */
export class TimelineDataConverter {
	constructor(source) {
		this.x_prop = 'date';

		this.y_prop = null;
		this.y_keys = null;
		this.y_format = null;

		this.val_prop = null;
		this.val_format = null;

		this.from = null;
		this.to = null;

		this.resolution = null;

		assign(this, this, source);
	}

	assertSettings() {
		if (!this.y_prop && this.val_prop && this.resolution) {
			throw new Error(`Missing settings`);
		}
	}

	format(formatter, val) {
		if (!formatter) {
			return val;
		}
		if (formatter.apply) {
			return formatter(val);
		}
		return formatter[val];
	}

	/**
	 * @return {TimelineData}
	 */
	convert(data) {
		this.assertSettings();

		const yFormat = this.format.bind(this, this.y_format || String);
		const resultsHash = {};

		// Fill in provided y keys into a hash
		const yLabelsHash = this.y_keys
			? zipObject(this.y_keys, Object.values(this.y_keys).map(yFormat))
			: {};

		// Fill in the data we have
		// Example item:
		//	{ date: Date, company: 'XYC', count: 123 }
		Object.values(data).forEach(item => {
			const date = get(item, this.x_prop); // date
			const yKey = get(item, this.y_prop); // 'company'
			const yFormattedKey = yFormat(yKey); // 'Company'
			const formattedValue = this.format(this.val_format, get(item, this.val_prop)); // 123

			if (!yLabelsHash[yKey]) {
				if (this.y_keys) {
					// Unsupported key, ignore it
					return;
				}

				// If we are auto-detecting y axis keys, add it
				yLabelsHash[yKey] = yFormattedKey;
			}

			const xKey = toTimeOrdinalString(date);
			let ob = resultsHash[xKey];
			if (!ob) {
				resultsHash[xKey] = ob = {};
			}
			ob[yFormattedKey] = formattedValue;
		});

		let { from } = this;
		if (!from) {
			// derive from data
			from = getDefaultTimeOrdinalsLowerBound(this.resolution);
			Object.values(data).forEach(item => {
				const date = get(item, this.x_prop);
				if (from.isAfter(date)) {
					from = moment.utc(date).subtract(1, this.resolution);
				}
			});
		}

		const timeOrdinals = getTimeOrdinals(this.resolution, from, this.to);

		// Prepare the result data structure
		const result = new TimelineData();
		Object.values(yLabelsHash).forEach(yFormattedKey => {
			result.yLabels.push(yFormattedKey);
			result.datasets[yFormattedKey] = [];
		});

		// Convert the ordinals into final data
		timeOrdinals.forEach(xKey => {
			const ob = resultsHash[xKey];
			result.xLabels.push(formatDateToResolution(xKey, this.resolution));
			result.yLabels.forEach(yFormattedKey => {
				const dataPoint = ob && ob.hasOwnProperty(yFormattedKey) ? ob[yFormattedKey] : 0;
				result.datasets[yFormattedKey].push(dataPoint);
			});
		});

		return result;
	}
}

// *********************************************************************************************************************

class SummaryData {
	constructor(labels, data) {
		this.labels = labels || [];
		this.data = data || [];
	}
}

/**
 * Converts server-provided data into a summary suitable data
 */
export class SummaryDataConverter {
	constructor(source) {
		this.label_prop = null;
		this.label_format = null;
		this.val_prop = null;
		this.val_format = null;

		assign(this, this, source);
	}

	assertSettings() {
		if (!this.label_prop && this.val_prop) {
			throw new Error(`Missing settings`);
		}
	}

	format(formatter, val) {
		if (!formatter) {
			return val;
		}
		if (formatter.apply) {
			return formatter(val);
		}
		return formatter[val];
	}

	/**
	 * @param data
	 * @return {SummaryData}
	 */
	convert(data) {
		this.assertSettings();

		const result = new SummaryData();

		Object.values(data).forEach(item => {
			const label = this.format(this.label_format, item[this.label_prop]);
			const value = this.format(this.val_format, item[this.val_prop]);

			result.labels.push(label);
			result.data.push(value);
		});

		return result;
	}
}

// *********************************************************************************************************************

class StackedData {
	constructor(datasets, xLabels, yLabels) {
		this.datasets = datasets || {};
		this.xLabels = xLabels || [];
		this.yLabels = yLabels || [];
	}
}

/**
 * Converts server-provided data into a stacked data
 */
export class StackedDataConverter {
	constructor(source) {
		this.x_prop = null;
		this.x_label_prop = null;
		this.x_keys = null;
		this.x_format = null;

		this.y_prop = null;
		this.y_label_prop = null;
		this.y_keys = null;
		this.y_format = null;

		this.val_prop = null;
		this.val_format = null;

		assign(this, this, source);
	}

	assertSettings() {
		if (!this.y_prop && this.val_prop) {
			throw new Error(`Missing settings`);
		}
	}

	format(formatter, val) {
		if (!formatter) {
			return val;
		}
		if (formatter.apply) {
			return formatter(val);
		}
		return formatter[val];
	}

	convert(data) {
		this.assertSettings();

		const yFormat = this.format.bind(this, this.y_format || String);
		const xFormat = this.format.bind(this, this.x_format || String);
		const resultsHash = {};

		// Fill in provided y keys into a hash
		const yLabelsHash = this.y_keys
			? zipObject(
					this.y_keys,
					Object.entries(this.y_keys).map(([key, val]) => yFormat(val, key)),
			  )
			: {};

		// Fill in provided y keys into a hash
		const xLabelsHash = this.x_keys
			? zipObject(
					this.x_keys,
					Object.entries(this.x_keys).map(([key, val]) => xFormat(val, key)),
			  )
			: {};

		// Fill in the data we have
		// Example item:
		//	{ date: Date, company: 'XYC', count: 123 }
		Object.values(data).forEach(item => {
			const xKey = get(item, this.x_prop);
			const yKey = get(item, this.y_prop);

			const xLabel = get(item, this.x_label_prop);
			const yLabel = get(item, this.y_label_prop);

			const xFormattedKey = yFormat(xLabel);
			const yFormattedKey = yFormat(yLabel);
			const formattedValue = this.format(this.val_format, get(item, this.val_prop)); // 123

			if (!yLabelsHash[yKey]) {
				if (this.y_keys) {
					// Unsupported key, ignore it
					return;
				}

				// If we are auto-detecting y axis keys, add it
				yLabelsHash[yKey] = yFormattedKey;
			}

			if (!xLabelsHash[xKey]) {
				if (this.x_keys) {
					// Unsupported key, ignore it
					return;
				}

				// If we are auto-detecting x axis keys, add it
				xLabelsHash[xKey] = xFormattedKey;
			}

			let ob = resultsHash[xKey];
			if (!ob) {
				resultsHash[xKey] = ob = {};
			}
			ob[yFormattedKey] = formattedValue;
		});

		const xLabels = Object.values(xLabelsHash);
		const yLabels = Object.values(yLabelsHash);
		const datasets = {};

		yLabels.forEach(yFormattedKey => {
			datasets[yFormattedKey] = [];
		});

		// Convert the ordinals into final data
		Object.entries(resultsHash).forEach(([xKey, ob]) => {
			yLabels.forEach(yFormattedKey => {
				const dataPoint = ob && ob.hasOwnProperty(yFormattedKey) ? ob[yFormattedKey] : 0;
				datasets[yFormattedKey].push(dataPoint);
			});
		});

		return new StackedData(datasets, xLabels, yLabels);
	}
}

// *********************************************************************************************************************

export class BaseReportCriteria extends Criteria {
	coerce() {
		super.coerce();

		if (this.s) {
			this.s = Number(this.s) || null;
		}
		if (this.from) {
			this.from = new Date(this.from);
		}
		if (this.to) {
			this.to = new Date(this.to);
		}
		if (this.report_type === REPORT_TYPES.timeline) {
			this.chart_type = REPORT_CHART_TYPES.line;
		}
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_type = REPORT_CHART_TYPES.pie;
		}
		return this;
	}

	getTimeFrameParam(paramName) {
		if (this.time_frame === TIME_FRAMES.custom) {
			return this[paramName] || undefined;
		}
		if (!TIME_FRAMES[this.time_frame]) {
			return undefined;
		}
		if (!TIME_FRAME_GETTERS[this.time_frame][paramName]) {
			return undefined;
		}
		return TIME_FRAME_GETTERS[this.time_frame][paramName]();
	}

	getEffectiveFrom() {
		return this.getTimeFrameParam('from');
	}

	getEffectiveTo() {
		return this.getTimeFrameParam('to');
	}

	toQuery(fromPage) {
		super.coerce();
		const query = super.toQuery(fromPage);

		if (REPORT_EXPORT_FORMATS[this.format]) {
			query.format = this.format;
		}

		if (REPORT_TYPES[this.report_type]) {
			query['report-type'] = this.report_type;
		}

		if (TIME_RESOLUTIONS[this.time_resolution]) {
			query['time-resolution'] = this.time_resolution;
		}

		if (AUDIENCE_VIEW_MODES[this.view_mode]) {
			query['view-mode'] = this.view_mode;
		}
		if (EXPORT_LAYOUTS[this.export_layout]) {
			query['export-layout'] = this.export_layout;
		}

		query.from = this.getEffectiveFrom();
		query.to = this.getEffectiveTo();

		return query;
	}
}

export class ReportCriteria extends BaseReportCriteria {
	constructor(source) {
		super();

		this.s = null;
		this.display = null;
		this.format = REPORT_DISPLAY_FORMATS.table;
		this.chart_type = REPORT_CHART_TYPES.line;
		this.chart_stacked = undefined;
		this.report_type = REPORT_TYPES.timeline;
		this.time_resolution = TIME_RESOLUTIONS.day;
		this.time_frame = TIME_FRAMES.today;
		this.from = undefined;
		this.to = undefined;
		this.page_size = null;
		this.view_mode = AUDIENCE_VIEW_MODES.any;
		this.date_changed = false;
		this.time_changed = false;

		assign(this, this, source);

		this.coerce();
	}
}

/**
 * @type {ReportCriteria}
 */
export const REPORT_CRITERIA = enumize(new ReportCriteria());

export const EXPORT_LAYOUTS = {
	inline: 'inline',
	normal: 'normal',
};

export class HeatmapsCriteria extends BaseReportCriteria {
	constructor(source) {
		super();

		this.s = null;
		this.site_id = null;
		this.scene_id = null;
		this.format = undefined;
		this.time_frame = TIME_FRAMES.today;
		this.from = undefined;
		this.to = undefined;
		this.view_mode = AUDIENCE_VIEW_MODES.any;
		// hard code the type of content
		// TODO: SET TO TYPE ONLY SUPPORTED
		this.site_category = 'tour';
		this.export_layout = EXPORT_LAYOUTS.normal;
		this.date_changed = false;
		this.time_changed = false;

		assign(this, this, source);

		this.coerce();
	}

	serialize() {
		const serialized = super.serialize();
		return serialized;
	}

	toQuery(fromPage) {
		super.coerce();
		const query = super.toQuery(fromPage);

		if (this.scene_id) {
			query['scene-id'] = this.scene_id;
		}

		return query;
	}

	static DEFAULT_IGNORED_KEYS = [
		'site_id',
		'site_category',
		'export_layout',
		'date_changed',
		'time_changed',
	];
}

/**
 * @type {HeatmapsCriteria}
 */
export const HEATHMAP_CRITERIA = enumize(new HeatmapsCriteria());

// *********************************************************************************************************************

export class OrdersReport {
	constructor() {
		this.date = undefined;
		this.product_id = undefined;
		this.product_name = undefined;
		this.orders_count = undefined;
		this.item_qty = undefined;
		this.item_price = undefined;
		this.total_price = undefined;
	}
}

/**
 * @type {OrdersReport}
 */
export const ORDERS_REPORT = enumize(new OrdersReport());

export const ORDERS_REPORT_DIMENSIONS = {
	item_qty: ORDERS_REPORT.item_qty,
	orders_count: ORDERS_REPORT.orders_count,
	total_price: ORDERS_REPORT.total_price,
};

export const ORDERS_REPORT_DIMENSION_LABELS = {
	[ORDERS_REPORT_DIMENSIONS.orders_count]: 'Orders',
	[ORDERS_REPORT_DIMENSIONS.item_qty]: 'Item Qty',
	[ORDERS_REPORT_DIMENSIONS.total_price]: 'Total Price',
};

export class OrdersReportCriteria extends ReportCriteria {
	constructor(source) {
		super();

		this.product_category = undefined;
		this.product_id = undefined;
		this.buyer_id = undefined;
		this.beneficiary_id = undefined;

		this.sort_by = 'date';
		this.sort_direction = SORT_DIRECTIONS.asc;
		this.time_frame = TIME_FRAMES.this_month;
		this.dimension = ORDERS_REPORT_DIMENSIONS.orders_count;
		this.label_prop = ORDERS_REPORT.product_name;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();

		this.product_id = numberify(this.product_id);
		this.buyer_id = numberify(this.buyer_id);
		this.beneficiary_id = numberify(this.beneficiary_id);
	}

	toQuery(fromPage) {
		const query = super.toQuery(fromPage);

		if (this.report_type === REPORT_TYPES.summary && this.sort_by === 'date') {
			// TODO: Should this happen here? Revisit once we have more experience
			delete query['sort-by'];
		}

		if (this.product_id) {
			query['product-id'] = this.product_id;
		} else if (this.product_category) {
			query['product-category'] = this.product_category;
		}

		if (this.buyer_id) {
			query['buyer-id'] = this.buyer_id;
		}

		if (this.beneficiary_id) {
			query['beneficiary-id'] = this.beneficiary_id;
		}

		return query;
	}
}

/**
 * @type {OrdersReportCriteria}
 */
export const ORDERS_REPORT_CRITERIA = enumize(new OrdersReportCriteria());

// *********************************************************************************************************************

export class SitesReport {
	constructor() {
		this.site_id = undefined;
		this.site = undefined;

		this.sessions_count = undefined;
		this.session_duration_total = undefined;
		this.session_duration_avg = undefined;

		this.users_count = undefined;
		this.new_users_count = undefined;
		this.bounces_count = undefined;

		this.geo_country = undefined;
		this.geo_city = undefined;

		this.ua_device = undefined;
		this.ua_platform = undefined;

		this.ua_browser = undefined;
		this.ua_browser = undefined;
		this.ua_browser_version = undefined;

		this.ua_os = undefined;
		this.ua_os = undefined;
		this.ua_os_version = undefined;

		this.referrer = undefined;

		this.date = undefined;
	}
}

/**
 * @type {SitesReport}
 */
export const SITES_REPORT = enumize(new SitesReport());

export const SITES_REPORT_LABELS = {
	[SITES_REPORT.date]: 'Date',
	[SITES_REPORT.site]: 'Sites',
	[SITES_REPORT.sessions_count]: 'Sessions',
	[SITES_REPORT.users_count]: 'Users',
	[SITES_REPORT.new_users_count]: 'New Users',
	[SITES_REPORT.bounces_count]: 'Bounces',
	[SITES_REPORT.geo_country]: 'Country',
	[SITES_REPORT.geo_city]: 'City',
	[SITES_REPORT.ua_device]: 'Device',
	[SITES_REPORT.ua_platform]: 'Platform',
	[SITES_REPORT.ua_browser]: 'Browser',
	[SITES_REPORT.ua_os]: 'OS',
	[SITES_REPORT.referrer]: 'Referrer',
	[SITES_REPORT.session_duration_total]: 'Duration SUM',
	[SITES_REPORT.session_duration_avg]: 'Duration AVG',
};

export const SITES_REPORT_DIMENSIONS = {
	bounces_count: SITES_REPORT.bounces_count,
	session_duration_avg: SITES_REPORT.session_duration_avg,
	session_duration_total: SITES_REPORT.session_duration_total,
	sessions_count: SITES_REPORT.sessions_count,
	users_count: SITES_REPORT.users_count,
};

export const SITES_REPORT_DIMENSION_LABELS = {
	[SITES_REPORT_DIMENSIONS.sessions_count]: 'Sessions',
	[SITES_REPORT_DIMENSIONS.users_count]: 'Users',
	[SITES_REPORT_DIMENSIONS.bounces_count]: 'Bounces',
	[SITES_REPORT_DIMENSIONS.session_duration_total]: 'Duration SUM',
	[SITES_REPORT_DIMENSIONS.session_duration_avg]: 'Duration AVG',
};

export class SitesReportCriteria extends ReportCriteria {
	constructor(source) {
		super(source);

		this.site = undefined;
		this.site_id = undefined;
		this.site_category = undefined;
		this.geo_country = undefined;
		this.ua_device = undefined;

		this.sort_by = SITES_REPORT.date;
		this.sort_direction = SORT_DIRECTIONS.asc;
		this.time_frame = TIME_FRAMES.this_month;
		this.dimension = SITES_REPORT_DIMENSIONS.sessions_count;

		this.x_prop = SITES_REPORT.site;
		this.x_label_prop = SITES_REPORT.site;

		this.y_prop = SITES_REPORT.site_id;
		this.y_label_prop = SITES_REPORT.site;

		this.format = REPORT_DISPLAY_FORMATS.table;
		this.label_prop = SITES_REPORT.site;
		this.report_type = REPORT_TYPES.timeline;
		this.time_frame = TIME_FRAMES.prev_month; // TODO for testing, remove later

		this.group_by = undefined;
		this.group_limit = MAX_CHART_LABELS;
		this.group_sort = this.dimension;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.site_id = numberify(this.site_id);
		if (this.report_type === REPORT_TYPES.summary && this.sort_by === SITES_REPORT.date) {
			this.sort_by = [this.dimension, this.y_prop].join(',');
		}
	}

	toQuery(fromPage) {
		const query = super.toQuery(fromPage);

		if (this.report_type === REPORT_TYPES.summary && this.sort_by === SITES_REPORT.date) {
			// TODO: Should this happen here? Revisit once we have more experience
			delete query['sort-by'];
		}

		if (this.site_id) {
			query['site-id'] = this.site_id;
		}
		if (this.site) {
			query.site = this.site;
		}
		if (this.site_category) {
			query['site-category'] = this.site_category;
		}
		if (this.geo_country) {
			query['geo-country'] = this.geo_country;
		}

		if (this.group_by) {
			query['group-by'] = this.group_by;
		}
		if (this.group_limit) {
			query['group-limit'] = this.group_limit;
		}

		return query;
	}
}

/**
 * @type {SitesReportCriteria}
 */
export const SITES_REPORT_CRITERIA = enumize(new SitesReportCriteria());

export class GeoReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.group_by = SITES_REPORT.geo_country;
		this.label_prop = SITES_REPORT.geo_country;
		this.report_type = REPORT_TYPES.summary;

		this.format = REPORT_DISPLAY_FORMATS.chart;
		this.group_limit = undefined;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_type = REPORT_CHART_TYPES.geo_map;
		}
		if (this.report_type === REPORT_TYPES.timeline) {
			this.chart_type = REPORT_CHART_TYPES.line;
		}
	}
}

/**
 * @type {GeoReportCriteria}
 */
export const GEO_REPORT_CRITERIA = enumize(new GeoReportCriteria());

export class CitiesReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;
		this.sort_by = SITES_REPORT.geo_city;
		this.label_prop = SITES_REPORT.geo_city;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.geo_city;
	}
}

/**
 * @type {CitiesReportCriteria}
 */
export const CITIES_REPORT_CRITERIA = enumize(new CitiesReportCriteria());

export class DeviceReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;

		this.y_prop = SITES_REPORT.ua_device;
		this.y_label_prop = SITES_REPORT.ua_device;
		this.label_prop = SITES_REPORT.ua_device;

		this.format = REPORT_DISPLAY_FORMATS.chart;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.ua_device;
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_stacked = true;
		}
	}
}

/**
 * @type {DeviceReportCriteria}
 */
export const DEVICE_REPORT_CRITERIA = enumize(new DeviceReportCriteria());

export class PlatformReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;

		this.y_prop = SITES_REPORT.ua_platform;
		this.y_label_prop = SITES_REPORT.ua_platform;
		this.label_prop = SITES_REPORT.ua_platform;

		this.format = REPORT_DISPLAY_FORMATS.chart;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.ua_platform;
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_stacked = true;
		}
	}
}

/**
 * @type {PlatformReportCriteria}
 */
export const PLATFORM_REPORT_CRITERIA = enumize(new PlatformReportCriteria());

export class BrowserReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;

		this.y_prop = SITES_REPORT.ua_browser;
		this.y_label_prop = SITES_REPORT.ua_browser;
		this.label_prop = SITES_REPORT.ua_browser;

		this.format = REPORT_DISPLAY_FORMATS.chart;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.ua_browser;
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_stacked = true;
		}
	}
}

/**
 * @type {BrowserReportCriteria}
 */
export const BROWSER_REPORT_CRITERIA = enumize(new BrowserReportCriteria());

export class OSReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;

		this.y_prop = SITES_REPORT.ua_os;
		this.y_label_prop = SITES_REPORT.ua_os;
		this.label_prop = SITES_REPORT.ua_os;

		this.format = REPORT_DISPLAY_FORMATS.chart;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.ua_os;
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_stacked = true;
		}
	}
}

/**
 * @type {OSReportCriteria}
 */
export const OS_REPORT_CRITERIA = enumize(new OSReportCriteria());

export class ReferralReportCriteria extends SitesReportCriteria {
	constructor(source) {
		super(source);

		this.geo_country = undefined;

		this.y_prop = SITES_REPORT.referrer;
		this.y_label_prop = SITES_REPORT.referrer;
		this.label_prop = SITES_REPORT.referrer;

		assign(this, this, source);
		this.coerce();
	}

	coerce() {
		super.coerce();
		this.group_by = SITES_REPORT.referrer;
		if (this.report_type === REPORT_TYPES.summary) {
			this.chart_stacked = true;
		}
	}
}

/**
 * @type {OSReportCriteria}
 */
export const REFERRAL_REPORT_CRITERIA = enumize(new ReferralReportCriteria());

// *********************************************************************************************************************

export class VisualStatsCriteria extends ReportCriteria {
	constructor(source) {
		super();

		this.time_frame = TIME_FRAMES.all_time;
		this.user_id = null;
		this.view_mode = AUDIENCE_VIEW_MODES.any;

		assign(this, this, source);
		this.coerce();
	}
}
