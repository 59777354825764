import { some } from 'lodash';

import * as api from '@/lib/api';
import * as routes from '@/lib/routes';
import { withType } from '@/lib/util';
import { CompanyCriteria, LOOKUP_PROPS } from '@/types/companies';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast } from './application';

/**
 * @param {CompanyCriteria} criteria
 * @param {PaginationInfo} fromPage Used for pagination
 */
export const loadCompanies =
	(criteria, fromPage = null) =>
	(dispatch, getState, container) => {
		dispatch(
			withType(TYPES.COMPANIES_LOAD_START, {
				invalidateAll: !fromPage,
				paginating: !!fromPage,
			}),
		);

		api.getCompanies(container.http, criteria, fromPage).then(
			queryResult => {
				dispatch(
					withType(TYPES.COMPANIES_GOT_DATA, {
						data: queryResult.data,
						nextPage: queryResult.next_page,
					}),
				);
				dispatch(withType(TYPES.COMPANIES_LOAD_END));
			},
			error => {
				dispatch(withType(TYPES.COMPANIES_LOAD_END, { error }));
			},
		);
	};

export const lookupCompanies =
	(
		parent = undefined,
		filter = '',
		ensureId = null,
		maxCount = 100,
		sortCriteria = {},
		limitToCurrentCompanyWithDescendants = undefined,
	) =>
	(dispatch, getState, container) => {
		const criteria = new CompanyCriteria({
			filter,
			parent,
			...sortCriteria,
			descendants_of: limitToCurrentCompanyWithDescendants,
		});
		criteria.props = LOOKUP_PROPS;
		criteria.page_size = maxCount;

		if (ensureId) {
			criteria.filter_exclude_id = ensureId;
		}

		return api.getCompanies(container.http, criteria).then(queryResult => {
			const hasEnsuredId = !ensureId || some(queryResult.data, cwd => cwd.company.id === ensureId);

			if (hasEnsuredId) {
				return queryResult.data;
			}

			return api.getCompany(container.http, ensureId).then(cwd => {
				return [cwd].concat(queryResult.data);
			});
		});
	};

export const loadCompany =
	(companyId, invalidate = true) =>
	(dispatch, getState, container) => {
		dispatch(
			withType(TYPES.COMPANIES_LOAD_START, {
				invalidateId: invalidate ? companyId : null,
			}),
		);

		return api.getCompany(container.http, companyId).then(
			cwd => {
				dispatch(
					withType(TYPES.COMPANIES_GOT_DATA, {
						data: [cwd],
						reset: false,
					}),
				);

				dispatch(withType(TYPES.COMPANIES_LOAD_END));
			},
			error => {
				dispatch(withType(TYPES.COMPANIES_LOAD_END, { error }));
			},
		);
	};

export const createOrUpdateCompany =
	(companyId, payload, images, shoudRedirect = true) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.COMPANIES_OP_START));

		const promise = companyId
			? api.updateCompany(container.http, companyId, payload)
			: api.createCompany(container.http, payload);

		return promise.then(
			res => {
				companyId = res.record.id;
				if (!companyId) {
					throw new Error('Unexpected response');
				}
				dispatch(withType(TYPES.COMPANIES_OP_END, { invalidateId: companyId }));
				dispatch(loadCompany(companyId));

				if (shoudRedirect) {
					container.history.push(routes.COMPANIES);
				}
			},
			error => {
				dispatch(withType(TYPES.COMPANIES_OP_END, { error }));
			},
		);
	};

export const createCompanyWithFetch = payload => (dispatch, getState, container) => {
	let companyId = null;

	const promiseQueue = [api.createCompany(container.http, payload)];

	return Promise.all(promiseQueue).then(results => {
		companyId = results[0].record.id;
		if (!companyId) {
			throw new Error('Unexpected response');
		}
		return results[0];
	}, null);
};

export const deleteCompany =
	companyId =>
	(dispatch, getState, { history, http }) => {
		dispatch(withType(TYPES.COMPANIES_OP_START));

		return api.deleteCompany(http, companyId).then(
			() => {
				dispatch(withType(TYPES.COMPANIES_OP_END, { invalidateId: companyId }));
				dispatch(addToast(new Toast(`Company ${companyId} was successfully deleted`)));
				history.push(routes.COMPANIES);
			},
			error => {
				dispatch(withType(TYPES.COMPANIES_OP_END, { error }));
				dispatch(addToast(Toast.warning(error.message)));
			},
		);
	};

export const resetOpError = () => {
	return withType(TYPES.COMPANIES_OP_RESET);
};

/**
 * This method will combine all ids for at least 100 ms, and then call api.lookupCompanyAccessRights.
 * All callers will get the same promise and the same combined hash.
 * This is so that we don't spam server with calls.
 *
 * Only resolve will be called. Error will be handled internally, through a toast.
 * This is so that we don't have 100 components try to handle the same error at once.
 */
export const lookupCompanyAccessRightsOrNull = (() => {
	const LOOKUP_DELAY = 100;
	let waitingLookup = null;

	return ids => (dispatch, getState, container) => {
		if (!waitingLookup) {
			waitingLookup = {
				ids: {},
				promise: null,
				resolve: null,
				timeout: null,
			};

			waitingLookup.promise = new Promise(resolve => {
				waitingLookup.resolve = resolve;
			});
		}

		clearTimeout(waitingLookup.timeout);
		waitingLookup.timeout = setTimeout(waitDone, LOOKUP_DELAY);

		ids.forEach(id => {
			waitingLookup.ids[id] = true;
		});

		return waitingLookup.promise;

		function waitDone() {
			const executingLookup = waitingLookup;
			waitingLookup = null;

			const ids = Object.keys(executingLookup.ids);

			return api.lookupCompanyAccessRights(container.http, ids).then(
				result => {
					executingLookup.resolve(result);
				},
				error => {
					executingLookup.resolve(null);
					dispatch(addToast(Toast.error(error, 'Failed to lookup access rights')));
				},
			);
		}
	};
})();
