import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import container from '@/container';
import ENV from '@/env';
import * as routes from '@/lib/routes';
import { isLoggedIn } from '@/selectors/auth';
import StretchableSpinner from '@/views/widgets/StretchableSpinner';

const PublicRoutes = lazy(() => import('@/routes/public/PublicRoutes'));
const AppRoutes = lazy(() => import('@/routes/app/AppRoutes'));
const NotFound = lazy(() => import('@/views/pages/NotFoundPage'));

export function AppRouter() {
	const isUserLoggedIn = useSelector(isLoggedIn);

	return (
		<Router history={container.history}>
			<Switch>
				{isUserLoggedIn ? (
					<Route key="App" path={routes.ROOT}>
						<Suspense fallback={<StretchableSpinner pushHeight={false} />}>
							<AppRoutes />
						</Suspense>
					</Route>
				) : (
					<Route path={routes.ROOT} title={ENV.TITLE}>
						<Suspense fallback={<StretchableSpinner pushHeight={false} />}>
							<PublicRoutes />
						</Suspense>
					</Route>
				)}
				<Route>
					<NotFound />
				</Route>
			</Switch>
		</Router>
	);
}
