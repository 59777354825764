import { Component } from 'react';
import { get, isFunction, map } from 'lodash';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';
import Select from '@/views/widgets/input/Select';
import ErrorPopover from '@/views/widgets/popovers/ErrorPopover';

import './AjaxPicker.scss';

class AjaxPicker extends Component {
	/**
	 * @type {Select.propTypes}
	 */
	static propTypes = {
		async: PropTypes.bool,
		forceSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		inline: PropTypes.bool,
		labelPath: PropTypes.string.isRequired,
		localFilter: PropTypes.func,
		lookup: PropTypes.func.isRequired,
		multi: PropTypes.bool,
		onSelect: PropTypes.func,
		optionRenderer: PropTypes.func,
		selectedId: PropTypes.any,
		size: PropTypes.string,
		valuePath: PropTypes.string.isRequired,
		valueRenderer: PropTypes.func,
	};

	static defaultProps = {
		async: true,
		multi: false,
		selectedId: [],
	};

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			filter: null,
		};
	}

	componentDidMount() {
		this._mounted = true;
		this.select.loadOptions('');
	}

	componentWillUnmount() {
		this._mounted = false;
		/**
		 * temporary hack until react select goes into stable stage
		 */
		if (this.select) {
			this.select._callback = null;
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedId && this.props.selectedId !== prevProps.selectedId && this.select) {
			// Hack around the problem where you select an option that is outside of async list (but appears when you filter),
			// nothing is shown until the picker loses focus. Revisit as select gets updated, maybe they fix this
			this.select.loadOptions('');
		}
	}

	loadOptions = filter => {
		if (!this._mounted) {
			return;
		}

		return this.props.lookup(filter, this.props.selectedId).then(
			records => {
				if (!this._mounted) {
					return;
				}
				const options = map(records, record => {
					return {
						label: get(record, this.props.labelPath),
						record,
						value: get(record, this.props.valuePath),
					};
				});

				if (this.props.forceSelection && !this.props.selectedId && options.length) {
					// Auto select one of available options if we start with null
					// If true, we pick the first option. If functions, we use it as delegate to choose the default option
					// Ideally, we would do this on the outside, but this is an escape hatch if that's too slow (used for companies ATM)
					const option = isFunction(this.props.forceSelection)
						? this.props.forceSelection(options)
						: options[0];

					this.onSelect(option);
				}

				this.setState({ filter });

				return { options };
			},
			error => {
				if (this._mounted) {
					this.setState({ error });
				}
			},
		);
	};

	filterOption = (option, filter) => {
		if (!this.props.localFilter) {
			// If nothing is specified, we rely solely on ajax backend filtering
			return true;
		}

		return this.props.localFilter(option, filter);
	};

	onOpen = () => {
		// Autoload doesn't work properly, so we do it manually.
		// Warning: This is marked as private API. If something breaks after npm update, look here
		if (this.select) {
			this.select.loadOptions('');
		}
	};

	onSelect = option => {
		const { multi, onSelect } = this.props;
		if (onSelect) {
			if (multi) {
				const options = [];
				Object.keys(option).map(o => (option[o] ? options.push(option[o].value) : null));
				return this.props.onSelect(options);
			}
			this.props.onSelect(option ? option.value : null);
		}
	};

	render() {
		let {
			async,
			className,
			forceSelection,
			inline,
			multi,
			onSelect,
			optionRenderer,
			selectedId,
			selectedIds,
			...props
		} = this.props;

		className = classes('sv-AjaxPicker', className, inline && 'sv-AjaxPicker-inline');

		let optionRendererWithFilter = null;
		if (optionRenderer) {
			optionRendererWithFilter = option => {
				return optionRenderer(option, this.state.filter, this.state.error);
			};
		}

		return (
			<ErrorPopover
				className={className}
				error={this.state.error}
				placement="top"
				title="Loading error"
			>
				<Select
					{...props}
					ref={select => (this.select = select)}
					async={async}
					autoload={false}
					cache={false}
					filterOption={this.filterOption}
					loadOptions={this.loadOptions}
					multi={multi}
					optionRenderer={optionRendererWithFilter}
					value={selectedId}
					valueRenderer={this.props.valueRenderer}
					onChange={this.onSelect}
					onOpen={this.onOpen}
				/>
			</ErrorPopover>
		);
	}
}

export default AjaxPicker;
