import * as api from '@/lib/api';
import { withType } from '@/lib/util';
import { ConnectedModal } from '@/types/connected_modal';
import {
	Criteria,
	getSelectedIds,
	LIBRARY_NAMESPACE,
	LIBRARY_VIEW_SORT_TYPES_FLIPPED,
	LIBRARY_VIEW_TYPES_FLIPPED,
} from '@/types/library';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast, addToastError, refresh } from './application';
import { updateJobProgress } from './background_jobs';
import { addConnectedModal } from './connected_modal';
import { deleteSites, moveSites } from './sites';

function getStartPayload(fromPage) {
	return {
		invalidateAll: !fromPage,
		paginating: !!fromPage,
	};
}

export const loadFolder =
	(criteria, fromPage = {}, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		if (typeof criteria !== 'object') {
			criteria = new Criteria({ folder_id: criteria });
		}
		if (typeof params !== 'object') {
			params = { namespace: LIBRARY_NAMESPACE.view };
		}

		if (criteria.folder_id === 'orphans') {
			return null;
		}
		if (!(criteria instanceof Criteria)) {
			criteria = new Criteria(criteria);
		}
		let folderId =
			typeof criteria.folder_id !== 'number'
				? Number.parseInt(criteria.folder_id, 10)
				: criteria.folder_id;

		if (!Number.isInteger(folderId)) {
			folderId = null;
		}

		const namespace = params.namespace || LIBRARY_NAMESPACE.view;

		!params.silent &&
			dispatch(withType(TYPES.FOLDERS_LOAD_START, { ...getStartPayload(fromPage), namespace }));

		return api.getFolderData(container.http, criteria, fromPage).then(
			queryResult => {
				const { breadcrumbs, next_page, ...rest } = queryResult;
				const result = params.silent ? rest : queryResult;
				dispatch(loadFolderSuccess({ ...result, namespace }, folderId));
				return dispatch(withType(TYPES.FOLDERS_LOAD_END, { namespace }));
			},
			error => {
				dispatch(withType(TYPES.FOLDERS_LOAD_END, { error, namespace }));
			},
		);
	};

export const createOrUpdateFolder = (folderId, payload) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.FOLDERS_OP_START));

	const opError = error => {
		if (error.error_name === 'DuplicateRecordError') {
			dispatch(addToastError(`A folder with the same name already exists!`));
		} else {
			dispatch(withType(TYPES.FOLDERS_OP_END, { error }));
		}
	};

	const folders = getState().folders.data;
	const fwd = (folders && folders[folderId]) || { folder: {} };

	const promise = folderId
		? api.editFolder(container.http, folderId, payload)
		: api.createFolder(container.http, payload.parent_id, payload);

	return promise.then(crudResult => {
		const { created, record } = crudResult;
		const resolvedFolderId = record && record.id;
		if (!resolvedFolderId) {
			return dispatch(
				withType(TYPES.FOLDERS_OP_END, {
					error: new Error(`Operation has failed on the server`),
				}),
			);
		}

		const opCompleted = () => {
			dispatch(
				updateFolders({
					data: [{ ...fwd, ...crudResult, folder: { ...fwd.folder, ...record } }],
				}),
			);

			dispatch(withType(TYPES.FOLDERS_OP_END));
			dispatch(addToast(new Toast(`Folder successfully ${created ? 'created' : 'updated'}!`)));
			return crudResult;
		};

		return opCompleted();
	}, opError);
};
export const updateFolder = createOrUpdateFolder; // alias

const loadFolderSuccess = (queryResult, invalidateId = null) => {
	const { next_page, ...rest } = queryResult;
	return updateFolders({
		...rest,
		invalidateId,
		nextPage: next_page,
	});
};

const updateFolders = action => withType(TYPES.FOLDERS_GOT_DATA, action);

export const deleteFolder = (folderId, name) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.FOLDERS_OP_START));

	const finalizeDelete = () => {
		return api.deleteFolder(container.http, folderId).then(
			() => {
				dispatch(refresh());
				dispatch(withType(TYPES.FOLDERS_OP_END, { invalidateId: folderId }));
			},
			error => {
				dispatch(refresh());
				dispatch(withType(TYPES.FOLDERS_OP_END, { error }));
			},
		);
	};

	const modalProps = {
		content: `Are you sure you want to delete ${name || 'this folder'}?`,
		onSubmit: finalizeDelete,
	};

	const modal = ConnectedModal.delete_site(modalProps);
	dispatch(addConnectedModal(modal));
};

export const clearFolderContent = namespace => dispatch =>
	dispatch(withType(TYPES.CLEAR_FOLDER_CONTENT, { namespace }));

export const toggleLibraryView = (view, namespace) => (dispatch, getState) => {
	const state = getState();
	const libraryView = view || LIBRARY_VIEW_TYPES_FLIPPED[state.folders.libraryView];
	const action = { key: 'libraryView', libraryView, namespace, value: libraryView };
	dispatch(withType(TYPES.LOCAL_STORAGE_SET, action));
	return dispatch(withType(TYPES.TOGGLE_LIBRARY_VIEW, action));
};

export const toggleDisabledContent = (sort, namespace) => (dispatch, getState) => {
	const state = getState();
	const hideDisabledContent =
		sort || LIBRARY_VIEW_SORT_TYPES_FLIPPED[state.folders.hideDisabledContent];
	const action = {
		hideDisabledContent,
		key: 'hideDisabledContent',
		namespace,
		value: hideDisabledContent,
	};
	dispatch(withType(TYPES.LOCAL_STORAGE_SET, action));
	return dispatch(withType(TYPES.TOGGLE_LIBRARY_SORT_VIEW, action));
};

const itemsActionCreator =
	(apiAction, successMessage) =>
	(folderId, payload, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		dispatch(withType(TYPES.FOLDERS_OP_START));
		const { namespace } = params;
		const opError = error => {
			dispatch(withType(TYPES.FOLDERS_OP_END, { error, namespace }));
		};

		return apiAction(container.http, folderId, payload).then(res => {
			if (res.error) {
				return dispatch(
					withType(TYPES.FOLDERS_OP_END, {
						error: new Error(`Operation has failed on the server`),
						namespace,
					}),
				);
			}

			const opCompleted = () => {
				if (successMessage) {
					dispatch(addToast(new Toast(successMessage)));
				}
				dispatch(withType(TYPES.FOLDERS_OP_END, { namespace }));
				dispatch(updateJobProgress(res));
			};

			return opCompleted();
		}, opError);
	};

export const cloneItemsAction = itemsActionCreator(api.cloneItems, 'Items scheduled for cloning.');
export const moveFolders = itemsActionCreator(api.moveFolders, 'Folders successfully moved.');
export const deleteFolders = itemsActionCreator(api.deleteFolders, 'Folders successfully deleted.');
export const downloadItemsAction = itemsActionCreator(api.downloadItems, 'Preparing for download.');

export const moveItems =
	(items, toFolderId = null, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		const siteIds = getSelectedIds('s', items);
		const folderIds = getSelectedIds('f', items);
		const doMoveItems = folderId => {
			return Promise.all(
				[
					siteIds.length && dispatch(moveSites(folderId, folderId, { ids: siteIds }, params)),
					folderIds.length && dispatch(moveFolders(folderId, { ids: folderIds }, params)),
				].filter(Boolean),
			).then(res => {
				return dispatch(loadFolder(folderId, false, params)).then(() => {
					return res;
				});
			});
		};

		if (toFolderId) {
			return doMoveItems(toFolderId);
		}

		return new Promise((resolve, reject) => {
			const modalProps = {
				...params,
				onClose: () => resolve(false),
				onSubmit: folderId => doMoveItems(folderId).then(resolve).catch(reject),
			};

			const modal = ConnectedModal.move_site(modalProps);
			dispatch(addConnectedModal(modal));
		});
	};

export const cloneItems =
	(items, toFolderId = null, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		const siteIds = getSelectedIds('s', items);
		const folderIds = getSelectedIds('f', items);
		const payload = {
			folders: folderIds,
			sites: siteIds,
		};

		const doCloneItems = folderId => {
			return dispatch(cloneItemsAction(folderId, payload, params));
		};

		if (toFolderId) {
			return doCloneItems(toFolderId);
		}

		return new Promise((resolve, reject) => {
			const modalProps = {
				...params,
				onClose: () => resolve(false),
				onSubmit: folderId => doCloneItems(folderId).then(resolve).catch(reject),
			};

			const modal = ConnectedModal.clone(modalProps);
			dispatch(addConnectedModal(modal));
		});
	};

export const deleteItems =
	(items, folderId, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		const siteIds = getSelectedIds('s', items);
		const folderIds = getSelectedIds('f', items);

		return new Promise((resolve, reject) => {
			const modalProps = {
				// content: `<p>Are you sure you want to delete <b>${items.length} selected items?</b></p>`,
				content: `Are you sure you want to delete ${items.length} selected items?`,
				onClose: () => resolve(false),
				onSubmit: () => {
					return Promise.all(
						[
							siteIds.length && dispatch(deleteSites({ ids: siteIds }, params)),
							folderIds.length && dispatch(deleteFolders(folderId, { ids: folderIds }, params)),
						].filter(Boolean),
					)
						.then(resolve)
						.catch(reject);
				},
			};

			const modal = ConnectedModal.delete_site(modalProps);
			dispatch(addConnectedModal(modal));
		});
	};

export const downloadItems =
	(items, folderId, params = { namespace: LIBRARY_NAMESPACE.view }) =>
	(dispatch, getState, container) => {
		const siteIds = getSelectedIds('s', items);
		const folderIds = getSelectedIds('f', items);
		const payload = {
			folders: folderIds,
			sites: siteIds,
		};
		return dispatch(downloadItemsAction(folderId, payload, params));
	};
