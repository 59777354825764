import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiMediaViewerLightbox } from '@spinview/shared-components';
import classNames from 'classnames';
import { omit } from 'lodash';

import { makeSiteUrlOrNull } from '@/lib/asset_router';
import { preventDefault, stopPropagation, withStopPropagation } from '@/lib/util';
import { orphansFolder } from '@/selectors/folders';
import { TABLE_COLUMN_SIZING, TableColumn } from '@/types/data';
import FA from '@/types/font_awesome';
import {
	LIBRARY_COLUMN_HEADER,
	LIBRARY_CONTEXT_TYPE,
	LIBRARY_LIST_VISUALS,
	LIBRARY_TYPE,
} from '@/types/library';
import { FOLDER_LABELS, LIBRARY_FOLDER_ICONS } from '@/types/library_folders';
import { SITE_LABELS } from '@/types/sites';
import CompanyLink from '@/views/companies/widgets/CompanyLink';
import { useSitePreview } from '@/views/hooks/useSitePreview';
import SiteIcon from '@/views/sites/widgets/SiteIcon';
import Image from '@/views/widgets/images/Image';
import TriggeredPopover from '@/views/widgets/popovers/TriggeredPopover';
import Highlighted from '@/views/widgets/presentation/Highlighted';
import TextSpan from '@/views/widgets/presentation/TextSpan';
import { SmallStretchableSpinner } from '@/views/widgets/StretchableSpinner';
import TableDataBody from '@/views/widgets/tables/TableDataBody';
import TableDataHead, { TableDataHeadColumn } from '@/views/widgets/tables/TableDataHead';
import TablePagination from '@/views/widgets/tables/TablePagination';
import TableStylized from '@/views/widgets/tables/TableStylized';

import LibraryListViewRow from './LibraryListViewRow';

import './LibraryListView.scss';

const { thumbnail_height, thumbnail_width } = LIBRARY_LIST_VISUALS;
const TYPE_CLASS = {
	[LIBRARY_TYPE.FOLDER]: 'sv-Folder',
	[LIBRARY_TYPE.FILE]: 'sv-File',
};

export const LIBRARY_HEADERS = {
	[LIBRARY_COLUMN_HEADER.name]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.name,
		sort_path: 'name',
		title: 'Name',
	}),
	[LIBRARY_COLUMN_HEADER.url]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.url,
		sort_path: 'site.url_path',
		title: 'Url',
	}),
	[LIBRARY_COLUMN_HEADER.asset]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.asset,
		sort_path: 'asset',
		title: 'Asset',
	}),
	[LIBRARY_COLUMN_HEADER.owner]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.owner,
		sort_path: 'site.owner_id',
		title: 'Owner',
	}),
	[LIBRARY_COLUMN_HEADER.type]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.type,
		sort_path: 'type',
		title: 'Type',
	}),
	[LIBRARY_COLUMN_HEADER.modified]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.modified,
		sort_path: 'updated_at',
		title: 'Modified',
	}),
	[LIBRARY_COLUMN_HEADER.fileSize]: new TableColumn({
		name: LIBRARY_COLUMN_HEADER.fileSize,
		sort_path: 'site.size',
		title: 'Size',
	}),
};

const HEADER = Object.values(LIBRARY_HEADERS);
const HEADER_WO = Object.values(omit(LIBRARY_HEADERS, 'owner'));
const getHeaders = ({
	allowSeeOwnership,
	columns,
	disabledColumns,
	displayCheckbox,
	itemsLen,
	onSelectAllToggle,
	onSelection,
	selectedItems,
}) => {
	let cols = allowSeeOwnership ? HEADER : HEADER_WO;
	if (columns) {
		cols = columns;
	}

	if (displayCheckbox && itemsLen && onSelection) {
		const selectedLen = selectedItems && selectedItems.length;
		const allSelected = itemsLen === selectedLen;
		const SEL_COL = (
			<TableDataHeadColumn
				name={LIBRARY_COLUMN_HEADER.select}
				sizing={TABLE_COLUMN_SIZING.shrink}
				onClick={e => onSelectAllToggle(e, !allSelected)}
			>
				<FontAwesomeIcon icon={allSelected ? FA.check_square : FA.square_empty} />
			</TableDataHeadColumn>
		);
		cols = [SEL_COL, ...cols];
	}

	if (!disabledColumns) {
		return cols;
	}
	return cols.filter(col => !disabledColumns.includes(col.name));
};

const CLASS = 'sv-LibraryListView';
const COL_CLASS = classNames(`${CLASS}-row-data`, 'single-line');

function LibraryListView(props) {
	const handleOpenContext = (e, type, data) => {
		e.preventDefault();
		const { clientX, clientY } = e;
		const contextType =
			LIBRARY_TYPE.FOLDER === type ? LIBRARY_CONTEXT_TYPE.FOLDER : LIBRARY_CONTEXT_TYPE.FILE;
		const openContext =
			contextType === LIBRARY_CONTEXT_TYPE.FOLDER ? handleOpenFolderContext : handleOpenFileContext;

		openContext(contextType, { xPos: clientX, yPos: clientY }, data);
	};

	const handleOpenFolderContext = (type, position, folder) => {
		const { onContextMenu, pathPrefix } = props;
		const folderPath = `${pathPrefix}/${folder.id}`;
		onContextMenu &&
			onContextMenu(type, { folderId: folder.parent_id, folderPath, item: folder, position });
	};

	const handleOpenFileContext = (type, position, file) => {
		const { onContextMenu } = props;
		onContextMenu && onContextMenu(type, { folderId: file.folder_id, item: file, position });
	};

	const getIcon = (type, item) => {
		const image = item ? item.image : null;
		const siteType = item.site && (item.site.media_format || item.site.type);
		const folderTypeIcon = LIBRARY_FOLDER_ICONS[item.folder?.type];
		const fallback =
			type === LIBRARY_TYPE.FOLDER ? (
				<div className={`${CLASS}-icon-wrapper`}>
					<FontAwesomeIcon className={`${CLASS}-icon`} icon={FA.folder} />
					{folderTypeIcon && (
						<div className={`${CLASS}-subicon`}>
							<FontAwesomeIcon className={`${CLASS}-icon`} icon={FA[folderTypeIcon]} />
						</div>
					)}
				</div>
			) : (
				<SiteIcon alt siteType={siteType} />
			);

		return (
			<Image
				fallback={fallback}
				fontSize={30}
				height={thumbnail_height}
				image={image}
				width={thumbnail_width}
			/>
		);
	};

	const loadMore = nextPage => {
		const { foldersNextPage, loadMore, sitesNextPage } = props;
		nextPage = nextPage || foldersNextPage || sitesNextPage;
		if (!nextPage) {
			return;
		}
		loadMore(nextPage);
	};

	const highlight = content => {
		const { criteria } = props;
		const filter = criteria && criteria.filter;
		return <Highlighted match={filter}>{content}</Highlighted>;
	};

	const getTypeLabel = type => {
		if (FOLDER_LABELS[type]) {
			return FOLDER_LABELS[type];
		}

		return SITE_LABELS[type || props.siteType];
	};

	const renderUrlPathCell = (urlPath, preferredUrlSchema, disabled) => {
		const siteTypeLabel = (getTypeLabel() || 'content').toLowerCase();

		if (!urlPath) {
			return (
				<td className={COL_CLASS}>
					<TriggeredPopover
						content={`Your ${siteTypeLabel} hasn't been published yet`}
						title="Not published"
					>
						<TextSpan muted>None</TextSpan>
					</TriggeredPopover>
				</td>
			);
		}

		const url = makeSiteUrlOrNull(urlPath, preferredUrlSchema);
		return (
			<td className={COL_CLASS} onClick={withStopPropagation()}>
				{!disabled ? (
					<TriggeredPopover
						content={`Open your ${siteTypeLabel} in a new browser window`}
						title={`View ${siteTypeLabel}`}
					>
						<a href={url} rel="noopener noreferrer" target="_blank" title={urlPath}>
							{highlight(urlPath)}
						</a>
					</TriggeredPopover>
				) : (
					highlight(urlPath)
				)}
			</td>
		);
	};

	const renderOwnerCell = owner => {
		if (!props.allowSeeOwnership) {
			return null;
		}
		const { criteria } = props;
		const filter = criteria ? criteria.filter : null;
		return (
			<td className="single-line">
				<CompanyLink company={owner} highlight={filter} />
			</td>
		);
	};

	const handleClick = (e, type, item) => {
		stopPropagation(e);
		const { createFileHref, createFolderHref, history, onFileClick, onFolderClick, selecting } =
			props;
		if (selecting && handleSelection(e, type, item) !== false) {
			return;
		}
		const handler = type === LIBRARY_TYPE.FOLDER ? onFolderClick : onFileClick;
		handler && handler(item, e);
		if (e.defaultPrevented) {
			return;
		}

		const url = type === LIBRARY_TYPE.FOLDER ? createFolderHref(item) : createFileHref(item);
		if (!url) {
			return;
		}
		preventDefault(e);
		history.push({ pathname: url });
	};

	const handleDoubleClick = (e, type, item) => {
		stopPropagation(e);
		const { createFileHref, createFolderHref, history, onFileDoubleClick, onFolderDoubleClick } =
			props;
		const handler = type === LIBRARY_TYPE.FOLDER ? onFolderDoubleClick : onFileDoubleClick;
		handler && handler(item, e);
		if (e.defaultPrevented) {
			return;
		}

		const url = type === LIBRARY_TYPE.FOLDER ? createFolderHref(item) : createFileHref(item);
		if (!url) {
			return;
		}
		preventDefault(e);
		history.push({ pathname: url });
	};

	const handleSelection = (e, type, item) => {
		const { onSelection, selectable } = props;
		if ((selectable !== true && selectable !== type) || !onSelection) {
			return false;
		}

		return onSelection(item, e);
	};

	const renderRow =
		(itemType, { columns, itemsLen }) =>
		// eslint-disable-next-line react/display-name, react/function-component-definition
		(item, index) => {
			if (!itemType) {
				return null;
			}

			const owner = item.owner || item.company;
			let {
				createFileHref,
				createFolderHref,
				itemClassName,
				listViewActions,
				onSelection,
				renderItem,
				selectable,
				selectedItems,
				selecting,
			} = props;
			const { brochure, disabled, folder, key, site } = item;
			const isSelected = selectedItems && selectedItems.includes(key);
			const record = itemType === LIBRARY_TYPE.FOLDER ? folder : { ...site, brochure };
			selectable = selectable === true || selectable === itemType;
			if (typeof renderItem === 'function') {
				return renderItem({ ...item, isSelected, itemType });
			}

			const icon = getIcon(itemType, item);

			const createHref = itemType === LIBRARY_TYPE.FOLDER ? createFolderHref : createFileHref;

			const disabledClass = classNames(`${CLASS}-row-disabled`, `${itemClassName}-disabled`);
			const typeClass = TYPE_CLASS[itemType];
			let className = classNames(
				`${CLASS}-row`,
				selectable ? `${CLASS}-row-selectable` : null,
				itemClassName,
				typeClass,
				disabled && disabledClass,
			);

			if (itemType === LIBRARY_TYPE.FOLDER && record.type) {
				className = classNames(className, [typeClass, record.type.replace(/_/g, '-')].join('-'));
			}

			const clickHandler = disabled ? undefined : handleClick;
			const doubleClickHandler = disabled ? undefined : handleDoubleClick;
			const columnNames = columns.map(col => col.name || (col.props && col.props.name));
			return (
				<LibraryListViewRow
					key={key}
					className={className}
					columnNames={columnNames}
					createHref={createHref}
					getTypeLabel={getTypeLabel}
					icon={icon}
					isFilePreviewable={isFilePreviewable}
					isSelected={isSelected}
					item={item}
					listViewActions={listViewActions}
					owner={owner}
					record={record}
					renderHighlight={highlight}
					renderOwnerCell={renderOwnerCell}
					renderUrlPathCell={renderUrlPathCell}
					selectable={selectable}
					selecting={selecting && !!itemsLen}
					type={itemType}
					updatePreviewItem={updatePreviewItem}
					onClick={clickHandler}
					onContextMenu={e => handleOpenContext(e, itemType, record)}
					onDoubleClick={doubleClickHandler}
					onSelection={onSelection && handleSelection}
				/>
			);
		};

	const {
		criteria,
		disableFiles,
		disableFolders,
		files,
		folders,
		foldersNextPage,
		onCriteriaChanged,
		selectable,
		showOrphans,
		sitesNextPage,
	} = props;

	const {
		initialOpenedPreviewIndex,
		isFilePreviewable,
		previewLightboxItems,
		selectedPreviewItem,
		updatePreviewItem,
	} = useSitePreview(files);

	const allFoldersLength = folders ? folders.length : 0;
	const foldersLength = folders ? folders.filter(fwd => !fwd.isCompany).length : 0;
	const filesLength = files ? files.length : 0;
	const lengths = {
		[LIBRARY_TYPE.FOLDER]: foldersLength,
		[LIBRARY_TYPE.FILE]: filesLength,
	};

	const itemsLen = selectable === true ? foldersLength + filesLength : lengths[selectable];

	const columns = getHeaders({ ...props, itemsLen });

	return (
		<>
			{previewLightboxItems?.length > 0 && selectedPreviewItem && (
				<MultiMediaViewerLightbox
					isOpen
					initialOpenedIndex={initialOpenedPreviewIndex}
					items={previewLightboxItems}
					viewerComponentProps={{
						LoadingComponent: SmallStretchableSpinner,
					}}
					onClose={() => updatePreviewItem(null)}
				/>
			)}
			<TableStylized bordered={false} className={CLASS}>
				<TableDataHead
					caretPosition={TableDataHead.CARET_POSITIONS.left}
					columns={columns}
					criteria={criteria}
					onCriteriaChanged={onCriteriaChanged}
				/>
				{!disableFolders && showOrphans ? (
					<TableDataBody
						data={[orphansFolder]}
						renderRow={renderRow(LIBRARY_TYPE.FOLDER, { columns, itemsLen })}
					/>
				) : null}
				{allFoldersLength && !disableFolders ? (
					<TableDataBody
						data={folders}
						renderRow={renderRow(LIBRARY_TYPE.FOLDER, { columns, itemsLen })}
					/>
				) : null}
				{!disableFolders && foldersNextPage ? (
					<TableDataBody data={[]}>
						<TablePagination nextPage={foldersNextPage} onLoadMore={loadMore} />
					</TableDataBody>
				) : null}
				{filesLength && !disableFiles ? (
					<TableDataBody
						data={files}
						renderRow={renderRow(LIBRARY_TYPE.FILE, { columns, itemsLen })}
					/>
				) : null}
				{!disableFiles && sitesNextPage ? (
					<TableDataBody data={[]}>
						<TablePagination nextPage={sitesNextPage} onLoadMore={loadMore} />
					</TableDataBody>
				) : null}
			</TableStylized>
		</>
	);
}

export default withRouter(LibraryListView);
