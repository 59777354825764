import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	billboards: {
		data: null,
		loadError: null,
		op: null,
		opError: null,
	},
};

const updateBillboards = (state, ob) => {
	return { ...state, billboards: { ...state.billboards, ...ob } };
};

// eslint-disable-next-line
const withInvalidation = (nextState, action) => {
	if (action.invalidateId && nextState.billboards && nextState.billboards.data) {
		delete nextState.billboards.data[action.invalidateId];
	}
	return nextState;
};

const helper = new ReduceHelper('billboards', 'billboard.id');

const gotData = (state, action) => {
	return helper.gotData(state, action);
};

const loadStart = (state, action) => {
	const nextState = updateBillboards(state, {
		loadError: null,
	});

	if (action.invalidateAll) {
		nextState.billboards.data = null;
	}

	return withInvalidation(nextState, action);
};

const loadEnd = (state, action) => {
	return updateBillboards(state, {
		loadError: action.error || null,
	});
};

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const opEnd = (state, action) => {
	return helper.withInvalidation(helper.opEnd(state, action), action);
};

const opReset = state => {
	return updateBillboards(state, {
		opError: null,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.TOUR_BILLBOARDS_GOT_DATA]: gotData,
	[TYPES.TOUR_BILLBOARDS_LOAD_START]: loadStart,
	[TYPES.BILLBOARDS_LOAD_START]: loadStart,
	[TYPES.BILLBOARDS_GOT_DATA]: gotData,
	[TYPES.TOUR_BILLBOARDS_LOAD_END]: loadEnd,
	[TYPES.BILLBOARDS_LOAD_END]: loadEnd,
	[TYPES.TOUR_BILLBOARD_OP_START]: opStart,
	[TYPES.BILLBOARDS_OP_START]: opStart,
	[TYPES.TOUR_BILLBOARD_OP_END]: opEnd,
	[TYPES.BILLBOARDS_OP_END]: opEnd,
	[TYPES.TOUR_BILLBOARD_OP_RESET]: opReset,
	[TYPES.BILLBOARDS_OP_RESET]: opReset,
	[TYPES.OP_RESET_ALL]: opReset,
};
