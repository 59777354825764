import { Component } from 'react';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

import { takeFolderId } from '@/selectors/folders';
import FA from '@/types/font_awesome';
import { DEFAULT_CRITERIA, LIBRARY_COLUMN_HEADER, LIBRARY_NAMESPACE } from '@/types/library';
import LibraryView from '@/views/library/LibraryView';
import IconButton from '@/views/widgets/IconButton';

import './SaveToModal.scss';

const CLASS = 'sv-SaveToModal';

const DISABLED_COLUMNS = [
	LIBRARY_COLUMN_HEADER.url,
	LIBRARY_COLUMN_HEADER.owner,
	LIBRARY_COLUMN_HEADER.type,
	LIBRARY_COLUMN_HEADER.modified,
	LIBRARY_COLUMN_HEADER.fileSize,
];
class SaveToModal extends Component {
	static defaultProps = {
		folderId: undefined,
		submitLabel: 'Save',
		title: 'Choose Location',
	};

	constructor(props) {
		super(props);

		this.state = {
			criteria: DEFAULT_CRITERIA.clone(),
			folderId: props.folderId,
		};
	}

	componentDidMount() {
		const { criteria, folderId = null } = this.props;
		this.handleCriteriaChanged(criteria);
		this.setState({ folderId });
	}

	handleFolderClick = (fwd, e) => {
		e.preventDefault();
		this.setState({ folderId: fwd.id || fwd.folder.id });
	};

	handleCriteriaChanged = newCriteria => {
		const { criteria } = this.state;
		if (!newCriteria || criteria.equals(newCriteria)) {
			return null;
		}
		this.setState({ criteria: newCriteria });
	};

	handleSubmit = () => {
		const { folderId } = this.state;
		const { onSubmit } = this.props;

		onSubmit(folderId);
	};

	renderModalHeader = () => {
		const { onClose, title } = this.props;
		if (!title) {
			return null;
		}

		return (
			<Modal.Header className={`${CLASS}-header`}>
				<div className={`${CLASS}-header-title`}>{title}</div>

				<div className={`${CLASS}-header-close`}>
					<IconButton icon={FA.close} onClick={onClose} />
				</div>
			</Modal.Header>
		);
	};

	renderLibraryView() {
		const { criteria, folderId } = this.state;

		return (
			<LibraryView
				disableFiles
				disableFilter
				displayNewFolder
				createFileHref={null}
				createFolderHref={null}
				disableActions={!folderId}
				disabledColumns={DISABLED_COLUMNS}
				displaySort={false}
				displayUpload={false}
				folderId={folderId}
				foldersCriteria={criteria}
				namespace={LIBRARY_NAMESPACE.modal}
				selectable={false}
				onContextMenu={null}
				onCriteriaChanged={this.handleCriteriaChanged}
				onFolderClick={this.handleFolderClick}
			/>
		);
	}

	renderModalBody = () => {
		return <Modal.Body className={`${CLASS}-body`}>{this.renderLibraryView()}</Modal.Body>;
	};

	renderModalFooter = () => {
		const { onClose, submitLabel } = this.props;

		const { folderId } = this.state;
		const disabled = !folderId;

		return (
			<Modal.Footer className={`${CLASS}-buttonWrapper`}>
				<IconButton secondary onClick={onClose}>
					Cancel
				</IconButton>
				<IconButton disabled={disabled} onClick={this.handleSubmit}>
					{submitLabel}
				</IconButton>
			</Modal.Footer>
		);
	};

	render() {
		return (
			<div className={`${CLASS}`}>
				{this.renderModalHeader()}
				{this.renderModalBody()}
				{this.renderModalFooter()}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		folderId: takeFolderId(state, props),
	};
};

export default connect(mapStateToProps, null)(SaveToModal);
