import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { lookupCompanies } from '@/actions/companies';
import ENV from '@/env';
import { classes } from '@/lib/util';
import AjaxPicker from '@/views/widgets/ajax/AjaxPicker';
import Image from '@/views/widgets/images/Image';
import Highlighted from '@/views/widgets/presentation/Highlighted';

import './CompanyPicker.scss';

class CompanyPicker extends Component {
	static propTypes = {
		clearable: PropTypes.bool,
		companyId: PropTypes.any,
		disabled: PropTypes.bool,
		forceSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		inline: PropTypes.bool,
		limitToCurrentCompanyWithDescendants: PropTypes.number,
		limitToParentId: PropTypes.number,
		localFilter: PropTypes.func,
		lookupCompanies: PropTypes.func,
		multi: PropTypes.bool,
		onSelect: PropTypes.func,
		placeholder: PropTypes.string,
		size: PropTypes.string,
	};

	static defaultProps = {
		companyId: null,
	};

	renderOption = (option, filter) => {
		if (!option || !option.value) {
			return null;
		}

		const { company, logo } = option.record;

		let labelClass = 'title';
		if (!company.reference) {
			labelClass += ' no-reference';
		}
		return (
			<div className="sv-CompanyPicker-item">
				<Image
					height={ENV.visuals.company_tiny_height}
					image={logo}
					width={ENV.visuals.company_tiny_width}
				/>
				<div className={labelClass}>
					<Highlighted match={filter}>{option.label}</Highlighted>
				</div>
				<div className="sv-CompanyPicker-item-reference">
					<Highlighted match={filter}>{company.reference}</Highlighted>
				</div>
			</div>
		);
	};

	renderValue = option => {
		if (!option) {
			// Nothing is selected, placeholder will be shown instead
			return null;
		}
		const { company, logo } = option.record || option;

		const reference = company.reference && (
			<span className="sv-CompanyPicker-value-reference">({company.reference})</span>
		);

		return (
			<div className="sv-CompanyPicker-value">
				<Image
					height={ENV.visuals.company_inline_height}
					image={logo}
					width={ENV.visuals.company_inline_width}
				/>
				<span>{option.label}</span>
				{reference}
			</div>
		);
	};

	lookupCompanies = (filter, ensureId) => {
		const { multi } = this.props;
		if (multi && ensureId) {
			ensureId = ensureId[ensureId.length - 1];
		}
		return this.props.lookupCompanies(
			this.props.limitToParentId,
			filter,
			ensureId,
			this.props.limitToCurrentCompanyWithDescendants,
		);
	};

	onSelect = option => {
		this.props.onSelect(option ? option.value : null);
	};

	render() {
		let { async, className, clearable, companyId, lookupCompanies, multi, ...props } = this.props;

		className = classes('sv-CompanyPicker', className);

		return (
			<AjaxPicker
				async={async}
				className={className}
				clearable={clearable}
				labelPath="company.name"
				lookup={this.lookupCompanies}
				multi={multi}
				optionRenderer={this.renderOption}
				selectedId={companyId}
				valuePath="company.id"
				valueRenderer={this.renderValue}
				onChange={this.onSelect}
				{...props}
			/>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	lookupCompanies: (parentId, filter, ensureId, descendantsOf) => {
		return dispatch(
			lookupCompanies(parentId, filter, ensureId, undefined, undefined, descendantsOf),
		);
	},
});

export default connect(null, mapDispatchToProps)(CompanyPicker);
