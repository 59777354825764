import { Component } from 'react';
import PropTypes from 'prop-types';

import ImageGrid from './ImageGrid';
import ImageUploader from './ImageUploader';

import './ImageUploaderBox.scss';

class ImageUploaderBox extends Component {
	static propTypes = {
		...ImageUploader.WrappedComponent.propTypes,
		acceptedFileTypes: PropTypes.array,
		centerImage: PropTypes.bool,
		className: PropTypes.string,
		disableClick: PropTypes.bool,
		emptyRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
		hint: PropTypes.string,
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		imageHeight: PropTypes.number,
		imageWidth: PropTypes.number,
		isPreview: PropTypes.bool,
		onFiles: PropTypes.func,
		onImageChanged: PropTypes.func,
		overlayIcon: PropTypes.string,
		uploadLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	};

	static defaultProps = {
		emptyRenderer: null,
		hint: 'Drag your pic here!',
		imageHeight: 100,
		imageWidth: 100,
		isPreview: false,
		uploadLabel: 'Upload',
	};

	onImageRemoved = () => {
		this.props.onImageChanged && this.props.onImageChanged(null);
	};

	onImageUploaded = image => {
		this.props.onImageChanged && this.props.onImageChanged(image);
	};

	renderEmptyOverlay = () => {
		const { emptyRenderer, image } = this.props;
		const shouldRender = !image;

		if (!shouldRender && !emptyRenderer) {
			return null;
		}

		if (typeof emptyRenderer === 'function') {
			return emptyRenderer();
		}

		return emptyRenderer;
	};

	render() {
		const images = this.props.image ? [this.props.image] : [];
		const {
			acceptedFileTypes,
			centerImage,
			className,
			disableClick,
			displayHint,
			hint,
			imageHeight,
			imageWidth,
			isPreview,
			onFiles,
			overlayIcon,
			uploadLabel,
			...rest
		} = this.props;

		return (
			<div className={`sv-ImageUploaderBox ${className}`}>
				<ImageUploader
					acceptedFileTypes={acceptedFileTypes}
					disableClick={disableClick}
					displayHint={displayHint}
					hint={hint}
					multiple={false}
					overlayIcon={overlayIcon}
					uploadLabel={uploadLabel}
					onFiles={onFiles}
					onUploaded={this.onImageUploaded}
					{...rest}
				>
					{this.renderEmptyOverlay()}
					<ImageGrid
						centerImage={centerImage}
						imageHeight={imageHeight}
						imageWidth={imageWidth}
						images={images}
						isPreview={isPreview}
						padding={0}
						onRemove={this.onImageRemoved}
					/>
				</ImageUploader>
			</div>
		);
	}
}

export default ImageUploaderBox;
