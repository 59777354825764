import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import filesize from 'file-size';

import { getFilesSelector } from '@/selectors/sub_tours';
import FA from '@/types/font_awesome';
import { LIBRARY_COLUMN_HEADER } from '@/types/library';
import IconButton from '@/views/widgets/IconButton';

const headers = [
	{ key: LIBRARY_COLUMN_HEADER.name, label: LIBRARY_COLUMN_HEADER.name },
	{ key: LIBRARY_COLUMN_HEADER.url, label: LIBRARY_COLUMN_HEADER.url },
	{ key: LIBRARY_COLUMN_HEADER.owner, label: LIBRARY_COLUMN_HEADER.owner },
	{ key: LIBRARY_COLUMN_HEADER.type, label: LIBRARY_COLUMN_HEADER.type },
	{ key: LIBRARY_COLUMN_HEADER.modified, label: LIBRARY_COLUMN_HEADER.modified },
	{ key: LIBRARY_COLUMN_HEADER.fileSize, label: 'size' },
];

function LibraryCsvDownload({ files }) {
	if (!files || !files.length) {
		return null;
	}
	return (
		<CSVLink data={files} filename="sites.csv" headers={headers}>
			<IconButton icon={FA.download} />
		</CSVLink>
	);
}

const mapStateToProps = (state, props) => {
	let files = getFilesSelector(state, props);

	files = files?.map(item => ({
		fileSize: item.site?.size ? filesize(item.site.size).human('jedec') : 'N/A',
		modified: item.site?.updated_at,
		name: item.site?.name,
		owner: item.owner?.name,
		type: item.site?.type,
		url: item.site?.url_path,
	}));
	return {
		files,
	};
};

export default connect(mapStateToProps, null)(LibraryCsvDownload);
