import { useState } from 'react';

import { makeSiteUrlOrNull } from '@/lib/asset_router';
import { LIBRARY_FILE_TYPES_WITH_PREVIEW } from '@/types/library';

export function useSitePreview(items) {
	const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);

	const updatePreviewItem = (item, event = null) => {
		event?.preventDefault();
		setSelectedPreviewItem(item);
	};

	const isFilePreviewable = itemType => {
		return LIBRARY_FILE_TYPES_WITH_PREVIEW.includes(itemType);
	};

	const getLightboxFormatFromSiteType = site => {
		switch (site.type) {
			case 'orthophoto':
			case 'drone_image':
			case 'floorplan':
				return 'jpg';
			default:
				return 'iframe';
		}
	};

	const getLightboxTypeFromSiteType = site => {
		switch (site.type) {
			case 'orthophoto':
			case 'drone_image':
			case 'floorplan':
				return 'image';
			default:
				return 'iframe';
		}
	};

	const previewLightboxItems = items
		?.filter(item => LIBRARY_FILE_TYPES_WITH_PREVIEW.includes(item.site?.type))
		.map(item => ({
			format: getLightboxFormatFromSiteType(item.site),
			height: item.image?.height,
			key: item.id,
			src: makeSiteUrlOrNull(item.site.url_path),
			title: item.site.name,
			type: getLightboxTypeFromSiteType(item.site),
			width: item.image?.width,
		}));

	const initialOpenedPreviewIndex = selectedPreviewItem
		? previewLightboxItems.findIndex(item => item.key === selectedPreviewItem.site.id)
		: null;

	return {
		initialOpenedPreviewIndex,
		isFilePreviewable,
		previewLightboxItems,
		selectedPreviewItem,
		updatePreviewItem,
	};
}
