import PropTypes from 'prop-types';

import './Highlighted.scss';

const REGEX_STORE = {};
// eslint-disable-next-line no-useless-escape
const ESCAPE_REGEX = /([.*+?^=!:${}()|\[\]\/\\])/g;

function getRegex(str) {
	if (!str) {
		return null;
	}

	let regex = REGEX_STORE[str];
	if (regex) {
		regex.lastIndex = 0;
		return regex;
	}

	ESCAPE_REGEX.lastIndex = 0;
	const escapedStr = str.replace(ESCAPE_REGEX, '\\$1');
	regex = REGEX_STORE[str] = new RegExp(escapedStr, 'gi');
	return regex;
}

function Highlighted({ children, className = null, match, ...props }) {
	className = `sv-Highlighted ${className || ''}`;

	if (!Array.isArray(children)) {
		children = [children];
	}

	const parts = [];

	const addPart = (str, from, to, highlighted) => {
		const className = highlighted ? 'sv-Highlighted-highlight' : null;
		parts.push(
			<span key={`part_${parts.length}`} className={className}>
				{str.substring(from, to)}
			</span>,
		);
	};

	children.forEach(str => {
		if (!str) {
			return null;
		}

		if (Number.isFinite(str)) {
			str = String(str);
		}

		if (typeof str !== 'string') {
			throw new Error(`We only support strings for now`);
		}

		if (!match) {
			addPart(str, 0, str.length, false);
			return;
		}

		const re = getRegex(match);
		let partFrom = 0;

		if (re) {
			let m;
			// eslint-disable-next-line no-cond-assign
			while ((m = re.exec(str)) !== null) {
				if (m.index > partFrom) {
					addPart(str, partFrom, m.index, false);
				}
				addPart(str, m.index, re.lastIndex, true);
				partFrom = re.lastIndex;
			}
		}

		if (partFrom < str.length) {
			addPart(str, partFrom, str.length, false);
		}
	});

	return (
		<span className={className} {...props}>
			{parts}
		</span>
	);
}

Highlighted.propTypes = {
	className: PropTypes.string,
	match: PropTypes.string,
};

export default Highlighted;
