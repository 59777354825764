import { clone, each, isArray, isEmpty, omit } from 'lodash';

import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	fileExplorer: {
		breadcrumbs: [],
		error: null,
		fileContents: null,
		files: null,
		identifier: null,
		loading: false,
		subPath: null,
	},
};

function flattenRootStructure(rootStructure, result = {}) {
	each(rootStructure, v => {
		result[v.relPath] = omit(clone(v), ['children']);
		result[v.relPath].selected = false;
		if (v.children && !isEmpty(v.children)) {
			flattenRootStructure(v.children, result);
		}
	});
	return result;
}

const updateFileExplorer = (state, ob) => {
	return { ...state, fileExplorer: { ...state.fileExplorer, ...ob } };
};

const loadFileExplorerStart = state => {
	return updateFileExplorer(state, {
		error: null,
		loading: true,
	});
};

const gotFileExplorerData = (state, action) => {
	const { files } = state.fileExplorer;
	let { data, identifier, reset, subPath } = action;
	if (subPath) {
		files[subPath] = files[subPath] || {};
		const dir = files[subPath];
		each(data, item => {
			item.relPath = subPath + item.relPath;
		});
		dir.children = data;
		data = files;
	}
	let rootStructure = flattenRootStructure(data);
	if (!reset) {
		rootStructure = { ...files, ...rootStructure };
	}
	return updateFileExplorer(state, {
		files: rootStructure,
		identifier,
		loading: false,
		subPath,
	});
};

const loadFileExplorerEnd = (state, action) => {
	return updateFileExplorer(state, {
		error: action.error || null,
		loading: false,
	});
};

const fileExplorerFileDelete = (state, action) => {
	if (!isArray(action.path)) {
		action.path = [action.path];
	}

	return updateFileExplorer(state, {
		files: omit(state.fileExplorer.files, action.path),
	});
};

const fileExplorerFileRenamed = (state, action) => {
	const files = { ...state.fileExplorer.files };
	delete files[action.path];
	files[action.data.relPath] = action.data;
	return updateFileExplorer(state, { files });
};

const fileExplorerFileLoaded = (state, action) => {
	const fileContents = { ...state.fileExplorer.fileContents };
	fileContents[action.relPath] = action.data;
	return updateFileExplorer(state, { fileContents });
};

const fileExplorerFileEdited = (state, action) => {
	const fileContents = { ...state.fileExplorer.fileContents };
	delete fileContents[action.relPath];
	return updateFileExplorer(state, { fileContents });
};

const setFileExplorerBreadcrumbs = (state, action) => {
	const { breadcrumbs } = action;
	const subPath = `/${breadcrumbs ? breadcrumbs.join('/') : ''}`;
	return updateFileExplorer(state, {
		breadcrumbs,
		subPath,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.FILE_EXPLORER_LOAD_START]: loadFileExplorerStart,
	[TYPES.FILE_EXPLORER_GOT_DATA]: gotFileExplorerData,
	[TYPES.FILE_EXPLORER_LOAD_END]: loadFileExplorerEnd,
	[TYPES.FILE_EXPLORER_PATH_CHANGED]: setFileExplorerBreadcrumbs,
	[TYPES.FILE_EXPLORER_FILE_DELETED]: fileExplorerFileDelete,
	[TYPES.FILE_EXPLORER_FILE_RENAMED]: fileExplorerFileRenamed,
	[TYPES.FILE_EXPLORER_FILE_LOADED]: fileExplorerFileLoaded,
	[TYPES.FILE_EXPLORER_FILE_EDITED]: fileExplorerFileEdited,
};
