import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';

import './TableStylized.scss';

function TableStylized({
	after = null,
	children = null,
	className = null,
	noTablet = null,
	roundedImage = null,
	...rest
}) {
	// const tabletClassName = noTablet ? 'sv-TableStylized-tablet-off' : 'sv-TableStylized-tablet-on';
	const tabletClassName = 'sv-TableStylized-tablet-off';
	const roundedImageClassName = roundedImage ? 'sv-TableStylized-roundedImage' : '';
	return (
		<div className={classes('sv-TableStylized', tabletClassName, roundedImageClassName, className)}>
			<Table {...rest}>{children}</Table>
			{after}
		</div>
	);
}

TableStylized.propTypes = {
	after: PropTypes.element,
	className: PropTypes.string,
	noTablet: PropTypes.bool,
};

export default TableStylized;
