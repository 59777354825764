import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { takeFolderId } from '@/selectors/folders';
import { getSitesWithDetailsSelector } from '@/selectors/sites';
import FA from '@/types/font_awesome';
import { LIBRARY_COLUMN_HEADER, LIBRARY_NAMESPACE, LIBRARY_TYPE } from '@/types/library';
import { DEFAULT_CRITERIA } from '@/types/sub_tours';
import LibraryView from '@/views/library/LibraryView';
import IconButton from '@/views/widgets/IconButton';

import './ContentPickerModal.scss';

const CLASS = 'sv-ContentPickerModal';

const FOLDER = 'folder';

class ContentPickerModal extends Component {
	static defaultProps = {
		bare: false,
		cancelLabel: 'Cancel',
		folderId: undefined,
		multiSelect: false,
		pickType: 'site',
		plain: false,
		submitLabel: 'Select',
	};

	static propTypes = {
		bare: PropTypes.bool,
		cancelLabel: PropTypes.string,
		criteria: PropTypes.object,
		files: PropTypes.array,
		folderId: PropTypes.number,
		multiSelect: PropTypes.bool,
		namespace: PropTypes.string,
		onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
		onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
		pickType: PropTypes.string,
		plain: PropTypes.bool,
		submitLabel: PropTypes.string,
		title: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.state = {
			criteria: DEFAULT_CRITERIA.clone(),
			folderId: props.folderId,
			selectedItems: [],
		};
	}

	componentDidMount() {
		const { criteria, folderId } = this.props;
		this.handleCriteriaChanged(criteria);
		this.setState({ folderId });
	}

	handleCriteriaChanged = newCriteria => {
		const { criteria } = this.state;
		if (!newCriteria || criteria === newCriteria) {
			return null;
		}
		this.setState({ criteria: newCriteria });
	};

	handleFolderClick = (fwd, e) => {
		e && e.preventDefault();
		this.setState({ criteria: this.props.criteria, folderId: fwd.id || fwd.folder.id });
	};

	handleSelection = items => {
		const { multiSelect } = this.props;

		if (!multiSelect) {
			return this.setState({ selectedItems: items.slice(0, 1) });
		}
		return this.setState({ selectedItems: items });
	};

	handleSubmit = () => {
		let { folderId, selectedItems } = this.state;
		const { files, onSubmit, pickType } = this.props;
		selectedItems = files ? files.filter(item => selectedItems.includes(item.key)) : [];
		return onSubmit(pickType === FOLDER ? folderId : selectedItems);
	};

	renderModalHeader = () => {
		const { onClose, title } = this.props;
		if (!title) {
			return null;
		}

		return (
			<>
				<div className={`${CLASS}-header-title`}>{title}</div>

				<div className={`${CLASS}-header-close`}>
					<IconButton icon={FA.close} onClick={onClose} />
				</div>
			</>
		);
	};

	renderModalBody = () => {
		const {
			className,
			criteria,
			files,
			folderId,
			multiSelect,
			namespace,
			onClose,
			onSubmit,
			plain,
			submitLabel,
			...rest
		} = this.props;

		const { selectedItems } = this.state;

		return (
			<LibraryView
				disableFilter
				displayNewFolder
				selecting
				createFileHref={null}
				createFolderHref={null}
				criteria={this.state.criteria || criteria}
				disableActions={!folderId}
				displaySort={false}
				displayUpload={false}
				folderId={this.state.folderId}
				isRoot={!this.state.folderId}
				namespace={LIBRARY_NAMESPACE.modal}
				selectable={LIBRARY_TYPE.FILE}
				selectedItems={selectedItems}
				disabledColumns={[
					LIBRARY_COLUMN_HEADER.url,
					LIBRARY_COLUMN_HEADER.owner,
					LIBRARY_COLUMN_HEADER.type,
					LIBRARY_COLUMN_HEADER.modified,
					LIBRARY_COLUMN_HEADER.fileSize,
				]}
				onContextMenu={null}
				onCriteriaChanged={this.handleCriteriaChanged}
				onFolderClick={this.handleFolderClick}
				onSelection={this.handleSelection}
				{...rest}
			/>
		);
	};

	renderModalFooter = () => {
		const { cancelLabel, onClose, pickType, submitLabel } = this.props;

		const { selectedItems } = this.state;
		const disabled = pickType !== FOLDER || selectedItems.length > 0;

		return (
			<div className={`${CLASS}-button-wrapper`}>
				<IconButton secondary onClick={onClose}>
					{cancelLabel}
				</IconButton>
				<IconButton disabled={disabled} onClick={this.handleSubmit}>
					{submitLabel}
				</IconButton>
			</div>
		);
	};

	render() {
		const { bare, plain } = this.props;
		const plainClassName = plain ? `${CLASS}-plain` : '';

		return (
			<div className={`${CLASS} ${plainClassName}`}>
				{bare ? (
					this.renderModalHeader()
				) : (
					<Modal.Header className={`${CLASS}-header`}>{this.renderModalHeader()}</Modal.Header>
				)}
				{bare ? (
					this.renderModalBody()
				) : (
					<Modal.Body className={`${CLASS}-body`}>{this.renderModalBody()}</Modal.Body>
				)}
				{bare ? (
					this.renderModalFooter()
				) : (
					<Modal.Footer className={`${CLASS}-buttonWrapper`}>
						{this.renderModalFooter()}
					</Modal.Footer>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		files: getSitesWithDetailsSelector(state, props),
		folderId: takeFolderId(state, props),
	};
};

export default connect(mapStateToProps, null)(ContentPickerModal);
