const socket_events = {
	subscribe: '__subscribe',
	unsubscribe: '__unsubscribe',
};

export const EMIT = {
	SUBSCRIBE: socket_events.subscribe,
	UNSUBSCRIBE: socket_events.unsubscribe,
};

export const UPLOADER_EVENTS = {
	UPLOADS_ASSETS_PROCESSED: 'UPLOADS_ASSETS_PROCESSED',
	UPLOADS_ASSETS_STATUS: 'UPLOADS_ASSETS_STATUS',
};

export const SITE_EVENTS = {
	CLONE_SITE_STATUS: 'CLONE_SITE_STATUS',
	SITE_EDITED: 'SITE_EDITED',
};

export const ASSET_ROUTER_EVENTS = {
	ROUTES_UPDATED: 'ROUTES_UPDATED',
};

export const JOB_EVENTS = {
	JOB_PROGRESS: 'JOB_PROGRESS',
};

export const CLONE_SITE_PHASES = {
	clone_site: 'clone_site',
	clone_site_billboards: 'clone_site_billboards',
	clone_site_end: 'clone_site_end',
	clone_site_images: 'clone_site_images',
	clone_site_versions: 'clone_site_versions',
};
