export const ROUTER_ACTIONS = {
	POP: 'POP',
	PUSH: 'PUSH',
	REPLACE: 'REPLACE',
};

export class RouterLocation {
	/**
	 * @param {string | RouterLocation} [source]
	 */
	constructor(source) {
		/** One of ROUTER_ACTIONS. Used by router during navigation */
		this.action = 'POP';

		/** The hash part after URL */
		this.hash = '';

		/** That random string generated by history.getKey() */
		this.key = '';

		/** Example: /a/b/c */
		this.pathname = '';

		/** Query represented as key-value dictionary. Eg. {a: 'b'} */
		this.query = {};

		/** Query string representation, with leading '?'. Eg. '?a=b' */
		this.search = '';

		/** Extra hidden data you can stuff into a history record */
		this.state = undefined;

		Object.assign(this, source);
	}
}

export class RouterHistory {
	/**
	 * Create a href from a location object or just string
	 * It will prepend base name and "#/" (in case of hashHistory)
	 * @param {RouterLocation|string} location
	 * @returns string
	 * */
	createHref(location) {}

	/**
	 * Create one of those short random strings they append to query
	 * @returns string
	 * */
	createKey() {}

	/**
	 * Normalized location, can be given string or a partial location
	 * @param {RouterLocation|string} location
	 * @returns {RouterLocation}
	 * */
	createLocation(location) {
		return new RouterLocation(location);
	}

	/**
	 * This seems to be the same as createHref, only without prepending #/ and base path
	 * @param {RouterLocation|string} location
	 * @returns string
	 * */
	createPath(location) {}

	/**
	 * Returns current location
	 * @returns {RouterLocation}
	 */
	getCurrentLocation() {
		return new RouterLocation();
	}

	/**
	 * Wraps native History.go. Go forwards or backwards n steps (negative for back).
	 * Do not use with hashHistory.
	 * @param {Number} n
	 */
	go(n) {}

	/**
	 * Wrapper around go(-1)
	 */
	goBack() {}

	/**
	 * Wrapper around go(1)
	 */
	goForward() {}

	/**
	 * @callback routerLocationListener
	 * @param {RouterLocation} location
	 */

	/**
	 * History will call the function you give it every time location changes.
	 * It returns the unsubscribe function, you can call to make it stop
	 * @param {routerLocationListener} listener
	 * @returns function
	 */
	listen(listener) {}

	/**
	 * @callback routerLocationHook
	 * @param {RouterLocation} location
	 * @param {function} done
	 */

	/**
	 * The same as listen(), except you are called with the route you are about to leave.
	 * Also, you have to call done to proceed. I guess you can also call with an alternative location? It's not super clear.
	 * @param {routerLocationHook} hook
	 * @returns function
	 */
	listenBefore(hook) {}

	/**
	 * Navigate to a new location and make it so it appears in browser history (so that pressing back
	 * will lead you back to your current location).
	 * @param {RouterLocation} location
	 */
	push(location) {}

	/**
	 * Navigate to a new location, but without influencing browser history. Only the URL in URL bar will change,
	 * history list will remain unchanged.
	 * @param {RouterLocation} location
	 */
	replace(location) {}

	/**
	 * Backing method behind both push and replace (the only difference is they enforcing the "action" field on Location)
	 * @param {RouterLocation} nextLocation
	 */
	transitionTo(nextLocation) {}
}

export class RouteDefinition {
	constructor(source) {
		/**
		 * List of child route definitions
		 * @type {RouteDefinition[]}
		 */
		this.childRoutes = [];

		/**
		 * Component that will be rendered at this route
		 * @type {function}
		 */
		this.component = null;

		/**
		 * Index route for this route (at the same level?)
		 * @type {RouteDefinition}
		 */
		this.indexRoute = null;

		/**
		 * Name given to this route
		 * @type {string}
		 */
		this.name = '';

		/**
		 * Path of this route (with placeholders, eg. /users/:userId/profile)
		 * @type {string}
		 */
		this.path = '';
	}
}

export class Router extends RouterHistory {
	isActive(location, indexOnly) {}

	setRouteLeaveHook(route, hook) {}

	constructor(source) {
		super();

		/**
		 * The same as calling getCurrentLocation(), it seems
		 * @type {RouterLocation}
		 */
		this.location = new RouterLocation();

		/**
		 * Parsed route params from the pathname
		 * @type {Object.<string, string>}
		 */
		this.params = {};

		/**
		 * List of routes on stack, starting from home root ('/'), all the way down to your current location.
		 * You might use this to build a breadcrumbs view.
		 * @type {RouteDefinition[]}
		 */
		this.routes = [];

		Object.assign(this, source);
	}
}

export class PlatformRestrictions {
	constructor(source) {
		this.canSeeTours = true;

		this.canSee360Objects = true;

		this.canSeeVideos = true;

		this.canSeeImages = true;

		this.canSeeUnityTours = true;

		this.canCloneTours = true;

		Object.assign(this, source);
	}
}
