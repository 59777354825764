import { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { uploadImages } from '@/actions/application';
import { ACCEPT_IMAGE } from '@/types/consts';
import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';
import LargeErrorBox from '@/views/widgets/presentation/LargeErrorBox';
import StretchableSpinner from '@/views/widgets/StretchableSpinner';

import './ImageUploader.scss';

const canAcceptFiles = state => {
	return !state.isUploading && !state.error;
};

class ImageUploader extends Component {
	static propTypes = {
		acceptedFileTypes: PropTypes.array,
		bare: PropTypes.bool,
		children: PropTypes.any,
		disableClick: PropTypes.bool,
		displayHint: PropTypes.bool,
		displayUploadButton: PropTypes.bool,
		hint: PropTypes.string,
		multiple: PropTypes.bool,
		onFiles: PropTypes.func,
		onUploaded: PropTypes.func,
		overlayIcon: PropTypes.string,
		uploadLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	};

	static defaultProps = {
		acceptedFileTypes: ACCEPT_IMAGE,
		bare: false,
		disableClick: true,
		displayHint: true,
		displayUploadButton: true,
		hint: 'You can drag images into this box!',
		multiple: false,
		overlayIcon: FA.arrow_down,
		uploadLabel: 'Upload',
	};

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			isUploading: false,
		};

		this.onDropFiles = this.onDropFiles.bind(this);
		this.onUploadClicked = this.onUploadClicked.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onUploaded(newImages) {
		const payload = this.props.multiple ? newImages : newImages[0];
		this.props.onUploaded && this.props.onUploaded(payload);
	}

	onDropFiles(acceptedFiles) {
		if (!acceptedFiles || !acceptedFiles.length) {
			return;
		}

		if (this.state.isUploading) {
			return;
		}

		this.setState({
			isUploading: true,
		});

		if (this.props.onFiles) {
			this.props.onFiles(acceptedFiles);
		}

		return this.props.uploadImages(acceptedFiles).then(
			newImages => {
				if (!this._isMounted) {
					return;
				}

				this.setState({
					isUploading: false,
				});

				this.onUploaded(newImages);
			},
			error => {
				if (!this._isMounted) {
					return;
				}

				this.setState({
					error,
					isUploading: false,
				});
			},
		);
	}

	onUploadClicked(e) {
		if (e && e.preventDefault) {
			e.stopPropagation();
			e.preventDefault();
		}
		if (this.dropzone) {
			this.dropzone.open();
		}
		return false;
	}

	renderErrorOverlay() {
		if (!this.state.error) {
			return null;
		}

		return (
			<div className="sv-ImageUploader-error-overlay">
				<LargeErrorBox
					error={this.state.error}
					message="Image upload failed"
					onDismiss={() => this.setState({ error: null })}
				/>
			</div>
		);
	}

	renderUploadingOverlay() {
		if (!this.state.isUploading) {
			return this.props.bare ? this.props.children : null;
		}

		return (
			<div className="sv-ImageUploader-spinner-overlay">
				<StretchableSpinner />
			</div>
		);
	}

	setRef = node => {
		if (this.props.setRef) {
			this.props.setRef(node);
		}

		this.dropzone = node;
	};

	render() {
		const enableDropzone = canAcceptFiles(this.state);
		const {
			acceptedFileTypes,
			bare,
			children,
			disableClick,
			displayHint,
			displayUploadButton,
			multiple,
			onUploaded,
			overlayIcon,
			uploadLabel,
		} = this.props;

		let hint = null;
		if (this.props.hint) {
			hint = (
				<span className="sv-ImageUploader-bar-hint">
					<Badge bg="info">Hint</Badge>
					{` ${this.props.hint}`}
				</span>
			);
		}

		let uploadButton = null;
		if (onUploaded && displayUploadButton) {
			uploadButton = (
				<IconButton
					secondary
					className="sv-ImageUploader-bar-button"
					disabled={!enableDropzone}
					onClick={this.onUploadClicked}
				>
					{uploadLabel}
				</IconButton>
			);
		}

		let innerContent;
		if (bare) {
			innerContent = this.state.isUploading ? (
				<span className="text-muted">Uploading</span>
			) : (
				children
			);
		} else {
			innerContent = (
				<div className="sv-ImageUploader-content">
					{displayHint ? (
						<div className="sv-ImageUploader-bar">
							{hint}
							{uploadButton}
						</div>
					) : null}

					{children}

					<div className="sv-ImageUploader-drop-overlay">
						<FontAwesomeIcon icon={overlayIcon} />
					</div>

					{this.renderUploadingOverlay()}

					{this.renderErrorOverlay()}
				</div>
			);
		}

		return (
			<div className="sv-ImageUploader">
				{enableDropzone ? (
					<Dropzone
						ref={this.setRef}
						accept={acceptedFileTypes}
						activeClassName="sv-ImageUploader-dropzone-active"
						className="sv-ImageUploader-dropzone"
						multiple={multiple}
						noClick={disableClick}
						onDrop={this.onDropFiles}
					>
						{({ getRootProps }) => <section {...getRootProps()}>{innerContent}</section>}
					</Dropzone>
				) : (
					innerContent
				)}
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	uploadImages: files => {
		return dispatch(uploadImages(files));
	},
});

export default connect(null, mapDispatchToProps)(ImageUploader);
