import { get } from 'lodash';
import { createSelector } from 'reselect';

import { CompanyCriteria, DEFAULT_CRITERIA } from '@/types/companies';
import { makeGetter, sortByIndexingProperty, takeFromProps } from '@/types/data';

const getState = makeGetter('companies');
const dataGetter = makeGetter('data');
export const getData = createSelector(getState, dataGetter);
export const takeCompanyId = takeFromProps('companyId');

export const getCompanyWithDetails = (state, id) => {
	const data = getData(state);
	return data ? data[id] || null : null;
};

export const getCompanyWithDetailsSelector = createSelector(getData, takeCompanyId, get);

export const getCriteria = createSelector(
	(state, props) => (props.location ? props.location.query : props.query),
	query => {
		return new CompanyCriteria({ ...DEFAULT_CRITERIA, ...query });
	},
);

export const getSortedCompaniesWithDetails = createSelector([getData], sortByIndexingProperty);

export const companyLabel = company => {
	let result = company.name;
	if (company.reference) {
		result = `${result} (${company.reference})`;
	}
	return result;
};
