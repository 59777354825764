import { Children, Component } from 'react';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';

import './CriteriaFilterDropdown.scss';

const CLASS = 'sv-CriteriaFilterDropdown';

class CriteriaFilterDropdown extends Component {
	state = {
		display: false,
	};

	static propTypes = {
		content: PropTypes.array,
		loading: PropTypes.bool,
	};

	displayOptions = () => {
		this.setState({
			display: !this.state.display,
		});
	};

	render() {
		const { children, disableFilter } = this.props;
		const { display } = this.state;
		const icon = display ? FA.chevron_up : FA.chevron_down;
		const buttonClass = display ? '-filterButton active' : '-filterButton';
		return (
			<div className={CLASS}>
				<IconButton
					secondary
					className={CLASS + buttonClass}
					disabled={disableFilter}
					icon={icon}
					onClick={this.displayOptions}
				>
					Filter
				</IconButton>
				<Collapse in={display}>
					<Container className={`${CLASS}-filterArea`}>
						{display
							? Children.map(children, (child, key) => (
									<Row key={key} className={`${CLASS}-filterArea-row`}>
										<Col md={3} xs={4}>
											<div>{child.props.label}</div>
										</Col>
										<Col md={9} xs={8}>
											<div>{child}</div>
										</Col>
									</Row>
							  ))
							: null}
					</Container>
				</Collapse>
			</div>
		);
	}
}

export default CriteriaFilterDropdown;
