import { createSelector } from 'reselect';

import { makeGetter, sortByIndexingProperty, takeFromProps } from '@/types/data';
import { DEFAULT_CRITERIA, UserCriteria } from '@/types/users';

import { takeQuery } from './application';
import { getLoggedInUserId, getLoggedInUserWithDetails } from './auth';
import { takeCompanyId } from './companies';

const getState = makeGetter('users');
const getUser = makeGetter('user');
const getData = makeGetter('data');
export const getUsersData = createSelector(getState, getData);

export const takeUserId = takeFromProps('userId');
export const takeIsMe = takeFromProps('isMe'); // lol

/**
 * @return {UserWithDetails|null}
 */
export const getUserWithDetailsSelector = createSelector(
	takeUserId,
	getLoggedInUserId,
	getLoggedInUserWithDetails,
	getUsersData,
	(userId, loggedInUserId, uwd, users) => {
		if ((!userId && loggedInUserId) || parseInt(userId, 10) === parseInt(loggedInUserId, 10)) {
			return uwd;
		}
		return users && users[userId] ? users[userId] : null;
	},
);
export const getUserSelector = createSelector(
	getUserWithDetailsSelector,
	uwd => getUser(uwd) || uwd,
);
export const getSortedUsersWithDetails = createSelector([getUsersData], sortByIndexingProperty);

export const getCriteria = createSelector(
	takeQuery,
	takeFromProps('criteria'),
	takeCompanyId,
	(query, criteria, companyId) => {
		if (criteria) {
			query = null;
		}
		return new UserCriteria({ ...DEFAULT_CRITERIA, ...query, ...criteria, companyId });
	},
);
