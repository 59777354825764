import ENV from '@/env';
import { assign } from '@/lib/util';

import {
	ACCEPT_DOCUMENT,
	ACCEPT_DOCUMENT_EXTENSION,
	ACCEPT_IMAGE,
	ACCEPT_IMAGE_EXTENSION,
	ACCEPT_VIDEO,
	ACCEPT_VIDEO_EXTENSION,
	ACCEPT_ZIP,
	ACCEPT_ZIP_EXTENSION,
} from './consts';
import { Criteria as BaseCriteria, ModelBase, SORT_DIRECTIONS } from './data';
import FA from './font_awesome';
import { TIME_FRAME_GETTERS, TIME_FRAMES } from './reports';

/** @typedef {Partial<Image> | Partial<Video> | Partial<Document>} Attachment */
/** @typedef {"image" | "video" | "document"} AttachmentType */

//* *********************************************************************************************************************
export const SITE_CHANGED_ROOM_TEMPLATE = 'site-edited-$siteId';

export const SITE_TYPES = {
	bim: 'bim',
	brochure: 'brochure',
	document: 'document',
	drone_image: 'drone_image',
	floorplan: 'floorplan',
	image: 'image',
	model_3d: 'model_3d',
	object_360: 'object_360',
	orthophoto: 'orthophoto',
	other: 'other',
	pointcloud: 'pointcloud',
	recording: 'recording',
	survey: 'survey',
	tour: 'tour',
	unity: 'unity',
	video: 'video',
};

export const SITE_LABELS = {
	agority_avatar: 'Agority Avatar',
	agority_note: 'Agority Note',
	agority_selfie: 'Agority Selfie',
	bim: 'BIM',
	brochure: ENV.custom_brochure_naming?.site_labels || 'Brochure',
	document: 'Document',
	document_doc: 'DOC Document',
	document_ortho: 'Orthographic Document',
	document_pdf: 'PDF Document',
	document_ppt: 'PPT Document',
	document_xls: 'XLS Document',
	drone_image: 'Drone Image',
	floorplan: 'Floorplan',
	floorplan_image: 'Floorplan (image)',
	image: 'Image',
	image_360: '360 Image',
	model_3d: '3D Model',
	object_360: '360 Object',
	orthophoto: 'Orthophoto',
	other: 'Other',
	pano_tour: 'Tour',
	pointcloud: 'Pointcloud',
	recording: 'Recording',
	subtour: 'Tour',
	survey: 'Survey',
	sv_tour: 'Tour',
	tileset: 'Pointcloud (tileset)',
	tour: 'Tour',
	unity: 'Unity tour',
	video: 'Video',
	video_360: '360 Video',
	video_360_mono: '360 Video(mono)',
	video_360_stereo_ltr: `360 Video(stereo LTR)`,
	video_360_stereo_ttb: `360 Video(stereo TTB)`,
};

export const createSingularOrPluralLabel =
	(LABELS_PLURAL, LABELS) =>
	(value, lowercase = false, plural = false) => {
		let result = plural ? LABELS_PLURAL[value] : LABELS[value];

		if (!result) {
			return null;
		}

		if (lowercase) {
			result = result.toLowerCase();
		}
		return result;
	};

export const SITE_LABELS_PLURAL = {
	agority_avatar: 'Agority Avatars',
	agority_note: 'Agority Notes',
	agority_selfie: 'Agority Selfies',
	bim: 'BIM',
	brochure: 'Brochures',
	document: 'Documents',
	document_doc: 'DOC Documents',
	document_ortho: 'Orthographic Documents',
	document_pdf: 'PDF Documents',
	document_ppt: 'PPT Documents',
	document_xls: 'XLS Documents',
	drone_image: 'Drone Images',
	floorplan: 'Floorplans',
	floorplan_image: 'Floorplans (image)',
	image: 'Images',
	image_360: '360 Images',
	model_3d: '3D Models',
	object_360: 'Products',
	orthophoto: 'Orthophotos',
	other: 'Other',
	pano_tour: 'Tours',
	pointcloud: 'Pointcloud',
	recording: 'Recordings',
	subtour: 'Tours',
	survey: 'Surveys',
	sv_tour: 'Tours',
	tileset: 'Pointclouds (tileset)',
	tour: 'Tours',
	unity: 'Unity tours',
	video: 'Videos',
	video_360: '360 Videos',
	video_360_mono: '360 Videos(mono)',
	video_360_stereo_ltr: `360 Videos(stereo LTR)`,
	video_360_stereo_ttb: `360 Videos(stereo TTB)`,
};

// TODO: Refactor a bunch of places to use this instead of SITE_LABELS directly
export const siteTypeLabel = createSingularOrPluralLabel(SITE_LABELS_PLURAL, SITE_LABELS);
export const mediaFormatLabel = (...args) => {
	const result = createSingularOrPluralLabel(SITE_LABELS_PLURAL, SITE_LABELS)(...args);
	return result ?? MEDIA_FORMATS_NONE_LABEL;
};

export const SITE_ICONS = {
	agority_avatar: FA.user_circle,
	agority_note: FA.image,

	agority_selfie: FA.image,

	bim: FA.bim,

	brochure: FA.file,
	document: FA.file,
	document_doc: FA.file_word_o,
	document_ortho: FA.file_pdf_o,
	document_pdf: FA.file_pdf_o,
	document_ppt: FA.file_powerpoint_o,
	document_xls: FA.file_excel_o,
	drone_image: FA.drone,
	floorplan: FA.floor_plan,
	floorplan_image: FA.file_image_o,
	image: FA.image,
	image_360: FA.image,
	model_3d: FA.cubes,
	object_360: FA.cubes,
	orthophoto: FA.image,
	other: FA.file,
	pano_tour: FA.street_view,
	pointcloud: FA.cubes,
	recording: FA.file_audio_o,
	subtour: FA.street_view,
	survey: FA.align_left,
	sv_tour: FA.street_view,
	tileset: FA.cubes,
	tour: FA.street_view,
	unity: FA.cube,
	video: FA.video_camera,
	video_360: FA.video_camera,
	video_360_mono: FA.video_camera,
	video_360_stereo_ltr: FA.video_camera,
	video_360_stereo_ttb: FA.video_camera,
};

export const SITE_ICONS_ALT = {
	agority_avatar: FA.user_circle_o,
	agority_note: FA.file_image_o,

	agority_selfie: FA.file_image_o,

	brochure: FA.file_o,
	document: FA.file_o,
	document_doc: FA.file_word_o,
	document_ortho: FA.file_pdf_o,
	document_pdf: FA.file_pdf_o,
	document_ppt: FA.file_powerpoint_o,
	document_xls: FA.file_excel_o,
	drone_image: FA.drone,
	floorplan: FA.floor_plan,
	floorplan_image: FA.file_image_o,
	image: FA.file_image_o,
	image_360: FA.file_image_o,
	model_3d: FA.object_ungroup,
	object_360: FA.file_image_o,
	orthophoto: FA.file_image_o,
	other: FA.file_o,
	pano_tour: FA.file_image_o,
	pointcloud: FA.object_ungroup,
	recording: FA.file_sound_o,
	subtour: FA.file_image_o,
	survey: FA.align_left,
	sv_tour: FA.file_image_o,
	tileset: FA.object_ungroup,
	tour: FA.file_image_o,
	unity: FA.file_image_o,
	video: FA.file_video_o,
	video_360: FA.file_video_o,
	video_360_mono: FA.video_camera,
	video_360_stereo_ltr: FA.video_camera,
	video_360_stereo_ttb: FA.video_camera,
};

export const MEDIA_FORMATS = {
	agority_avatar: 'agority_avatar',
	agority_note: 'agority_note',
	agority_selfie: 'agority_selfie',
	document_doc: 'document_doc',
	document_ortho: 'document_ortho',
	document_pdf: 'document_pdf',
	document_ppt: 'document_ppt',
	document_xls: 'document_xls',
	floorplan_image: 'floorplan_image',
	image_360: 'image_360',
	orthophoto: 'orthophoto',
	tileset: 'tileset',
	video_360: 'video_360',
	video_360_mono: 'video_360_mono',
	video_360_stereo_ltr: 'video_360_stereo_ltr',
	video_360_stereo_ttb: 'video_360_stereo_ttb',
};

// Set to string because react-select doesn't work when sending null or undefined as the option's value
// https://github.com/facebook/react/issues/4085
export const MEDIA_FORMATS_NONE_VALUE = 'null';
export const MEDIA_FORMATS_NONE_LABEL = 'None';

export const SITE_TYPES_WITH_BELONGING_MEDIA_FORMATS = {
	[SITE_TYPES.bim]: [],
	[SITE_TYPES.brochure]: [],
	[SITE_TYPES.document]: [
		MEDIA_FORMATS_NONE_VALUE,
		MEDIA_FORMATS.document_doc,
		MEDIA_FORMATS.document_pdf,
		MEDIA_FORMATS.document_ppt,
		MEDIA_FORMATS.document_xls,
	],
	[SITE_TYPES.drone_image]: [],
	[SITE_TYPES.floorplan]: [
		MEDIA_FORMATS_NONE_VALUE,
		MEDIA_FORMATS.floorplan_image,
		MEDIA_FORMATS.document_pdf,
	],
	[SITE_TYPES.image]: [
		MEDIA_FORMATS_NONE_VALUE,
		MEDIA_FORMATS.image_360,
		MEDIA_FORMATS.agority_selfie,
		MEDIA_FORMATS.orthophoto,
	],
	[SITE_TYPES.model_3d]: [
		MEDIA_FORMATS_NONE_VALUE,
		MEDIA_FORMATS.agority_avatar,
		MEDIA_FORMATS.tileset,
	],
	[SITE_TYPES.object_360]: [],
	[SITE_TYPES.orthophoto]: [MEDIA_FORMATS_NONE_VALUE, MEDIA_FORMATS.orthophoto],
	[SITE_TYPES.other]: [],
	[SITE_TYPES.pointcloud]: [MEDIA_FORMATS_NONE_VALUE, MEDIA_FORMATS.tileset],
	[SITE_TYPES.recording]: [],
	[SITE_TYPES.survey]: [],
	[SITE_TYPES.tour]: [],
	[SITE_TYPES.unity]: [],
	[SITE_TYPES.video]: [
		MEDIA_FORMATS_NONE_VALUE,
		MEDIA_FORMATS.video_360,
		MEDIA_FORMATS.video_360_mono,
		MEDIA_FORMATS.video_360_stereo_ltr,
		MEDIA_FORMATS.video_360_stereo_ttb,
	],
};

export const MEDIA_FORMAT_LABELS = {
	...SITE_LABELS,
	floorplan_image: 'Floorplan (image)',
	image_360: '360 Image',
	orthophoto: 'Image Orthographic',
	pano_tour: 'Pano tour',
	sv_tour: 'Spinview tour',
	video_360: '360 Video',
	video_360_mono: '360 Video(mono)',
	video_360_stereo_ltr: `360 Video(stereo LTR)`,
	video_360_stereo_ttb: `360 Video(stereo TTB)`,
};

export const MEDIA_FORMAT_LABELS_PLURAL = {
	...SITE_LABELS_PLURAL,
	floorplan_image: 'Floorplans (image)',
	image_360: '360 Images',
	orthophoto: 'Orthographic Images',
	pano_tour: 'Pano tours',
	sv_tour: 'Spinview tours',
	video_360: '360 Videos',
	video_360_mono: '360 Videos(mono)',
	video_360_stereo_ltr: `360 Videos(stereo LTR)`,
	video_360_stereo_ttb: `360 Videos(stereo TTB)`,
};

export const MEDIA_FORMAT_TYPES = {
	agority_avatar: SITE_TYPES.model_3d,
	agority_note: SITE_TYPES.image,
	agority_selfie: SITE_TYPES.image,
	floorplan_image: SITE_TYPES.floorplan,
	image_360: SITE_TYPES.image,
	orthophoto: SITE_TYPES.image,
	pano_tour: SITE_TYPES.tour,
	sv_tour: SITE_TYPES.tour,
	video_360: SITE_TYPES.video_360,
	video_360_mono: SITE_TYPES.video,
	video_360_stereo_ltr: SITE_TYPES.video,
	video_360_stereo_ttb: SITE_TYPES.video,
};

export const siteMediaFormatType = mediaFormat => MEDIA_FORMAT_TYPES[mediaFormat];

export const SITE_MIME_TYPES = {
	document: ACCEPT_DOCUMENT,
	image: ACCEPT_IMAGE,
	other: ACCEPT_ZIP,
	subtour: ACCEPT_ZIP,
	tour: ACCEPT_ZIP,
	unity: ACCEPT_ZIP,
	video: ACCEPT_VIDEO,
};

export const SITE_EXTENSION_TYPES = {
	document: ACCEPT_DOCUMENT_EXTENSION,
	image: ACCEPT_IMAGE_EXTENSION,
	other: ACCEPT_ZIP_EXTENSION,
	subtour: ACCEPT_ZIP_EXTENSION,
	tour: ACCEPT_ZIP_EXTENSION,
	unity: ACCEPT_ZIP_EXTENSION,
	video: ACCEPT_VIDEO_EXTENSION,
};

export const BILLBOARD_TYPES = {
	document: 'document',
	image: 'image',
	text: 'text',
	video: 'video',
};

export const BILLBOARD_TYPE_OPTIONS = {
	[BILLBOARD_TYPES.text]: 'Text',
	[BILLBOARD_TYPES.image]: 'Image',
	[BILLBOARD_TYPES.video]: 'Video',
	[BILLBOARD_TYPES.document]: 'Document',
};

export const BILLBOARD_TYPE_PROPS = {
	[BILLBOARD_TYPES.text]: 'enable_text',
	[BILLBOARD_TYPES.image]: 'enable_image',
	[BILLBOARD_TYPES.video]: 'enable_video',
	[BILLBOARD_TYPES.document]: 'enable_document',
};

export const BILLBOARD_TYPES_ARRAY = [
	BILLBOARD_TYPES.text,
	BILLBOARD_TYPES.image,
	BILLBOARD_TYPES.video,
	BILLBOARD_TYPES.document,
];

export const AR_APP_TYPES = [SITE_TYPES.model_3d, SITE_TYPES.image];

//* *********************************************************************************************************************

const site = {
	city: 'city',
	country: 'country',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	description: 'description',
	directory: 'directory',
	folder_id: 'folder_id',
	id: 'id',
	index_file: 'index_file',
	legacy_stats: 'legacy_stats',
	media_format: 'media_format',
	metadata: 'metadata',
	name: 'name',
	owner_id: 'owner_id',
	parent_id: 'parent_id',
	preferred_url_schema: 'preferred_url_schema',
	preview_image_id: 'preview_image_id',
	production_version_id: 'production_version_id',
	stats_version_id: 'stats_version_id',
	type: 'type',
	updated_at: 'updated_at',
	url_path: 'url_path',
};

export const SITE = site;

export class Site {
	constructor(source) {
		/** @type {number} */ this.id = undefined;
		/** @type {string} */ this.name = undefined;
		/** @type {string} */ this.description = undefined;
		/** @type {string} */ this.type = undefined;
		/** @type {string} */ this.url_path = undefined;
		/** @type {string} */ this.preferred_url_schema = undefined;
		/** @type {string} */ this.country = undefined;
		/** @type {string} */ this.city = undefined;
		/** @type {string} */ this.directory = undefined;
		/** @type {number} */ this.owner_id = undefined;
		/** @type {number} */ this.preview_image_id = undefined;
		/** @type {boolean} */ this.legacy_stats = undefined;
		/** @type {string} */ this.index_file = undefined;
		/** @type {string} */ this.media_format = undefined;
		/** @type {number} */ this.production_version_id = undefined;
		/** @type {number} */ this.stats_version_id = undefined;
		/** @type {number} */ this.cloned_from_id = undefined;
		/** @type {number} */ this.parent_id = undefined;
		/** @type {number} */ this.folder_id = undefined;
		/** @type {number} */ this.user_id = undefined;
		/** @type {number} */ this.size = undefined;
		/** @type {any} */ this.metadata = undefined;

		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

const siteMetadata = {
	size: 'metadata.size',
	version: 'metadata.version',
};

export const SITE_METADATA = siteMetadata;

export class SiteMetadata {
	constructor(source) {
		this.size = undefined;
		this.version = undefined;
		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

export const SITE_TAG_SPEC = {
	description: 'description',
	icon: 'icon',
	title: 'title',
	variant: 'variant',
};

//* *********************************************************************************************************************

const version = {
	created_at: 'created_at',
	id: 'id',
	notes: 'notes',
	site_id: 'site_id',
	tag: 'tag',
	updated_at: 'updated_at',
};

export const SITE_VERSION = version;

export class SiteVersion extends ModelBase {
	/** @param {Partial<SiteVersion>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;
		/** @type {string} */
		this.tag = undefined;
		/** @type {number} */
		this.site_id = undefined;
		/** @type {string} */
		this.notes = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.updated_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

const image = {
	accessed_at: 'accessed_at',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	directory: 'directory',
	format: 'format',
	hash: 'hash',
	height: 'height',
	id: 'id',
	is_360: 'is_360',
	key: 'key',
	original_name: 'original_name',
	updated_at: 'updated_at',
	width: 'width',
};

export const IMAGE = image;

export class Image extends ModelBase {
	/** @param {Partial<Image>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;
		/** @type {string} */
		this.directory = undefined;
		/** @type {string} */
		this.key = undefined;
		/** @type {string} */
		this.original_name = undefined;
		/** @type {string} */
		this.format = undefined;
		/** @type {boolean} */
		this.is_360 = undefined;
		/** @type {number} */
		this.width = undefined;
		/** @type {number} */
		this.height = undefined;
		/** @type {string} */
		this.hash = undefined;

		/** @type {Date} */
		this.accessed_at = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.updated_at = undefined;
		/** @type {Date} */
		this.deleted_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

const video = {
	compressed_at: 'compressed_at',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	directory: 'directory',
	format: 'format',
	hash: 'hash',
	id: 'id',
	key: 'key',
	original_name: 'original_name',
	quality: 'quality',
};

export const VIDEO = video;

export class Video extends ModelBase {
	/** @param {Partial<Video>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;
		/** @type {string} */
		this.directory = undefined;
		/** @type {string} */
		this.key = undefined;
		/** @type {string} */
		this.original_name = undefined;
		/** @type {string} */
		this.format = undefined;
		/** @type {string} */
		this.quality = undefined;
		/** @type {string} */
		this.hash = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.deleted_at = undefined;
		/** @type {Date} */
		this.compressed_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

const document = {
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	directory: 'directory',
	format: 'format',
	id: 'id',
	key: 'key',
	original_name: 'original_name',
};

export const DOCUMENT = document;

export class Document extends ModelBase {
	/** @param {Partial<Document>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;
		/** @type {string} */
		this.directory = undefined;
		/** @type {string} */
		this.key = undefined;
		/** @type {string} */
		this.original_name = undefined;
		/** @type {string} */
		this.format = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.deleted_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

/**
 * @lends SiteWithDetails.prototype
 */
const swd = {
	billboards: 'billboards',
	brochures: 'brochures',
	client: 'client',
	country_name: 'country_name',
	folder: 'folder',
	image: 'image',
	images: 'images',
	metadata: 'metadata',
	other_versions: 'other_versions',
	owner: 'owner',
	owner_reference: 'owner_reference',
	parent: 'parent',
	production_version: 'production_version',
	site: 'site',
	stats_version: 'stats_version',
	tour: 'tour',
	tour_tags: 'tour_tags',
};

export const SWD = swd;

export const SWD_DETAILS = [];

export const SWD_FULL_DETAILS = [
	SWD.parent,
	SWD.production_version,
	SWD.stats_version,
	SWD.tour_tags,
	// SWD.other_versions,
	SWD.brochures,
	SWD.billboards,
	SWD.metadata,
	SWD.tour,
	SWD.image,
	SWD.owner,
	SWD.client,
	SWD.folder,
];

export const SWD_FILTER_PATHS = [
	SITE.id,
	SITE.url_path,
	SITE.name,
	SITE.type,
	SITE.media_format,
	SITE.city,
	SITE.country,
	SITE.folder_id,
	SITE.owner_id,
	SITE.updated_at,
];

export class SiteWithDetails extends ModelBase {
	/** @param {Partial<SiteWithDetails>} [source] */
	constructor(source) {
		super();

		/** @type {Site} */
		this.site = undefined;

		/** @type {Site} */
		this.parent = undefined;

		/** @type {SiteVersion} */
		this.production_version = undefined;

		/** @type {SiteVersion} */
		this.stats_version = undefined;

		/** @type {SiteVersion[]} */
		this.other_versions = undefined;

		/** @type {Image} */
		this.image = undefined;

		/** @type {Array<Image>} */
		this.images = undefined;

		/** @type {import('./companies').Company} */
		this.owner = undefined;

		/** @type {import('./companies').Company} */
		this.client = undefined;

		/** @type {import('./billboards').Billboard} */
		this.billboards = undefined;

		/** @type {import('./brochures').SiteBrochure[]} */
		this.brochures = undefined;

		/** @type {import('./sub_tours').SubTour} */
		this.tour = undefined;

		/** @type {import('./tags').Tag[]} */
		this.tags = undefined;

		/** @type {import('./sub_tours').KrTourWithDetails[]} */
		this.metadata = undefined;

		/** @type {import('./library').Folder} */
		this.folder = undefined;

		this.tour_tags = undefined;
		this.country_name = undefined;
		this.owner_reference = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

export class Criteria extends BaseCriteria {
	constructor(source) {
		super();

		this.for_parent = undefined;
		this.time_frame = undefined;
		this.with_metadata_tags = undefined;
		this.with_360s = undefined;
		this.with_scenes = undefined;
		this.with_gps = undefined;
		this.with_billboards = undefined;
		this.is_agority_app = undefined;
		this.is_ar_app = undefined;
		this.with_user_device_id = undefined;
		this.not_media_format = undefined;
		SWD_FILTER_PATHS.forEach(key => {
			this[key] = undefined;
		});

		assign(this, this, source);
	}

	getTimeFrameParam(paramName) {
		if (this.time_frame === TIME_FRAMES.custom) {
			return this[paramName] || undefined;
		}
		if (!TIME_FRAMES[this.time_frame]) {
			return undefined;
		}
		if (!TIME_FRAME_GETTERS[this.time_frame][paramName]) {
			return undefined;
		}
		return TIME_FRAME_GETTERS[this.time_frame][paramName]() || undefined;
	}

	getSortBy(sort_by) {
		sort_by = sort_by ? sort_by.split('.') : [SITE.name];
		if (sort_by.length > 1) {
			sort_by.shift();
		}
		if (!Object.prototype.hasOwnProperty.call(this, sort_by[0])) {
			sort_by[0] = SITE.name;
		}
		return [SWD.site, ...sort_by].join('.');
	}

	serialize() {
		this.coerce();
		const { folder_id, for_parent, page_size, ...rest } = super.serialize();
		return rest;
	}

	toQuery(fromPage) {
		this.coerce();
		const query = super.toQuery(fromPage);
		if (query['sort-by']) {
			query['sort-by'] = this.getSortBy(query['sort-by']);
		}
		if (this.with_metadata_tags !== undefined)
			query['with-metadata_tags'] = this.with_metadata_tags;
		if (this.with_360s !== undefined) query['with-360s'] = this.with_360s;
		if (this.with_scenes !== undefined) query['with-scenes'] = this.with_scenes;
		if (this.with_gps !== undefined) query['with-gps'] = this.with_gps;
		if (this.with_billboards !== undefined) query['with-billboards'] = this.with_billboards;
		if (this.is_agority_app !== undefined) query['is-agority-app'] = String(!!this.is_agority_app);
		if (this.not_media_format !== undefined)
			query['not_media_format'.replace(/_/g, '-')] = String(this.not_media_format);
		if (this.is_ar_app !== undefined) query['is-ar-app'] = String(!!this.is_ar_app);
		if (this.with_user_device_id !== undefined)
			query['with-user-device-id'] = this.with_user_device_id;
		if (this.for_parent !== undefined) query['for-parent'] = this.for_parent;

		SWD_FILTER_PATHS.forEach(key => {
			if (this[key]) {
				query[key.replace(/_/g, '-')] = String(this[key]);
			}
		});
		if (this.time_frame) {
			query['updated-at'] = this.getTimeFrameParam('from');
		}

		if (this.hasDefaultDetails(DEFAULT_CRITERIA.details)) {
			delete query.details;
		}
		if (query['folder-id'] === 'orphans') {
			delete query['folder-id'];
			query['owner-id'] = 'null';
		}

		return query;
	}
}

export const DEFAULT_CRITERIA = new Criteria({
	details: SWD_DETAILS,
	page_size: 50,
	sort_by: SITE.id,
	sort_direction: SORT_DIRECTIONS.desc,
});

export const DETAILED_CRITERIA = new Criteria({
	...DEFAULT_CRITERIA,
	details: [...SWD_FULL_DETAILS],
	page_size: 50,
});
