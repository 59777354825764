import { map } from 'lodash';
import PropTypes from 'prop-types';

import LargeErrorBox from '@/views/widgets/presentation/LargeErrorBox';
import StretchableSpinner from '@/views/widgets/StretchableSpinner';

import TableSingleCell from './TableSingleCell';

const wrap = content => {
	return <tbody>{content}</tbody>;
};

function TableDataBody({
	children = null,
	data,
	error,
	errorMessage = 'Failed to load data',
	renderRow,
}) {
	if (error) {
		return wrap(
			<TableSingleCell>
				<LargeErrorBox error={error} message={errorMessage} />
			</TableSingleCell>,
		);
	}

	if (!data) {
		return wrap(
			<TableSingleCell>
				<StretchableSpinner />
			</TableSingleCell>,
		);
	}

	const rows = map(data, (item, index) => renderRow(item, index));

	if (!rows.length) {
		return wrap(<TableSingleCell>{children}</TableSingleCell>);
	}

	return wrap(rows);
}

TableDataBody.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	error: PropTypes.object,
	errorMessage: PropTypes.string,
	renderRow: PropTypes.func,
};

export default TableDataBody;
