import { resetAllStates, setAuthRedirect } from '@/actions/application';
import container from '@/container';
import { log } from '@/lib/logger';
import * as routes from '@/lib/routes';
import { isAdmin, isLoggedIn } from '@/selectors/auth';

export const composeRouteHooks = hooks => {
	function routeHooks(nextState, replace) {
		return hooks.find(hook => hook(nextState, replace));
	}
	routeHooks.hooks = hooks;
	return routeHooks;
};

export const makeStateReseter = targets => {
	function stateReseter() {
		log('routeHooks', stateReseter.displayName);
		container.store.dispatch(resetAllStates(targets));
	}
	stateReseter.displayName = `stateReseter([${targets.join(', ')}])`;
	return stateReseter;
};

export const makeAuthRedirect = (loggedInState, targetRoute) => {
	function authRedirect(nextState) {
		const state = container.store.getState();

		const { history } = nextState;
		if (typeof loggedInState === 'function' && loggedInState(state)) {
			log('routeHooks', authRedirect.displayName, false);
			return;
		}
		if (typeof loggedInState === 'boolean' && loggedInState !== isLoggedIn(state)) {
			log('routeHooks', authRedirect.displayName, false);
			// Nothing needs to be done
			return;
		}
		log('routeHooks', authRedirect.displayName, true);
		container.store.dispatch(setAuthRedirect(nextState.location));

		return history.replace({
			pathname: targetRoute,
		});
	}
	authRedirect.displayName = `authRedirect(${loggedInState}, ${targetRoute})`;
	return authRedirect;
};

export const makeToolRedirect = (tool, targetRoute) => {
	function toolRedirect(nextState) {
		const state = container.store.getState();
		const { history } = nextState;
		const { appSettings } = state;
		const toolEnabled = appSettings[`access_${tool}`];
		if (toolEnabled) {
			return; // carry on
		}
		log('routeHooks', toolRedirect.displayName, `appSettings[access_${tool}] = ${toolEnabled}`);
		return history.replace({
			pathname: targetRoute,
		});
	}
	toolRedirect.displayName = `toolRedirect(${tool}, ${targetRoute})`;
	return toolRedirect;
};

export const kioskMustBeLoggedIn = makeAuthRedirect(false, routes.KIOSK_DEFAULT_LOGGED_OUT);
export const kioskMustBeAnonymous = makeAuthRedirect(true, routes.KIOSK_DEFAULT_LOGGED_IN);

export const libraryToolMustBeAllowed = makeToolRedirect('library', routes.CUSTOM_CONTACT_LIBRARY);
export const salesToolMustBeAllowed = makeToolRedirect(
	'sales_view',
	routes.CUSTOM_CONTACT_SALES_VIEW,
);
export const planningToolMustBeAllowed = makeToolRedirect(
	'planning_view',
	routes.CUSTOM_CONTACT_PLANNING_VIEW,
);
export const brochureToolMustBeAllowed = makeToolRedirect(
	'brochure_tool',
	routes.CUSTOM_CONTACT_BROCHURE_VIEW,
);
export const tagsToolMustBeAllowed = makeToolRedirect('tags', routes.CUSTOM_CONTACT_TAGS);
export const virtualScreenManagerToolMustBeAllowed = makeToolRedirect(
	'virtual_screen_manager',
	routes.CUSTOM_CONTACT_VIRTUAL_SCREEN_MANAGER,
);

export const agorityMustBeAllowed = makeToolRedirect('agority', routes.CUSTOM_CONTACT_AGORITY);
export const arAppMustBeAllowed = makeToolRedirect('ar_view', routes.CUSTOM_CONTACT_AR_APP);

export const audienceToolMustBeAllowed = makeToolRedirect(
	'audience_view',
	routes.CUSTOM_CONTACT_AUDIENCE,
);
export const productToolMustBeAllowed = makeToolRedirect(
	'product_view',
	routes.CUSTOM_CONTACT_PRODUCT,
);
export const eyeToolMustBeAllowed = makeToolRedirect('eye_view', routes.CUSTOM_CONTACT_EYE);
export const heatmapsToolMustBeAllowed = makeToolRedirect(
	'heatmaps_view',
	routes.CUSTOM_CONTACT_HEATMAPS,
);
export const routeToolMustBeAllowed = makeToolRedirect('route_view', routes.CUSTOM_CONTACT_ROUTE);

export const publishToolMustBeAllowed = makeToolRedirect('publish', routes.CUSTOM_CONTACT_PUBLISH);

export const shopToolMustBeAllowed = makeToolRedirect('shop', routes.CUSTOM_CONTACT_SHOP);

export const viewerToolMustBeAllowed = makeToolRedirect('viewer', routes.CUSTOM_CONTACT_VISION);
export const railToolMustBeAllowed = makeToolRedirect('rail', routes.CUSTOM_CONTACT_RAIL);

export const iotToolMustBeAllowed = makeToolRedirect('iot', routes.CUSTOM_CONTACT_IOT);
export const plannerToolMustBeAllowed = makeToolRedirect('planner', routes.CUSTOM_CONTACT_PLANNER);
export const addAssetToolMustBeAllowed = makeToolRedirect(
	'add_asset',
	routes.CUSTOM_CONTACT_ADD_ASSET,
);
export const iframeAddonsToolMustBeAllowed = makeToolRedirect(
	'iframe_addons',
	routes.CUSTOM_CONTACT_IFRAME_ADDONS,
);

export const tagManagementMustBeAllowed = makeToolRedirect(
	'tag_management',
	routes.CUSTOM_CONTACT_TAG_MANAGEMENT,
);

export const mustBeLoggedIn = makeAuthRedirect(false, routes.DEFAULT_LOGGED_OUT);
export const mustBeAnonymous = makeAuthRedirect(true, routes.DEFAULT_LOGGED_IN);
export const mustBeAnAdmin = makeAuthRedirect(isAdmin, routes.NOT_FOUND);
export const libraryReseter = makeStateReseter(['sites', 'folders', 'library_modal']);
export const flashStorageReseter = makeStateReseter(['flash_storage', 'library_modal']);
export const billboardsStateReseter = makeStateReseter(['billboards']);

export const tagsViewOnEnter = composeRouteHooks([kioskMustBeLoggedIn, tagsToolMustBeAllowed]);
export const socialOnEnterHooks = composeRouteHooks([mustBeLoggedIn, publishToolMustBeAllowed]);
export const libraryOnEnterHooks = composeRouteHooks([mustBeLoggedIn, libraryToolMustBeAllowed]);
export const toolSectionOnEnter = composeRouteHooks([mustBeLoggedIn, salesToolMustBeAllowed]);
export const shopToolOnEnter = composeRouteHooks([mustBeLoggedIn, shopToolMustBeAllowed]);
export const viewerToolOnEnter = composeRouteHooks([mustBeLoggedIn, viewerToolMustBeAllowed]);
export const railToolOnEnter = composeRouteHooks([mustBeLoggedIn, railToolMustBeAllowed]);

export const audienceToolOnEnter = composeRouteHooks([mustBeLoggedIn, audienceToolMustBeAllowed]);
export const productToolOnEnter = composeRouteHooks([mustBeLoggedIn, productToolMustBeAllowed]);
export const eyeToolOnEnter = composeRouteHooks([mustBeLoggedIn, eyeToolMustBeAllowed]);
export const routeToolOnEnter = composeRouteHooks([mustBeLoggedIn, routeToolMustBeAllowed]);
export const heatmapsToolOnEnter = composeRouteHooks([mustBeLoggedIn, heatmapsToolMustBeAllowed]);

export const arAppOnEnter = composeRouteHooks([mustBeLoggedIn, arAppMustBeAllowed]);
export const agorityOnEnter = composeRouteHooks([mustBeLoggedIn, agorityMustBeAllowed]);

export const iotToolOnEnter = composeRouteHooks([mustBeLoggedIn, iotToolMustBeAllowed]);
export const plannerToolOnEnter = composeRouteHooks([mustBeLoggedIn, plannerToolMustBeAllowed]);
export const addAssetToolOnEnter = composeRouteHooks([mustBeLoggedIn, addAssetToolMustBeAllowed]);
export const iframeAddonsToolOnEnter = composeRouteHooks([
	mustBeLoggedIn,
	addAssetToolMustBeAllowed,
]);
export const tagManagementOnEnter = composeRouteHooks([mustBeLoggedIn, tagManagementMustBeAllowed]);
