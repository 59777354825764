import { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { moveSite } from '@/actions/sites';
import * as routes from '@/lib/routes';
import { LIBRARY_ACTIONS, LIBRARY_TYPE } from '@/types/library';
import IconButton from '@/views/widgets/IconButton';

class LibraryActions extends Component {
	openCreateFolderModal = () => {
		const { criteria, folderId } = this.props;
		this.props.createFolder(folderId || criteria.folder_id);
	};

	handleNewAssetClick = () => {
		const { criteria, folderId, history } = this.props;
		const path = generatePath(routes.ADD_ASSET, {
			folderId: folderId || criteria.folder_id,
		});
		history.push(path);
	};

	render() {
		let {
			disableNewAsset,
			disableNewFolder,
			disableUpload,
			disabledActions,
			displayNewFolder,
			displayUpload,
			onUploadClick,
		} = this.props;

		if (disabledActions && !Array.isArray(disabledActions)) {
			disabledActions = [
				LIBRARY_ACTIONS.new_asset,
				LIBRARY_ACTIONS.new_folder,
				LIBRARY_ACTIONS.upload,
			];
		}
		if (disabledActions === false) {
			disabledActions = [];
		}

		disableNewAsset = disableNewAsset || disabledActions.includes(LIBRARY_ACTIONS.new_asset);
		disableNewFolder = disableNewFolder || disabledActions.includes(LIBRARY_ACTIONS.new_folder);
		disableUpload = disabledActions.includes(LIBRARY_ACTIONS.upload);

		return (
			<>
				{displayUpload && (
					<IconButton
						className="sv-LibraryActions-upload"
						disabled={disableUpload}
						onClick={onUploadClick}
					>
						Upload
					</IconButton>
				)}

				<IconButton
					secondary
					className="sv-LibraryActions-newAsset"
					disabled={disableNewAsset}
					onClick={this.handleNewAssetClick}
				>
					New Asset
				</IconButton>

				{displayNewFolder && (
					<IconButton
						secondary
						className="sv-LibraryActions-newFolder"
						disabled={disableNewFolder}
						onClick={this.openCreateFolderModal}
					>
						New Folder
					</IconButton>
				)}
			</>
		);
	}
}

LibraryActions.defaultProps = {
	displayNewFolder: true,
};

LibraryActions.propTypes = {
	createFolder: PropTypes.func,
	displayNewFolder: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, props) => ({
	createFolder: folderId =>
		dispatch(moveSite(undefined, LIBRARY_TYPE.FOLDER, folderId, { namespace: props.namespace })),
});

export default connect(null, mapDispatchToProps)(withRouter(LibraryActions));
