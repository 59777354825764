import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import filesize from 'file-size';
import moment from 'moment';

import FA from '@/types/font_awesome';
import { LIBRARY_COLUMN_HEADER } from '@/types/library';

const CLASS = 'sv-LibraryListView';
const COL_CLASS = classNames(`${CLASS}-row-data`, 'single-line');

function LibraryListViewRow(props) {
	const handleClick = e => {
		const { item, onClick, type } = props;
		onClick && onClick(e, type, item);
	};

	const handleDoubleClick = e => {
		const { item, onDoubleClick, type } = props;
		onDoubleClick && onDoubleClick(e, type, item);
	};

	const toggleSelected = e => {
		const { item, onSelection, selectable, type } = props;
		if (!selectable || !onSelection) {
			return;
		}

		return onSelection && onSelection(e, type, item);
	};

	let {
		className,

		columnNames,

		columns,
		createHref,
		getTypeLabel,
		icon,

		index,
		isFilePreviewable,
		isSelected,
		item,
		items,

		listViewActions,
		onContextMenu,
		onSelection,
		owner,

		record,
		renderHighlight,
		renderOwnerCell,
		renderUrlPathCell,
		selectable,
		selecting,
		type,
		updatePreviewItem,
		...rest
	} = props;

	if (!owner) {
		owner = item.owner || item.company;
	}
	const { disabled } = item;

	const url = createHref(item, index);
	className = classNames(className, isSelected && 'selected');

	const showPreview = isFilePreviewable(item.site?.type);

	return (
		<tr
			{...rest}
			className={className}
			onClick={handleClick}
			onContextMenu={onContextMenu}
			onDoubleClick={handleDoubleClick}
		>
			{columnNames.includes(LIBRARY_COLUMN_HEADER.select) &&
				(selectable || selecting) &&
				onSelection && (
					<td
						className={classNames(COL_CLASS, 'select-checkbox')}
						onClick={!disabled && selectable ? toggleSelected : undefined}
					>
						<FontAwesomeIcon icon={isSelected ? FA.check_square : FA.square_empty} />
					</td>
				)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.name) && (
				<td className={COL_CLASS} onClick={disabled ? undefined : handleClick}>
					<Link
						disabled={disabled}
						title={record.name}
						to={disabled ? undefined : url}
						onClick={handleClick}
					>
						{icon} {renderHighlight(record.name)}
					</Link>
				</td>
			)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.url) &&
				renderUrlPathCell(record.url_path, record.preferred_url_schema, disabled)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.asset) && (
				<td className={COL_CLASS}>{item.asset ? item.asset.name : ''}</td>
			)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.owner) && renderOwnerCell(owner)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.type) && (
				<td className={COL_CLASS}>{getTypeLabel(record.media_format || record.type)}</td>
			)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.modified) && (
				<td className={COL_CLASS}>{moment(record.updated_at).fromNow()}</td>
			)}
			{columnNames.includes(LIBRARY_COLUMN_HEADER.fileSize) && (
				<td className={COL_CLASS}>{record.size ? filesize(record.size).human('jedec') : 'N/A'}</td>
			)}
			{showPreview ? (
				<td className={COL_CLASS}>
					<div className={`${CLASS}-icon-wrapper`}>
						<FontAwesomeIcon
							className={`${CLASS}-icon`}
							icon={FA.eye}
							onClick={e => updatePreviewItem(item, e)}
						/>
					</div>
				</td>
			) : (
				<td />
			)}
			{listViewActions ? (
				<td className={COL_CLASS}>
					<div className={`${CLASS}-icon-wrapper`}>
						<FontAwesomeIcon
							className={`${CLASS}-icon`}
							icon={FA.ellipsis_h}
							onClick={onContextMenu}
						/>
					</div>
				</td>
			) : (
				<td />
			)}
		</tr>
	);
}

export default LibraryListViewRow;
