import { Children } from 'react';
import { MultiMediaViewerLightbox } from '@spinview/shared-components';
import classNames from 'classnames';

import { useSitePreview } from '@/views/hooks/useSitePreview';
import { SmallStretchableSpinner } from '@/views/widgets/StretchableSpinner';

import GridItem from './GridItem';

import './GridView.scss';

const CLASS = 'sv-GridView';

function GridView({
	children,
	className,
	items,
	onItemClick,
	onItemContextMenu,
	onItemDoubleClick,
	onSelection,
	selectable,
	selecting,
}) {
	const {
		initialOpenedPreviewIndex,
		isFilePreviewable,
		previewLightboxItems,
		selectedPreviewItem,
		updatePreviewItem,
	} = useSitePreview(items ?? []);

	if (!items || !items.length) {
		return (
			<div className={classNames(CLASS, className)}>
				<h5>No content available</h5>
			</div>
		);
	}

	const handleItemClick = (index, e) => {
		const item = items[index];
		const handler =
			(selectable === true || selectable === item.type) && selecting ? onSelection : onItemClick;
		if (!item || !handler) {
			return false;
		}
		return handler(item, e);
	};

	const handleItemDoubleClick = (index, e) => {
		const item = items[index];
		if (!item || !onItemDoubleClick) {
			return null;
		}
		return onItemDoubleClick(item, e);
	};

	const handleItemContextMenu = (index, e) => {
		const item = items[index];
		if (!item || !onItemContextMenu) {
			return null;
		}
		return onItemContextMenu(item, e);
	};

	const handleItemSelectionToggle = (index, e) => {
		const item = items[index];
		if (!selectable) {
			return false;
		}
		return onSelection && onSelection(item, e);
	};

	const handleItemPreview = item => {
		updatePreviewItem(item);
	};

	return (
		<div className={classNames(CLASS, className)}>
			{previewLightboxItems.length > 0 && selectedPreviewItem && (
				<MultiMediaViewerLightbox
					isOpen
					initialOpenedIndex={initialOpenedPreviewIndex}
					items={previewLightboxItems}
					viewerComponentProps={{
						LoadingComponent: SmallStretchableSpinner,
					}}
					onClose={() => updatePreviewItem(null)}
				/>
			)}
			{Children.map(children, child => {
				const { disabled, isSelected, item, type, ...rest } = child.props;
				const { index } = rest;
				const selectableType = selectable === true || selectable === type;
				const toggleSelectedHandler =
					disabled || !selectable ? undefined : handleItemSelectionToggle;
				const clickHandler = disabled ? undefined : handleItemClick;
				const doubleClickHandler = disabled ? undefined : handleItemDoubleClick;

				return (
					<GridItem
						key={item.key}
						disabled={disabled}
						index={index}
						isFilePreviewable={isFilePreviewable}
						isSelected={isSelected}
						item={item}
						selectable={selectableType}
						selecting={selecting}
						updatePreviewItem={updatePreviewItem}
						onClick={clickHandler}
						onContextMenu={handleItemContextMenu}
						onDoubleClick={doubleClickHandler}
						onPreview={handleItemPreview}
						onSelection={toggleSelectedHandler}
					>
						{child}
					</GridItem>
				);
			})}
		</div>
	);
}
export default GridView;
