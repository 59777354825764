import PropTypes from 'prop-types';

import { classes } from '@/lib/util';

import './Toolbar.scss';

const CLASS = 'sv-Toolbar';

function Toolbar({ children, className, flat = false }) {
	return <div className={classes(CLASS, className, 'clearfix')}>{children}</div>;
}

Toolbar.propTypes = {
	className: PropTypes.string,
	flat: PropTypes.bool,
};

Toolbar.RIGHT = `${CLASS}-right`;
Toolbar.LEFT = `${CLASS}-left`;

export default Toolbar;
