import * as api from '@/lib/api';
import * as routes from '@/lib/routes';
import { withType } from '@/lib/util';
import { getBackgroundJobs as backgroundJobsSelector } from '@/selectors/background_jobs';
import { getCriteria as getSitesCriteria, getSiteSelector } from '@/selectors/sites';
import { AgendaJob, BACKGROUND_JOB_TYPES } from '@/types/background_jobs';
import { Folder, LIBRARY_NAMESPACE } from '@/types/library';
import { Site, SITE_LABELS } from '@/types/sites';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { avatarStatusUpdated } from './agority_avatars';
import { addToast } from './application';
import { loadSites } from './sites';

/** @typedef {import('../types/background_jobs').RunningJob} RunningJob */
/** @typedef {import('../types/library').FolderWithDetails} FolderWithDetails */

export const getBackgroundJobs = () => (dispatch, getState, container) => {
	const { auth } = getState();
	if (!auth || !auth.uwd || !auth.session) {
		return;
	}

	dispatch(withType(TYPES.BACKGROUND_JOBS_LOAD_START, { loading: true }));

	return api.getBackgroundJobs(container.http).then(res => {
		dispatch(withType(TYPES.BACKGROUND_JOBS_GOT_DATA, { data: res }));
		dispatch(withType(TYPES.BACKGROUND_JOBS_LOAD_END));
	});
};

/** @param {AgendaJob} job */
export const setBackgroundJob = job => {
	return withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job });
};

/**
 * @param {array} ids
 */
export const dismissBackgroundJobs = ids => (dispatch, getState, container) => {
	dispatch(withType(TYPES.BACKGROUND_JOBS_OP_START));

	return api
		.dismissBackgroundJobs(container.http, ids)
		.then(() => {
			dispatch(removeBackgroundJobs(ids));
			return dispatch(withType(TYPES.BACKGROUND_JOBS_OP_END));
		})
		.catch(error => {
			return dispatch(withType(TYPES.BACKGROUND_JOBS_OP_END, { error, ids }));
		});
};

/** @param {AgendaJob & {data: {folder_id?: number; filename?: string;}}} jobAttrs */
export const updateJobProgress = jobAttrs => (dispatch, getState, container) => {
	if (!jobAttrs?.data) {
		return;
	}

	const job = new AgendaJob(jobAttrs);

	const { _id } = job;
	/** @type {Partial<RunningJob> & {folder_id?: number; filename?: string;}} */
	const data = { ...job.data, id: _id };
	const { ended_at, job_title, job_type, percent, record_type, result, started_at } = data;

	let record;

	const state = getState();
	const jobs = backgroundJobsSelector(state);
	const thisJob = jobs && jobs[jobAttrs._id];
	const notified = thisJob ? thisJob.notified : false;
	jobAttrs.data.notified = notified;

	dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job: jobAttrs }));

	const isAllowedJobType = [
		BACKGROUND_JOB_TYPES.clone_items,
		BACKGROUND_JOB_TYPES.compress_items,
	].includes(job_type);
	const isAgorityAvatarJobType = [
		BACKGROUND_JOB_TYPES.agority_upload_avatar,
		BACKGROUND_JOB_TYPES.agority_process_avatar,
	].includes(job_type);

	if (Math.ceil(percent * 100) < 100 || ended_at < started_at) {
		return;
	}
	if (isAgorityAvatarJobType) {
		dispatch(avatarStatusUpdated(jobAttrs));
		return;
	}
	if (!isAllowedJobType || !result || !Array.isArray(result)) {
		return;
	}

	/** @type {number | string} */
	let recordId;
	/** @type {string} */
	let recordName;
	/** @type {string} */
	let location;

	if (result.length === 1) {
		record = result[0];
		recordId = record?.id;
	} else {
		location = routes.folderView(data.folder_id);
		data.location = location;
		// dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, {job: jobAttrs}));
		return;
	}

	if (record_type === 'site') {
		record = new Site(record);
		const siteType = record.type;
		const swd = getSiteSelector(state, { siteId: record.cloned_from_id });
		const sourceName = swd ? swd.site.name : record.cloned_from_id;
		recordName = /** @type {Site} */ (record)?.name || job_title;
		location = routes.siteOverview(record.id);
		data.location = location;
		data.job_title = `${SITE_LABELS[siteType]} | ${record.name}`;

		// dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, {job: jobAttrs}));

		if (!notified) {
			jobAttrs.data.notified = true;
			dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job: jobAttrs }));
			const criteria = getSitesCriteria(state, { criteria: { folder_id: data?.folder_id } });
			dispatch(
				loadSites(criteria, null, {
					invalidateAll: false,
					namespace: LIBRARY_NAMESPACE.view,
				}),
			);
			dispatch(
				addToast(
					new Toast(
						`${SITE_LABELS[siteType]} ${sourceName} successfully cloned into ${
							SITE_LABELS[siteType]
						} ${recordName || recordId}`,
					),
				),
			);
		}

		// container.history.push(location);
	}

	if (record_type === 'folder') {
		record = new Folder(/** @type {FolderWithDetails} */ (record).folder);
		recordName = record.name || job_title;
		location = routes.folderView(record.id);
		data.location = location;
		data.job_title = `Folder | ${record.name}`;
		// dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, {job: jobAttrs}));

		if (!notified) {
			jobAttrs.data.notified = true;
			dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job: jobAttrs }));
			dispatch(
				addToast(
					new Toast(`Folder ${data.folder_id} successfully cloned into ${recordName || recordId}`),
				),
			);
		}

		// container.history.push(location);
	}

	if (record_type === 'prepared_download') {
		location = record ? api.preparedDownloadUrl(record.id) : null;
		data.location = location;
		// dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, {job: jobAttrs}));

		if (!notified) {
			jobAttrs.data.notified = true;
			dispatch(withType(TYPES.SET_OR_UPDATE_BACKGROUND_JOB, { job: jobAttrs }));
			dispatch(addToast(new Toast(`${job_title} successfully compressed into ${data.filename}`)));
			if (location) {
				window.location.href = location;
			}
		}

		// container.history.push(location);
	}
};

/**
 * @param {string|string[]} ids
 */
export const removeBackgroundJobs = ids => {
	if (!Array.isArray(ids)) {
		ids = [ids];
	}
	return withType(TYPES.DELETE_BACKGROUND_JOB, { ids });
};
