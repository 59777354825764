import { get, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { makeGetter, sortByIndexingProperty, takeFromProps } from '@/types/data';
import { LIBRARY_VIEW_SORT_TYPES } from '@/types/library';
import { SITE_TYPES } from '@/types/sites';
import { Bounds, Criteria, DEFAULT_CRITERIA, TOOLS } from '@/types/sub_tours';

import { takeFilterString, takeQuery } from './application';
import { _takeFolderId, getLibraryState } from './folders';
import {
	getChildSiteWithDetailsSelector,
	getCriteria as getSitesCriteria,
	getSitesWithDetailsSelector,
	getSiteWithDetailsSelector,
	takeSiteId,
} from './sites';

export const getState = makeGetter('sub_tours');
export const getData = createSelector(getState, makeGetter('data'));
export const takeTool = takeFromProps('tool');
export const takeTourId = takeFromProps('tourId');
export const takeLocationId = takeFromProps('locationId');
export const takeId = takeFromProps('id');
export const getScenes = makeGetter('scenes');
export const takeHideDisabledContent = createSelector(
	getLibraryState,
	takeFromProps('hideDisabledContent'),
);

export const getDefaultCriteria = createSelector(takeTool, getSitesCriteria, (tool, criteria) => {
	const { with_billboards, with_gps, with_scenes } = criteria;
	const defaultCriteria = DEFAULT_CRITERIA.clone();
	let media_format;
	let type;
	const details = [...DEFAULT_CRITERIA.details];

	return defaultCriteria.update({
		details,
		media_format,
		type,
		with_billboards,
		with_gps,
		with_scenes,
	});
});

const ensureThumbnails = scenes => {
	if (!scenes || !Array.isArray(scenes)) {
		return scenes;
	}
	scenes.forEach(scwd => {
		if (!scwd.thumbnail) {
			scwd.thumbnail = scwd.preview_image || scwd.image;
		}
	});
	return scenes;
};

const getTourWithDetails = state => {
	if (!state || !state.site) {
		return null;
	}
	const { floorplan, image, krpano_tour, locations, scenes, site } = state;
	return {
		floorplan,
		image,
		krpano_tour,
		locations,
		scenes: ensureThumbnails(scenes),
		site,
	};
};

export const getTourSelector = createSelector(getData, takeTourId, get);
export const getTourWithDetailsSelector = createSelector(getTourSelector, getTourWithDetails);

export const getParentTourWithDetailsSelector = createSelector(getState, getTourWithDetails);
export const getChildTourWithDetailsSelector = createSelector(
	takeTourId,
	getTourWithDetailsSelector,
	getChildSiteWithDetailsSelector,
	(tourId, stwd, swd) => {
		if (!swd) {
			return stwd;
		}

		if (!stwd && swd.tour) {
			stwd = swd.tour;
		}
		if (!stwd) {
			return null;
		}
		return {
			...swd,
			...stwd,
		};
	},
);

export const getSortedChildToursWithDetails = createSelector(getData, sortByIndexingProperty);

export const getSitesEnabledToolsSelector = createSelector(getSitesWithDetailsSelector, sites => {
	if (!sites) {
		return null;
	}
	return sites.map(getSiteEnabledTools);
});
export const getSiteEnabledTools = swd => {
	if (!swd) {
		return null;
	}
	const { billboard_count, geo_count, scene_count, site } = swd;
	const { id, parent_id, type } = site;
	const hasScenes = scene_count > 0;
	const hasBillboards = billboard_count > 0;
	// const hasGeoResult = !!parent_id || geo_count > 0;
	const planning_tool = hasScenes;
	const virtual_screens = hasScenes && hasBillboards;
	const brochure_tool = [SITE_TYPES.tour, SITE_TYPES.image, SITE_TYPES.video].includes(type);

	return {
		...swd,
		id,
		site,
		type,
		[TOOLS.planning_tool]: planning_tool,
		[TOOLS.virtual_screens_tool]: virtual_screens,
		[TOOLS.sales_tool]: planning_tool || virtual_screens,
		[TOOLS.brochure_tool]: brochure_tool,
	};
};

export const getSiteEnabledToolsSelector = createSelector(
	getSiteWithDetailsSelector,
	getSiteEnabledTools,
);

function getFiles(sites, folderId, tool = null) {
	if (!sites) {
		return sites;
	}

	let swds = Object.values(sites);
	if (tool) {
		swds = swds.map(swd => {
			return {
				...swd,
				disabled: !swd[tool],
			};
		});
		swds = sortBy(swds, 'disabled');
	}
	if (folderId === undefined) {
		return swds;
	}
	if (folderId) {
		folderId = Number(folderId);
	}
	return swds.filter(swd => swd.site.folder_id === folderId);
}

export const getSearchedFilesSelector = createSelector(
	getSitesEnabledToolsSelector,
	takeTool,
	getFiles,
);
export const searchedFilesSelector = createSelector(
	getSearchedFilesSelector,
	takeHideDisabledContent,
	(siteWithDetails, hideDisabledContent) => {
		if (!siteWithDetails || hideDisabledContent === LIBRARY_VIEW_SORT_TYPES.all) {
			return siteWithDetails;
		}
		return siteWithDetails.filter(swd => !swd.disabled);
	},
);

export const getFilesSelector = createSelector(
	getSitesEnabledToolsSelector,
	_takeFolderId,
	takeTool,
	getFiles,
);

export const filesSelector = createSelector(
	getFilesSelector,
	takeHideDisabledContent,
	(swds, hideDisabledContent) => {
		if (!swds || hideDisabledContent === LIBRARY_VIEW_SORT_TYPES.all) {
			return swds;
		}
		return swds.filter(swd => !swd.disabled);
	},
);

export const getCriteria = createSelector(
	getDefaultCriteria,
	takeQuery,
	takeFromProps('criteria'),
	takeFromProps('sitesCriteria'),
	takeSiteId,
	(defaultCriteria, query, criteria, sitesCriteria, siteId) => {
		if (criteria || sitesCriteria) {
			query = null;
		}
		criteria = new Criteria({ ...defaultCriteria, ...query, ...criteria, ...sitesCriteria });

		if (criteria.details) {
			const index = criteria.details.indexOf('company');
			if (index !== -1) {
				criteria.details[index] = 'owner';
			}
		}
		if (siteId) {
			criteria.filter_exclude_id = siteId;
		}
		return criteria;
	},
);

export const getAttribute = createSelector(
	getParentTourWithDetailsSelector,
	(state, attributeId) => attributeId,
	(ptwd, attributeId) => {
		if (!ptwd) {
			return null;
		}
		const { attributes, values } = ptwd;
		if (!attributes) {
			return null;
		}
		const awd = attributes.find(awd => {
			return awd.attribute_id === Number(attributeId);
		});
		if (awd) {
			awd.values = values ? values.filter(v => v.attribute_id === Number(attributeId)) : [];
		}
		return awd;
	},
);

export const getLocation = createSelector(
	getParentTourWithDetailsSelector,
	(state, locationId) => locationId,
	(ptwd, locationId) => {
		if (!ptwd) {
			return null;
		}
		const { locations, scenes } = ptwd;
		if (!locations) {
			return null;
		}
		let lwd = ptwd.locations.find(lwd => {
			return lwd.location_id === Number(locationId);
		});
		if (lwd) {
			lwd = {
				...lwd,
				scenes: scenes ? scenes.filter(v => v.location_id === Number(locationId)) : [],
			};
		}
		return lwd;
	},
);

export const getAttributesWithValues = createSelector(
	getParentTourWithDetailsSelector,
	(ptwd, valueIds) => valueIds || [],
	(ptwd, valueIds) => {
		if (!ptwd) {
			return [];
		}
		const { attributes, values } = ptwd;
		if (!attributes || !values) {
			return [];
		}
		return attributes.map(awd => {
			const { attribute } = awd;
			const vals = values.filter(v => v.attribute_id === attribute.id);
			const value = vals.find(v => {
				return valueIds.includes(v.id);
			});
			if (value) {
				value.selected = true;
			}
			return {
				attribute,
				selectedValue: value,
				values: vals,
			};
		});
	},
);

function nameSorter(path) {
	return (a, b) => {
		const valueA = get(a, path);
		const valueB = get(b, path);
		let numberPrefixA = parseInt(valueA, 10);
		let numberPrefixB = parseInt(valueB, 10);
		if (Number.isInteger(numberPrefixA) && Number.isInteger(numberPrefixB)) {
			return numberPrefixB - numberPrefixA;
		}
		numberPrefixA = parseFloat(valueA);
		numberPrefixB = parseFloat(valueB);
		if (Number.isNaN(numberPrefixA) || Number.isNaN(numberPrefixA)) {
			return String(valueA).localeCompare(String(valueB));
		}
		return numberPrefixB - numberPrefixA;
	};
}

export const buildLocationTree = createSelector(
	takeFilterString,
	getParentTourWithDetailsSelector,
	getChildTourWithDetailsSelector,
	takeFromProps('selectedSceneIds'),
	takeFromProps('onlySelectedScenes'),
	takeFromProps('selectedMarkerKeys'),
	(filter, ptwd, stwd, selectedSceneIds, onlySelectedScenes, selectedMarkerKeys) => {
		const locationsHash = {};
		const allNodes = {};
		const rootNodes = {};
		let filteredKeys;

		if (!ptwd) {
			return {
				// *: use to render a tree
				allItems: Object.values(allNodes),

				allNodes,

				rootItems: Object.values(rootNodes),
				// *: use allNodes to easily find selected items (markers)
				rootNodes,
			};
		}

		let { floorplan, locations, scenes } = ptwd;
		locations.sort(nameSorter('location.name'));
		scenes.sort(nameSorter('scene.sort'));

		if (onlySelectedScenes && Array.isArray(selectedSceneIds)) {
			scenes = scenes.filter(scwd => selectedSceneIds.includes(scwd.scene_id));
		}
		if (filter) {
			filter = String(filter).trim().split(/\s*/);
			scenes = scenes.filter(scwd => {
				const name = scwd.scene.name.toLowerCase();
				return filter.every(part => name.includes(part));
			});
			filteredKeys = [];
			scenes.forEach(scwd => {
				const { location_id } = scwd;
				if (!filteredKeys.includes(`l-${location_id}`)) {
					filteredKeys.push(`l-${location_id}`);
				}
			});
		}

		let tourNode = null;

		scenes.forEach(buildParents);

		const freeScenes = Object.values(allNodes)
			.map(node => {
				if (node.type === 'l' && !node.parent) {
					return node;
				}
				if (node.type !== 's' || !node.floor_position) {
					return null;
				}
				if (node.parent && node.parent.item.floorplan) {
					return null;
				}

				return node;
			})
			.filter(Boolean);

		if (floorplan) {
			tourNode = {
				children: freeScenes,

				id: 'f',

				item: {
					...ptwd,
				},
				// TODO: this is not actually a location
				key: `l-f`,
				name: ptwd.site.name,
				parent: null,
				parent_id: null,
				position: {
					lat: null,
					lng: null,
				},
				type: 'l',
			};
			allNodes[tourNode.key] = tourNode;
		}

		Object.values(allNodes)
			.sort((a, b) => a.children.length - b.children.length)
			.forEach(node => {
				if (!node.children || (node.position.lat && node.position.lng)) {
					return;
				}
				node.position = Bounds.getCenter(node.children);
			});

		return {
			allItems: Object.values(allNodes),
			allNodes,
			filter,
			filteredKeys,
			rootItems: [tourNode, ...Object.values(rootNodes)].filter(Boolean),
			rootNodes,
			tourNode,
		};

		function findFloorPosition(child, parent) {
			const floorPlanImage = (parent && parent.item.floorplan) || ptwd.floorplan;
			const parentGroup = get(parent, 'krpano_scene_group.name') || 'tour';
			const spotSrc = floorPlanImage === ptwd.floorplan ? 'tour' : parentGroup;
			const mapSpotPoint =
				spotSrc &&
				child.item.hotspots.find(hotspot => {
					return hotspot.source === spotSrc;
				});
			if (mapSpotPoint) {
				child.x = mapSpotPoint.x * 100;
				child.y = mapSpotPoint.y * 100;
			}
			if (child.x === null || child.y === null) {
				return child;
			}

			const x = (child.x / 100) * floorPlanImage.width;
			const y = (child.y / 100) * floorPlanImage.height;
			child.floor_position = floorPlanImage ? { x, y } : child.floor_position;
			return child;
		}

		function buildParents(node, index) {
			const { location, scene } = node;
			let child;
			if (location && locationsHash[location.id]) {
				return locationsHash[location.id];
			}
			if (!scene && location) {
				const { id, lat, lng, name, parent_id } = location;
				child = locationsHash[id] = {
					children: [],
					id,
					item: node,
					name,
					parent: null,
					parent_id,
					position: {
						lat: lat || null,
						lng: lng || null,
					},
					selected: node.selected,
					type: 'l',
				};
			}
			if (scene) {
				const { id, lat, lng, location_id, name, x, y } = scene;
				let selected;
				if (selectedSceneIds) {
					selected = selectedSceneIds.find(_id => {
						return _id === id;
					});
				}
				if (!selected && selectedMarkerKeys) {
					selected = selectedMarkerKeys.find(key => {
						return key === `s-${id}`;
					});
				}
				if (!selected && stwd) {
					selected = stwd.scenes.find(s => s.scene_id === id);
				}
				child = {
					children: [],
					floor_position: null,
					id,
					index,
					item: node,
					name,
					parent: null,
					parent_id: location_id,
					position: {
						lat: lat || null,
						lng: lng || null,
					},
					selected: node.selected || !!selected,
					type: 's',
					x: x || null,
					y: y || null,
				};
			}

			const key = `${child.type}-${child.id}`;
			child.key = key;
			allNodes[key] = child;

			if (child.parent_id) {
				const { parent_id } = child;
				const lwd = locations.find(lwd => lwd.location.id === parent_id);
				if (!lwd) {
					rootNodes[key] = child;
					return child;
				}
				const parent = locationsHash[parent_id] || buildParents(lwd);
				child.parent = parent;

				if (!child.position.lat || !child.position.lng) {
					child.position = parent.position;
				}

				parent.children.push(child);
				if (child.selected) {
					parent.selected = true;
				}

				const floorPlanImage = (parent && parent.item.floorplan) || ptwd.floorplan;
				if (!floorPlanImage || child.floor_position) {
					return child;
				}
				findFloorPosition(child, parent);
			} else {
				rootNodes[key] = child;
			}

			if ((child.x === null || child.y === null) && child.item.hotspots) {
				const parent = child.parent || tourNode;
				findFloorPosition(child, parent);
			}

			return child;
		}
	},
);

export const flattenNodes = (node, callback = null, agg = []) => {
	if (Array.isArray(node)) {
		node.forEach(node => flattenNodes(node, callback, agg));
		return agg;
	}
	if (!node.children || !node.children.length) {
		let value = node;
		if (callback) {
			value = callback(node);
		}
		if (value === null) {
			return agg;
		}
		if (typeof value === 'undefined') {
			value = node;
		}
		agg.push(value);
		return agg;
	}
	node.children.forEach(child => flattenNodes(child, callback, agg));
	return agg;
};

export const getProductionTour = createSelector(getSiteWithDetailsSelector, swd => {
	if (!swd || !swd.metadata || !swd.metadata.length) {
		return null;
	}
	return swd.metadata.find(twd => twd.tour.version_id === swd.production_version.id);
});

function findBillboards(twd, swd, tourId, stwd) {
	if (!twd || !swd || (!tourId && !swd.billboards)) {
		return [];
	}
	if (tourId && (!stwd || !stwd.billboards)) {
		return [];
	}
	let billboards = tourId && stwd ? stwd.billboards : swd.billboards;
	const billboardsHash = {};
	let { hotspots = [] } = twd;
	const scenes =
		stwd && stwd.scenes
			? stwd.scenes.map(scwd => ({
					...scwd,
					scene: scwd.krpano_scene,
					scene_id: scwd.krpano_scene_id,
			  }))
			: twd.scenes;
	if (!hotspots.length) {
		hotspots = [];
		swd.tour.scenes.forEach(scwd => {
			scwd.hotspots.forEach(hotspot => {
				if (hotspot.billboard_id) {
					hotspots.push(hotspot);
				}
			});
		});
	}
	hotspots.forEach(hotspot => {
		const krpano_scene = scenes.find(krscwd => {
			return krscwd.scene_id === hotspot.scene_id;
		});

		if (!krpano_scene) {
			return null;
		}
		let bwd = swd.billboards.find(bwd => {
			return bwd.billboard.id === hotspot.billboard_id;
		});

		if (!bwd) {
			return null;
		}
		if (stwd) {
			bwd = billboards.find(({ billboard }) => billboard.key === bwd.billboard.key);
			if (!bwd) {
				return null;
			}
		}

		const { billboard } = bwd;
		const { selected_type } = billboard;
		const thumbnail = bwd[selected_type] || bwd.image;

		const hashKey = `${hotspot.scene_id}-${billboard.id}`;
		if (billboardsHash[hashKey]) {
			return null;
		}

		billboardsHash[hashKey] = {
			...billboard,
			hotspot,
			krpano_scene,
			scene_id: hotspot.scene_id,
			thumbnail,
			type: selected_type || 'image',
		};
	});
	billboards = Object.values(billboardsHash);
	return sortBy(billboards, ['order_index', 'name']);
}

export const findBillboardsSelector = createSelector(
	createSelector(s => s, takeSiteId, getProductionTour),
	getSiteWithDetailsSelector,
	takeTourId,
	getChildTourWithDetailsSelector,
	findBillboards,
);
