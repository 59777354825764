import { withType } from '@/lib/util';

import * as TYPES from './action_types';

export const dismissConnectedModal = id => {
	return withType(TYPES.CONNECTED_MODAL_DISMISS, { id });
};

export const addConnectedModal = modal => {
	return withType(TYPES.CONNECTED_MODAL_ADD, { modal });
};
