import { Component } from 'react';
import PropTypes from 'prop-types';

import { classes } from '@/lib/util';
import { SITE_TYPES, siteTypeLabel } from '@/types/sites';
import Select from '@/views/widgets/input/Select';

import SiteIcon from './SiteIcon';

import './SiteTypePicker.scss';

const OPTIONS = Object.values(SITE_TYPES).sort();

class SiteTypePicker extends Component {
	static propTypes = {
		className: PropTypes.string,
		clearable: PropTypes.bool,
		disableSiteTypePicker: PropTypes.bool,
		onSelect: PropTypes.func,
		pluralize: PropTypes.bool,
		siteType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	};

	static defaultProps = {
		disableSiteTypePicker: false,
	};

	constructor(props) {
		super(props);

		this.renderOption = this.renderOption.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	renderOption(option) {
		if (!option) {
			return null;
		}

		return (
			<div className="sv-SiteTypePicker-option">
				<SiteIcon siteType={option.value} />

				<span className="sv-SiteTypePicker-option-title">{option.label}</span>
			</div>
		);
	}

	onSelect(option) {
		const { multi, onSelect } = this.props;

		if (onSelect) {
			if (multi) {
				const options = [];
				Object.keys(option).map(o => (option[o] ? options.push(option[o].value) : null));
				return this.props.onSelect(options);
			}
			this.props.onSelect(option ? option.value : null);
		}
	}

	optionCreator = siteType => {
		const { pluralize } = this.props;
		const label = siteTypeLabel(siteType, false, pluralize);
		return {
			label,
			value: siteType,
		};
	};

	render() {
		const { className, clearable, disableSiteTypePicker, onSelect, pluralize, ...passProps } =
			this.props;

		const options = OPTIONS.map(this.optionCreator);

		const siteType = Array.isArray(this.props.siteType)
			? this.props.siteType.map(this.optionCreator)
			: this.props.siteType;

		return (
			<Select
				async={false}
				className={classes('sv-SiteTypePicker', className, 'select-picker')}
				clearable={clearable}
				disabled={disableSiteTypePicker}
				options={options}
				value={siteType}
				{...passProps}
				optionRenderer={this.renderOption}
				valueRenderer={this.renderOption}
				onChange={this.onSelect}
			/>
		);
	}
}

export default SiteTypePicker;
