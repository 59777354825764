import axios from 'axios';

const get = require('lodash/get');
const merge = require('lodash/merge');
const reduce = require('lodash/reduce');

const MANDATORY_KEYS = [
	'api.base_url',
	'asset_router.hostname',
	'app_url',
	'stripe.publishable_key',
	'static_assets.base_url',
];

const COPYRIGHT_TO_DATE = new Date().getFullYear();

export const DEFAULT_THEME = 'spinview';

const ENV = {
	CESIUM_TOKEN:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlOGYzMWU5Yi1mNjA2LTQ4MjQtYTU0OS04MDk3MTA1ODU3MWUiLCJpZCI6NDU3NTIsImlhdCI6MTYxNzI3MjE2OX0.Q3ZiO8F-29zDQV4I04Ks0eqwjJIfcUXXG87F2pMUfOI',

	GOOGLE_PHOTOREALISTIC_TILES_URL:
		'https://tile.googleapis.com/v1/3dtiles/root.json?key=AIzaSyBQ7Wj99aTxRqET-22qYWGFcDCWgVDt89A',
	MICROSOFT_ATLAS_BASE_URL: 'https://atlas.microsoft.com/map/',
	NODE_ENV: process.env.NODE_ENV,

	OSM_BUILDING_TILES_URL:
		'https://assets.ion.cesium.com/us-east-1/asset_depot/96188/OpenStreetMap/CWT/2024-03-04/tileset.json?v=17',

	PUBLIC_URL: process.env.PUBLIC_URL,

	RESELECT_TOOLS_ENABLED: false,
	ROLLBAR_API_KEY: 'ada595899ae04258914f8d81cdc7e910',

	THEME: process.env.THEME || DEFAULT_THEME,

	TILE_API_AUTH: {
		CLIENT_ID: 'ee92b6c4-75c5-4116-8dd1-a8c25588a671',
		KEY: 'e5UrnV9V0o9pk-bqRqWhTTWrYEQXXFhcomZkNxconTc',
		KEY_FIELD: 'subscription-key',
	},

	TILE_ATTRIBUTION: '',

	TILE_RETINA_SUPPORTED: false,

	TILE_SUBDOMAINS: ['a', 'b', 'c'],

	TILE_URL:
		'https://atlas.microsoft.com/map/tile/png?api-version=1.0&layer=hybrid&style=main&zoom={z}&x={x}&y={y}',

	TITLE: process.env.TITLE || 'Spinview',

	// Show both register and login tabs on the home screen
	allow_register: false,

	api: {
		auth_header: 'Authorization',
		base_url: 'https://api.dev.spinview.io/api/v1',
		session_cookie: 'session',
	},

	app_url: 'http://localhost:3000',

	asset_router: {
		default_schema: 'https',
		hostname: 'vr.dev.spinview.io',
	},

	billboard_media_prefix: 'billboard_',

	/**
	 * false = no sales tool
	 * true = enable sales tool
	 */

	company: {
		cookie_policy: 'https://spinview.io/cookie-policy/',
		copyright: `Copyright 2015-${COPYRIGHT_TO_DATE}`,
		copyright_details: 'Copyright Spinview Global Ltd. All Rights Reserved',
		email: 'info@example.com',
		email_title: null,
		phone: '+123456789',
		privacy_policy: 'https://spinview.io/privacy/ ',
		register_link: 'https://spinview.io/register/',
		terms_and_conditions: 'https://spinview.io/customer-terms-and-conditions/',
		title: 'Company',
	},

	currency: 'gbp',

	custom_brochure_naming: {
		choose_template_brochure: 'Choose a template for report',
		default_title: 'Inserting content on report',
		delete_brochure: 'Delete report',
		details_brochure: 'Overview or edit report',
		edit_brohure: 'Edit report',
		go_back: 'Leaving reports',
		go_back_cancel: 'Exit without saving',
		go_back_confirm: 'Save and Exit',
		go_back_title:
			'You have unsaved work on this report. Are you sure you want to leave this page?',
		name_dialog: 'Name report',
		new_brochure: 'New report',
		notification_type: 'Report',
		route_name: 'Reports',
		save_dialog: 'Save report',
		save_name_dialog: 'Report name',
		search_brochure: 'Search report',
		section_title_holder: 'Select a section and build your report',
		site_labels: 'Report',
	},

	date_format: {
		locale: 'en-gb',
		long: 'LLLL',
		medium: 'DD/MM/Y [at] HH:mm a',
		short: 'DD/MM/Y',
		timeonly: 'HH:mm a',
		timezone: '450',
	},

	/** @type {[number, number]} */
	default_map_center: [51.517654, -0.1235],

	/* eslint-disable sort-keys-fix/sort-keys-fix */
	default_menu_items: {
		library: {
			title: 'Library',
			access_allowed: true,
			should_render: true,
		},
		tools: {
			title: 'Tools',
			// renderPerChild: true,
			children: {
				brochure_view: {
					title: 'Brochure View',
					access_allowed: true,
					should_render: true,
				},
				tag_view: {
					title: 'Tag View',
					access_allowed: true,
					should_render: false,
				},
				sales_view: {
					title: 'Sales View',
					access_allowed: true,
					should_render: true,
				},
				virtual_screen_manager: {
					title: 'Virtual Screen Manager',
					access_allowed: true,
					should_render: true,
				},
				vision: {
					title: 'Vision',
					access_allowed: true,
					should_render: true,
				},
				rail: {
					title: 'Vision - Rail',
					access_allowed: true,
					should_render: true,
				},
			},
		},
		apps: {
			title: 'Apps',
			children: {
				ar_view: {
					title: '+AR View',
					access_allowed: true,
				},
				agority: {
					title: 'Agority',
					access_allowed: true,
				},
			},
		},
		insights: {
			title: 'Insights',
			children: {
				audience_view: {
					title: 'Audience View',
					access_allowed: true,
				},
				product_view: {
					title: 'Product View',
					access_allowed: true,
				},
				eye_view: {
					title: 'Eye View',
					access_allowed: true,
				},
				heatmaps_view: {
					title: 'Heatmaps View',
					access_allowed: true,
				},
				route_view: {
					title: 'Route View',
					access_allowed: true,
				},
			},
		},
		publish: {
			title: 'Publish',
			access_allowed: true,
		},
		shop: {
			title: 'Enquiries',
			access_allowed: true,
		},
	},

	access_allowed_sections: {
		add_asset: { access_allowed: true },
		agority: { access_allowed: false },
		audience_view: { access_allowed: false },
		brochure_tool: { access_allowed: false },
		heatmaps_view: { access_allowed: false },
		iframe_addons: { access_allowed: true },
		iot: { access_allowed: true },
		library: { access_allowed: true },
		planner: { access_allowed: false },
		sales_view: { access_allowed: false },
		tags: { access_allowed: false },
		virtual_screen_manager: { access_allowed: false },
		viewer: { access_allowed: true },
		tag_management: { access_allowed: true },
		shop: { access_allowed: true },
	},

	/* eslint-enable sort-keys-fix/sort-keys-fix */
	dev: false,

	devServer: {
		disableHostCheck: true,
		host: 'localhost',
		stats: {
			assets: false,
			cachedAssets: false,
			children: false,
			chunkModules: false,
			chunkOrigins: false,
			chunks: false,
			chunksSort: false,
			entrypoints: false,
			errorDetails: false,
			modules: false,
		},
		watchOptions: {
			aggregateTimeout: 1600,
		},
	},

	/**
	 * Values are:
	 * wizard: generic, old wizard
	 * gfk: GFK order form
	 * panoskin: Panoskin google upload form, with payment
	 */
	enquiry_system: 'wizard',

	environment_sensor_data_asset_ids: [139, 141, 195],

	favicon: 'favicon-spinview.png',

	image_store_url: 'https://images.spinviewglobal.com',

	// for hiding search during development
	kiosk_search_should_render: {
		cesium: false,
		rail: false,
	},

	log: {
		contexts: {
			action: false,
			api: false,
			reducer: false,
			sockets: false,
			store: false,
		},
		enabled: false,
	},

	logo_image: 'logo-spinview.png',

	// ACC MENU ITEMS
	my_acc: {
		admin_users: {
			name: null,
			shouldRender: true,
		},
		companies: {
			name: null,
			shouldRender: true,
		},
		console: {
			name: null,
			shouldRender: true,
		},
		manager_users: {
			name: null,
			shouldRender: true,
		},
		my_account: {
			name: null,
			shouldRender: true,
		},
		orders: {
			name: null,
			shouldRender: true,
		},
		referals: {
			name: null,
			shouldRender: true,
		},
		reports: {
			name: null,
			shouldRender: true,
		},
	},

	page_size: 50,

	platform_restrictions: {
		canCloneTours: true,
		canSee360Objects: true,
		canSeeImages: true,
		canSeeOtherTours: true,
		canSeeTours: true,
		canSeeUnityTours: true,
		canSeeVideos: true,
	},

	/* Enable this on custom whitelabels */
	powered_by_notice: false,

	primary_countries: ['GB', 'SE'],

	// REGISTER LINK
	register_link: false,

	reports: {
		browser: {
			chart: 13,
			table: 6,
		},
		device: {
			chart: 16,
			table: 4,
		},
		geo: {
			chart: 15,
			table: 3,
		},
		os: {
			chart: 14,
			table: 7,
		},
		platform: {
			chart: 12,
			table: 5,
		},
		referral: {
			chart: 11,
			table: 8,
		},
		traffic: {
			chart: 10,
			table: 2,
		},
	},

	show_lightbox: false,

	show_prices: false,

	show_processes: true,

	site_details_context_menu_skip: [],

	// SiteDetails Should render naming
	site_details_tabs: {
		billboards: {
			name: null,
			should_render: true,
		},
		brochure: {
			name: null,
			should_render: true,
		},
		details: {
			name: null,
			should_render: true,
		},
		files: {
			name: null,
			should_render: true,
		},
		insights: {
			name: null,
			should_render: true,
		},
		project_file: {
			name: null,
			should_render: true,
		},
		stats: {
			name: null,
			should_render: true,
		},
		subtours: {
			name: null,
			should_render: true,
		},
		virtual_screens_subtour: {
			name: null,
			should_render: true,
		},
		virtual_screens_tour: {
			name: null,
			should_render: true,
		},
	},

	static_assets: {
		base_url: 'https://static-hosting.spinviewglobal.com/',
	},

	static_video_url: 'https://vr.dev.spinview.io',

	stripe: {
		publishable_key: 'pk_test_suqwxitVV8pzlMMJ4LPdU3An',
	},

	subscription_currency: '£',

	subscription_vat: 20,

	tag_sensor_data_company_ids: [877, 865],

	tag_sensor_data_enabled: true,

	version_checker: {
		postpone_reload_interval: 30 * 1000,
		update_interval: 60 * 1000,
	},

	vision_settings: {
		isMapFull: true,
		isNavCollapsable: true,
		isNavTransparent: true,
		isVision: true,
		type: null,
		// all enabled or a list of enabled issue types ['crosswalk', 'cracking', 'signage', 'steelcovers', 'barriers', 'overhead', 'refuse', 'pothole', 'deformation', 'other'],
		viewer_download_enabled: true,
	},

	visuals: {
		agority_device_image_height: 40,
		agority_device_image_width: 40,
		company_image_height: 150,
		company_image_width: 150,
		company_inline_height: 22,
		company_inline_width: 22,
		company_thumbnail_height: 50,
		company_thumbnail_width: 50,
		company_tiny_height: 35,
		company_tiny_width: 35,

		floor_preview_height: 200,
		floor_preview_width: 400,
		max_lightbox_width: 1920,
		preview_image_max_height: 540,

		preview_image_max_width: 960,
		product_image_height: 300,
		product_image_width: 300,
		product_thumbnail_height: 50,
		product_thumbnail_width: 50,
		product_tiny_height: 25,
		product_tiny_width: 25,
		site_inline_height: 22,

		site_inline_width: 22,
		site_preview_height: 200,
		site_preview_width: 300,
		site_thumbnail_height: 100,

		site_thumbnail_height_big: 134,
		site_thumbnail_height_small: 50,
		site_thumbnail_width: 150,
		site_thumbnail_width_big: 200,

		site_thumbnail_width_small: 50,
		social_account_small_height: 20,

		social_account_small_width: 20,

		social_account_thumbnail_height: 30,
		social_account_thumbnail_width: 30,

		user_image_height: 150,
		user_image_width: 150,

		user_thumbnail_height: 50,
		user_thumbnail_width: 50,
	},
};

ENV.dev = ENV.NODE_ENV === 'development';

export const overrideEnv = async () => {
	try {
		const [envOverrides, envOverridesLocal] = await Promise.all([
			axios({ url: '/env.overrides.json' }),
			axios({ url: '/env.overrides.local.json' }),
		]);
		merge(ENV, envOverrides.data, envOverridesLocal.data);
		document.body.classList.add(`whitelabel-${ENV.THEME}`);
	} catch (e) {
		console.error(e);
	}
};

const { TILE_API_AUTH } = ENV;
const { KEY: TILE_API_KEY, KEY_FIELD: TILE_API_KEY_FIELD } = TILE_API_AUTH || {};
const TILE_AUTH_STR =
	(ENV.TILE_URL.includes('?') ? '&' : '?') +
	(TILE_API_AUTH ? `${TILE_API_KEY_FIELD}=${TILE_API_KEY}` : '');
ENV.TILE_URL += TILE_AUTH_STR;

// Validate that ENV is correct

const missingKeys = reduce(
	MANDATORY_KEYS,
	(missing, key) => {
		const value = get(ENV, key);
		if (value === undefined || value === null) {
			missing = missing || [];
			missing.push(key);
		}
		return missing;
	},
	null,
);

if (missingKeys) {
	throw new Error(`The following values are missing from your env.js: ${missingKeys.join(', ')}`);
}

export default ENV;
