import * as TYPES from '@/actions/action_types';
import { LIBRARY_NAMESPACE } from '@/types/library';

import ReduceHelper from './reduce_helper';

const MODAL_NAMESPACE = LIBRARY_NAMESPACE.modal;

const INITIAL_STATE = /** @lends State.prototype */ {
	agorityDeviceManagement: {
		[MODAL_NAMESPACE]: {
			operation: null, // move, delete, etc
		},
		data: [],
		error: null,
		loading: true,
		masterKey: null,
		newDeviceDetails: {},
		ownerList: [],
		showEditModal: false,
		showMasterKeyModal: false,
	},
};

const helper = new ReduceHelper('agorityDeviceManagement', 'id');

const modalHelper = new ReduceHelper(`${'agorityDeviceManagement'}.${MODAL_NAMESPACE}`, 'id');

const setDeviceMgmtObj = (state, action) => {
	return helper.updateNamespace(state, {
		data: action.data,
	});
};

const setMasterKey = (state, action) => {
	return helper.updateNamespace(state, {
		masterKey: action.data,
	});
};

const setNewDeviceDetail = (state, action) => {
	return helper.updateNamespace(state, {
		newDeviceDetails: action.data,
	});
};

const setDeviceOwnerList = (state, action) => {
	return helper.updateNamespace(state, {
		ownerList: action.data,
	});
};

const setCustomerDeviceOwnerList = (state, action) => {
	const formattedData = {};
	const formattedArray = [];
	formattedArray.push(action.data);
	formattedData.data = formattedArray;
	return helper.updateNamespace(state, {
		ownerList: formattedData,
	});
};

function deviceModalControl(state, action) {
	return modalHelper.updateNamespace(state, action);
}

function closeDeviceModal(state) {
	return modalHelper.updateNamespace(state, INITIAL_STATE.agorityDeviceManagement[MODAL_NAMESPACE]);
}

export default {
	INITIAL_STATE,
	[TYPES.AGORITY_DEVICE_LOAD_START]: helper.loadStart,
	[TYPES.AGORITY_DEVICE_LOAD_END]: helper.loadEnd,
	[TYPES.AGORITY_DEVICE_OP_START]: helper.opStart,
	[TYPES.AGORITY_DEVICE_OP_END]: helper.opEnd,
	[TYPES.AGORITY_DEVICE_OP_RESET]: helper.opReset,
	[TYPES.DEVICE_GOT_DATA]: setDeviceMgmtObj,
	[TYPES.DEVICE_MASTER_KEY]: setMasterKey,
	[TYPES.DEVICE_NEW_DETAIL]: setNewDeviceDetail,
	[TYPES.DEVICE_OWNER_LIST]: setDeviceOwnerList,
	[TYPES.CUSTOMER_DEVICE_OWNER_LIST]: setCustomerDeviceOwnerList,
	[TYPES.OPEN_DEVICE_MODAL]: deviceModalControl,
	[TYPES.CLOSE_DEVICE_MODAL]: closeDeviceModal,
};
