import { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { SITE_ICONS, SITE_LABELS } from '@/types/sites';
import Image from '@/views/widgets/images/Image';
import FormattedDate from '@/views/widgets/presentation/FormattedDate';

import './File.scss';

const CLASS = 'sv-File';

export default class File extends Component {
	render() {
		let {
			className,
			createHref,
			disabled,
			index,
			isSelected,
			item,
			onClick,

			onContextMenu,
			onSelection,
			record,
			selectable,
			selecting,
			...rest
		} = this.props;

		const { image } = item;
		const { created_at, media_format, name, type } = record;
		const fileType = media_format || type;
		const typeIcon = SITE_ICONS[fileType];
		const typeIconTitle = SITE_LABELS[fileType] || fileType;
		const url = !disabled && createHref ? createHref(item, index) : undefined;

		className = classNames(className, CLASS, disabled && `${CLASS}-disabled`);

		return (
			<Link
				className={className}
				title={name}
				to={url}
				onClick={disabled ? null : onClick}
				onContextMenu={onContextMenu}
				{...rest}
			>
				<div className="sv-Image-container">
					<Image image={image} width={188} />
					{typeIcon ? (
						<FontAwesomeIcon
							className={`${CLASS}-icon-type`}
							icon={typeIcon}
							title={typeIconTitle}
						/>
					) : null}
				</div>
				<div className={`${CLASS}-description`}>
					<span className={`${CLASS}-description-name`} title={name}>
						{name}
					</span>
					{created_at && (
						<div className={`${CLASS}-description-uploaded`}>
							<span>Uploaded</span>
							<FormattedDate humanize date={created_at} />
						</div>
					)}
				</div>
			</Link>
		);
	}
}
