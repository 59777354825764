import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	tracker: {
		event: null,
		instanceId: null,
		loadError: null,
		loading: null,
		op: null,
	},
};

const helper = new ReduceHelper('tracker', 'tracker.instance_id');

const gotData = (state, data) => {
	return {
		...state,
		tracker: {
			...state.tracker,
			instanceId: data.instance_id,
		},
	};
};

export default {
	INITIAL_STATE,
	[TYPES.TRACKER_GOT_DATA]: gotData,
	[TYPES.TRACKER_OP_START]: helper.opStart,
	[TYPES.TRACKER_OP_END]: helper.opEnd,
};
