import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	brochures: {
		brochure: null,
		data: null,
		loadError: null,
		loading: null,
		nextPage: null,
		op: null,
		section: null,
	},
};

const gotData = (state, brochure) => {
	return {
		...state,
		brochures: {
			...state.brochures,
			brochure,
		},
	};
};

const loadStart = (state, action) => {
	const newState = {
		...state.brochures,
		loading: true,
	};
	if (action.reset) {
		newState.brochure = null;
	}
	return { ...state, brochures: newState };
};

const brochureFromSection = (state, section) => {
	return {
		...state,
		brochures: {
			...state.brochures,
			section,
		},
	};
};

const helper = new ReduceHelper('brochures', 'site.id');

export default {
	INITIAL_STATE,
	[TYPES.BROCHURE_GOT_DATA]: gotData,
	[TYPES.BROCHURE_FROM_SECTION]: brochureFromSection,
	[TYPES.BROCHURE_SITES_GOT_DATA]: helper.gotData,
	[TYPES.BROCHURE_SITES_LOAD_START]: [loadStart, helper.loadStart],
	[TYPES.BROCHURE_SITES_LOAD_END]: helper.loadEnd,
	[TYPES.BROCHURE_SITES_OP_START]: helper.opStart,
	[TYPES.BROCHURE_SITES_OP_END]: helper.opEnd,
	[TYPES.BROCHURE_SITES_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
};
