import { shallowEqual } from 'react-redux';

import * as TYPES from '@/actions/action_types';
import ENV from '@/env';
import { PlatformRestrictions } from '@/types/application';

/** @typedef {import('../types/application').RouterLocation} RouterLocation */

/** @typedef {typeof INITIAL_STATE} State */
const INITIAL_STATE = /** @lends State.prototype */ {
	appRawSettings: { data: null },

	/**
	 * List of platformwide settings
	 * */
	appSettings: null,

	contentBounds: {
		content: {
			bottom: null,
			height: null,
			left: null,
			right: null,
			top: null,
			width: null,
		},
	},

	/**
	 * Current location. Used internally. The same as history.location
	 * @type {RouterLocation}
	 * */
	currentLocation: null,

	// Used for testing
	echo: null,

	// We show full screen error and user is forced to reload
	fatalError: null,

	// We don't render anything until this is true
	initialized: false,

	// Whether we are in kiosk mode. Influences some actions. Determined during initialize(), based on the URL
	kioskMode: false,

	// Kiosk Root Path i.e. /kiosk/vision used for dynamically settings routes throught kiosk applications
	kioskRoot: '',

	// Kiosk top nav bar transparency style
	kioskTopNavIsTransparent: null,

	// Controls whether side menu is open when browsing the app on smaller screens
	mobileMenuOpen: false,

	/**
	 * List of platformwide restrictions to control rendering of platform sections
	 * @type PlatformRestrictions
	 * */
	platformRestrictions: new PlatformRestrictions(ENV.platform_restrictions),

	/**
	 * Previous router location. Useful for going back to where you came from
	 * @type {RouterLocation}
	 * */
	previousLocation: null,

	// Used as a signal for refresh page action
	refreshing: false,

	// Dynamic titles set by routes. They are shown in the page breadcrumbs at top
	routeTitles: {},

	// API version, initial and current
	version: {},
};

const setKioskMode = (state, action) => {
	return { ...state, kioskMode: action.kioskMode };
};

const setKioskRoot = (state, { root }) => {
	return {
		...state,
		kioskRoot: root,
	};
};

const setKioskTopNavTransparency = (state, { transparency }) => {
	return {
		...state,
		kioskTopNavIsTransparent: transparency,
	};
};

const initialized = state => {
	return { ...state, initialized: true };
};

const fatalError = (state, action) => {
	return { ...state, fatalError: action.error };
};

const setRouteTitle = (state, action) => {
	if (state.routeTitles[action.name] === action.title) {
		return state;
	}
	const newRouteTitles = { ...state.routeTitles };
	newRouteTitles[action.name] = action.title;
	return { ...state, routeTitles: newRouteTitles };
};

const refreshing = (state, action) => {
	return { ...state, refreshing: action.refreshing };
};

const versionUpdate = (state, action) => {
	const version = { ...state.version };
	if (!version.initial) {
		version.initial = action.version;
	} else {
		if (version.current === action.version) {
			return state;
		}
		version.current = action.version;
	}

	return { ...state, version };
};

const pushAlert = (state, action) => {
	const toasts = { ...state.toasts };
	toasts[action.toast.id] = action.toast;
	return { ...state, toasts };
};

const dismissAlert = (state, action) => {
	if (state.toasts && state.toasts[action.id]) {
		const toasts = { ...state.toasts };
		delete toasts[action.id];
		return { ...state, toasts };
	}
	return state;
};

const toggleMobileMenu = (state, action) => {
	return { ...state, mobileMenuOpen: !state.mobileMenuOpen };
};

/**
 * @param {State} state
 * @param {{location: RouterLocation}} location
 */
const navigateToLocation = (state, { location }) => {
	const { currentLocation } = state;

	if (
		!location ||
		(currentLocation &&
			location.pathname === currentLocation.pathname &&
			shallowEqual(location.state, currentLocation.state))
	) {
		// Only track unique changes
		return state;
	}

	return {
		...state,
		currentLocation: location,
		previousLocation: currentLocation,
	};
};

const setAppSettings = (state, action) => {
	const settings = typeof action.data === 'object' ? action.data : null;
	const allowedTools = {};
	for (const item in ENV.access_allowed_sections) {
		const obj = ENV.access_allowed_sections[item];
		if (obj.access_allowed !== undefined) {
			Object.assign(allowedTools, {
				[`access_${item}`]: obj.access_allowed,
			});
		} else {
			for (const children in obj.children) {
				const child = obj.children[children];
				Object.assign(allowedTools, {
					[`access_${children}`]: child.access_allowed,
				});
			}
		}
	}
	Object.assign(settings, allowedTools);
	return { ...state, appSettings: settings };
};

const setRawAppSettings = (state, action) => {
	const settings = typeof action.raw === 'object' ? { data: action.raw } : null;
	return { ...state, appRawSettings: settings };
};

const initializeRollbarTracking = (state, action) => {
	const { session } = action;
	const { cwds, user, ...uwd } = action.uwd;

	// @ts-ignore
	if (window.Rollbar) {
		const companies =
			cwds &&
			Array.isArray(cwds) &&
			cwds
				.map(cwd => {
					return ['id', 'name', 'parent_id', 'grandparent_id', 'greatgreat_grandparent_id']
						.map(k => cwd.company[k])
						.filter(Boolean)
						.join(', ');
				})
				.join('\n');

		// @ts-ignore
		window.Rollbar.configure({
			payload: {
				custom: {
					companies,
					uwd,
				},
				person: user ? { ...user } : {},
				session,
				whitelabel: ENV.THEME,
			},
		});
	}

	return state;
};

const setContentBounds = (state, action) => {
	return {
		...state,
		contentBounds: {
			...state.contentBounds,
			[action.namespace]: action.bounds,
		},
	};
};

export default {
	INITIAL_STATE,
	[TYPES.INITIALIZED]: initialized,
	[TYPES.FATAL_ERROR]: fatalError,
	[TYPES.OP_RESET_ALL]: fatalError,
	[TYPES.SET_KIOSK_MODE]: setKioskMode,
	[TYPES.SET_KIOSK_ROOT]: setKioskRoot,
	[TYPES.SET_KIOSK_TOP_NAV_TRANSPARENCY]: setKioskTopNavTransparency,
	[TYPES.SET_ROUTE_TITLE]: setRouteTitle,
	[TYPES.REFRESHING]: refreshing,
	[TYPES.APP_VERSION_UPDATE]: versionUpdate,
	[TYPES.TOAST_ADD]: pushAlert,
	[TYPES.TOAST_DISMISS]: dismissAlert,
	[TYPES.TOGGLE_MOBILE_MENU]: toggleMobileMenu,
	[TYPES.NAVIGATE_TO_LOCATION]: navigateToLocation,
	[TYPES.GET_APP_SETTINGS_END]: setAppSettings,
	[TYPES.SET_APP_SETTINGS_END]: setAppSettings,
	[TYPES.GET_RAW_APP_SETTINGS_END]: setRawAppSettings,
	[TYPES.INITIALIZE_ROLLBAR_TRACKING]: initializeRollbarTracking,
	[TYPES.SET_CONTENT_BOUNDS]: setContentBounds,
};
