import { createElement } from 'react';
import * as CountryIcons from 'country-flag-icons/react/3x2';
import { concat, forEach, sortedIndexBy } from 'lodash';
import PropTypes from 'prop-types';

import ENV from '@/env';
import COUNTRIES from '@/types/COUNTRIES.json';

import Select from './Select';

import './CountryPicker.scss';

const topOptions = [];
let options = [];
forEach(COUNTRIES, (name, code) => {
	const option = { label: name, value: code };
	const topOptionIndex = ENV.primary_countries.indexOf(code);
	if (topOptionIndex >= 0) {
		topOptions[topOptionIndex] = option;
	} else {
		options.splice(sortedIndexBy(options, option, 'label'), 0, option);
	}
});

options = concat(topOptions, options);

const renderOption = option => {
	const flagIcon = createElement(CountryIcons[option.value], {});
	return (
		<div className="sv-CountryPicker-item">
			{flagIcon}
			<span>{option.label}</span>
		</div>
	);
};

function CountryPicker({ country, onSelect, ...props }) {
	return (
		<Select
			className="sv-CountryPicker"
			optionRenderer={renderOption}
			options={options}
			value={country}
			valueRenderer={renderOption}
			onChange={onSelect}
			{...props}
		/>
	);
}

CountryPicker.propTypes = {
	country: PropTypes.string,
	onSelect: PropTypes.func,
};

export default CountryPicker;
