/* eslint-disable eqeqeq */
/* eslint-disable no-cond-assign */
/* eslint-disable no-void */
/* eslint-disable no-underscore-dangle */
exports.__esModule = true;
exports.compilePattern = compilePattern;
exports.getParamNames = getParamNames;
exports.getParams = getParams;
exports.formatPattern = formatPattern;
const _invariant = require('invariant');
const ReactRouter = require('react-router-dom');

const _invariant2 = _interopRequireDefault(_invariant);

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : { default: obj };
}

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function _compilePattern(pattern) {
	let regexpSource = '';
	const paramNames = [];
	const tokens = [];

	let match = void 0;
	let lastIndex = 0;
	const matcher = /:([a-zA-Z_$][a-zA-Z0-9_$]*)|\*\*|\*|\(|\)|\\\(|\\\)/g;
	while ((match = matcher.exec(pattern))) {
		if (match.index !== lastIndex) {
			tokens.push(pattern.slice(lastIndex, match.index));
			regexpSource += escapeRegExp(pattern.slice(lastIndex, match.index));
		}

		if (match[1]) {
			regexpSource += '([^/]+)';
			paramNames.push(match[1]);
		} else if (match[0] === '**') {
			regexpSource += '(.*)';
			paramNames.push('splat');
		} else if (match[0] === '*') {
			regexpSource += '(.*?)';
			paramNames.push('splat');
		} else if (match[0] === '(') {
			regexpSource += '(?:';
		} else if (match[0] === ')') {
			regexpSource += ')?';
		} else if (match[0] === '\\(') {
			regexpSource += '\\(';
		} else if (match[0] === '\\)') {
			regexpSource += '\\)';
		}

		tokens.push(match[0]);

		lastIndex = matcher.lastIndex;
	}

	if (lastIndex !== pattern.length) {
		tokens.push(pattern.slice(lastIndex, pattern.length));
		regexpSource += escapeRegExp(pattern.slice(lastIndex, pattern.length));
	}

	return {
		paramNames,
		pattern,
		regexpSource,
		tokens,
	};
}

const CompiledPatternsCache = Object.create(null);

function compilePattern(pattern) {
	if (!CompiledPatternsCache[pattern]) CompiledPatternsCache[pattern] = _compilePattern(pattern);

	return CompiledPatternsCache[pattern];
}

function getParamNames(pattern) {
	return compilePattern(pattern).paramNames;
}

function getParams(pattern, pathname) {
	const match = ReactRouter.matchPath(pathname, {
		exact: true,
		path: pattern,
		strict: false,
	});

	if (!match) {
		return null;
	}

	return match.params;
}

/**
 * Returns a version of the given pattern with params interpolated. Throws
 * if there is a dynamic segment of the pattern for which there is no param.
 */
function formatPattern(pattern, params) {
	params = params || {};

	const _compilePattern3 = compilePattern(pattern);
	const { tokens } = _compilePattern3;

	let parenCount = 0;
	let pathname = '';
	let splatIndex = 0;
	const parenHistory = [];

	let token = void 0;
	let paramName = void 0;
	let paramValue = void 0;
	for (let i = 0, len = tokens.length; i < len; ++i) {
		token = tokens[i];

		if (token === '*' || token === '**') {
			paramValue = Array.isArray(params.splat) ? params.splat[splatIndex++] : params.splat;

			!(paramValue != null || parenCount > 0)
				? process.env.NODE_ENV !== 'production'
					? (0, _invariant2.default)(false, 'Missing splat #%s for path "%s"', splatIndex, pattern)
					: (0, _invariant2.default)(false)
				: void 0;

			if (paramValue != null) pathname += encodeURI(paramValue);
		} else if (token === '(') {
			parenHistory[parenCount] = '';
			parenCount += 1;
		} else if (token === ')') {
			const parenText = parenHistory.pop();
			parenCount -= 1;

			if (parenCount) parenHistory[parenCount - 1] += parenText;
			else pathname += parenText;
		} else if (token === '\\(') {
			pathname += '(';
		} else if (token === '\\)') {
			pathname += ')';
		} else if (token.charAt(0) === ':') {
			paramName = token.substring(1);
			paramValue = params[paramName];

			!(paramValue != null || parenCount > 0)
				? process.env.NODE_ENV !== 'production'
					? (0, _invariant2.default)(
							false,
							'Missing "%s" parameter for path "%s"',
							paramName,
							pattern,
					  )
					: (0, _invariant2.default)(false)
				: void 0;

			if (paramValue == null) {
				if (parenCount) {
					parenHistory[parenCount - 1] = '';

					const curTokenIdx = tokens.indexOf(token);
					const tokensSubset = tokens.slice(curTokenIdx, tokens.length);
					let nextParenIdx = -1;

					for (let _i = 0; _i < tokensSubset.length; _i++) {
						if (tokensSubset[_i] == ')') {
							nextParenIdx = _i;
							break;
						}
					}

					!(nextParenIdx > 0)
						? process.env.NODE_ENV !== 'production'
							? (0, _invariant2.default)(
									false,
									'Path "%s" is missing end paren at segment "%s"',
									pattern,
									tokensSubset.join(''),
							  )
							: (0, _invariant2.default)(false)
						: void 0;

					// jump to ending paren
					i = curTokenIdx + nextParenIdx - 1;
				}
			} else if (parenCount) parenHistory[parenCount - 1] += encodeURIComponent(paramValue);
			else pathname += encodeURIComponent(paramValue);
		} else if (parenCount) parenHistory[parenCount - 1] += token;
		else pathname += token;
	}

	!(parenCount <= 0)
		? process.env.NODE_ENV !== 'production'
			? (0, _invariant2.default)(false, 'Path "%s" is missing end paren', pattern)
			: (0, _invariant2.default)(false)
		: void 0;

	return pathname.replace(/\/+/g, '/');
}
