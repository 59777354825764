import queryParams from 'query-string';
import { createSelector } from 'reselect';

import { makeDocumentUrl, makeImageUrl, makeVideoUrl } from '@/lib/api';
import { MEDIA, MEDIA_EXTENSIONS, MEDIA_TYPES } from '@/types/consts';
import { takeFromProps } from '@/types/data';

export const MEDIA_TYPE_TO_URL_METHOD = {
	[MEDIA.image]: makeImageUrl,
	[MEDIA.video]: makeVideoUrl,
	[MEDIA.document]: makeDocumentUrl,
};

export const getUpload = (state, key) => state.uploads[key] || null;

const getInitialVersion = state => state.version.initial;
const getCurrentVersion = state => state.version.current;

export const getUpdatedVersion = createSelector(
	getInitialVersion,
	getCurrentVersion,
	(initialVersion, currentVersion) => {
		if (!initialVersion || !currentVersion || initialVersion === currentVersion) {
			return null;
		}

		return currentVersion;
	},
);

export const canSeeTours = state => state.platformRestrictions.canSeeTours;
export const canSee360Objects = state => state.platformRestrictions.canSee360Objects;
export const canSeeVideos = state => state.platformRestrictions.canSeeVideos;
export const canSeeImages = state => state.platformRestrictions.canSeeImages;
export const canSeeUnityTours = state => state.platformRestrictions.canSeeUnityTours;
export const canSeeOtherTours = state => state.platformRestrictions.canSeeOtherTours;
export const canCloneTours = state => state.platformRestrictions.canCloneTours;

export const getMediaType = ext => {
	return MEDIA_TYPES.find(type => {
		const acceptExtensions = MEDIA_EXTENSIONS[type] || [];
		return acceptExtensions.includes(ext);
	});
};

export const getMediaUrlMethod = mediaType => MEDIA_TYPE_TO_URL_METHOD[mediaType];

export const getMediaSrc = (format, key, width, height, ...rest) => {
	const type = getMediaType(format);
	const urlMethod = getMediaUrlMethod(type);
	if (!urlMethod) {
		return null;
	}

	return urlMethod(key, width, height, ...rest);
};

export const takeQuery = createSelector(
	createSelector(
		createSelector(takeFromProps('history'), takeFromProps('location')),
		takeFromProps('location'),
		takeFromProps('search'),
	),
	search => queryParams.parse(search || ''),
);

export const takeFilterString = createSelector(takeQuery, takeFromProps('filter'));
