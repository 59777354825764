import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';
import TextInput from '@/views/widgets/forms/TextInput';
import IconButton from '@/views/widgets/IconButton';

import './AssetInfoModal.scss';

const CLASS = 'sv-AssetInfoModal';

function AssetInfoModalHeader({ onClose, title }) {
	return (
		<Modal.Header className={`${CLASS}-header`}>
			<div className={`${CLASS}-header-title`}>{title}</div>

			<div className={`${CLASS}-header-close`}>
				<IconButton icon={FA.close} onClick={onClose} />
			</div>
		</Modal.Header>
	);
}

export default function AssetInfoModal(props) {
	const [newLatitude, setNewLatitude] = useState(props.lat);
	const [newLongitude, setNewLongitude] = useState(props.lng);
	const [newHeight, setNewHeight] = useState(props.height);

	const [saveAllowed, setAllowSaving] = useState(false);

	useEffect(() => {
		function requiredAssetInfoIsEmpty() {
			return !newLatitude || !newLongitude;
		}
		function assetInfoIsUnchanged() {
			return newLatitude === props.lat && newLongitude === props.lng && newHeight === props.height;
		}

		if (requiredAssetInfoIsEmpty() || assetInfoIsUnchanged()) {
			setAllowSaving(false);
		} else {
			setAllowSaving(true);
		}
	}, [newLatitude, newLongitude, newHeight, props.lat, props.lng, props.height]);

	const submit = () => {
		setAllowSaving(false);
		props.onSubmit({
			alt: newHeight,
			lat: newLatitude,
			lng: newLongitude,
		});
	};

	return (
		<div className={CLASS}>
			<AssetInfoModalHeader title={props.title} onClose={props.onClose} />
			<Modal.Body>
				<TextInput
					initialValue={props.lat}
					label="Latitude"
					placeholder="For example: 44.801893"
					type={TextInput.TYPES.number}
					onChange={lat => setNewLatitude(Number(lat))}
				/>
				<TextInput
					initialValue={props.lng}
					label="Longitude"
					placeholder="For example: 20.448474"
					type={TextInput.TYPES.number}
					onChange={lng => setNewLongitude(Number(lng))}
				/>
				<TextInput
					initialValue={props.height}
					label="Height"
					placeholder="For example: 117.07 (in meters)"
					type={TextInput.TYPES.number}
					onChange={height => setNewHeight(Number(height))}
				/>
			</Modal.Body>
			<Modal.Footer>
				<IconButton secondary onClick={props.onClose}>
					Cancel
				</IconButton>
				<IconButton disabled={!saveAllowed} onClick={submit}>
					Save
				</IconButton>
			</Modal.Footer>
		</div>
	);
}

AssetInfoModal.propTypes = {
	height: PropTypes.number,
	lat: PropTypes.number,
	lng: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	title: PropTypes.string,
};

AssetInfoModal.defaultProps = {
	height: 0,
	lat: 0,
	lng: 0,
	title: 'Edit Asset Info',
};
