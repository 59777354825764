import { withType } from '@/lib/util';

import * as TYPES from './action_types';

export const subscribe = room => (dispatch, getState, /** Container */ container) => {
	container.socket.subscribe(room, (error, res) => {
		if (error) {
			return dispatch(withType(TYPES.SOCKET_SUBSCRIBE_ERROR, { error }));
		}

		dispatch(
			withType(TYPES.SOCKET_SUBSCRIBED, {
				data: res.subscriptions,
				reset: true,
				room,
			}),
		);
	});
};

export const unsubscribe = room => (dispatch, getState, /** Container */ container) => {
	container.socket.unsubscribe(room, (error, res) => {
		if (error) {
			return dispatch(withType(TYPES.SOCKET_UNSUBSCRIBE_ERROR, { error }));
		}

		dispatch(
			withType(TYPES.SOCKET_UNSUBSCRIBED, {
				data: res.subscriptions,
				reset: true,
				room,
			}),
		);
	});
};

export const resubscribe = () => (dispatch, getState, /** Container */ container) => {
	const { socketSubscriptions } = getState();
	if (!socketSubscriptions || !socketSubscriptions.data) {
		return;
	}

	// eslint-disable-next-line
	const [oldId, ...rooms] = Object.keys(socketSubscriptions.data);
	rooms.forEach(room => {
		dispatch(subscribe(room));
	});
};
