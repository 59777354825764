import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';

import { cloneBrochure } from '@/actions/brochures';
import {
	cloneSite,
	createOrUpdateSite,
	deleteSite,
	loadSites,
	moveSite,
	prepareSiteFilesDownload,
	rename,
} from '@/actions/sites';
import ENV from '@/env';
import {
	editBrochure as brochureEdit,
	insightsHeatmaps,
	newBrochure,
	siteOverview,
	siteToolTab,
	tourPlanningToolView,
	tourSalesToolView,
	tourVirtualScreensView,
} from '@/lib/routes';
import { allowDownloadSiteFiles, isBasicUser } from '@/selectors/auth';
import { getCriteria, isPreparingSiteFilesDownload } from '@/selectors/sites';
import { getSiteEnabledToolsSelector } from '@/selectors/sub_tours';
import { FILE_CONTEXT_MENU, LIBRARY_TYPE } from '@/types/library';
import { SITE, SITE_TYPES } from '@/types/sites';
import { TOOLS } from '@/types/sub_tours';
import Menu from '@/views/common/menu/Menu';

class FileContextMenu extends Component {
	navigateTo =
		(nextRouteHandler, ...args) =>
		() => {
			const { history, item } = this.props;
			const record = item.site || item;
			const nextRoute =
				typeof nextRouteHandler === 'string'
					? nextRouteHandler
					: nextRouteHandler(record.id, ...args);

			history.push(nextRoute);
		};

	resetPreview = id => {
		const { updateSite } = this.props;
		updateSite && updateSite(id, { [SITE.preview_image_id]: null });
	};

	initDownload = () => {
		const { item, prepareSiteFilesDownload } = this.props;
		const record = item.site || item;
		if (!record.id) {
			return;
		}

		return (
			prepareSiteFilesDownload && prepareSiteFilesDownload(record.id, record.production_version_id)
		);
	};

	handleClick = (e, valuePath, value, props) => {
		const { item } = this.props;
		const record = item.site || item;
		const payload = {
			site: {
				id: record.id,
				metadata: record.metadata || {},
			},
		};

		if (props.type === 'checkbox') {
			let v = get(item, valuePath);
			if (v === undefined) {
				v = props.default;
			}
			value = v === value;
		}
		set(payload, valuePath, value);
		return this.props.updateSite(record.id, payload.site);
	};

	reloadSites = () => {
		const { criteria, folderId } = this.props;
		criteria.folder_id = folderId;
		return loadSites(criteria);
	};

	createContextMenu = () => {
		const { admin, enabledTools = {}, folderId, isBasicUser, item, menuItems } = this.props;
		const {
			clone,
			deleteItem,
			details,
			download,
			editBrochure,
			heatmap,
			move,
			rename,
			resetPreview,
			tools,
			...rest
		} = menuItems;
		const record = item.site || item;

		return [
			{
				action: this.navigateTo(siteOverview),
				divider: true,
				...details,
			},
			{
				action: () => this.props.moveSite(record.id, folderId),
				...move,
			},
			{
				...tools,
				children:
					enabledTools &&
					[
						{
							action: this.navigateTo(
								record.parent_id
									? siteToolTab(TOOLS.sales_tool, record.parent_id, `edit/${record.id}/2`)
									: tourVirtualScreensView,
							),
							disabled: !enabledTools.virtual_screens_tool,
							...tools.children.virtual_screens_tool,
						},
						{
							action: this.navigateTo(tourPlanningToolView),
							disabled: !enabledTools.planning_tool,
							...tools.children.planning_tool,
						},
						{
							action: this.navigateTo(tourSalesToolView),
							disabled: !enabledTools.sales_tool,
							...tools.children.sales_tool,
						},
						{
							action: this.navigateTo(newBrochure(record.id)),
							disabled: !enabledTools.brochure_tool,
							...tools.children.brochure_tool,
						},
						{
							action: this.navigateTo(insightsHeatmaps),
							shouldRender: record.type === SITE_TYPES.tour,
							...tools.children.heatmaps,
						},
					].map(v => ({ ...v, record })),
				disabled: !(
					enabledTools?.virtual_screens_tool ||
					enabledTools?.planning_tool ||
					enabledTools?.sales_tool ||
					enabledTools?.brochure_tool
				),
				divider: true,
			},
			{
				action: () => this.props.rename(record.id, record.name, folderId),
				...rename,
			},
			{
				action:
					item.type === SITE_TYPES.brochure && item.brochure
						? () =>
								this.props.cloneBrochure({
									brochureKey: item.brochure.key,
									folderId,
									onSuccess: this.reloadSites,
								})
						: () => this.props.cloneSite(record.id, folderId),
				...clone,
			},
			!isBasicUser && {
				action: this.initDownload,
				disabled: !allowDownloadSiteFiles || item.type === SITE_TYPES.brochure,
				...download,
			},
			{
				action: () => this.props.deleteFile(record.id, record.name, folderId),
				...deleteItem,
			},
			admin && {
				action: () => this.resetPreview(record.id),
				...resetPreview,
			},
			item.type === SITE_TYPES.brochure && {
				action: this.navigateTo(brochureEdit(item && item.brochure && item.brochure.key)),
				...editBrochure,
			},
			...Object.values(rest),
		]
			.filter(Boolean)
			.map(v => ({ ...v, item }));
	};

	render() {
		const { item, placement } = this.props;

		if (!item) {
			return null;
		}

		let skip = this.props.skip || [...ENV.site_details_context_menu_skip] || {};
		if (Array.isArray(skip)) {
			skip = skip.reduce((agg, key) => {
				agg[key] = key;
				return agg;
			}, {});
		}

		let menuItems = this.createContextMenu();
		menuItems = menuItems.map(item => {
			let { name, shouldRender } = item;
			if (shouldRender === undefined) {
				shouldRender = true;
			}

			return {
				...item,
				shouldRender: shouldRender && !skip[name],
			};
		});

		return <Menu menuItems={menuItems} placement={placement} />;
	}
}

FileContextMenu.propTypes = {
	folderId: PropTypes.number,
	folderPath: PropTypes.string,
	history: PropTypes.object,
	menuItems: PropTypes.object,
	position: PropTypes.object,
	skip: PropTypes.array,
};

const mapStateToProps = (state, props) => {
	const item = props.item || {};
	const record = item.site || item;
	const siteId = record.id;
	const menuItems = props.menuItems
		? { ...FILE_CONTEXT_MENU, ...props.menuItems }
		: FILE_CONTEXT_MENU;

	return {
		allowDownloadSiteFiles: allowDownloadSiteFiles(state),
		criteria: getCriteria(state, props),
		enabledTools: getSiteEnabledToolsSelector(state, { ...props, ...item, siteId }),
		isBasicUser: isBasicUser(state),
		isPreparingSiteFilesDownload: isPreparingSiteFilesDownload(state, siteId),
		menuItems,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cloneBrochure: params => dispatch(cloneBrochure(params)),
		cloneSite: (id, folderId) => dispatch(cloneSite(id, null, folderId)),
		deleteFile: (id, name, folderId) => dispatch(deleteSite(id, name, folderId)),
		moveSite: (id, folderId) => dispatch(moveSite(id, LIBRARY_TYPE.FILE, folderId)),
		prepareSiteFilesDownload: (siteId, versionId, paths) =>
			dispatch(prepareSiteFilesDownload(siteId, versionId, paths)),
		rename: (id, name, folderId) => dispatch(rename(id, name, LIBRARY_TYPE.FILE, folderId)),
		updateSite: (siteId, payload) => dispatch(createOrUpdateSite(siteId, payload)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileContextMenu));
