import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	backgroundJobs: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
	},
};

const helper = new ReduceHelper('backgroundJobs', '_id', job => ({ ...job.data, id: job._id }));

const setOrUpdateBackgroundJob = (state, action) => {
	return helper.gotData(state, { data: [action.job] });
};

const removeBackgroundJobs = (state, action) => {
	return helper.withInvalidation(state, { invalidateIds: action.ids });
};

export default {
	INITIAL_STATE,
	[TYPES.BACKGROUND_JOBS_GOT_DATA]: helper.gotData,
	[TYPES.BACKGROUND_JOBS_LOAD_START]: helper.loadStart,
	[TYPES.BACKGROUND_JOBS_LOAD_END]: helper.loadEnd,
	[TYPES.BACKGROUND_JOBS_OP_START]: helper.opStart,
	[TYPES.BACKGROUND_JOBS_OP_END]: helper.opEnd,
	[TYPES.BACKGROUND_JOBS_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
	[TYPES.SET_OR_UPDATE_BACKGROUND_JOB]: setOrUpdateBackgroundJob,
	[TYPES.DELETE_BACKGROUND_JOB]: removeBackgroundJobs,
};
