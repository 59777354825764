import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	// Current uploads
	uploads: {},
};

const helper = new ReduceHelper('uploads');

const opStart = (state, action) => {
	return helper.withInvalidation(helper.opStart(state), action);
};

const opEnd = (state, action) => {
	return helper.withInvalidation(helper.opEnd(state), action);
};

const newResumableField = (state, { key, resumableField }) => {
	const uploads = { ...state.uploads };
	let target = uploads[key];
	if (!target) {
		target = {
			files: {},
			isProcessing: false,
			resumableField,
		};
	}
	const ob = {
		[key]: { ...target },
	};
	return helper.updateNamespace(state, ob);
};

const addFileUpload = (state, { file, key }) => {
	const uploads = { ...state.uploads };
	let target = uploads[key];
	if (!target) {
		target = {
			files: {},
			resumableField: file.flowObj,
		};
	}
	const ob = {
		[key]: {
			...target,
			files: { ...target.files, [file.uniqueIdentifier]: file },
			isProcessing: true,
		},
	};
	return helper.updateNamespace(state, ob);
};

const removeFileUpload = (state, { identifier, key }) => {
	const uploads = { ...state.uploads };
	const target = uploads[key];
	if (!target) {
		return state;
	}

	delete target.files[identifier];

	const ob = {
		[key]: {
			...target,
			files: { ...target.files },
			isProcessing: !!Object.keys(target.files).length,
		},
	};

	return helper.updateNamespace(state, ob);
};

const uploadEnd = (state, { key }) => {
	const newState = { ...state };
	const uploads = { ...newState.uploads };

	delete uploads[key];

	newState.uploads = uploads;
	return newState;
};

const resetUploads = state => {
	// Stop all uploads
	Object.keys(state.uploads).forEach(endpoint => {
		const uploadTarget = state.uploads[endpoint];
		if (uploadTarget && uploadTarget.resumableField) {
			uploadTarget.resumableField.cancel();
		}
	});

	const nextState = { ...state, uploads: {} };

	return nextState;
};

export default {
	INITIAL_STATE,
	[TYPES.UPLOADS_RESUMABLE_FIELD]: newResumableField,
	[TYPES.UPLOADS_FILE_ADD]: addFileUpload,
	[TYPES.UPLOADS_FILE_REMOVE]: removeFileUpload,
	[TYPES.UPLOADS_END]: uploadEnd,
	[TYPES.UPLOADS_OP_START]: opStart,
	[TYPES.UPLOADS_OP_END]: opEnd,
	[TYPES.UPLOADS_RESET]: resetUploads,
};
