import { createSelector } from 'reselect';

import { makeGetter, sortByIndexingProperty, takeFromProps } from '@/types/data';
import {
	Criteria,
	DEFAULT_CRITERIA,
	LIBRARY_NAMESPACE,
	MAX_FOLDER_PREVIEWS,
} from '@/types/library';
import { FOLDER_TYPES } from '@/types/library_folders';

import { takeQuery } from './application';
import { allowSiteSeeOrphans, getLoggedInUserWithDetails } from './auth';
import { getData as getCompaniesData } from './companies';

const findParent = (folders, currentFolderParentId, breadcrumbs = []) => {
	if (currentFolderParentId === null) {
		return breadcrumbs;
	}

	const parentFolder = folders.find(folder => folder.id === currentFolderParentId);
	if (parentFolder) {
		breadcrumbs.unshift(parentFolder);
		return findParent(folders, parentFolder.parent_id, breadcrumbs);
	}

	return breadcrumbs;
};

export const normalizeBreadcrumbs = (breadcrumbs, folderId) => {
	const folders = breadcrumbs ? Object.values(breadcrumbs) : [];
	const currentFolder = folders.find(folder => folder.id === Number(folderId));
	return currentFolder ? findParent(folders, currentFolder.parent_id, [currentFolder]) : [];
};

export const takePath = takeFromProps('path');
export const takePathPrefix = takeFromProps('pathPrefix');
export const takeFolderRoutePattern = takeFromProps('folderRoutePattern');
export const takeSiteRoutePattern = takeFromProps('siteRoutePattern');
export const takeShowOrphans = takeFromProps('showOrphans');
export const takeFolderId = createSelector(
	takeFromProps('folderId'),
	takeFromProps('disableRedirect'),
	getLoggedInUserWithDetails,
	(folderId, disableRedirect, uwd) => {
		if (disableRedirect || folderId !== undefined || !uwd || !uwd.cwds) {
			return folderId;
		}
	},
);
export const _takeFolderId = (state, props) => {
	const folderId = takeFolderId(state, props);
	if (folderId === 'orphans') {
		return undefined;
	}
	return folderId;
};
export const takeFolders = takeFromProps('folders');
export const takeFiles = takeFromProps('files');
export const takeRecursive = takeFromProps('recursive');
export const takeNamespace = takeFromProps('namespace');

const getFoldersState = makeGetter('folders');
const getModalState = makeGetter('library_modal');

export const getLibraryState = createSelector(
	takeNamespace,
	getFoldersState,
	getModalState,
	(namespace, folders, library_modal) => {
		if (namespace === LIBRARY_NAMESPACE.view) {
			return folders;
		}
		if (namespace === LIBRARY_NAMESPACE.modal) {
			return library_modal;
		}

		return folders;
	},
);

const dataGetter = makeGetter('data');
export const getData = createSelector(getLibraryState, dataGetter);

export const getBreadCrumbs = (breadcrumbs, folderId) => {
	if (!breadcrumbs) {
		return breadcrumbs;
	}
	breadcrumbs = breadcrumbs.map(fwd => fwd.folder || fwd);
	return normalizeBreadcrumbs(breadcrumbs, folderId);
};

export const getLibraryBreadCrumbs = createSelector(
	createSelector(getLibraryState, makeGetter('breadcrumbs')),
	sortByIndexingProperty,
);

export const libraryBreadCrumbsSelector = createSelector(
	getLibraryBreadCrumbs,
	takeFolderId,
	getBreadCrumbs,
);

export const weAreInsideVisionAssetFolder = createSelector(
	libraryBreadCrumbsSelector,
	breadcrumbs =>
		Array.isArray(breadcrumbs) &&
		breadcrumbs.some(folder => folder.type === FOLDER_TYPES.vision_asset),
);

const selectFolder = (folders, folderId) => (folders ? folders[folderId] : null);

export const getFolderSelector = createSelector(getData, _takeFolderId, selectFolder);

export const getCriteria = createSelector(
	_takeFolderId,
	takeQuery,
	takeFromProps('criteria'),
	takeFromProps('foldersCriteria'),
	(folder_id, query, criteria, foldersCriteria) => {
		if (criteria) {
			query = null;
		}
		return new Criteria({
			folder_id,
			...DEFAULT_CRITERIA,
			...query,
			...criteria,
			...foldersCriteria,
		});
	},
);

export const sortedFoldersSelector = createSelector(getData, sortByIndexingProperty);

const isDescendantOf = createSelector(
	state => state,
	takeFromProps('parentId'),
	takeFromProps('folderId'),
	getData,
	(_, props) => props,
	(state, parentId, folderId, folders) => {
		const parent = folders[parentId];
		const firstParent = folders[folderId];

		if (Number(parentId) === Number(folderId)) {
			return true;
		}
		if (!folders) {
			return false;
		}

		if (!parent || !firstParent) {
			return false;
		}
		return isDescendantOf(state, { folderId: firstParent.parent_id, parentId });
	},
);

export const orphansFolder = {
	folder: {
		id: 'orphans',
		name: 'Orphans',
	},
	key: 'f-orphans',
};

export const getShowOrphans = createSelector(takeShowOrphans, allowSiteSeeOrphans, (...args) =>
	args.every(Boolean),
);

export const childFoldersSelector = createSelector(
	takeFolders,
	_takeFolderId,
	takeRecursive,
	getFolderSelector,
	sortedFoldersSelector,
	getCompaniesData,
	getChildFolders,
);

function getChildFolders(foldersFromProps, parentId, isRecursive, parent, folders, companies) {
	if (foldersFromProps !== undefined) {
		return foldersFromProps;
	}

	if (!folders || parentId === undefined) {
		return folders;
	}

	parent = parent || { company: {}, folder: { id: parentId, parent_id: null } };

	return folders.filter(fwd => {
		const { company, folder } = fwd;
		if (!folder) {
			console.error('invalid folder', fwd); // eslint-disable-line no-console
			return false;
		}
		if (folder.parent_id === parentId) {
			return true;
		}
		if (folder.parent_id && (!isRecursive || isRecursive === 'false')) {
			return false;
		}
		if (!parent.folder || parent.folder.parent_id !== null || !parent.company || !company) {
			return false;
		}
		if (company.parent_id === parent.company.id) {
			return true;
		}
		return false;
	});
}

export const foldersWithDetailsSelector = createSelector(
	childFoldersSelector,
	getFoldersWithDetails,
);

export const searchedFoldersWithDetailsSelector = createSelector(getData, folderWithDetails => {
	const folders = folderWithDetails ? Object.values(folderWithDetails) : null;
	const tmpFolders = getFoldersWithDetails(folders);
	return tmpFolders;
});

function getFoldersWithDetails(fwds) {
	if (!fwds) {
		return null;
	}
	return fwds.map(fwd => {
		let { asset, company, folder, image, images, logo } = fwd;
		images = images || [];

		const belongsToChildCompany =
			company && company.id !== company.parent_id && company.parent_id === folder.company_id;
		const isCompany = folder.parent_id === null || belongsToChildCompany;

		const thumbnail = !isCompany
			? images
					.slice(0, MAX_FOLDER_PREVIEWS)
					.map(image => ({ ...image, is_360: false }))
					.find(image => image.key)
			: null;

		const preview = image || (isCompany ? logo : thumbnail);
		const key = `f-${folder.id}`;
		const folderItem = {
			asset,
			company,
			folder,
			id: folder.id,
			image,
			isCompany,
			key,
			parent_id: folder.parent_id,
			preview,
			type: folder.type,
		};

		return folderItem;
	});
}
