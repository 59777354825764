import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { Criteria as BaseCriteria, SITE } from '@/types/sites';
import CompanyPicker from '@/views/companies/widgets/CompanyPicker';
import ReportTimeFramePicker from '@/views/reports/widgets/ReportTimeFramePicker';
import SiteTypePicker from '@/views/sites/widgets/SiteTypePicker';
import CriteriaFilterField from '@/views/widgets/criteria/CriteriaFilterField';
import CountryPicker from '@/views/widgets/input/CountryPicker';

import './LibrarySearch.scss';

const CLASS = 'sv-LibrarySearch';

export default class LibrarySearch extends Component {
	constructor(props) {
		super(props);
		this.searchInput = createRef();

		this.state = {
			hasInput: false,
		};
	}

	static propTypes = {
		criteria: PropTypes.oneOfType([PropTypes.instanceOf(BaseCriteria)]).isRequired,
		isInLibraryView: PropTypes.bool,
		onCriteriaChanged: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.searchInput.current.attributes.value.nodeValue.length > 0 &&
			this.setState({ hasInput: true });
		window.addEventListener('keydown', e => {
			if (e.key === '/') {
				e.preventDefault();
				this.focusOnSearchInput();
			}
		});
	}

	focusOnSearchInput = () => {
		this.searchInput.current && this.searchInput.current.focus();
	};

	handleChange = (val, key) => {
		const { criteria, onCriteriaChanged } = this.props;
		const newCriteria = criteria.update({ [key]: val });

		if (!val && [SITE.type, SITE.media_format].includes(key)) {
			delete newCriteria[SITE.type];
			delete newCriteria[SITE.media_format];
		}

		if (key === SITE.type) {
			newCriteria[key] = val || null;
		}
		onCriteriaChanged(newCriteria);
	};

	checkIfInputExists = currentInput => {
		this.setState({
			hasInput: !!currentInput,
		});
	};

	render() {
		const { criteria, disabled, onCriteriaChanged, ...rest } = this.props;
		if (!criteria) {
			return null;
		}
		let { country, owner_id, type } = criteria;
		if (!type || !Array.isArray(type)) {
			type = [type];
		}
		type = new Set([...type].filter(Boolean));
		const dropdownEnabled = !disabled;

		const hasInputClass = this.state.hasInput ? ' hasInput' : '';

		return (
			<div className={CLASS + hasInputClass}>
				<CriteriaFilterField
					criteria={criteria}
					feedbackIcon="search"
					inputFocusRef={this.searchInput}
					placeholder="Search content"
					withDropdown={dropdownEnabled}
					onChange={this.checkIfInputExists}
					onCriteriaChanged={onCriteriaChanged}
					{...rest}
				>
					<SiteTypePicker
						isClearable
						multi
						label="Type"
						placeholder="Any"
						siteType={[...type]}
						onSelect={format => this.handleChange(format, 'type')}
					/>
					<ReportTimeFramePicker
						className={CLASS}
						criteria={criteria}
						displayLabel={false}
						label="Date modified"
						placeholder="Any"
						onCriteriaChanged={onCriteriaChanged}
					/>
					<CompanyPicker
						companyId={owner_id}
						label="Owner"
						placeholder="Any"
						onSelect={owner_id => this.handleChange(owner_id, 'owner_id')}
					/>
					<CountryPicker
						country={country}
						label="Country"
						placeholder="Any"
						onSelect={option => this.handleChange((option && option.value) || null, 'country')}
					/>
				</CriteriaFilterField>
			</div>
		);
	}
}
