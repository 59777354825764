export default {
	AFG: 'AF',
	AI: 'AI',
	ALB: 'AL',
	ALG: 'DZ',
	AN: 'AN',
	AND: 'AD',
	ANG: 'AO',
	ANT: 'AG',
	AQ: 'AQ',
	ARG: 'AR',
	ARM: 'AM',
	ARU: 'AW',
	ASA: 'AS',
	AUS: 'AU',
	AUT: 'AT',
	AX: 'AX',
	AZE: 'AZ',
	BAH: 'BS',
	BAN: 'BD',
	BAR: 'BB',
	BDI: 'BI',
	BEL: 'BE',
	BEN: 'BJ',
	BER: 'BM',
	BHU: 'BT',
	BIH: 'BA',
	BIZ: 'BZ',
	BL: 'BL',
	BOL: 'BO',
	BOT: 'BW',
	BQ: 'BQ',
	BRA: 'BR',
	BRN: 'BH',
	BRU: 'BN',
	BU: 'BU',
	BUL: 'BG',
	BUR: 'BF',
	BV: 'BV',
	BY: 'BY',
	CAF: 'CF',
	CAM: 'KH',
	CAN: 'CA',
	CAY: 'KY',
	CC: 'CC',
	CGO: 'CG',
	CHA: 'TD',
	CHI: 'CL',
	CHN: 'CN',
	CIV: 'CI',
	CMR: 'CM',
	COD: 'CD',
	COK: 'CK',
	COL: 'CO',
	COM: 'KM',
	CP: 'CP',
	CPV: 'CV',
	CRC: 'CR',
	CRO: 'HR',
	CS: 'CS',
	CT: 'CT',
	CUB: 'CU',
	CW: 'CW',
	CX: 'CX',
	CYP: 'CY',
	CZE: 'CZ',
	DD: 'DD',
	DEN: 'DK',
	DG: 'DG',
	DJI: 'DJ',
	DMA: 'DM',
	DOM: 'DO',
	DY: 'DY',
	EA: 'EA',
	ECU: 'EC',
	EGY: 'EG',
	EH: 'EH',
	ERI: 'ER',
	ESA: 'SV',
	ESP: 'ES',
	EST: 'EE',
	ETH: 'ET',
	EU: 'EU',
	FAI: 'FO',
	FIJ: 'FJ',
	FIN: 'FI',
	FK: 'FK',
	FQ: 'FQ',
	FRA: 'FR',
	FSM: 'FM',
	FX: 'FX',
	GAB: 'GA',
	GAM: 'GM',
	GBR: 'GB',
	GBS: 'GW',
	GCI: 'GG',
	GE: 'GE',
	GEQ: 'GQ',
	GER: 'DE',
	GF: 'GF',
	GHA: 'GH',
	GI: 'GI',
	GL: 'GL',
	GP: 'GP',
	GRE: 'GR',
	GRN: 'GD',
	GS: 'GS',
	GUA: 'GT',
	GUI: 'GN',
	GUM: 'GU',
	GUY: 'GY',
	HAI: 'HT',
	HKG: 'HK',
	HM: 'HM',
	HON: 'HN',
	HUN: 'HU',
	HV: 'HV',
	IC: 'IC',
	IM: 'IM',
	INA: 'ID',
	IND: 'IN',
	IO: 'IO',
	IRI: 'IR',
	IRL: 'IE',
	IRQ: 'IQ',
	ISL: 'IS',
	ISR: 'IL',
	ISV: 'VI',
	ITA: 'IT',
	IVB: 'VG',
	JAM: 'JM',
	JCI: 'JE',
	JOR: 'JO',
	JPN: 'JP',
	JT: 'JT',
	KAZ: 'KZ',
	KEN: 'KE',
	KGZ: 'KG',
	KIR: 'KI',
	KOR: 'KR',
	KOS: 'XK',
	KSA: 'SA',
	KUW: 'KW',
	LAO: 'LA',
	LAT: 'LV',
	LBA: 'LY',
	LBR: 'LR',
	LCA: 'LC',
	LES: 'LS',
	LIB: 'LB',
	LIE: 'LI',
	LTU: 'LT',
	LUX: 'LU',
	MAC: 'MO',
	MAD: 'MG',
	MAR: 'MA',
	MAS: 'MY',
	MAW: 'MW',
	MDA: 'MD',
	MDV: 'MV',
	MEX: 'MX',
	MF: 'MF',
	MGL: 'MN',
	MHL: 'MH',
	MI: 'MI',
	MKD: 'MK',
	MLI: 'ML',
	MLT: 'MT',
	MNE: 'ME',
	MON: 'MC',
	MOZ: 'MZ',
	MP: 'MP',
	MQ: 'MQ',
	MRI: 'MU',
	MS: 'MS',
	MTN: 'MR',
	MYA: 'MM',
	NAM: 'NA',
	NC: 'NC',
	NCA: 'NI',
	NED: 'NL',
	NEP: 'NP',
	NF: 'NF',
	NGR: 'NG',
	NH: 'NH',
	NIG: 'NE',
	NOR: 'NO',
	NQ: 'NQ',
	NRU: 'NR',
	NT: 'NT',
	NU: 'NU',
	NZL: 'NZ',
	OMA: 'OM',
	PAK: 'PK',
	PAN: 'PA',
	PAR: 'PY',
	PC: 'PC',
	PER: 'PE',
	PF: 'PF',
	PHI: 'PH',
	PLE: 'PS',
	PLW: 'PW',
	PM: 'PM',
	PN: 'PN',
	PNG: 'PG',
	POL: 'PL',
	POR: 'PT',
	PRK: 'KP',
	PU: 'PU',
	PUR: 'PR',
	PZ: 'PZ',
	QAT: 'QA',
	RE: 'RE',
	RH: 'RH',
	ROU: 'RO',
	RSA: 'ZA',
	RUS: 'RU',
	RWA: 'RW',
	SAM: 'WS',
	SEN: 'SN',
	SEY: 'SC',
	SH: 'SH',
	SHP: 'AC',
	SIN: 'SG',
	SJ: 'SJ',
	SK: 'SK',
	SKN: 'KN',
	SLE: 'SL',
	SLO: 'SI',
	SMR: 'SM',
	SOL: 'SB',
	SOM: 'SO',
	SRB: 'RS',
	SRI: 'LK',
	SSD: 'SS',
	STP: 'ST',
	SU: 'SU',
	SUD: 'SD',
	SUI: 'CH',
	SUR: 'SR',
	SWE: 'SE',
	SWZ: 'SZ',
	SX: 'SX',
	SYR: 'SY',
	TA: 'TA',
	TAN: 'TZ',
	TC: 'TC',
	TF: 'TF',
	TGA: 'TO',
	THA: 'TH',
	TJK: 'TJ',
	TK: 'TK',
	TKM: 'TM',
	TLS: 'TL',
	TOG: 'TG',
	TP: 'TP',
	TPE: 'TW',
	TTO: 'TT',
	TUN: 'TN',
	TUR: 'TR',
	TUV: 'TV',
	UAE: 'AE',
	UGA: 'UG',
	UK: 'UK',
	UKR: 'UA',
	UM: 'UM',
	URU: 'UY',
	USA: 'US',
	UZB: 'UZ',
	VA: 'VA',
	VAN: 'VU',
	VD: 'VD',
	VEN: 'VE',
	VIE: 'VN',
	VIN: 'VC',
	WF: 'WF',
	WK: 'WK',
	YD: 'YD',
	YEM: 'YE',
	YT: 'YT',
	YU: 'YU',
	ZAM: 'ZM',
	ZIM: 'ZW',
	ZR: 'ZR',
	ZZ: 'ZZ',
};
