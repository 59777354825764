import { Component } from 'react';
import PropTypes from 'prop-types';

import { withStopPropagation } from '@/lib/util';
import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';

import Image from './Image';

import './ImageGrid.scss';

const DEFAULT_PADDING = 5;
const IMAGE_BORDER = 2;
const DEFAULT_MIN_ROWS = 1;

class ImageGrid extends Component {
	static propTypes = {
		imageHeight: PropTypes.number,
		imageWidth: PropTypes.number,
		images: PropTypes.array,
		minRows: PropTypes.number,
		nullImage: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		onRemove: PropTypes.func,
		onSelect: PropTypes.func,
		padding: PropTypes.number,
		selectedId: PropTypes.number,
		selectedIds: PropTypes.array,
	};

	static defaultProps = {
		centerImage: false,
		minRows: DEFAULT_MIN_ROWS,
		padding: DEFAULT_PADDING,
	};

	constructor(props) {
		super(props);

		this.renderImage = this.renderImage.bind(this);
	}

	renderImage(image, key = null) {
		const {
			centerImage,
			imageHeight,
			imageWidth,
			isPreview,
			nullImage,
			onRemove,
			onSelect,
			padding,
			selectedIds,
		} = this.props;

		if (!image && !nullImage) {
			// No render of nulls
			return;
		}

		const isSelected = !!(selectedIds && selectedIds.includes(image.id));
		const isDisabled = image && image.is_used;

		let className = 'sv-ImageGrid-image';
		if (isSelected) {
			className += ' sv-ImageGrid-image-selected';
		}
		if (isDisabled && !isSelected) {
			className += ' sv-ImageGrid-image-disabled';
		}
		if (onSelect) {
			className += ' sv-ImageGrid-image-selectable';
		}

		const imageStyle = {
			height: `${imageHeight}px`,
			margin: `${padding}px`,
			width: `${imageWidth}px`,
		};

		if (centerImage) {
			imageStyle.margin = `${padding}px auto`;
			imageStyle.display = 'block';
		}

		const onClick = !isDisabled && onSelect ? withStopPropagation(() => onSelect(image)) : null;

		let removeBtn = null;
		if (onRemove) {
			removeBtn = (
				<IconButton icon={FA.remove} onClick={withStopPropagation(() => onRemove(image))} />
			);
		}

		if (image) {
			// Render normal image
			const imageSrc = typeof image === 'string' ? image : undefined;
			return (
				<div key={key} onClick={onClick}>
					<div className={className} style={imageStyle}>
						<Image
							alt={image.name || image.original_name || image.key}
							height={imageHeight}
							image={image}
							imageSrc={imageSrc}
							width={imageWidth}
						/>
						{!isPreview && removeBtn}
					</div>
				</div>
			);
		}

		// Render null image
		const nullImageContent =
			typeof nullImage === 'function' ? nullImage(imageWidth, imageHeight) : nullImage;

		return (
			<div key={key} className={className} style={imageStyle} onClick={onClick}>
				{nullImageContent}
			</div>
		);
	}

	render() {
		const { imageHeight, imageWidth, images, minRows, padding } = this.props;

		const imagesContainerStyle = {
			minHeight: `${
				(imageHeight + IMAGE_BORDER * 2) * minRows + padding * (minRows - 1) + padding * 2
			}px`,
			minWidth: `${imageWidth + IMAGE_BORDER * 2 + padding * 2}px`,
		};

		return (
			<div className="sv-ImageGrid" style={imagesContainerStyle}>
				{this.renderImage(null)}
				{images.map(this.renderImage)}
			</div>
		);
	}
}

export default ImageGrid;
