import * as TYPES from '@/actions/action_types';
import { makeGetter } from '@/types/data';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	companies: {
		data: null,
		loadError: null,
		nextPage: null,
		op: null,
	},
};

const makeMapper = key => {
	const getter = makeGetter(key);
	const mapper = record => {
		if (!record) {
			return record;
		}
		const cwd = getter(record);
		if (cwd && !cwd.company) {
			return { company: cwd };
		}
		return cwd || null;
	};
	return mapper;
};

const helper = new ReduceHelper('companies', 'company.id');
const foldersHelper = new ReduceHelper('companies', 'company.id', makeMapper('company'));
const sitesHelper = new ReduceHelper('companies', 'owner.id', makeMapper('owner'));

function gotSession(state, action) {
	if (!action.uwd || !action.uwd.cwds) {
		return state;
	}
	return helper.gotData(state, { data: action.uwd.cwds });
}

export default {
	INITIAL_STATE,
	[TYPES.AUTH_GOT_SESSION]: gotSession,
	[TYPES.COMPANIES_GOT_DATA]: helper.gotData,
	[TYPES.COMPANIES_LOAD_START]: helper.loadStart,
	[TYPES.COMPANIES_LOAD_END]: helper.loadEnd,
	[TYPES.COMPANIES_OP_START]: helper.opStart,
	[TYPES.COMPANIES_OP_END]: helper.opEnd,
	[TYPES.COMPANIES_OP_RESET]: helper.opReset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
	[TYPES.FOLDERS_GOT_DATA]: foldersHelper.gotData,
	[TYPES.SITES_GOT_DATA]: sitesHelper.gotData,
};
