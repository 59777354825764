import * as TYPES from '@/actions/action_types';

const NAMESPACE = 'localStorage';
const INITIAL_STATE = /** @lends State.prototype */ {
	[NAMESPACE]: {},
};

const set = (state, action) => {
	const { key, value } = action;
	const newState = {
		...state,
		[NAMESPACE]: {
			...state[NAMESPACE],
			[key]: value,
		},
	};
	if (!window.localStorage) {
		return newState;
	}
	window.localStorage.setItem(key, value);
	return newState;
};

const remove = (state, action) => {
	const { key } = action;
	const newState = {
		...state,
		[NAMESPACE]: {
			...state[NAMESPACE],
		},
	};
	delete newState[key];

	if (!window.localStorage) {
		return newState;
	}
	window.localStorage.removeItem(key);
	return newState;
};

const clear = state => {
	const newState = {
		...state,
		[NAMESPACE]: {},
	};

	if (!window.localStorage) {
		return newState;
	}
	window.localStorage.clear();
	return newState;
};

const read = state => {
	if (!window.localStorage) {
		return state;
	}

	const length = window.localStorage && window.localStorage.length;
	const storage = {};
	for (let i = 0; i < length; i++) {
		const key = window.localStorage.key(i);
		const value = window.localStorage.getItem(key);
		storage[key] = value;
	}
	const newState = {
		...state,
		[NAMESPACE]: {
			...state[NAMESPACE],
			...storage,
		},
	};
	return newState;
};

export default {
	INITIAL_STATE,
	[TYPES.LOCAL_STORAGE_SET]: set,
	[TYPES.LOCAL_STORAGE_REMOVE]: remove,
	[TYPES.LOCAL_STORAGE_READ]: read,
	[TYPES.LOCAL_STORAGE_CLEAR]: clear,
};
