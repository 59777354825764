import { assign } from '@/lib/util';
import { takeCompanyId } from '@/selectors/companies';

import { Criteria, SORT_DIRECTIONS } from './data';

const user = {
	address: 'address',
	avatar_id: 'avatar_id',
	created_at: 'created_at',
	currency: 'currency',
	current_password: 'current_password',
	default_landing_page: 'default_landing_page',
	deleted_at: 'deleted_at',
	display_name: 'display_name',
	email: 'email',
	email_signature: 'email_signature',
	id: 'id',
	job_title: 'job_title',
	password: 'password',
	password_hash: 'password_hash',
	password_salt: 'password_salt',
	phone: 'phone',
	reset_password: 'reset_password',
	role: 'role',
	timedate_format: 'timedate_format',
	timezone: 'timezone',
	updated_at: 'updated_at',
	username: 'username',
};

export const USER = user;

export class User {
	constructor(source) {
		/** @type {number} */ this.id = null;
		/** @type {number} */ this.avatar_id = null;
		/** @type {string} */ this.username = null;
		/** @type {string} */ this.password = null;
		/** @type {string} */ this.password_hash = null;
		/** @type {string} */ this.password_salt = null;
		/** @type {string} */ this.reset_password = null;
		/** @type {string} */ this.email = null;
		/** @type {string} */ this.display_name = null;
		/** @type {string} */ this.role = null;
		/** @type {string} */ this.phone = null;
		/** @type {string} */ this.job_title = null;
		/** @type {string} */ this.address = null;
		/** @type {string} */ this.email_signature = null;
		/** @type {string} */ this.timedate_format = null;
		/** @type {string} */ this.timezone = null;
		/** @type {string} */ this.currency = null;
		/** @type {string} */ this.current_password = null;
		/** @type {string} */ this.default_landing_page = null;
		/** @type {Date} */ this.created_at = null;
		/** @type {Date} */ this.updated_at = null;
		/** @type {Date} */ this.deleted_at = null;

		Object.assign(this, source);
	}
}

const uwd = {
	avatar: 'avatar',
	cwds: 'cwds',
	user: 'user',
};

export const UWD = uwd;

export class UserWithDetails {
	constructor(source) {
		/** @type {User} */ this.user = null;
		/** @type {Image} */ this.avatar = null;
		/** @type {import('./companies').CompanyWithDetails[]} */ this.cwds = null;

		Object.assign(this, source);
	}
}

export const ROLES = {
	admin: 'admin',
	basic_user: 'basic_user',
	manager: 'manager',
	trusted_user: 'trusted_user',
};

export const ROLE_LABELS = {
	[ROLES.admin]: 'Admin',
	[ROLES.basic_user]: 'Basic User',
	[ROLES.manager]: 'Manager',
	[ROLES.trusted_user]: 'Trusted User',
};

export const CURRENCY = {
	dollar: 'Dollar ($)',
	euro: 'Euro (€)',
	pound: 'Pound (£)',
};

export const LANDING_PAGES = {
	iot: 'iot',
	library: 'library',
	viewer: 'viewer',
};

//* *********************************************************************************************************************

export class UserCriteria extends Criteria {
	constructor(source, props) {
		super();
		assign(this, this, source);

		const cid = takeCompanyId(source, props) || 0;

		if (cid >= 1) {
			this.company_id = cid;
		}
	}

	toQuery(fromPage) {
		const query = super.toQuery(fromPage);

		if (this.company_id) {
			query.company_id = this.company_id;
		}

		return query;
	}
}

//* *********************************************************************************************************************

export const DEFAULT_CRITERIA = new UserCriteria({
	filter: '',
	sort_by: `user.${USER.display_name}`,
	sort_direction: SORT_DIRECTIONS.asc,
});
