import { generatePath, Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import BreadcrumbCustom from './BreadcrumbCustom';

const addPrefix = (path, prefix) => {
	return prefix ? `${prefix}/${path || ''}` : path;
};

function Breadcrumb(props) {
	const { breadcrumb, component, ...rest } = props;
	const params = useParams();

	let {
		linkPath = props.linkPath,
		onClick = props.onClick,
		pathPrefix = props.pathPrefix,
	} = breadcrumb;
	pathPrefix = generatePath(pathPrefix, params);

	const handleClick = e => {
		onClick && onClick(breadcrumb, e);
	};

	if (component) {
		return (
			<BreadcrumbCustom
				Component={component}
				breadcrumb={breadcrumb}
				linkPath={linkPath}
				pathPrefix={pathPrefix}
				onClick={onClick}
				{...rest}
			/>
		);
	}

	return (
		<Link to={addPrefix(breadcrumb[linkPath], pathPrefix)} onClick={handleClick}>
			{breadcrumb.name}
		</Link>
	);
}

Breadcrumb.propTypes = {
	breadcrumb: PropTypes.object.isRequired,
	component: PropTypes.element,
	isLastElement: PropTypes.bool,
	linkPath: PropTypes.any,
	pathPrefix: PropTypes.string,
};

Breadcrumb.defaultProps = {
	linkPath: 'path',
};

export default Breadcrumb;
