import {
	faBlackTie,
	faInternetExplorer,
	faSlideshare,
	faWindows,
} from '@fortawesome/free-brands-svg-icons';
import {
	faBell as farBell,
	faBuilding,
	faCalendar as farCalendar,
	faCalendarXmark as farCalendarXmark,
	faCamera as farCamera,
	faCar,
	faCircle as farCircle,
	faCircleNotch as farCircleNotch,
	faCircleQuestion as farCircleQuestion,
	faClock as farClock,
	faCommenting as farCommenting,
	faComments as farComments,
	faCompass as farCompass,
	faCube as farCube,
	faDotCircle as farDotCircle,
	faDownload as farDownload,
	faEarthEurope as farEarthEurope,
	faEnvelope as farEnvelope,
	faFile as farFile,
	faFileAudio as farFileAudio,
	faFileCode as farFileCode,
	faFileCsv as farFileCsv,
	faFileExcel as farFileExcel,
	faFileImage as farFileImage,
	faFilePdf as farFilePdf,
	faFilePowerpoint as farFilePowerpoint,
	faFiles as farFiles,
	faFileText as farFileText,
	faFileVideo as farFileVideo,
	faFileWord as farFileWord,
	faFileZipper as farFileZipper,
	faFloppyDisk as farFloppyDisk,
	faFolder as farFolder,
	faFolderClosed as farFolderClosed,
	faFolderOpen as farFolderOpen,
	faGauge as farGauge,
	faGem as farGem,
	faGraduationCap as farGraduationCap,
	faHandPointer as farHandPointer,
	faHandshake as farHandshake,
	faHourglass as farHourglass,
	faImage as farImage,
	faLayerGroup as farLayerGroup,
	faMap as farMap,
	faPerson as farPerson,
	faPieChart as farPieChart,
	faPlusSquare as farPlusSquare,
	faS as faLetterS,
	faSatellite,
	faShovel as farShovel,
	faSignOut as farSignOut,
	faSquare as farSquare,
	faSquareCheck as farSquareCheck,
	faSquareO as farSquareO,
	faStreetView as farStreetView,
	faTableColumns as farTableColumns,
	faTags as farTags,
	faTape as farTape,
	faTrain,
	faUserCircle as farUserCircle,
	faVrCardboard as farVrCardboard,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faAlignCenter,
	faAlignJustify,
	faAlignLeft,
	faAlignRight,
	faAngleDoubleDown,
	faAngleDoubleUp,
	faAngleLeft,
	faAngleRight,
	faAreaChart,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrows,
	faArrowUp,
	faAt,
	faBalanceScale,
	faBan,
	faBarChart,
	faBars,
	faBold,
	faBolt,
	faBullhorn,
	faCalendar,
	faCamera,
	faCameraRetro,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircleXmark,
	faClipboard,
	faClose,
	faCloud,
	faCloudUpload,
	faCode,
	faCog,
	faCogs,
	faCompass,
	faCopy,
	faCreditCard,
	faCube,
	faCubes,
	faDownload,
	faDrone,
	faEdit,
	faEllipsisH,
	faEllipsisV,
	faExchange,
	faExclamation,
	faExclamationTriangle,
	faExternalLink,
	faEye,
	faEyeSlash,
	faFile,
	faFiles,
	faFileText,
	faFlag,
	faFlask,
	faFolder,
	faFont,
	faGbp,
	faGear,
	faGears,
	faGlobe,
	faGraduationCap,
	faGrid,
	faHeartbeat,
	faInfo,
	faInfoCircle,
	faItalic,
	faLaptop,
	faLevelUp,
	faLifeRing,
	faLineChart,
	faLink,
	faList,
	faListAlt,
	faListOl,
	faListUl,
	faLock,
	faLongArrowRight,
	faMailForward,
	faMale,
	faMap,
	faMapMarker,
	faMinus,
	faMobilePhone,
	faMountain,
	faObjectUngroup,
	faPaintBrush,
	faPanorama,
	faPaperclip,
	faParagraph,
	faPen,
	faPencil,
	faPencilSquare,
	faPhone,
	faPieChart,
	faPlay,
	faPlug,
	faPlus,
	faPuzzlePiece,
	faQuestion,
	faQuestionCircle,
	faRefresh,
	faRemove,
	faRepeat,
	faRobot,
	faSave,
	faSearch,
	faSearchMinus,
	faSearchPlus,
	faSeedling,
	faSend,
	faShare,
	faShareAlt,
	faShareAltSquare,
	faShoppingBasket,
	faShoppingCart,
	faSitemap,
	faSliders,
	faSpinner,
	faSquare,
	faSquareCheck,
	faStop,
	faStreetView,
	faStrikethrough,
	faSubscript,
	faSubway,
	faSuperscript,
	faTable,
	faTachometer,
	faTag,
	faTags,
	faTasks,
	faTh,
	faThermometerEmpty,
	faThermometerHalf,
	faThLarge,
	faTimes,
	faTrash,
	faTv,
	faUnderline,
	faUndo,
	faUnlock,
	faUpload,
	faUser,
	faUserCircle,
	faUsers,
	faVideoCamera,
	faWarning,
	faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { faIndustryWindows } from '@fortawesome/sharp-regular-svg-icons';

import { customFontAwesomeIcon } from '@/images/custom-icons/vision-icons';

const fasvBim = customFontAwesomeIcon('bim');
const fasvFloorPlan = customFontAwesomeIcon('floorPlan');
const fasvPointCloud = customFontAwesomeIcon('pointCloud');
const fasvSplitViewHorizontal = customFontAwesomeIcon('splitViewHorizontal');
const fasvOsmToggle = customFontAwesomeIcon('osmToggle');

const FA = {
	align_center: faAlignCenter,
	align_justify: faAlignJustify,
	align_left: faAlignLeft,
	align_right: faAlignRight,
	angle_double_down: faAngleDoubleDown,
	angle_double_up: faAngleDoubleUp,
	angle_left: faAngleLeft,
	angle_right: faAngleRight,
	area_chart: faAreaChart,
	arrow_down: faArrowDown,
	arrow_left: faArrowLeft,
	arrow_right: faArrowRight,
	arrow_up: faArrowUp,
	arrows: faArrows,
	at: faAt,
	balance_scale: faBalanceScale,
	ban: faBan,
	bar_chart: faBarChart,
	bars: faBars,
	bell_o: farBell,
	bim: fasvBim,
	black_tie: faBlackTie,
	bold: faBold,
	bolt: faBolt,
	building: faBuilding,
	bullhorn: faBullhorn,
	calendar: faCalendar,
	calendar_o: farCalendar,
	calendar_times_o: farCalendarXmark,
	camera: faCamera,
	camera_o: farCamera,
	camera_retro: faCameraRetro,
	car: faCar,
	caret_down: faCaretDown,
	caret_up: faCaretUp,
	check: faCheck,
	check_circle: faCheckCircle,
	check_square: faSquareCheck,
	check_square_o: farSquareCheck,
	chevron_circle_left: faChevronCircleLeft,
	chevron_circle_right: faChevronCircleRight,
	chevron_down: faChevronDown,
	chevron_left: faChevronLeft,
	chevron_right: faChevronRight,
	chevron_up: faChevronUp,
	circle_o: farCircle,
	circle_o_notch: farCircleNotch,
	circle_question_o: farCircleQuestion,
	circle_thin: farCircle,
	circle_x_mark: faCircleXmark,
	clipboard: faClipboard,
	clock_o: farClock,
	close: faClose,
	cloud: faCloud,
	cloud_upload: faCloudUpload,
	code: faCode,
	cog: faCog,
	cogs: faCogs,
	commenting_o: farCommenting,
	comments: farComments,
	compass: faCompass,
	compass_o: farCompass,
	copy: faCopy,
	credit_card: faCreditCard,
	cube: faCube,
	cube_o: farCube,
	cubes: faCubes,
	dot_circle_o: farDotCircle,
	download: faDownload,
	download_o: farDownload,
	drone: faDrone,
	earth_europe: farEarthEurope,
	edit: faEdit,
	ellipsis_h: faEllipsisH,
	ellipsis_v: faEllipsisV,
	envelope_o: farEnvelope,
	exchange: faExchange,
	exclamation: faExclamation,
	exclamation_triangle: faExclamationTriangle,
	external_link: faExternalLink,
	eye: faEye,
	eye_slash: faEyeSlash,
	file: faFile,
	file_audio_o: farFileAudio,
	file_code_o: farFileCode,
	file_csv_o: farFileCsv,
	file_excel_o: farFileExcel,
	file_image_o: farFileImage,
	file_movie_o: farFileVideo,
	file_o: farFile,
	file_pdf_o: farFilePdf,
	file_powerpoint_o: farFilePowerpoint,
	file_sound_o: farFileAudio,
	file_text: faFileText,
	file_text_o: farFileText,
	file_video_o: farFileVideo,
	file_word_o: farFileWord,
	file_zip_o: farFileZipper,
	files: faFiles,
	files_o: farFiles,
	flag: faFlag,
	flask: faFlask,
	floor_plan: fasvFloorPlan,
	floppy_o: farFloppyDisk,
	folder: faFolder,
	folder_closed_o: farFolderClosed,
	folder_o: farFolder,
	folder_open_o: farFolderOpen,
	font: faFont,
	gauge_o: farGauge,
	gbp: faGbp,
	gear: faGear,
	gears: faGears,
	gem_o: farGem,
	globe: faGlobe,
	graduation_cap: faGraduationCap,
	graduation_cap_o: farGraduationCap,
	grid: faGrid,
	hand_pointer_o: farHandPointer,
	handshake_o: farHandshake,
	heartbeat: faHeartbeat,
	hourglass_o: farHourglass,
	image: farImage,
	industryWindows: faIndustryWindows,
	info: faInfo,
	info_circle: faInfoCircle,
	internet_explorer: faInternetExplorer,
	italic: faItalic,
	laptop: faLaptop,
	layer_group_o: farLayerGroup,
	letter_s: faLetterS,
	level_up: faLevelUp,
	line_chart: faLineChart,
	link: faLink,
	list: faList,
	list_alt: faListAlt,
	list_ol: faListOl,
	list_ul: faListUl,
	lock: faLock,
	long_arrow_right: faLongArrowRight,
	mail_forward: faMailForward,
	male: faMale,
	map: faMap,
	map_marker: faMapMarker,
	map_o: farMap,
	minus: faMinus,
	mobile_phone: faMobilePhone,
	mountain: faMountain,
	object_ungroup: faObjectUngroup,
	osm_toggle: fasvOsmToggle,
	paint_brush: faPaintBrush,
	panorama: faPanorama,
	paperclip: faPaperclip,
	paragraph: faParagraph,
	pen: faPen,
	pencil: faPencil,
	pencil_square: faPencilSquare,
	person_o: farPerson,
	phone: faPhone,
	picture_o: farImage,
	pie_chart: faPieChart,
	pie_chart_o: farPieChart,
	play: faPlay,
	plug: faPlug,
	plus: faPlus,
	plus_square_o: farPlusSquare,
	point_cloud: fasvPointCloud,
	puzzle_piece: faPuzzlePiece,
	question: faQuestion,
	question_circle: faQuestionCircle,
	refresh: faRefresh,
	remove: faRemove,
	repeat: faRepeat,
	robot: faRobot,
	satellite: faSatellite,
	save: faSave,
	search: faSearch,
	search_minus: faSearchMinus,
	search_plus: faSearchPlus,
	seedling: faSeedling,
	send: faSend,
	share: faShare,
	share_alt: faShareAlt,
	share_alt_square: faShareAltSquare,
	shopping_basket: faShoppingBasket,
	shopping_cart: faShoppingCart,
	shovel_o: farShovel,
	sign_out: farSignOut,
	sitemap: faSitemap,
	sliders: faSliders,
	slideshare: faSlideshare,
	spinner: faSpinner,
	split_view_horizontal: fasvSplitViewHorizontal,
	square: faSquare,
	square_empty: farSquare,
	square_o: farSquareO,
	sterling_sign: faGbp,
	stop: faStop,
	street_view: faStreetView,
	street_view_o: farStreetView,
	strikethrough: faStrikethrough,
	subscript: faSubscript,
	subway: faSubway,
	superscript: faSuperscript,
	support: faLifeRing,
	table: faTable,
	table_columns_o: farTableColumns,
	tachometer: faTachometer,
	tag: faTag,
	tags: faTags,
	tags_o: farTags,
	tape_o: farTape,
	tasks: faTasks,
	th: faTh,
	th_large: faThLarge,
	thermometer_empty: faThermometerEmpty,
	thermometer_half: faThermometerHalf,
	times: faTimes,
	train: faTrain,
	trash: faTrash,
	tv: faTv,
	underline: faUnderline,
	undo: faUndo,
	unlock: faUnlock,
	upload: faUpload,
	user: faUser,
	user_circle: faUserCircle,
	user_circle_o: farUserCircle,
	users: faUsers,
	video_camera: faVideoCamera,
	vr_cardboard_o: farVrCardboard,
	warning: faWarning,
	windows: faWindows,
	wrench: faWrench,
};

Object.keys(FA).forEach(key => {
	const hyphened = key.replace(/_/g, '-');
	if (key !== hyphened) {
		FA[hyphened] = FA[key];
	}
});

export default FA;
