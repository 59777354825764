import { cloneElement } from 'react';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import FA from '@/types/font_awesome';

const ITEM_CLASS = 'sv-GridItem';
const ICON_CLASS = `${ITEM_CLASS}-icon`;

function GridItem({
	children,
	index,
	isFilePreviewable,
	item,
	onClick,
	onContextMenu,
	onDoubleClick,
	onMouseOut,
	onMouseOver,
	onSelection,
	updatePreviewItem,
	...rest
}) {
	const { disabled, isSelected, selectable, selecting } = rest;

	const showPreview = isFilePreviewable(item.site?.type);

	const handleMouseOver = e => {
		onMouseOver?.(index, e);
	};

	const handleMouseOut = e => {
		onMouseOut?.(index, e);
	};

	const toggleSelected = e => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		onSelection?.(index, e);
	};

	const handleClick = e => {
		if (selecting && onSelection) {
			return toggleSelected(e);
		}
		if (disabled) {
			e.preventDefault();
			return;
		}
		onClick?.(index, e);
	};

	const handleDoubleClick = e => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		onDoubleClick?.(index, e);
	};

	const handleContextMenu = e => {
		e?.preventDefault();
		onContextMenu?.(index, e);
	};

	const className = classNames({
		[ITEM_CLASS]: ITEM_CLASS,
		[`${ITEM_CLASS}-disabled`]: disabled,
		[`${ITEM_CLASS}-selected`]: isSelected,
		[`${ITEM_CLASS}-selectable`]: !disabled && !isSelected,
	});
	const childProps = {
		disabled,
		isSelected,
		...rest,
		onClick: handleClick,
		onContextMenu: onContextMenu ? handleContextMenu : undefined,
		onDoubleClick: onDoubleClick ? handleDoubleClick : undefined,
		onMouseOut: onMouseOut ? handleMouseOut : undefined,
		onMouseOver: onMouseOver ? handleMouseOver : undefined,
		onSelection: toggleSelected,
	};

	return (
		<div className={className}>
			<div className={`${ITEM_CLASS}-overlay`} />

			<div className={`${ITEM_CLASS}-content`}>{cloneElement(children, childProps)}</div>

			<div className={`${ITEM_CLASS}-controls`}>
				{selectable && onSelection ? (
					<FontAwesomeIcon
						className={classNames(ICON_CLASS, `${ICON_CLASS}-select`)}
						icon={isSelected ? FA.check_circle : FA.circle_o}
						onClick={toggleSelected}
					/>
				) : null}

				{!selecting && selectable && onContextMenu ? (
					<div className={`${ICON_CLASS}-context`}>
						{showPreview && (
							<FontAwesomeIcon
								className={classNames(ICON_CLASS)}
								icon={faEye}
								title="Preview"
								onClick={e => updatePreviewItem(item, e)}
							/>
						)}
						<FontAwesomeIcon
							className={classNames(ICON_CLASS)}
							icon={FA.ellipsis_h}
							title="Context menu"
							onClick={handleContextMenu}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default GridItem;
