import { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorScreen } from '@spinview/shared-components';
import PropTypes from 'prop-types';

import { initialize, resetAllOps, throwFatalError } from '@/actions/application';
import * as logger from '@/lib/logger';
import * as routes from '@/lib/routes';

import './BootingScreen.scss';

export class BootingScreen extends Component {
	static propTypes = {
		error: PropTypes.object,
		initialized: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			info: null,
		};
	}

	componentDidCatch(error, info) {
		if (!error) {
			error = new Error('unknown');
		}
		error.info = info;
		if (info) {
			info.stack = error.stack;
		}
		logger.error(error); // eslint-disable-line no-console
		return this.props.throwFatalError(error);
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	wrap(content) {
		return <div className="sv-BootingScreen container">{content}</div>;
	}

	renderInitializing() {
		return this.wrap();
	}

	handleResetAllOps = () => {
		this.props.resetAllOps();
		if (!this.props.initialized) {
			this.props.initialize();
		}
		this.setState({ error: null, info: null });
	};

	renderError() {
		return this.wrap(
			<ErrorScreen
				error={this.state.error || this.props.error}
				onDismiss={this.handleResetAllOps}
				onReturnHome={() => routes.goToAppLanding(true)}
			/>,
		);
	}

	render() {
		if (this.state.error || this.props.error) {
			return this.renderError();
		}

		if (!this.props.initialized) {
			return this.renderInitializing();
		}

		return this.props.children;
	}
}

const mapStateToProps = state => {
	return {
		error: state.fatalError,
		history: state.history,
		initialized: state.initialized,
	};
};

const mapDispatchToProps = dispatch => ({
	initialize: () => dispatch(initialize()),
	resetAllOps: () => dispatch(resetAllOps()),
	throwFatalError: error => dispatch(throwFatalError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BootingScreen);
