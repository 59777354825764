/* eslint-disable import/order */
import accounting from 'accounting';
import { Buffer } from 'buffer';
import classNames from 'classnames';
import { constant, get, isEqual, orderBy, set, times } from 'lodash';
/* eslint-disable no-prototype-builtins */
import moment from 'moment';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import tweenFunctions from 'tween-functions';
import uuidV4 from 'uuid/v4';

import ENV from '@/env';
import { CURRENCY_SYMBOLS } from '@/types/consts';
import IOC_TO_ISO from '@/types/ioc_iso_mapping';
import ISO_TO_IOC from '@/types/iso_ioc_mapping';

/** @typedef {import('moment').Moment} Moment */

/** ******************************************************************************************************************* */

export const isChromeBrowser = /Chrome/.test(navigator.userAgent);

/**
 * @param {import('history').Location & {query?: {[k: string]: string}}} location
 */
export function getLocationWithQuery(location) {
	if (!location) {
		return location;
	}

	const entries = [];
	const searchParams = new URLSearchParams(location.search);
	searchParams.forEach((value, key) => entries.push([key, value]));

	location.query = Object.fromEntries(entries);
	return location;
}

/**
 * @param {T} type
 * @param {D} [data]
 * @returns {{type: T} & D}
 * @template {string} T
 * @template {{[x: string]: any} | {}} D
 */
export const withType = (type, data) => {
	const result = /** @type {{type: T} & D} */ (data || {});
	result.type = type;
	return result;
};

/**
 * @param {string} baseUrl
 */
export function joinUrlPath(baseUrl) {
	let url = baseUrl || '';
	for (let i = 1; i < arguments.length; i++) {
		let fragment = arguments[i];
		if (!fragment) {
			continue;
		}
		const slashLeft = url[url.length - 1] === '/';
		const slashRight = fragment[0] === '/';
		if (!slashLeft && !slashRight) {
			url += '/';
		} else if (slashLeft && slashRight) {
			fragment = fragment.slice(1);
		}
		url += fragment;
	}
	if (url[url.length - 1] === '/') {
		url = url.slice(0, -1);
	}
	return url;
}

/**
 *
 * @param {string} url
 */
export const imageStoreUrl = url => {
	const url64 = Buffer.from(url).toString('base64');
	return `${ENV.image_store_url}/images/proxy/${url64}`;
};

/**
 * Format date or moment to string suitable for dict keys
 * @param {Date|Moment|*} d
 * @return {string}
 */
export const toTimeOrdinalString = d => {
	return d.toISOString ? d.toISOString() : moment(d).toISOString();
};

/**
 * Generate array of time units with jump step
 *
 * @param {moment.unitOfTime.Base} unit
 * @param {Moment|object|Date} from
 * @param {Moment|object|Date} [to]
 * @param {Number} [step]
 * @returns {Array}
 */
export const getTimeOrdinals = (unit, from, to = undefined, step = 1) => {
	to = moment.utc(to).startOf(unit);
	let m = moment.utc(from).startOf(unit);
	const ordinals = [toTimeOrdinalString(m)];

	while (m.isBefore(to)) {
		m = m.add(step, unit);
		ordinals.push(toTimeOrdinalString(m));
	}

	return ordinals;
};

/**
 * Converts default date format to human redable
 * @param {moment.MomentInput} date
 */
export const convertDateToHumanReadable = (date, format = ENV.date_format.short) => {
	const timestamp = moment(date);
	const timeAgo = moment().isAfter(timestamp, 'week')
		? timestamp.format(format)
		: timestamp.fromNow();
	return timeAgo;
};

/**
 * Default start date for generating time ordinals
 * @param {string} unit
 * @return {moment.Moment}
 */
export const getDefaultTimeOrdinalsLowerBound = unit => {
	switch (unit) {
		case 'hour':
			return moment.utc().subtract(1, 'day');
		case 'day':
			return moment.utc().subtract(1, 'month');
		case 'month':
			return moment.utc().subtract(1, 'year');
		case 'year':
			return moment.utc().subtract(5, 'years');
		default:
			throw new Error(`Unsupported unit: ${unit}`);
	}
};

/**
 * Time ordinals for the default period (last day, last month...)
 * WARNING: This is a legacy function and shouldn't be used by new code.
 * @param {moment.unitOfTime.Base} unit
 */
export const getDefaultTimeOrdinals = unit => {
	const from = getDefaultTimeOrdinalsLowerBound(unit);
	return getTimeOrdinals(unit, from);
};

/**
 * @param {Number} to
 * @param {Number} from
 * @returns {number}
 */
export const randomInt = (to, from = 0) => {
	return Math.floor(from + Math.random() * (to - from));
};

const RANDOM_CHARACTER_POOL = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

/**
 * @param {Number} chars Number of characters
 * @returns {string}
 */
export const randomString = (chars = 10) => {
	const res = [];
	for (let i = 0; i < chars; i++) {
		res.push(RANDOM_CHARACTER_POOL[randomInt(RANDOM_CHARACTER_POOL.length)]);
	}
	return res.join('');
};

let _last_popup_index = 0;

/**
 * @param {string} url
 * @param {Number} width
 * @param {Number} height
 * @param {string} name
 */
export const openPopup = (url, width = 500, height = 400, name = null) => {
	if (!name) {
		_last_popup_index++;
		name = `SvPopup${_last_popup_index}`;
	}

	const popup = window.open(url, name, `height=${height}, width=${width}`);
	popup.focus && popup.focus();
	return popup;
};

/**
 * Convert country ISO code to IOC
 *
 * @param iso
 * @returns {*}
 */
export const isoToIoc = iso => {
	const ioc = ISO_TO_IOC[iso];

	if (ioc === null) {
		throw new Error(`No IOC code for iso ${iso}`);
	}

	return ioc;
};

/**
 * Convert country IOC code to ISO
 *
 * @param ioc
 * @returns {*}
 */
export const iocToIso = ioc => {
	const iso = IOC_TO_ISO[ioc];

	if (iso === null) {
		throw new Error(`No IOC code for iso ${ioc}`);
	}

	return iso;
};

export const toHumanPrice = accountingPrice => {
	return accountingPrice / 100;
};

export const toAccountingPrice = humanPrice => {
	return Math.round(humanPrice * 100);
};

const currencyPrefix = CURRENCY_SYMBOLS[ENV.currency];
export const formatPrice = accountingPrice => {
	return accounting.formatMoney(toHumanPrice(accountingPrice), currencyPrefix, 2);
};

export const filterToRegExp = filterStr => {
	const words = filterStr.split(' ').map(word => word.replace(/[[^$.|?*+()]/g, ''));
	return new RegExp(words.join('.+'), 'i');
};

/**
 * Apply criteria to a collection and return filtered/sorted array
 * @param {object} data
 * @param {import('../types/data').Criteria} criteria
 * @param {string[]}FILTER_PATHS dot separated paths to use in filters. Eg. ["tour.name"]
 * @returns {*}
 */
export const applyCriteria = (data, criteria, FILTER_PATHS) => {
	const regexp = criteria.filter ? filterToRegExp(criteria.filter) : null;

	const items = Object.values(data).filter(item => {
		return (
			!regexp ||
			FILTER_PATHS.reduce((found, path) => {
				return found || regexp.test(get(item, path));
			}, false)
		);
	});

	const sortByFields = criteria.sort_by.split(',').map(path => ob => {
		let val = get(ob, path);

		// Make sure we are sorting case insensitive
		if (val && val.toLowerCase) {
			val = val.toLowerCase();
		}

		return val;
	});
	const sortOrderFields = times(sortByFields.length, constant(criteria.sort_direction));
	return orderBy(items, sortByFields, sortOrderFields);
};

/**
 * Assign to target from source, using provided PROPS hash as whitelist
 * @param {object} PROPS
 * @param {object} target
 * @param {object} source
 */
export const assign = (PROPS, target, source) => {
	if (!source) {
		return target;
	}
	for (const key in source) {
		if (
			(!source.hasOwnProperty || source.hasOwnProperty(key)) &&
			PROPS.hasOwnProperty(key) &&
			undefined !== source[key]
		) {
			target[key] = source[key];
		}
	}
	return target;
};

/**
 * Convert "Normal text, YO!" into a "slugified_text___"
 * @param txt
 */
export const slugify = (txt = '') => {
	return (txt || '').replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
};

/**
 * @returns {string} generated uuid
 */
export const uuid = () => {
	return uuidV4();
};

/**
 * Neuters any event with preventDefault
 */
export const preventDefault = e => {
	e && e.preventDefault && e.preventDefault();
	return false;
};

/**
 * Wrap given function with preventDefault
 */
export const withPreventDefault = fn => {
	return e => {
		preventDefault(e);
		fn && fn(e);
		return false;
	};
};

/**
 * Stops event propagation
 */
export const stopPropagation = e => {
	e && e.stopPropagation && e.stopPropagation();
};

/**
 * Wrap given function with stopPropagation
 */
export const withStopPropagation = fn => {
	return e => {
		stopPropagation(e);
		fn && fn(e);
		return false;
	};
};

/**
 * Joins all arguments with dots. Eg. pathify('joe', 'smith') => 'joe.smith'
 */
export function pathify() {
	let result = '';
	for (let i = 0; i < arguments.length; i++) {
		if (result) {
			result += `.${arguments[i]}`;
		} else {
			result = arguments[i];
		}
	}
	return result;
}

/**
 * Create an enum out of public properties of provided object
 * @param {T} ob
 * @returns {{[K in keyof T]: K}}
 * @template T
 */
export const enumize = ob => {
	/** @type {{[K in keyof T]: K}} */
	const result = {};
	for (const key in ob) {
		if (ob.hasOwnProperty(key)) {
			result[key] = key;
		}
	}
	return result;
};

// https://codepen.io/gapcode/pen/vEJNZN
export const detectIE = () => {
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}
	const trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		const rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}
	const edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}
	// other browser
	return false;
};

/**
 * Convert argument to number. If we can't, return fallback.
 * @param val
 * @param fallback
 * @return {*}
 */
export const numberify = (val, fallback = null) => {
	const res = Number(val);
	if (!Number.isNaN(res)) {
		return res;
	}

	return fallback;
};

/**
 * Join all arguments into a className string
 */
export function classes() {
	const results = [];
	for (let i = 0; i < arguments.length; i++) {
		let arg = arguments[i];
		if (!arg) {
			continue;
		}
		if (Array.isArray(arg)) {
			arg = classes.apply(null, arg);
		}
		results.push(String(arg));
	}
	return results.join(' ');
}

/**
 *
 * @param {string} suffix
 * @param  {...string} args
 * @returns {string}
 */
export function mergeClassNames(suffix, ...args) {
	if (!suffix) {
		return classNames(...args);
	}
	const values = args.filter(Boolean).map(c => `${c}-${suffix}`);
	return classNames([...new Set(values)]);
}

/**
 * Safely convert date to moment or return null
 * @param {Date} date
 * @return {Moment|moment}
 */
export const safeMoment = date => {
	let m;
	try {
		m = moment(date);
		if (!m.isValid()) {
			return null;
		}
	} catch (_) {
		return null;
	}
	return m;
};

/**
 * A selector that respects ob.equals(ob2) interface.
 * @param {T} currentVal
 * @param {T} previousVal
 * @param {number} index
 * @template {{equals: (other: T) => boolean}} T
 */
const equalsAwareCombiner = (currentVal, previousVal, index) => {
	if (previousVal && previousVal.equals && previousVal.equals(currentVal)) {
		return true;
	}

	return currentVal === previousVal;
};

export const createEqualsAwareSelector = createSelectorCreator(
	// @ts-ignore
	defaultMemoize,
	equalsAwareCombiner,
);

/**
 * Selector that does deep equality check
 */
export const createDeepEqualitySelector = createSelectorCreator(defaultMemoize, isEqual);

export function traverseChildren(nodes, mapFn) {
	if (!nodes) {
		return;
	}

	if (Array.isArray(nodes)) {
		return nodes.map(node => traverseChildren(node, mapFn)).filter(Boolean);
	}
	const { children } = nodes;

	const filtered = children ? traverseChildren(children, mapFn) : [];

	const node = {
		...nodes,
		children: filtered,
	};

	const result = mapFn(node);
	if (result === undefined || result === true) {
		return node;
	}
	return result;
}

/**
 * Round number to the requested number of decimal places, only if needed.
 * Eg. 1.7777 => 1.78, but 2.6 => 2.6
 * https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 * @param number
 * @param decimalPlaces
 * @return {number}
 */
export const roundIfNeeded = (number, decimalPlaces) => {
	return Number(`${Math.round(Number(`${number}e+${decimalPlaces}`))}e-${decimalPlaces}`);
};

/**
 * Parse a string like "a=b&c&d="
 */
export const parseQueryString = queryString => {
	const obj = {};

	(queryString || '').split('&').forEach(part => {
		if (!part) {
			return;
		}

		const kv = part.split('=');
		const key = decodeURIComponent(kv[0]);

		if (kv.length < 2) {
			// Just a switch, eg. ?enabled
			obj[key] = true;
		} else {
			obj[key] = decodeURIComponent(kv[1]);
		}
	});
	return obj;
};

/**
 * Convert relative url (eg. "/a/b/c") to full href based on current application URL.
 */
export const getFullUrl = (history, route) => {
	return history.createHref(typeof route === 'string' ? { pathname: route } : route);
};

/**
 * Prepare external urls
 */

export const prepareExternalUrl = (url, opts) => {
	if (typeof url !== 'string') {
		throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
	}

	url = url.trim();
	opts = { https: false, ...opts };

	if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
		return url;
	}

	return url.replace(/^(?!(?:\w+:)?\/\/)/, opts.https ? 'https://' : 'http://');
};

/**
 * Make an option object for use with Select type controls. Give it an enum lookup for labels
 */
export const option = (value, LABELS) => {
	return {
		label: (LABELS && LABELS[value]) || value,
		value,
	};
};

/**
 * Guards against mutating state of unmounted component
 * @param component
 * @param promise
 * @returns {Promise}
 */
export const mGuard = (component, promise) => {
	const fns = {};
	const guardPromise = new Promise((resolve, reject) => {
		fns.resolve = resolve;
		fns.reject = reject;
	});

	promise.then(
		res => {
			if (component._mounted) {
				fns.resolve(res);
			}
		},
		err => {
			if (component._mounted) {
				fns.reject(err);
			}
		},
	);

	return guardPromise;
};

/**
 * Attach hooks to track _mounted status of a component.
 * Use it in constructor: mTrack(this);
 */
export const mTrack = component => {
	const didMount = component.componentDidMount;
	component.componentDidMount = function onMountWrapper() {
		component._mounted = true;
		if (didMount) {
			didMount.apply(this, arguments);
		}
	};

	const willUnmount = component.componentWillUnmount;
	component.componentWillUnmount = function onUnmountWrapper() {
		component._mounted = false;
		if (willUnmount) {
			willUnmount.apply(this, arguments);
		}
	};
};

/**
 * Convert milisseconds to human readable format
 * @param {number} milliseconds
 * @return {String}
 */
export const msToHumanReadableFormat = milliseconds => {
	const time = moment.duration(milliseconds);
	const hours = Math.floor(time.hours());
	const minutes = Math.floor(time.minutes());
	const seconds = `${Math.floor(time.seconds())}sec`;

	return hours > 0
		? `${hours}h ${minutes}m ${seconds}`
		: minutes > 0
		? `${minutes}m ${seconds}`
		: seconds;
};

/**
 * Create a wrapper function with dispatch that just passes along all arguments.
 * Example:
 *     redispatch(dispatch, fn)(a, b, c);
 * is equivalent to:
 *     ((a, b, c) => dispatch(fn(a, b, c)))(a, b, c);
 * @template {(...args: any[]) => T} T
 * @param dispatch
 * @param {T} fn
 * @returns {(...args: any[]) => T}
 */
export function redispatch(dispatch, fn) {
	return function redispatcher() {
		return dispatch(fn.apply(this, arguments));
	};
}

/**
 * Scroll to top of page
 */
export function scrollToTop() {
	if (!window.requestAnimationFrame) {
		window.scrollTo(0, 0);
		return;
	}

	let startTime = null;
	const startY = window.pageYOffset;
	window.requestAnimationFrame(step);

	function step(timestamp) {
		if (!startTime) {
			startTime = timestamp;
		}
		const elapsed = timestamp - startTime;
		const y = Math.max(tweenFunctions.easeOutCubic(elapsed, startY, 0, SCROLL_TO_TOP_DURATION), 0);
		window.scrollTo(0, y);
		if (y > 0) {
			window.requestAnimationFrame(step);
		}
	}
}

/**
 * Cast all members on target to Ctr, if they already aren't that type.
 * Example castMembers(ob, Date, 'created_at', 'updated_at');
 * null-s and undefined-s will be unchanged.
 * @param target
 * @param Ctr
 * @param members
 */
export function castMembers(target, Ctr, ...members) {
	if (!target) {
		return target;
	}

	members.forEach(key => {
		const value = get(target, key);
		if (value !== undefined && value !== null && !(value instanceof Ctr)) {
			const castValue = Ctr.create ? Ctr.create(value) : new Ctr(value);
			set(target, key, castValue);
		}
	});

	return target;
}

const SCROLL_TO_TOP_DURATION = 200;

/**
 * Copies passed variable to clipboard
 * @param text
 *
 */
export function copyToClipboard(text) {
	const dummy = document.createElement('input');
	document.body.appendChild(dummy);
	dummy.setAttribute('value', text);
	/** @type {typeof dummy & {select: () => void}} */
	(dummy).select();
	document.execCommand('copy');
	document.body.removeChild(dummy);
}

/**
 * @callback WithKeyboardEventHandler
 * @param {React.KeyboardEvent<T>} e
 * @template {React.ReactElement} T
 */

/**
 * @export
 * @param {Function} next
 * @returns {WithKeyboardEventHandler<React.ReactElement>}
 */
export function withEnter(next, ...args) {
	if (!next) {
		return;
	}
	/**
	 * @type {WithKeyboardEventHandler<React.ReactElement>}
	 */
	return function _withEnter(e) {
		const key = (e && e.key) || e;
		if (key === 'Enter') {
			e.preventDefault();
			next(e, ...args);
			return false;
		}
	};
}

/**
 * @export
 * @param {Function} next
 * @returns {WithKeyboardEventHandler<React.ReactElement>}
 */
export function withShiftEnter(next, ...args) {
	if (!next) {
		return;
	}
	/**
	 * @type {WithKeyboardEventHandler<React.ReactElement>}
	 */
	return function _withShiftEnter(e) {
		if (!e || !e.shiftKey) {
			return;
		}
		const key = (e && e.key) || e;
		if (key === 'Enter') {
			return next(e, ...args);
		}
	};
}

export function loadFiles(files) {
	files.forEach(file => {
		file.relativePath = file.path.startsWith('/') ? file.path.slice(1) : file.path;
	});
}

export function isRetinaDisplay() {
	if (!window.matchMedia) {
		return false;
	}
	const mediaQueries = [
		'min--moz-device-pixel-ratio: 1.3',
		'-webkit-min-device-pixel-ratio: 1.3',
		'min-device-pixel-ratio: 1.3',
		'min-resolution: 1.3dppx',
	]
		.map(query => ['only screen and(', query, ')'].join(''))
		.join(', ');

	const mq = window.matchMedia(mediaQueries);
	return (mq && mq.matches) || window.devicePixelRatio > 1;
}

const DIRECT_REGEX = /([^\\]|^)\$([A-Za-z$_][A-Za-z$_0-9]*)/g;
const CURLY_BRACE_REGEX = /([^\\]|^)\$\{([^}]+)}/g;
const ESCAPE_CLEANUP_REGEX = /\\\$/g;

function notFoundToEmpty() {
	return '';
}

/**
 * @param {string} key
 */
function notFoundToError(key) {
	const err = new Error(`No data for expand key: ${key}`);
	/** @type {Error & {key: string}} */
	(err).key = key;
	throw err;
}

/**
 * Micro-templating, using *sh (or php) style formatting. Usage:
 * expandString('Hey $name', {name: 'Jack'}); // --> Hey Jack
 * expandString('Hey ${user.name}', {user: { name: 'Jack'}}); // --> Hey Jack
 * expandString('Hey \\${user.name}', {user: { name: 'Jack'}}); // --> Hey ${user.name}
 * The last argument is optional 'not found' handler.
 * - falsy (default) to replace with empty string.
 * - true to throw an error.
 * - function to act as custom handler (get matched key, return value or throw)
 * @param {string} str Input text
 * @param {object} data Object with key/values to use for replacement
 * @param {any} [notFound]
 * @returns {string}
 * @memberOf tools
 */
export function expandString(str, data, notFound) {
	str = str || '';
	data = data || {};

	if (notFound === true) {
		notFound = notFoundToError;
	} else if (!notFound) {
		notFound = notFoundToEmpty;
	}

	return str
		.replace(DIRECT_REGEX, directReplacer)
		.replace(CURLY_BRACE_REGEX, pathReplacer)
		.replace(ESCAPE_CLEANUP_REGEX, '$');

	function directReplacer(_, space, key) {
		let value = data[key];
		if (value === undefined) {
			value = notFound(key);
		}
		return (space || '') + value;
	}

	function pathReplacer(_, space, path) {
		let value = get(data, path);
		if (value === undefined) {
			value = notFound(path);
		}
		return (space || '') + value;
	}
}

/**
 * Return object with all keys from ob that match prefix, minus the prefix.
 * Useful for aliasing with SQL or query string.
 * Use idKey to check if the record is even returned (it must be truthy after de-prefixing).
 * @example
 * var ob = { 'a.a': 1, 'b.b': 2, 'a': 3 }
 * extractPrefixed(ob, 'a.'); // --> { a: 1 }
 * @param {object} ob
 * @param {string} prefix
 * @param {string|null} idKey Property to check for existence. If this is null, we return null
 * @memberOf tools
 */
export function extractPrefixed(ob, prefix, idKey = 'id') {
	const res = {};
	const prefixLength = prefix.length;
	for (const key in ob) {
		if (!ob.hasOwnProperty(key)) {
			continue;
		}
		if (key.length <= prefixLength) {
			continue;
		}
		if (key.substring(0, prefixLength) === prefix) {
			const resKey = key.slice(prefixLength);
			res[resKey] = ob[key];
		}
	}
	if (idKey && !res[idKey]) {
		return null;
	}
	return res;
}

export function dataUrlToBlob(dataUrl, type = 'image/png') {
	if (!dataUrl) return null;
	const binStr = atob(dataUrl.split(',')[1]);
	const len = binStr.length;
	const arr = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		arr[i] = binStr.charCodeAt(i);
	}
	return new Blob([arr], { type });
}

export function stripHtml(html) {
	const tmp = document.createElement('div');
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || '';
}
