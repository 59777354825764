import { Component } from 'react';
import PropTypes from 'prop-types';

import FilterInput from '@/views/widgets/input/FilterInput';

import CriteriaFilterDropdown from './dropdown/CriteriaFilterDropdown';

import './CriteriaFilterField.scss';

class CriteriaFilterField extends Component {
	static propTypes = {
		criteria: PropTypes.object,
		loading: PropTypes.bool,
		onCriteriaChanged: PropTypes.func.isRequired,
		withDropdown: PropTypes.bool,
	};

	handleChange = value => {
		const newCriteria = this.props.criteria.update({
			filter: value,
		});

		this.props.onCriteriaChanged(newCriteria);
	};

	render() {
		const { children, criteria, onCriteriaChanged, withDropdown, ...props } = this.props;
		const filter = criteria ? criteria.filter : undefined;
		return (
			<div className="sv-Criteria-filter">
				<FilterInput filter={filter} onFilterChange={this.handleChange} {...props} />
				{withDropdown && <CriteriaFilterDropdown>{children}</CriteriaFilterDropdown>}
			</div>
		);
	}
}

export default CriteriaFilterField;
