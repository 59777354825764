import { Component } from 'react';
import Lightbox from 'react-images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { min } from 'lodash';
import PropTypes from 'prop-types';

import ENV from '@/env';
import { makeImageUrl } from '@/lib/api';
import { isRetinaDisplay } from '@/lib/util';
import FA from '@/types/font_awesome';
import TriggeredPopover from '@/views/widgets/popovers/TriggeredPopover';

import './Image.scss';

const MAX_WIDTH = ENV.visuals.preview_image_max_width;
const MAX_HEIGHT = ENV.visuals.preview_image_max_height;

const CLASS = 'sv-Image';
class Image extends Component {
	static propTypes = {
		alt: PropTypes.string,
		classNames: PropTypes.string,
		fallback: PropTypes.element,
		fullSize: PropTypes.bool,
		height: PropTypes.number,
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		imageSrc: PropTypes.string,
		is360: PropTypes.bool,
		isVideo: PropTypes.bool,
		lightbox: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
		max: PropTypes.bool,
		noIconIndicator: PropTypes.bool,
		onClick: PropTypes.func,
		onLoad: PropTypes.func,
		style: PropTypes.object,
		width: PropTypes.number,
	};

	constructor(props) {
		super(props);

		this.state = {
			lightboxIsOpen: false,
		};
	}

	handleImageClick = e => {
		const { image, onClick } = this.props;
		onClick && onClick(e, image);
	};

	renderLightboxOverlay = () => {
		const { image, imageSrc, lightbox } = this.props;

		const shouldRenderOverlay = lightbox && (image || imageSrc) && ENV.show_lightbox;
		if (!shouldRenderOverlay) {
			return null;
		}

		const onClickHandler = typeof lightbox === 'function' ? lightbox : this.toggleLightbox;
		return (
			<TriggeredPopover content="Click here to open the image" title="Open Image">
				<FontAwesomeIcon
					className={`${CLASS}-zoom-icon`}
					icon={FA.image}
					onClick={onClickHandler}
				/>
			</TriggeredPopover>
		);
	};

	renderLightbox = () => {
		const { image, imageSrc, lightbox } = this.props;

		const shouldRenderLightbox =
			lightbox && typeof lightbox !== 'function' && (image || imageSrc) && ENV.show_lightbox;
		if (!shouldRenderLightbox) {
			return null;
		}

		/** @type {string} */
		const src =
			typeof lightbox === 'string'
				? lightbox
				: image
				? makeImageUrl(image.key || image.image_key)
				: imageSrc;

		return (
			<Lightbox
				components={{ FooterCount: null }}
				views={[{ source: src }]}
				modalProps={{
					onClose: this.toggleLightbox,
				}}
			/>
		);
	};

	toggleLightbox = () => {
		this.setState({
			lightboxIsOpen: !this.state.lightboxIsOpen,
		});
	};

	render() {
		let {
			alt,
			className,
			fallback,
			fallbackIcon,
			fullSize,
			height,
			image,
			imageRef,
			imageSrc,
			is360,
			isVideo,
			max,
			noIconIndicator,
			onLoad = null,
			opts,
			showType = true,
			style,
			width,
			wrapperRef,
			...rest
		} = this.props;

		const isRetina = isRetinaDisplay();

		const wrapperStyle = {
			height,
			width,
		};
		const imageStyle =
			(wrapperStyle.width || wrapperStyle.height) && isRetina
				? {
						height: (wrapperStyle.height || 0) * 2,
						width: (wrapperStyle.width || 0) * 2,
				  }
				: { ...wrapperStyle };

		Object.assign(wrapperStyle, style);
		const imageOpts = { ...opts };

		if (!imageStyle.width && !imageStyle.height && !fullSize) {
			imageStyle.width = MAX_WIDTH;
			imageStyle.height = MAX_HEIGHT;
			max = true;
		}

		imageSrc = imageSrc || image || null;
		if (imageSrc && typeof imageSrc !== 'string') {
			imageSrc = makeImageUrl(
				image.key || image.image_key,
				imageStyle.width,
				imageStyle.height,
				max,
				imageOpts,
			);
		}

		is360 = is360 === true || (image && image.is_360) || false;
		isVideo = isVideo === true || (image && image.type === 'video') || false;

		if (imageSrc) {
			let popoverIndicator = null;
			if (showType && (is360 || isVideo)) {
				const popoverIcon = is360 ? FA.street_view : FA.video_camera;
				popoverIndicator = (
					<TriggeredPopover
						content={`This is a 360° ${is360}` ? 'Image' : 'Video preview'}
						title={`360° ${is360}` ? 'Image' : 'Video'}
					>
						<FontAwesomeIcon className={`${CLASS}-360-icon`} icon={popoverIcon} />
					</TriggeredPopover>
				);
			}

			return (
				<div
					ref={wrapperRef}
					className={classNames(className, CLASS, `${CLASS}-wrapper`)}
					style={wrapperStyle}
				>
					{noIconIndicator ? null : popoverIndicator}
					<img
						ref={imageRef}
						alt={alt}
						className={CLASS}
						src={imageSrc}
						style={wrapperStyle}
						title={alt}
						onClick={this.handleImageClick}
						onLoad={onLoad}
						{...rest}
					/>
				</div>
			);
		}

		const iconStyle = {
			fontSize: rest.fontSize || (min([width, height].filter(Boolean)) || 0) * 0.4,
		};

		fallbackIcon =
			fallbackIcon === null ? null : (
				<FontAwesomeIcon icon={fallbackIcon || FA.picture_o} style={iconStyle} />
			);
		const fallbackImage = fallback || fallbackIcon;

		return (
			<div
				ref={wrapperRef}
				className={classNames(className, CLASS, `${CLASS}-fallback`)}
				style={wrapperStyle}
				title={alt}
				onClick={this.handleImageClick}
			>
				<div className={`${CLASS}-fallback-wrapper`}>{fallbackImage}</div>
			</div>
		);
	}
}

export default Image;
