import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';

import { slugify } from '@/lib/util';

import FlavoredPopover from './FlavoredPopover';

function TriggeredPopover({
	children,
	clickTriggered = false,
	content,
	overlayClass = '',
	placement = 'top',
	title,
	...rest
}) {
	const popover = (
		<FlavoredPopover className={overlayClass} id={`popover-${slugify(title)}`} {...rest}>
			{content}
		</FlavoredPopover>
	);

	const trigger = clickTriggered ? ['click'] : ['hover', 'focus', 'click'];

	return (
		<OverlayTrigger
			overlay={popover}
			placement={placement}
			rootClose={clickTriggered}
			trigger={trigger}
		>
			{children}
		</OverlayTrigger>
	);
}

TriggeredPopover.propTypes = {
	clickTriggered: PropTypes.bool,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	flavor: PropTypes.string,
	onDragOver: PropTypes.func,
	overlayClass: PropTypes.string,
	placement: PropTypes.string,
	title: PropTypes.string,
};

export default TriggeredPopover;
