import { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import onClickOutside from 'react-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';

import Label from './Label';

import './TextInput.scss';

const CLASS = 'sv-TextInput';

/** @typedef {typeof _TextInput["propTypes"]} propTypes */

const propKeys = {
	beforeElement: 'beforeElement',
	className: 'className',
	disableOnClickOutside: 'disableOnClickOutside',
	disabled: 'disabled',
	enableOnClickOutside: 'enableOnClickOutside',
	eventTypes: 'eventTypes',
	feedbackIcon: 'feedbackIcon',
	hasError: 'hasError',
	helpBlock: 'helpBlock',
	id: 'id',
	initialValue: 'initialValue',
	label: 'label',
	onBlur: 'onBlur',
	onChange: 'onChange',
	onClickOutside: 'onClickOutside',
	onFocus: 'onFocus',
	onKeyDown: 'onKeyDown',
	optional: 'optional',
	outsideClickIgnoreClass: 'outsideClickIgnoreClass',
	placeholder: 'placeholder',
	preventDefault: 'preventDefault',
	required: 'required',
	rows: 'rows',
	size: 'size',
	stopPropagation: 'stopPropagation',
	type: 'type',
	validationMessage: 'validationMessage',
	validator: 'validator',
	value: 'value',
};

class _TextInput extends Component {
	static propTypes = {
		beforeElement: PropTypes.any,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		feedbackIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		hasError: PropTypes.bool,
		helpBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		id: PropTypes.string,
		initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onClickOutside: PropTypes.func,
		onFocus: PropTypes.func,
		onKeyDown: PropTypes.func,
		optional: PropTypes.bool,
		placeholder: PropTypes.string,
		required: PropTypes.bool,
		rows: PropTypes.number,
		size: PropTypes.string,
		type: PropTypes.string,
		validationMessage: PropTypes.string,
		validator: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	};

	constructor(props) {
		super(props);

		this.state = {
			id: props.id || `text_input_${Math.random()}`,
			value: props.initialValue || '',
		};
	}

	handleChange = e => {
		this.setState({
			value: e.target.value,
		});
		if (this.props.onChange) {
			this.props.onChange(e.target.value, this.state.id);
		}
	};

	handleKeyDown = e => {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	};

	handleClickOutside = evt => {
		const { onClickOutside } = this.props;
		onClickOutside && onClickOutside();
	};

	renderValidationMessage() {
		if (!this.props.validator) {
			return null;
		}

		this.props.validator(this.state.value.toString());

		return (
			<div className={`${CLASS}-validation-message`}>
				<label>{this.props.validationMessage || ''}</label>
			</div>
		);
	}

	renderFeedbackIcon() {
		let { feedbackIcon } = this.props;

		if (!feedbackIcon) {
			return null;
		}

		if (Object.hasOwnProperty.call(feedbackIcon, 'iconName')) {
			feedbackIcon = feedbackIcon.iconName.replace(/-/g, '_');
		}

		if (typeof feedbackIcon === 'string' && FA[feedbackIcon]) {
			feedbackIcon = <FontAwesomeIcon icon={FA[feedbackIcon]} size="sm" />;
		}

		return feedbackIcon;
	}

	setInputValue = (value = '') => {
		this.setState({ value });

		if (this.props.onChange) {
			this.props.onChange(value, this.state.id);
		}
	};

	render() {
		const { onBlur, onFocus } = this.props;
		let controlType = 'text';
		let componentClass = 'input';
		let rows = null;

		if (this.props.type === TextInput.TYPES.password) {
			controlType = 'password';
		} else if (this.props.type === TextInput.TYPES.memo) {
			componentClass = 'textarea';
			rows = this.props.rows || null;
		} else if (this.props.type === TextInput.TYPES.number) {
			controlType = 'number';
			rows = this.props.rows || null;
		} else if (this.props.type === TextInput.TYPES.tel) {
			controlType = 'tel';
			rows = this.props.rows || null;
		} else if (this.props.type === TextInput.TYPES.range) {
			controlType = 'range';
		}

		const helpBlock = this.props.helpBlock ? (
			<Form.Text muted>{this.props.helpBlock}</Form.Text>
		) : null;

		let className = this.props.className || '';
		if (this.props.hasError) {
			className += ' has-error';
		}

		const childProps = {};
		Object.entries(this.props).forEach(([name, prop]) => {
			if (!propKeys[name]) {
				childProps[name] = prop;
			}
		});

		const value = this.props.value !== undefined ? this.props.value : this.state.value;
		const inputHasError = this.props.hasError ? 'has-error' : '';
		return (
			<FormGroup
				className={`${CLASS} ${className} form-group`}
				controlId={this.state.id}
				size={this.props.size}
			>
				{this.props.beforeElement}
				{this.props.label && (
					<Label optional={this.props.optional}>
						{this.props.label}
						<span>{this.props.required && '*'}</span>
					</Label>
				)}
				<FormControl
					ref={this.props.inputFocusRef}
					as={componentClass}
					className={`${this.props.feedbackIcon ? 'has-feedback' : null} ${inputHasError}`}
					disabled={this.props.disabled}
					name={this.state.id}
					placeholder={this.props.placeholder}
					rows={rows}
					type={controlType}
					value={value}
					onBlur={onBlur || null}
					onChange={this.handleChange}
					onFocus={onFocus || null}
					onKeyDown={this.handleKeyDown}
					{...childProps}
				/>
				{this.renderFeedbackIcon()}
				{this.renderValidationMessage()}
				{helpBlock}
				{this.props.children}
			</FormGroup>
		);
	}
}

/** @type {import('react-onclickoutside').WrapperClass<any, typeof _TextInput> & {propTypes?: typeof _TextInput['propTypes'], TYPES?: typeof TYPES}} */
const TextInput = onClickOutside(_TextInput);

const TYPES = {
	date: 'date',
	memo: 'memo',
	number: 'number',
	password: 'password',
	range: 'range',
	tel: 'tel',
	text: 'text',
};

TextInput.TYPES = TYPES;
export default TextInput;
