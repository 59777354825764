import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { get } from 'lodash';

import FA from '@/types/font_awesome';
import { FOLDER_TYPES, LIBRARY_FOLDER_ICONS } from '@/types/library_folders';
import Image from '@/views/widgets/images/Image';

import './Folder.scss';

const CLASS = 'sv-Folder';

const companyLogoFallback = require('../../../images/sv_icons/library.png');

const pathToFolder = (path, prefix) => `${prefix}/${path}`;

export default function Folder({
	createHref,
	criteria,
	isSelected,
	item,
	linkPath,
	onClick,
	onContextMenu,
	onDoubleClick,
	pathPrefix,
	...props
}) {
	const { asset, folder, image, isCompany, logo, preview, type } = item;
	const linkPathValue = linkPath ? get(item, linkPath) : item;
	const folderTypeIcon = LIBRARY_FOLDER_ICONS[type];
	const folderPath =
		typeof createHref === 'function'
			? createHref(linkPathValue)
			: pathToFolder(linkPathValue, pathPrefix);

	let imgClass = `${CLASS}-preview`;
	const imgOptions = {
		fallbackIcon: null,
		height: 20,
		image: preview,
		max: false,
	};

	if ((image || isCompany) && !folderTypeIcon) {
		imgClass = classNames(CLASS, `${CLASS}-preview-full`);
		imgOptions.fontSize = 66;
		imgOptions.height = 128;
		imgOptions.max = true;
	}
	if (typeof imgOptions.image === 'string') {
		imgOptions.imageSrc = imgOptions.image;
		delete imgOptions.image;
	}
	let className = classNames(CLASS, props.className, isSelected ? `${CLASS}-selected` : null);

	if (folder.type) {
		className = classNames(className, [CLASS, folder.type.replace(/_/g, '-')].join('-'));
	}

	const descriptionClass = `${CLASS}-description-outer`;
	const nameClass = `${CLASS}-name`;
	const assetClass = `${CLASS}-asset`;

	return (
		<Link
			className={className}
			title={folder.name}
			to={folderPath}
			onClick={onClick}
			onContextMenu={onContextMenu}
			onDoubleClick={onDoubleClick}
		>
			<div className="sv-Image-container">
				<div className={imgClass}>
					{!preview && isCompany ? (
						<img
							alt="Company Default Logo"
							className={`${CLASS}-default-company-logo`}
							src={logo || companyLogoFallback}
						/>
					) : (
						<Image className={`${CLASS}-image`} width={188} {...imgOptions} />
					)}
				</div>

				{folderTypeIcon && (
					<div className={`${CLASS}-icon`}>
						<FontAwesomeIcon icon={FA[folderTypeIcon]} />
					</div>
				)}
			</div>
			<div className={descriptionClass}>
				<span className={nameClass}>{folder.name}</span>
				{criteria.filter !== undefined && type !== FOLDER_TYPES.vision_asset && asset && (
					<span className={assetClass} title={asset.name}>{`${asset.name}`}</span>
				)}
			</div>
		</Link>
	);
}
