import { Component } from 'react';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { orphansFolder } from '@/selectors/folders';
import { LIBRARY_CONTEXT_TYPE, LIBRARY_NAMESPACE } from '@/types/library';
import StretchableSpinner from '@/views/widgets/StretchableSpinner';

import Files from './Files/Files';

const CLASS = 'sv-LibraryGridView';

class LibraryGridView extends Component {
	handleContextMenu(type, item, e) {
		const { onContextMenu, pathPrefix } = this.props;
		if (!onContextMenu) {
			return null;
		}
		e.preventDefault();
		const { clientX, clientY } = e;
		const position = { xPos: clientX, yPos: clientY };
		const data = { item, position };
		if (type === LIBRARY_CONTEXT_TYPE.FOLDER) {
			const folderPath = `${pathPrefix}/${item.folder.id}`;
			data.folderPath = folderPath;
			data.folderId = item.folder.parent_id;
		} else {
			data.folderId = item.site.folder_id;
		}
		onContextMenu(type, data);
	}

	handleFolderContextMenu = (item, e) => {
		this.handleContextMenu(LIBRARY_CONTEXT_TYPE.FOLDER, item, e);
	};

	handleFileContextMenu = (item, e) => {
		this.handleContextMenu(LIBRARY_CONTEXT_TYPE.FILE, item, e);
	};

	handleLoadMore(nextPage) {
		const { loadMore } = this.props;
		if (!loadMore) {
			return null;
		}
		return loadMore(nextPage);
	}

	handleLoadMoreFolders = () => this.handleLoadMore(this.props.foldersNextPage);

	handleLoadMoreSites = () => this.handleLoadMore(this.props.sitesNextPage);

	render() {
		const {
			createFileHref,
			createFolderHref,
			files,
			folders,
			foldersNextPage,
			loading,
			namespace,
			onFileClick,
			onFileDoubleClick,
			onFolderClick,
			onFolderDoubleClick,
			sitesNextPage,
			...rest
		} = this.props;
		const sections = groupBy(folders, folder => (folder.isCompany ? 'companies' : 'folders'));
		sections.files = files;
		if (!rest.disableFolders && rest.showOrphans) {
			sections.companies = sections.companies || [];
			sections.companies.unshift(orphansFolder);
		}

		const multiSection =
			Object.values(sections).reduce((a, v) => {
				if (!v) {
					return a;
				}
				return a + (v.length ? 1 : 0);
			}, 0) > 1;

		const commonProps = {
			...rest,
		};

		const selectableFolders = namespace === LIBRARY_NAMESPACE.modal ? false : rest.selecting;
		return (
			<div className={CLASS}>
				{loading && (isEmpty(folders) || !foldersNextPage) ? <StretchableSpinner /> : null}

				{!isEmpty(sections.companies) ? (
					<Files
						{...commonProps}
						createHref={createFolderHref}
						items={sections.companies}
						nextPage={foldersNextPage}
						sectionName={multiSection ? 'Companies' : null}
						selectable={false}
						onClick={onFolderClick}
						onDoubleClick={onFolderDoubleClick}
						onLoadMore={this.handleLoadMoreFolders}
					/>
				) : null}

				{!isEmpty(sections.folders) ? (
					<Files
						{...commonProps}
						createHref={createFolderHref}
						items={sections.folders}
						nextPage={foldersNextPage}
						sectionName={multiSection ? 'Folders' : null}
						selecting={selectableFolders}
						onClick={onFolderClick}
						onContextMenu={this.handleFolderContextMenu}
						onDoubleClick={onFolderDoubleClick}
						onLoadMore={this.handleLoadMoreFolders}
					/>
				) : null}

				{loading && (isEmpty(files) || !sitesNextPage) ? <StretchableSpinner /> : null}
				{!isEmpty(files) ? (
					<Files
						{...commonProps}
						createHref={createFileHref}
						items={files}
						nextPage={sitesNextPage}
						sectionName={multiSection ? 'Files' : null}
						onClick={onFileClick}
						onContextMenu={this.handleFileContextMenu}
						onDoubleClick={onFileDoubleClick}
						onLoadMore={this.handleLoadMoreSites}
					/>
				) : null}
			</div>
		);
	}
}

export default LibraryGridView;
