import { orderBy, pickBy } from 'lodash';
import { createSelector } from 'reselect';

export const getFileExplorerBreadcrumbs = (state, identifier) => {
	const storeIdentifier = state.fileExplorer.identifier;
	if (storeIdentifier && storeIdentifier === identifier) {
		return state.fileExplorer.breadcrumbs || [];
	}

	return [];
};

export const getFileExplorerSubPath = (state, identifier) => {
	const storeIdentifier = state.fileExplorer.identifier;
	if (storeIdentifier && storeIdentifier === identifier) {
		return state.fileExplorer.subPath || '/';
	}

	return '/';
};

export const getFileExplorerFileContents = createSelector(
	state => state.fileExplorer.identifier,
	(state, identifier) => identifier,
	state => state.fileExplorer.fileContents,
	(stateIdentifier, propIdentifier, fileContents) => {
		if (!stateIdentifier || stateIdentifier !== propIdentifier) {
			return [];
		}

		return fileContents;
	},
);

export const getFileExplorerFiles = createSelector(
	state => state.fileExplorer.identifier,
	(state, identifier) => identifier,
	state => state.fileExplorer.files,
	(state, identifier) => getFileExplorerBreadcrumbs(state, identifier),
	(stateIdentifier, propIdentifier, files, breadcrumbsPath) => {
		if (!stateIdentifier || stateIdentifier !== propIdentifier) {
			return [];
		}

		let resultHash;
		if (breadcrumbsPath.length > 0) {
			const prefixPath = `/${breadcrumbsPath.join('/')}/`;
			resultHash = pickBy(files, (v, k) => {
				return k.indexOf(prefixPath) === 0 && k.split('/').length === breadcrumbsPath.length + 2;
			});
		} else {
			resultHash = pickBy(files, (v, k) => {
				return k.split('/').length === 2;
			});
		}

		const result = orderBy(resultHash, [f => (f.isDirectory ? 1 : -1), 'name'], ['desc', 'asc']);

		return result;
	},
);
