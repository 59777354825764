import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer/index';

/**
 * @param {import('./container').Container} container
 * @returns {SvStore}
 */
export default function configureStore(container) {
	/* eslint-disable no-underscore-dangle */
	/** @type {typeof compose} */
	const composeEnhancers =
		// @ts-ignore
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? // @ts-ignore
			  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
					trace: false,
			  })
			: compose;

	/* eslint-enable */
	const enhancer = composeEnhancers(applyMiddleware(thunk.withExtraArgument(container)));
	const store = createStore(reducer, enhancer);

	// @ts-ignore
	if (process.env.NODE_ENV !== 'production' && module.hot) {
		// @ts-ignore
		module.hot.accept('./reducer/index', () => store.replaceReducer(reducer));
	}

	return store;
}
