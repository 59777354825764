import * as TYPES from '@/actions/action_types';
import { LIBRARY_NAMESPACE } from '@/types/library';

import ReduceHelper from './reduce_helper';

const NAMESPACE = 'sites';
const MODAL_NAMESPACE = `modal`;
const SITES_DATA = 'data';

/** @typedef {typeof INITIAL_STATE["sites"]} State */

const INITIAL_STATE = /** @lends State.prototype */ {
	[NAMESPACE]: {
		[MODAL_NAMESPACE]: {
			[SITES_DATA]: null,
			loadError: null,
			loading: null,
			nextPage: null,
			op: null,
		},
		[SITES_DATA]: null,
		loadError: null,
		loading: null,
		nextPage: null,
		op: null,
		preparingSiteFilesDownload: {},
		siteVersions: {
			data: null,
			siteId: null,
		},
	},
};

const libraryHelper = new ReduceHelper(NAMESPACE, 'site.id');
const modalHelper = new ReduceHelper(`${NAMESPACE}.${MODAL_NAMESPACE}`, 'site.id');

const helpers = {
	sites: [libraryHelper],
	[LIBRARY_NAMESPACE.view]: [libraryHelper],
	[LIBRARY_NAMESPACE.modal]: [modalHelper],
};

// NOTE: These throw warnings as they are not used. Remove if not needed.

const invalidate = (state, action, helper = libraryHelper) => {
	return helper.withInvalidation(state, action);
};
// const _gotData = (state, action, helper = libraryHelper) => {
// 	return helper.gotData(invalidate(state, action, helper), action);
// };
// const updateNamespace = (state, action, helper = libraryHelper) => {
// 	return helper.updateNamespace(invalidate(state, action, helper), action);
// };

function nameSpaceHandler(method, useHelper = libraryHelper) {
	return (state, action) => {
		const helper = action.namespace ? helpers[action.namespace] : useHelper;
		if (!helper) {
			return state;
		}
		if (Array.isArray(helper)) {
			return helper.reduce((_state, _helper) => {
				const nextState = invalidate(_state, action, _helper);
				return _helper[method](nextState, action);
			}, state);
		}
		return helper[method](invalidate(state, action, helper), action);
	};
}

const updateSites = (state, ob) => {
	return { ...state, sites: { ...state.sites, ...ob } };
};

const helper = new ReduceHelper('sites', 'site.id');

const siteFilesDownloadStart = (state, action) => {
	return helper.updateNamespace(state, {
		preparingSiteFilesDownload: {
			...state.enquiries.preparingSiteFilesDownload,
			[action.siteId]: new Date(),
		},
	});
};

const siteFilesDownloadEnd = (state, action) => {
	return helper.updateNamespace(state, {
		preparingSiteFilesDownload: {
			...state.enquiries.preparingSiteFilesDownload,
			[action.siteId]: null,
		},
	});
};

const versionsReset = (state, action) => {
	return updateSites(state, {
		siteVersions: {
			data: null,
			siteId: action.siteId,
		},
	});
};

const versionsGotData = (state, action) => {
	return updateSites(state, {
		siteVersions: action,
	});
};

export default {
	INITIAL_STATE,

	[TYPES.SITES_GOT_DATA]: nameSpaceHandler('gotData'),
	[TYPES.SITES_LOAD_START]: nameSpaceHandler('loadStart'),
	[TYPES.SITES_LOAD_END]: nameSpaceHandler('loadEnd'),
	[TYPES.SITES_OP_START]: nameSpaceHandler('opStart'),
	[TYPES.SITES_OP_END]: nameSpaceHandler('opEnd'),
	[TYPES.SITES_OP_RESET]: nameSpaceHandler('opReset'),
	[TYPES.OP_RESET_ALL]: [libraryHelper, modalHelper].map(helper =>
		nameSpaceHandler('opReset', helper),
	),

	[TYPES.DATA_RESET_ALL]: nameSpaceHandler('reset'),
	[TYPES.SWITCH_COMPANY_END]: nameSpaceHandler('reset'),
	[TYPES.SITES_VERSIONS_RESET]: versionsReset,
	[TYPES.SITES_VERSIONS_GOT_DATA]: versionsGotData,
	[TYPES.SITE_FILES_DOWNLOAD_START]: siteFilesDownloadStart,
	[TYPES.SITE_FILES_DOWNLOAD_END]: siteFilesDownloadEnd,
};
