import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';
import IconButton from '@/views/widgets/IconButton';

import './BasicModal.scss';

const CLASS = 'sv-BasicModal';

export default class BasicModal extends Component {
	static propTypes = {
		cancelLabel: PropTypes.string,
		className: PropTypes.string,
		confirmLabel: PropTypes.string,
		content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		disableCancel: PropTypes.bool,
		onClose: PropTypes.func,
		onSubmit: PropTypes.func,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	};

	static defaultProps = {
		cancelLabel: 'No',
		className: '',
		confirmLabel: 'Yes',
		disableCancel: false,
	};

	handleConfirm = () => {
		const { onSubmit } = this.props;

		onSubmit && onSubmit();
	};

	handleCancel = () => {
		const { onClose } = this.props;

		onClose && onClose();
	};

	renderModalHeader = () => {
		const { title } = this.props;
		if (!title) {
			return null;
		}

		return (
			<Modal.Header className={`${CLASS}-header`}>
				<div className={`${CLASS}-header-title`}>{title}</div>

				<div className={`${CLASS}-header-close`}>
					<IconButton icon={FA.close} onClick={this.handleCancel} />
				</div>
			</Modal.Header>
		);
	};

	renderModalBody = () => {
		const { content } = this.props;
		if (!content) {
			return null;
		}

		return <Modal.Body className={`${CLASS}-body`}>{content}</Modal.Body>;
	};

	renderModalFooter = () => {
		const { cancelLabel, confirmLabel, disableCancel } = this.props;

		return (
			<Modal.Footer className={`${CLASS}-footer different-modal`}>
				{!disableCancel && (
					<IconButton secondary onClick={this.handleCancel}>
						{cancelLabel}
					</IconButton>
				)}
				<IconButton onClick={this.handleConfirm}>{confirmLabel}</IconButton>
			</Modal.Footer>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div className={`${CLASS} ${className}`}>
				{this.renderModalHeader()}
				{this.renderModalBody()}
				{this.renderModalFooter()}
			</div>
		);
	}
}
