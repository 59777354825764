import ENV from '@/env';
import { assign } from '@/lib/util';

import { Criteria as BaseCriteria, ModelBase, SORT_DIRECTIONS } from './data';
import { TIME_FRAME_GETTERS, TIME_FRAMES } from './reports';
import { TOOLS } from './sub_tours';

//* *********************************************************************************************************************

export const LIBRARY_FILE_TYPES_WITH_PREVIEW = [
	'document',
	'video',
	'image',
	'tour',
	'floorplan',
	'orthophoto',
	'drone_image',
];

const folder = {
	company_id: 'company_id',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	folder_id: 'folder_id',
	id: 'id',
	image_id: 'image_id',
	name: 'name',
	parent_id: 'parent_id',
	updated_at: 'updated_at',
	user_id: 'user_id',
};

export const FOLDER = folder;

export class Folder extends ModelBase {
	/** @param {Partial<Folder>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;
		/** @type {string} */
		this.name = undefined;
		/** @type {number} */
		this.parent_id = undefined;
		/** @type {number} */
		this.user_id = undefined;
		/** @type {number} */
		this.folder_id = undefined;
		/** @type {number} */
		this.image_id = undefined;
		/** @type {number} */
		this.company_id = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.updated_at = undefined;
		/** @type {Date} */
		this.deleted_at = undefined;

		Object.assign(this, source);
	}
}

const preparedDownload = {
	company_id: 'company_id',
	created_at: 'created_at',
	deleted_at: 'deleted_at',
	folder_id: 'folder_id',
	id: 'id',
	image_id: 'image_id',
	name: 'name',
	parent_id: 'parent_id',
	updated_at: 'updated_at',
	user_id: 'user_id',
};

export const PREPARED_DOWNLOAD = preparedDownload;

export class PreparedDownload extends ModelBase {
	/** @param {Partial<PreparedDownload>} [source] */
	constructor(source) {
		super();

		/** @type {string} */
		this.id = undefined;
		/** @type {string} */
		this.signature = undefined;
		/** @type {string} */
		this.checksum = undefined;
		/** @type {string} */
		this.filename = undefined;
		/** @type {string} */
		this.content = undefined;
		/** @type {string} */
		this.location = undefined;
		/** @type {Date} */
		this.created_at = undefined;
		/** @type {Date} */
		this.deleted_at = undefined;

		Object.assign(this, source);
	}
}

//* *********************************************************************************************************************

const TOOLS_CONTEXT_MENU = {
	children: {
		[TOOLS.virtual_screens_tool]: {
			name: 'virtual_screens',
			shouldRender: false,
			title: 'Single Scenario',
		},
		[TOOLS.planning_tool]: {
			name: 'planning_tool',
			shouldRender: false,
			title: 'Sales View',
		},
		[TOOLS.sales_tool]: {
			name: 'sales-tool',
			shouldRender: ENV.access_allowed_sections.sales_view.access_allowed,
			title: 'Sales View',
		},
		[TOOLS.brochure_tool]: {
			name: 'brochure_tool',
			shouldRender: ENV.access_allowed_sections.brochure_tool.access_allowed,
			title: 'Brochure View',
		},
		heatmaps: {
			name: 'heatmaps',
			shouldRender: ENV.access_allowed_sections.heatmaps_view.access_allowed,
			title: 'Heatmaps',
		},
	},
	name: 'tools',
	title: 'Tools',
};

export const CONTEXT_MENU = {
	clone: {
		name: 'clone',
		title: 'Clone to',
	},
	delete: {
		name: 'delete',
		title: 'Delete',
	},
	move: {
		name: 'move',
		title: 'Move to',
	},
};

export const FILE_CONTEXT_MENU = {
	clone: {
		actionName: 'clone',
		name: 'clone',
		title: 'Clone',
	},
	deleteItem: {
		actionName: 'delete',
		name: 'delete',
		title: 'Delete',
	},
	details: {
		actionName: 'details',
		name: 'details',
		title: 'Open',
	},
	download: {
		actionName: 'download',
		name: 'download',
		title: 'Download',
	},
	editBrochure: {
		actionName: 'editBrochure',
		name: 'editBrochure',
		title: ENV.custom_brochure_naming?.edit_brohure || 'Edit brochure',
	},
	move: {
		actionName: 'move',
		name: 'move',
		title: 'Move to',
	},
	rename: {
		actionName: 'rename',
		name: 'rename',
		title: 'Rename',
	},
	resetPreview: {
		actionName: 'resetPreview',
		name: 'resetPreview',
		title: 'Reset cover (admin)',
	},
	tools: TOOLS_CONTEXT_MENU,
};

export const FOLDER_CONTEXT_MENU = {
	changeCover: {
		actionName: 'changeCover',
		name: 'changeCover',
		title: 'Change cover image',
	},
	clone: {
		actionName: 'clone',
		name: 'clone',
		title: 'Clone',
	},
	deleteItem: {
		actionName: 'delete',
		name: 'delete',
		title: 'Delete',
	},
	editAssetInfo: {
		actionName: 'editAssetInfo',
		name: 'editAssetInfo',
		title: 'Edit Asset Info',
	},
	move: {
		actionName: 'move',
		name: 'move',
		title: 'Move to',
	},
	open: {
		name: 'open',
		title: 'Open',
	},
	rename: {
		actionName: 'rename',
		name: 'rename',
		title: 'Rename',
	},
};

export const EMPTY_CONTEXT_MENU_ITEMS = Object.entries(FILE_CONTEXT_MENU).reduce(
	(agg, [key, value]) => {
		return {
			...agg,
			[key]: { ...value, shouldRender: false },
		};
	},
	{},
);

/**
 * @lends FolderWithDetails.prototype
 */
const fwd = {
	asset: 'asset',
	children: 'children',
	company: 'company',
	folder: 'folder',
	image: 'image',
	images: 'images',
	parent: 'parent',
	sites: 'sites',
};

export const FWD = fwd;

export class FolderWithDetails extends ModelBase {
	/** @param {Partial<FolderWithDetails>} [source] */
	constructor(source) {
		super();

		/** @type {number} */
		this.id = undefined;

		/** @type {number} */
		this.parent_id = undefined;

		/** @type {Folder} */
		this.folder = undefined;

		/** @type {Folder} */
		this.parent = undefined;

		/** @type {Folder[]} */
		this.children = undefined;

		/** @type {import('./sites').Site[]} */
		this.sites = undefined;

		/** @type {Image[]} */
		this.images = undefined;

		/** @type {Image} */
		this.image = undefined;

		/** @type {import('./companies').Company} */
		this.company = undefined;

		/** @type {Asset} */
		this.asset = undefined;

		Object.assign(this, source);
	}
}

export const FOLDER_SORT_BY = {
	modified: { label: 'Modified', value: `${FWD.folder}.${FOLDER.updated_at}` },
	name: { label: 'Name', value: `${FWD.folder}.${FOLDER.name}` },
};

//* *********************************************************************************************************************

export const FWD_FILTER_PATHS = [
	FOLDER.id,
	FOLDER.parent_id,
	FOLDER.folder_id,
	FOLDER.name,
	FOLDER.updated_at,
	`${FWD.company}.id`,
	`${FWD.company}.name`,
	'is_ar_app',
];

export class Criteria extends BaseCriteria {
	/** @param {Partial<Criteria>} [source] */
	constructor(source) {
		super();

		this.time_frame = undefined;

		FWD_FILTER_PATHS.forEach(key => {
			this[key] = undefined;
		});

		assign(this, this, source);
	}

	getTimeFrameParam(paramName) {
		if (this.time_frame === TIME_FRAMES.custom) {
			return this[paramName] || undefined;
		}
		if (!TIME_FRAMES[this.time_frame]) {
			return undefined;
		}
		if (!TIME_FRAME_GETTERS[this.time_frame][paramName]) {
			return undefined;
		}
		return TIME_FRAME_GETTERS[this.time_frame][paramName]() || undefined;
	}

	getSortBy(sort_by) {
		sort_by = sort_by ? sort_by.split('.') : [FOLDER.name];
		if (sort_by.length > 1) {
			sort_by.shift();
		}
		if (!Object.prototype.hasOwnProperty.call(this, sort_by[0])) {
			sort_by[0] = FOLDER.name;
		}
		return [FWD.folder, ...sort_by].join('.');
	}

	toQuery(fromPage) {
		const query = super.toQuery(fromPage);
		this.coerce();
		if (query['sort-by']) {
			query['sort-by'] = this.getSortBy(query['sort-by']);
		}
		FWD_FILTER_PATHS.forEach(key => {
			if (this[key] !== undefined) {
				query[key.replace(/_/g, '-')] = String(this[key]);
			}
		});
		if (this.time_frame) {
			query['updated-at'] = this.getTimeFrameParam('from');
		}

		if (query['folder-id'] === 'orphans') {
			delete query['folder-id'];
			query['owner-id'] = 'null';
		}

		return query;
	}
}

export const DEFAULT_CRITERIA = new Criteria({
	details: [FWD.company, FWD.images, FWD.project, FWD.asset],
	page_size: 50,
	sort_by: `${FWD.folder}.${FOLDER.name}`,
	sort_direction: SORT_DIRECTIONS.asc,
});

//* *********************************************************************************************************************

export const getSelectedIds = (prefix, selectedItems) => {
	return selectedItems
		.map(key => {
			const [p, id] = key.split('-');
			return p === prefix ? id : null;
		})
		.filter(Boolean);
};

export const MAX_FOLDER_PREVIEWS = 8;

export const LIBRARY_VIEW_TYPES = {
	grid: 'grid',
	list: 'list',
};

export const LIBRARY_VIEW_SORT_TYPES = {
	all: 'all',
	sorted: 'sorted',
};

export const LIBRARY_VIEW_SORT_TYPES_FLIPPED = {
	all: LIBRARY_VIEW_SORT_TYPES.sorted,
	sorted: LIBRARY_VIEW_SORT_TYPES.all,
};

export const LIBRARY_VIEW_TYPES_FLIPPED = {
	grid: LIBRARY_VIEW_TYPES.list,
	list: LIBRARY_VIEW_TYPES.grid,
};

export const LIBRARY_TYPE = {
	FILE: 'file',
	FOLDER: 'folder',
};

export const LIBRARY_CONTEXT_TYPE = LIBRARY_TYPE;

export const LIBRARY_GRID_VISUALS = {
	thumbnail_height: 136,
	thumbnail_width: 250,
};

export const LIBRARY_LIST_VISUALS = {
	thumbnail_height: 46,
	thumbnail_width: 46,
};

export const LIBRARY_VISUALS = {
	[LIBRARY_VIEW_TYPES.grid]: LIBRARY_GRID_VISUALS,
	[LIBRARY_VIEW_TYPES.list]: LIBRARY_LIST_VISUALS,
};

export const LIBRARY_COLUMN_HEADER = {
	asset: 'asset',
	fileSize: 'fileSize',
	modified: 'modified',
	name: 'name',
	owner: 'owner',
	select: 'select',
	type: 'type',
	url: 'url',
};

export const LIBRARY_ACTIONS = {
	clone: 'clone',
	delete: 'delete',
	download: 'download',
	move: 'move',
	new_asset: 'new_asset',
	new_folder: 'new_folder',
	upload: 'upload',
};

export const LIBRARY_NAMESPACE = {
	modal: 'library_modal',
	view: 'folders',
};
