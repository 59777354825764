import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	heatmapLegend: {
		data: null,
		loadError: null,
		loading: false,
	},
};

export const legendLoadStart = (state, action) => {
	return {
		...state,
		heatmapLegend: {
			loadError: null,
			loading: true,
		},
	};
};

export const legendLoadEnd = (state, action) => {
	return {
		...state,
		heatmapLegend: {
			loadError: action.error,
			loading: false,
		},
	};
};

export const legendGotData = (state, action) => {
	return {
		...state,
		heatmapLegend: {
			data: action.data,
		},
	};
};

export default {
	INITIAL_STATE,
	[TYPES.KIOSK_VISUAL_STATS_LEGEND_LOAD_START]: legendLoadStart,
	[TYPES.KIOSK_VISUAL_STATS_LEGEND_LOAD_END]: legendLoadEnd,
	[TYPES.KIOSK_VISUAL_STATS_LEGEND_GOT_DATA]: legendGotData,
};
