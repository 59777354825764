import { createContext, useContext } from 'react';

import * as http from '@/lib/http';

export const RequestHandlerContext = createContext(null);

export function RequestHandlerProvider({ children }) {
	return <RequestHandlerContext.Provider value={http}>{children}</RequestHandlerContext.Provider>;
}

export function useRequestHandler() {
	return useContext(RequestHandlerContext);
}
