import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';

import './CheckboxStyled.scss';

function CheckboxStyled({
	checkboxKey = undefined,
	checked = undefined,
	children = undefined,
	className = undefined,
	disabled = undefined,
	icon = undefined,
	icons = undefined,
	isToggle = undefined,
	labelLeft = undefined,
	labelRight = undefined,
	name = undefined,
	onChange = undefined,
	...rest
}) {
	const isSecondary = isToggle ? 'sv-CheckboxStyled-isToggle' : 'sv-CheckboxStyled';
	icons = icons && Array.isArray(icons) ? icons : [icon || FA.check, icon || FA.square];
	icon = icons[checked ? 0 : 1];
	icon = typeof icon === 'string' ? FA[icon] : icon;

	return (
		<div className={`${isSecondary} ${className}`}>
			{isToggle && labelLeft ? <label>{labelLeft}</label> : null}
			<Form.Check className="checkbox" {...rest}>
				<label title="">
					<Form.Check.Input
						key={checkboxKey}
						checked={checked}
						disabled={disabled}
						name={name}
						onChange={onChange}
					/>
					<span>{!isToggle ? <FontAwesomeIcon icon={icon} /> : <span className="slider" />}</span>
					{children}
				</label>
			</Form.Check>
			{isToggle && labelRight ? <label>{labelRight}</label> : null}
		</div>
	);
}

CheckboxStyled.propTypes = {
	checkboxKey: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	isToggle: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
};

export default CheckboxStyled;
