import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';

import ClearableInput from './ClearableInput';

import './FilterInput.scss';

const CLASS = 'sv-FilterInput';

const INPUT_DELAY = 500;

class FilterInput extends Component {
	static propTypes = {
		delay: PropTypes.number,
		filter: PropTypes.string,
		label: PropTypes.string,
		loading: PropTypes.bool,
		onFilterChange: PropTypes.func,
		placeholder: PropTypes.string,
	};

	static defaultProps = {
		delay: INPUT_DELAY,
		label: 'Search: ',
		placeholder: 'Search text',
	};

	constructor(props) {
		super(props);

		this.state = {
			writing: false,
		};

		const { delay } = props;
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputKeyDown = debounce(this.handleInputKeyDown.bind(this), delay);
		this.onFilterChange = debounce(this.onFilterChange.bind(this), delay);
		this.setWritingAnimation = debounce(this.setWritingAnimation.bind(this), delay);
	}

	componentWillUnmount() {
		this.onFilterChange.cancel();
	}

	handleInputKeyDown = e => {
		this.handleInputChange(e.target.value);
	};

	handleInputChange(value) {
		this.setState({ writing: !!value });
		this.onFilterChange(value);
		this.setWritingAnimation(false);
	}

	setWritingAnimation(val) {
		this.setState({ writing: val });
	}

	onFilterChange(value) {
		const { onFilterChange } = this.props;
		onFilterChange && onFilterChange(value);
	}

	renderIconOverride() {
		if (this.props.loading && !this.state.writing) {
			return <FontAwesomeIcon spin icon={FA.circle_o_notch} />;
		}

		if (this.state.writing && !this.props.loading && !this.props.inputFocusRef) {
			return <FontAwesomeIcon className="pencil-animation" icon={FA.pencil} />;
		}

		return null;
	}

	render() {
		const { className, filter, label, loading, onFilterChange, onKeyDown, ...props } = this.props;

		return (
			<ClearableInput
				className={`${className || ''} ${CLASS}`}
				iconOverride={this.renderIconOverride()}
				initialValue={filter}
				placeholder={this.props.placeholder}
				size="small"
				onChange={this.handleInputChange}
				onIconClick={this.handleInputChange}
				onKeyDown={this.handleInputKeyDown}
				{...props}
			/>
		);
	}
}

export default FilterInput;
