import * as TYPES from '@/actions/action_types';

const INITIAL_STATE = /** @lends State.prototype */ {
	flashStorage: {
		prefillSCSWD: null,
	},
};

const set = (state, action) => {
	return {
		...state,
		flashStorage: {
			...state.flashStorage,
			...action,
		},
	};
};

const clear = state => {
	return {
		...state,
		flashStorage: {},
	};
};

export default {
	INITIAL_STATE,
	[TYPES.FLASH_STORAGE_SET]: set,
	[TYPES.FLASH_STORAGE_CLEAR]: clear,
	[TYPES.DATA_RESET_ALL]: clear,
};
