import classNames from 'classnames';
import PropTypes from 'prop-types';

import './PageContainer.scss';

function PageContainer({
	bordered = null,
	children,
	className = null,
	content = null,
	createRef = null,
	padded = null,
	...props
}) {
	const classes = [className, 'sv-PageContainer'];
	if (content) {
		classes.push('sv-PageContainer-content');
	}
	if (bordered) {
		classes.push('sv-PageContainer-bordered');
	}
	if (padded) {
		classes.push('sv-PageContainer-padded');
	}

	className = classNames(classes);

	return (
		<div className={className} {...props}>
			<div ref={createRef} className="container-fluid">
				{children}
			</div>
		</div>
	);
}

PageContainer.propTypes = {
	bordered: PropTypes.bool,
	content: PropTypes.bool,
	padded: PropTypes.bool,
};

export default PageContainer;
