import { Component } from 'react';
import { isEqual } from 'lodash';

import { SORT_DIRECTIONS, SORT_DIRECTIONS_FLIP } from '@/types/data';
import FA from '@/types/font_awesome';
import { DEFAULT_CRITERIA, FOLDER_SORT_BY } from '@/types/library';
import IconButton from '@/views/widgets/IconButton';
import Select from '@/views/widgets/input/Select';

const folderSortBy = Object.values(FOLDER_SORT_BY).map(option => option);
const defaultSortBy = Object.values(FOLDER_SORT_BY).find(
	option => option.value === DEFAULT_CRITERIA.sort_by,
);

const CLASS = 'sv-LibraryFilters';
class LibraryFilters extends Component {
	state = {
		sortBy: defaultSortBy,
		sortDirection:
			(this.props.criteria && this.props.criteria.sort_direction) ||
			DEFAULT_CRITERIA.sort_direction,
	};

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevState, this.state)) {
			this.updateCriteria();
		}
	}

	handleSelect = option => this.setState(prevState => ({ ...prevState, sortBy: option }));

	toggleSortDirection = () => {
		const { sortDirection } = this.state;
		const direction = SORT_DIRECTIONS_FLIP[sortDirection];

		this.setState(prevState => ({
			...prevState,
			sortDirection: direction,
		}));
	};

	updateCriteria = () => {
		const { sortBy, sortDirection } = this.state;
		this.handleChange({
			sort_by: sortBy.value,
			sort_direction: sortDirection,
		});
	};

	handleChange = nextCriteria => {
		const { criteria } = this.props;
		const newCriteria = criteria.update(nextCriteria);
		this.handleCriteriaChanged(newCriteria);
	};

	handleCriteriaChanged = newCriteria => {
		const { onCriteriaChanged } = this.props;
		onCriteriaChanged(newCriteria);
	};

	renderOption = props => {
		return <div>{props.label}</div>;
	};

	render() {
		const { sortBy, sortDirection } = this.state;
		const direction = sortDirection === SORT_DIRECTIONS.asc ? 'up' : 'down';
		const sortByDisplay = {
			label: (
				<div>
					Sort by <span style={{ fontWeight: 'bold' }}>{sortBy.label}</span>
				</div>
			),
			value: sortBy.value,
		};
		return (
			<div className={CLASS}>
				<Select
					arrowRenderer={null}
					className={`${CLASS}-sort-by`}
					clearable={false}
					options={folderSortBy}
					searchable={false}
					value={sortByDisplay}
					onChange={this.handleSelect}
				/>
				<IconButton
					className={`${CLASS}-sort-direction`}
					icon={FA[`arrow_${direction}`]}
					onClick={this.toggleSortDirection}
				/>
			</div>
		);
	}
}

export default LibraryFilters;
