export default {
	AC: 'SHP',
	AD: 'AND',
	AE: 'UAE',
	AF: 'AFG',
	AG: 'ANT',
	AI: '',
	AL: 'ALB',
	AM: 'ARM',
	AN: '',
	AO: 'ANG',
	AQ: '',
	AR: 'ARG',
	AS: 'ASA',
	AT: 'AUT',
	AU: 'AUS',
	AW: 'ARU',
	AX: '',
	AZ: 'AZE',
	BA: 'BIH',
	BB: 'BAR',
	BD: 'BAN',
	BE: 'BEL',
	BF: 'BUR',
	BG: 'BUL',
	BH: 'BRN',
	BI: 'BDI',
	BJ: 'BEN',
	BL: '',
	BM: 'BER',
	BN: 'BRU',
	BO: 'BOL',
	BQ: '',
	BR: 'BRA',
	BS: 'BAH',
	BT: 'BHU',
	BU: '',
	BV: '',
	BW: 'BOT',
	BY: '',
	BZ: 'BIZ',
	CA: 'CAN',
	CC: '',
	CD: 'COD',
	CF: 'CAF',
	CG: 'CGO',
	CH: 'SUI',
	CI: 'CIV',
	CK: 'COK',
	CL: 'CHI',
	CM: 'CMR',
	CN: 'CHN',
	CO: 'COL',
	CP: '',
	CR: 'CRC',
	CS: '',
	CT: '',
	CU: 'CUB',
	CV: 'CPV',
	CW: '',
	CX: '',
	CY: 'CYP',
	CZ: 'CZE',
	DD: '',
	DE: 'GER',
	DG: '',
	DJ: 'DJI',
	DK: 'DEN',
	DM: 'DMA',
	DO: 'DOM',
	DY: '',
	DZ: 'ALG',
	EA: '',
	EC: 'ECU',
	EE: 'EST',
	EG: 'EGY',
	EH: '',
	ER: 'ERI',
	ES: 'ESP',
	ET: 'ETH',
	EU: '',
	FI: 'FIN',
	FJ: 'FIJ',
	FK: '',
	FM: 'FSM',
	FO: 'FAI',
	FQ: '',
	FR: 'FRA',
	FX: '',
	GA: 'GAB',
	GB: 'GBR',
	GD: 'GRN',
	GE: '',
	GF: '',
	GG: 'GCI',
	GH: 'GHA',
	GI: '',
	GL: '',
	GM: 'GAM',
	GN: 'GUI',
	GP: '',
	GQ: 'GEQ',
	GR: 'GRE',
	GS: '',
	GT: 'GUA',
	GU: 'GUM',
	GW: 'GBS',
	GY: 'GUY',
	HK: 'HKG',
	HM: '',
	HN: 'HON',
	HR: 'CRO',
	HT: 'HAI',
	HU: 'HUN',
	HV: '',
	IC: '',
	ID: 'INA',
	IE: 'IRL',
	IL: 'ISR',
	IM: '',
	IN: 'IND',
	IO: '',
	IQ: 'IRQ',
	IR: 'IRI',
	IS: 'ISL',
	IT: 'ITA',
	JE: 'JCI',
	JM: 'JAM',
	JO: 'JOR',
	JP: 'JPN',
	JT: '',
	KE: 'KEN',
	KG: 'KGZ',
	KH: 'CAM',
	KI: 'KIR',
	KM: 'COM',
	KN: 'SKN',
	KP: 'PRK',
	KR: 'KOR',
	KW: 'KUW',
	KY: 'CAY',
	KZ: 'KAZ',
	LA: 'LAO',
	LB: 'LIB',
	LC: 'LCA',
	LI: 'LIE',
	LK: 'SRI',
	LR: 'LBR',
	LS: 'LES',
	LT: 'LTU',
	LU: 'LUX',
	LV: 'LAT',
	LY: 'LBA',
	MA: 'MAR',
	MC: 'MON',
	MD: 'MDA',
	ME: 'MNE',
	MF: '',
	MG: 'MAD',
	MH: 'MHL',
	MI: '',
	MK: 'MKD',
	ML: 'MLI',
	MM: 'MYA',
	MN: 'MGL',
	MO: 'MAC',
	MP: '',
	MQ: '',
	MR: 'MTN',
	MS: '',
	MT: 'MLT',
	MU: 'MRI',
	MV: 'MDV',
	MW: 'MAW',
	MX: 'MEX',
	MY: 'MAS',
	MZ: 'MOZ',
	NA: 'NAM',
	NC: '',
	NE: 'NIG',
	NF: '',
	NG: 'NGR',
	NH: '',
	NI: 'NCA',
	NL: 'NED',
	NO: 'NOR',
	NP: 'NEP',
	NQ: '',
	NR: 'NRU',
	NT: '',
	NU: '',
	NZ: 'NZL',
	OM: 'OMA',
	PA: 'PAN',
	PC: '',
	PE: 'PER',
	PF: '',
	PG: 'PNG',
	PH: 'PHI',
	PK: 'PAK',
	PL: 'POL',
	PM: '',
	PN: '',
	PR: 'PUR',
	PS: 'PLE',
	PT: 'POR',
	PU: '',
	PW: 'PLW',
	PY: 'PAR',
	PZ: '',
	QA: 'QAT',
	RE: '',
	RH: '',
	RO: 'ROU',
	RS: 'SRB',
	RU: 'RUS',
	RW: 'RWA',
	SA: 'KSA',
	SB: 'SOL',
	SC: 'SEY',
	SD: 'SUD',
	SE: 'SWE',
	SG: 'SIN',
	SH: '',
	SI: 'SLO',
	SJ: '',
	SK: '',
	SL: 'SLE',
	SM: 'SMR',
	SN: 'SEN',
	SO: 'SOM',
	SR: 'SUR',
	SS: 'SSD',
	ST: 'STP',
	SU: '',
	SV: 'ESA',
	SX: '',
	SY: 'SYR',
	SZ: 'SWZ',
	TA: '',
	TC: '',
	TD: 'CHA',
	TF: '',
	TG: 'TOG',
	TH: 'THA',
	TJ: 'TJK',
	TK: '',
	TL: 'TLS',
	TM: 'TKM',
	TN: 'TUN',
	TO: 'TGA',
	TP: '',
	TR: 'TUR',
	TT: 'TTO',
	TV: 'TUV',
	TW: 'TPE',
	TZ: 'TAN',
	UA: 'UKR',
	UG: 'UGA',
	UK: '',
	UM: '',
	US: 'USA',
	UY: 'URU',
	UZ: 'UZB',
	VA: '',
	VC: 'VIN',
	VD: '',
	VE: 'VEN',
	VG: 'IVB',
	VI: 'ISV',
	VN: 'VIE',
	VU: 'VAN',
	WF: '',
	WK: '',
	WS: 'SAM',
	XK: 'KOS',
	YD: '',
	YE: 'YEM',
	YT: '',
	YU: '',
	ZA: 'RSA',
	ZM: 'ZAM',
	ZR: '',
	ZW: 'ZIM',
	ZZ: '',
};
