import { forEach, get, isEqual, isPlainObject, set, sortBy } from 'lodash';

import ENV from '@/env';
import { assign, enumize } from '@/lib/util';

export class ModelBase {
	/** @param {{[x: string]: any}} [source] */
	constructor(source) {
		assign(source, this, source);
	}

	clean() {
		/** @type {{[x: string]: any}} */
		const obj = {};
		for (const prop in this) {
			if (this[prop] !== undefined) {
				obj[prop] = isPlainObject(this[prop]) ? new ModelBase(this[prop]).clean() : this[prop];
			}
		}
		return obj;
	}
}

export const SORT_DIRECTIONS = {
	asc: 'asc',
	desc: 'desc',
};

export const SORT_DIRECTIONS_FLIP = {
	[SORT_DIRECTIONS.asc]: SORT_DIRECTIONS.desc,
	[SORT_DIRECTIONS.desc]: SORT_DIRECTIONS.asc,
};

//* *********************************************************************************************************************

export const TABLE_COLUMN_ALIGN = {
	center: 'center',
	left: 'left',
	right: 'right',
};

//* *********************************************************************************************************************

export const TABLE_COLUMN_SIZING = {
	auto: 'auto',
	shrink: 'shrink',
	stretch: 'stretch',
};

//* *********************************************************************************************************************

export const TABLE_COLUMN_SUMMARIZERS = {
	avg: 'avg',
	sum: 'sum',
};

//* *********************************************************************************************************************

/**
 * @typedef {Object} TableColumnSourceType
 * @property {string} [name]
 * @property {string} [title]
 * @property {string} [icon]
 * @property {string} [align]
 * @property {keyof typeof TABLE_COLUMN_SIZING} [sizing]
 * @property {string} [sort_path]
 * @property {boolean} [sortable]
 * @property {any} [summarizer]
 * @property {any} [getter]
 * @property {any} [setter]
 */

export class TableColumn {
	/**
	 *
	 * @param {TableColumnSourceType} source
	 */
	constructor(source = null) {
		this.title = undefined;
		this.icon = undefined;
		this.align = undefined;
		this.sizing = undefined;
		this.sort_path = undefined;
		this.summarizer = undefined;
		this.getter = undefined;
		this.setter = undefined;
		this.name = undefined;

		assign(this, this, source);

		this.sortable = this.sortable !== false;
		this.sizing = this.sizing || TABLE_COLUMN_SIZING.auto;
	}

	getValue(item) {
		if (this.getter) {
			return this.getter(item);
		}
		if (this.sort_path) {
			return get(item, this.sort_path);
		}
		return undefined;
	}

	setValue(item, value) {
		if (this.setter) {
			return this.setter(item, value);
		}
		if (this.sort_path) {
			return set(item, this.sort_path, value);
		}
		return undefined;
	}
}

export const TABLE_COLUMN = enumize(new TableColumn());

//* *********************************************************************************************************************

export class Criteria {
	constructor(source) {
		this.sort_by = undefined;
		this.sort_direction = undefined;
		this.filter = undefined;
		this.filter_exclude_id = undefined;
		this.details = undefined;
		this.props = undefined;
		this.page_size = undefined;
		this.include_deleted = undefined;

		assign(this, this, source);
	}

	/**
	 * @param {{value?: number, offset?: number}} fromPage
	 * @returns {{[x: string]: string|number}}
	 */
	toQuery(fromPage) {
		/** @type {{[x: string]: string|number}} */
		const query = {};

		if (this.props) {
			query.props = this.props.join(',');
		}

		if (this.details) {
			query.details = this.details.join ? this.details.join(',') : this.details;
		}

		if (this.filter) {
			query.filter = this.filter;
		}

		if (this.filter_exclude_id) {
			query['filter-exclude-id'] = this.filter_exclude_id;
		}

		if (this.sort_by) {
			query['sort-by'] = this.sort_by;
		}

		if (this.sort_direction) {
			query['sort-direction'] = this.sort_direction;
		}

		if (this.include_deleted !== undefined) {
			query['include-deleted'] = this.include_deleted;
		}

		if (fromPage) {
			query['page-from-value'] = fromPage.value;
			query['page-from-offset'] = fromPage.offset;
		}

		query['page-size'] = this.page_size !== undefined ? this.page_size : ENV.page_size || undefined;

		return query;
	}

	clone() {
		// @ts-ignore
		return new this.constructor(this);
	}

	update(ob) {
		const clone = this.clone();
		assign(clone, clone, ob);
		return clone;
	}

	equals(criteria, ignoreKeys = null) {
		if (!criteria) {
			return false;
		}
		const keys = Object.keys(this);
		return !keys.some(key => {
			if (ignoreKeys && (ignoreKeys[key] || (ignoreKeys.indexOf && ignoreKeys.indexOf(key) >= 0))) {
				return false;
			}

			const thisVal = this[key];
			const criteriaVal = criteria[key];
			if (thisVal && thisVal.getTime && criteriaVal && criteriaVal.getTime) {
				// Compare dates
				return thisVal.getTime() !== criteriaVal.getTime();
			}
			if (Array.isArray(thisVal)) {
				return !isEqual(thisVal, criteriaVal);
			}

			return thisVal !== criteriaVal;
		});
	}

	coerce() {
		if (this.s) {
			this.s = Number(this.s) || null;
		}
		return this;
	}

	serialize() {
		const serialized = {};
		// @ts-ignore
		const ignore = this.constructor.DEFAULT_IGNORED_KEYS || [];

		forEach(this, (value, key) => {
			if (ignore.includes(key)) {
				return;
			}
			// @ts-ignore
			if (value === undefined || value === '') {
				serialized[key] = undefined;
				return;
			}
			if (value instanceof Date) {
				// @ts-ignore
				value = value.toISOString();
			}

			serialized[key] = value;
		});
		if (!this.s) {
			delete serialized.s;
		}
		return serialized;
	}

	getSignature(fromPage) {
		return JSON.stringify(this.toQuery(fromPage));
	}

	hasDefaultDetails(defaultDetails = []) {
		return isEqual(this.details, defaultDetails);
	}
}

//* *********************************************************************************************************************

export const INDEXING_PROPERTY = '_index';

/**
 * Sort hash/dictionary using our INDEXING_PROPERTY. Used to convert our internal data representation into
 * a properly ordered list.
 */
export const sortByIndexingProperty = data => {
	if (!data) {
		return null;
	}

	// We already have sorted data from the server,
	// we just need to reorder it based on its original indexes
	return sortBy(data, INDEXING_PROPERTY);
};

//* *********************************************************************************************************************

/**
 * @lends QueryResult.prototype
 */
const queryResult = {
	data: 'data',
	next_page: 'next_page',
};

export const QUERY_RESULT = queryResult;

export function QueryResult(source) {
	assign(TABLE_COLUMN, this, source);
}

//* *********************************************************************************************************************

/**
 * @lends PaginationInfo.prototype
 */
const paginationInfo = {
	offset: 'offset',
	value: 'value',
};

export const PAGINATION_INFO = paginationInfo;

export function PaginationInfo(source) {
	assign(PAGINATION_INFO, this, source);
}

export const isInteger = value => {
	const type = typeof value;
	if (!value || type === 'boolean' || ['true', 'false'].includes(value)) {
		return false;
	}
	if (['number', 'string'].includes(type)) {
		return Number.isInteger(Number(value));
	}
	return false;
};

/**
// * @callback {<T>(obj: T) => T[KEY]} Getter
 * @callback Getter
 * @param {T} obj
 * @returns {T[KEY]}
 * @template {object} T
 * @template {keyof object} KEY
 */

/**
 *
 * @param {KEY} key
 * @param {any} [fallback]
 * @returns {(obj: T) => T[KEY]}
 * @template {keyof T} KEY
 * @template {object} T
 */
export const makeGetter = (key, fallback) => object => get(object, key) || fallback;

/**
 *
 * @param {T} prop
 * @param  {...[any, any, ...any[]][]} rest
 * @returns {(state: object, props: object) => state[prop]}
 * @template {string} T
 */
export const takeFromProps = (prop, ...rest) => {
	if (typeof prop !== 'string' || !prop) {
		throw new Error('Prop name must be a string!');
	}
	const propGetter = (state, props) => {
		if (!props && state) {
			return propGetter(null, state);
		}
		if (!props) {
			return null;
		}
		let value = get(props, prop);
		if (value === undefined && typeof props !== 'object') {
			value = props;
		} else if (value === undefined && (props.route || props.params)) {
			value = propGetter(null, props.route);
		}
		if (value === undefined && props.params) {
			value = propGetter(null, props.params);
		}
		if (value === undefined && props.query) {
			value = propGetter(null, props.query);
		}
		if (value === undefined && props.location) {
			value = propGetter(null, props.location.query);
		}
		if (value === undefined) {
			return value;
		}
		return isInteger(Number(value)) ? Number(value) : value;
	};

	if (rest.length) {
		return propGetter(...rest);
	}

	return propGetter;
};
