import { withType } from '@/lib/util';

import * as TYPES from './action_types';

export const renameFileFolder = (endpoint, filePath, name) => (dispatch, getState, container) => {
	return container.http
		.request({
			method: 'put',
			params: {
				name,
				path: filePath,
			},
			url: endpoint,
		})
		.then(
			res => {
				dispatch(
					withType(TYPES.FILE_EXPLORER_FILE_RENAMED, {
						data: res,
						endpoint,
						name,
						path: filePath,
					}),
				);
			},
			error => {
				dispatch(withType(TYPES.FILE_EXPLORER_FILE_RENAMED, { error }));
			},
		);
};

export const loadFile = (endpoint, relPath) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.FILE_EXPLORER_LOAD_START));
	return container.http
		.request({
			headers: {
				'x-handler-options': 'no-inject',
			},
			method: 'get',
			url: endpoint + relPath,
		})
		.then(
			res => {
				dispatch(withType(TYPES.FILE_EXPLORER_LOAD_END));
				dispatch(
					withType(TYPES.FILE_EXPLORER_FILE_LOADED, {
						data: res,
						endpoint,
						relPath,
					}),
				);
			},
			error => {
				dispatch(withType(TYPES.FILE_EXPLORER_LOAD_END, { error }));
			},
		);
};

export const editFile =
	(endpoint, fileName, relPath, contents) => (dispatch, getState, container) => {
		dispatch(
			withType(TYPES.FILE_EXPLORER_FILE_EDITED, {
				data: contents,
				endpoint,
				relPath,
			}),
		);
	};

export const deleteFileFolder = (endpoint, filePath) => (dispatch, getState, container) => {
	return container.http
		.request({
			method: 'delete',
			params: {
				path: filePath,
			},
			url: endpoint,
		})
		.then(
			() => {
				dispatch(
					withType(TYPES.FILE_EXPLORER_FILE_DELETED, {
						path: filePath,
					}),
				);
			},
			error => {
				dispatch(withType(TYPES.FILE_EXPLORER_FILE_DELETED, { error }));
			},
		);
};

export const setFileExplorerBreadcrumbs = breadcrumbs => {
	return withType(TYPES.FILE_EXPLORER_PATH_CHANGED, { breadcrumbs });
};

export const gotAssets = (identifier, subPath, data) => (dispatch, getState, container) => {
	return dispatch(
		withType(TYPES.FILE_EXPLORER_GOT_DATA, {
			data,
			identifier,
			subPath,
		}),
	);
};
