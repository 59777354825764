import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addConnectedModal } from '@/actions/connected_modal';
import { toggleDisabledContent } from '@/actions/folders';
import { getLibraryState } from '@/selectors/folders';
import {
	LIBRARY_VIEW_SORT_TYPES,
	LIBRARY_VIEW_SORT_TYPES_FLIPPED,
	LIBRARY_VIEW_TYPES,
} from '@/types/library';
import CheckboxStyled from '@/views/widgets/input/CheckboxStyled';
import Toolbar from '@/views/widgets/presentation/Toolbar';

import LibraryActions from './LibraryActions';
import LibraryCsvDownload from './LibraryCsvDownload';
import LibraryFilters from './LibraryFilters';
import LibrarySearch from './LibrarySearch';
import LibraryToggleView from './LibraryToggleView';

import './LibraryActionBar.scss';

const CLASS = 'sv-LibraryActionBar';
class LibraryActionBar extends Component {
	static propTypes = {
		criteria: PropTypes.object.isRequired,
		disableActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
		disableFilter: PropTypes.bool,
		disableNewFolder: PropTypes.bool,
		displayEnabledToggle: PropTypes.bool,
		displayHelpToggle: PropTypes.bool,
		displayLibrarySearch: PropTypes.bool,
		displayNewFolder: PropTypes.bool,
		displaySort: PropTypes.bool,
		folderId: PropTypes.number,
		isInLibraryView: PropTypes.bool,
		libraryLeftChild: PropTypes.any,
		libraryView: PropTypes.oneOf([LIBRARY_VIEW_TYPES.grid, LIBRARY_VIEW_TYPES.list]),
		namespace: PropTypes.string,
		onCriteriaChanged: PropTypes.func.isRequired,
		onUploadClick: PropTypes.func,
	};

	static defaultProps = {
		displayEnabledToggle: false,
		displayHelp: false,
		displayHelpToggle: false,
		displayUpload: true,
		displayViewToggle: true,
	};

	render() {
		const {
			criteria,
			disableNewAsset,
			disableNewFolder,
			displayEnabledToggle,
			displayHelp,
			displayHelpToggle,
			displayNewFolder,
			displaySort,
			displayUpload,
			displayViewToggle,
			folderId,
			hideDisabledContent,
			libraryLeftChild,
			libraryView,
			namespace,
			onCriteriaChanged,
			onKeyDown,
			onSortClick,
			onUploadClick,
		} = this.props;

		let { disableActions, disableFilter, displayLibrarySearch } = this.props;
		disableActions = disableActions !== undefined ? disableActions : false;
		disableFilter = disableFilter !== undefined ? disableFilter : false;

		const isSorted = hideDisabledContent !== LIBRARY_VIEW_SORT_TYPES.all;

		return (
			<Toolbar className={CLASS}>
				<div className={Toolbar.LEFT}>
					{libraryLeftChild}
					<LibraryActions
						criteria={criteria}
						disableNewAsset={disableNewAsset}
						disableNewFolder={disableNewFolder}
						disabledActions={disableActions}
						displayNewFolder={displayNewFolder}
						displayUpload={displayUpload}
						folderId={folderId}
						namespace={namespace}
						onUploadClick={onUploadClick}
					/>
					{displayLibrarySearch ? (
						<LibrarySearch
							criteria={criteria}
							disabled={disableFilter}
							namespace={namespace}
							onCriteriaChanged={onCriteriaChanged}
							onKeyDown={onKeyDown}
						/>
					) : null}
				</div>
				<div className={Toolbar.RIGHT}>
					{displayEnabledToggle ? (
						<div className={`${CLASS}-enabledToggle`}>
							<CheckboxStyled
								isToggle
								checked={isSorted}
								labelLeft="Show all"
								labelRight="Enabled only"
								onChange={onSortClick}
							/>
						</div>
					) : null}
					<LibraryCsvDownload />
					{libraryView === LIBRARY_VIEW_TYPES.grid && displaySort ? (
						<LibraryFilters
							criteria={criteria}
							namespace={namespace}
							onCriteriaChanged={onCriteriaChanged}
						/>
					) : null}
					{displayViewToggle ? <LibraryToggleView namespace={namespace} /> : null}
				</div>
			</Toolbar>
		);
	}
}

const mapStateToProps = (state, props, rest) => {
	const { hideDisabledContent } = getLibraryState(state);

	return {
		hideDisabledContent,
	};
};

const mapDispatchToProps = (dispatch, props, rest) => ({
	addConnectedModal: modal => {
		dispatch(addConnectedModal(modal));
	},
	toggleDisabledContent: hideDisabledContent => {
		dispatch(toggleDisabledContent(hideDisabledContent, props.namespace));
	},
});

const mergeProps = (
	{ hideDisabledContent },
	{ addConnectedModal, toggleDisabledContent },
	rest,
) => {
	return {
		...rest,
		addModal: modal => addConnectedModal(modal),
		hideDisabledContent,
		onSortClick: () => toggleDisabledContent(LIBRARY_VIEW_SORT_TYPES_FLIPPED[hideDisabledContent]),
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LibraryActionBar);
