import { useCallback, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Browser, LatLngBounds, Polyline } from 'leaflet';
import { flatten } from 'lodash';

import { uploadImages } from '@/actions/application';
import { useDispatch } from '@/container';
import FA from '@/types/font_awesome';
import FloorplanSketcher from '@/views/property_scans/FloorplanSketcher';
import IconButton from '@/views/widgets/IconButton';

import './SketchModal.scss';

const { retina } = Browser;

const CLASS = 'sv-Sketch';

/** @typedef {import('react').RefObject<import('react-leaflet').FeatureGroup>} FeatureGroupRef */
/** @typedef {import('react').Ref<import('react-leaflet').Map>} MapRef */

/** @typedef {import('lodash').List<import('lodash').Many<L.LatLng>>} ManyLatLngs */

function cropToCanvas(srcCanvas, dstCanvas, sx, sy, sw, sh, dx = 0, dy = 0, dw = sw, dh = sh) {
	dstCanvas.width = dw;
	dstCanvas.height = dh;
	const dstCtx = dstCanvas.getContext('2d');
	dstCtx.drawImage(srcCanvas, sx, sy, sw, sh, dx, dy, dw, dh);
}

export default function SketchModal({ floor, onClose, onSubmit }) {
	/** @type {MapRef>} */
	const sketcherRef = useRef(null);
	/** @type {FeatureGroupRef} */
	const fgRef = useRef(null);
	// const tmpCanvasRef = useRef(null);
	const dispatch = useDispatch();
	const uploadHandler = useCallback(
		files => {
			return dispatch(uploadImages(files));
		},
		[dispatch],
	);

	const { walls } = floor;
	const bounds = walls?.bounds;
	const lines = walls?.lines;
	const zoom = walls?.zoom ? walls.zoom - 1 : undefined;

	// useEffect(() => {
	// 	window.tmpCanvasRef = tmpCanvasRef;
	// 	window.tmpCanvas = tmpCanvasRef.current;
	// }, []);

	const saveHandler = useCallback(() => {
		const records = [];
		const wallLines = [];
		const sketcherMap = sketcherRef.current;
		const featureGroup = fgRef.current?._map;
		let fgBounds = featureGroup.getBounds();

		let c = fgBounds.getCenter();
		let sw = fgBounds.getSouthWest();
		let ne = fgBounds.getNorthEast();
		fgBounds = new LatLngBounds([sw.lat - c.lat, sw.lng - c.lng], [ne.lat - c.lat, ne.lng - c.lng]);
		c = fgBounds.getCenter();

		featureGroup.eachLayer(l => {
			const layer = /** @type {L.Polyline} */ (l);
			if (!layer.getLatLngs) return;
			const layerLatLngs = layer.getLatLngs();
			const latLngs = flatten(/** @type {ManyLatLngs} */ (layerLatLngs));
			latLngs.forEach(pos => {
				pos.lat -= c.lat;
				pos.lng -= c.lng;
			});
			layer.setLatLngs(layerLatLngs);
			const wallLine = latLngs.map(pos => [Number(pos.lat.toFixed(4)), Number(pos.lng.toFixed(4))]);
			wallLines.push(wallLine);
		});

		fgBounds = featureGroup.getBounds();
		sketcherMap.fitBounds(fgBounds, { animate: false, padding: [0, 0] });

		requestAnimationFrame(() => {
			// @ts-ignore
			const canvasRenderer = sketcherMap.getRenderer({ options: {} });
			/** @type {L.Bounds} */
			// @ts-ignore
			const rendererBounds = canvasRenderer._bounds;
			/** @type {HTMLCanvasElement} */
			// @ts-ignore
			const canvas = canvasRenderer._container;

			const topLeft = sketcherMap.latLngToLayerPoint(fgBounds.getNorthWest());
			const bottomRight = sketcherMap.latLngToLayerPoint(fgBounds.getSouthEast());
			const scale = retina ? 2 : 1;
			const fgSize = bottomRight.subtract(topLeft);
			const topLeftRel = topLeft.subtract(rendererBounds.min);
			const fgWidth = fgSize.x;
			const fgHeight = fgSize.y;
			const fgX = Math.abs(topLeftRel.x);
			const fgY = Math.abs(topLeftRel.y);

			c = fgBounds.getCenter();
			sw = fgBounds.getSouthWest();
			ne = fgBounds.getNorthEast();
			fgBounds = new LatLngBounds(
				[sw.lat - c.lat, sw.lng - c.lng],
				[ne.lat - c.lat, ne.lng - c.lng],
			);

			// const tmpCanvas = tmpCanvasRef.current;
			const tmpCanvas = document.createElement('canvas', undefined);
			cropToCanvas(
				canvas,
				tmpCanvas,
				fgX * scale,
				fgY * scale,
				fgWidth * scale,
				fgHeight * scale,
				0,
				0,
				fgWidth,
				fgHeight,
			);

			tmpCanvas.toBlob(blob => {
				const payload = [blob];
				return uploadHandler(payload)
					.then(res => {
						res.forEach(record => {
							records.push({ ...record, type: 'image' });
						});
					})
					.then(() => {
						onSubmit({
							image: records[0],
							walls: {
								// @ts-ignore
								bounds: [...fgBounds],
								lines: wallLines,
								zoom: sketcherMap.getZoom(),
							},
						});
					});
			}, 'image/png');
		});
	}, [onSubmit, uploadHandler]);

	const createShapeHandler = useCallback(e => {
		const featureGroup = fgRef.current?._map;
		const { layer, layerType } = e;

		if (layerType === 'polyline') {
			return;
		}

		const latLngs = flatten(/** @type {ManyLatLngs} */ (layer.getLatLngs()));
		latLngs.push(latLngs[0].clone());
		const polyLine = new Polyline(latLngs, layer.options);
		featureGroup.removeLayer(layer);
		featureGroup.addLayer(polyLine);
	}, []);

	return (
		<div className={CLASS}>
			<Modal.Header>
				<Modal.Title>Sketch a floorplan</Modal.Title>
				<IconButton icon={FA.close} onClick={onClose} />
			</Modal.Header>

			<Modal.Body>
				<FloorplanSketcher
					ref={sketcherRef}
					bounds={bounds}
					fgRef={fgRef}
					lines={lines}
					zoom={zoom}
					onCreated={createShapeHandler}
				/>
				{/* <div className={`${CLASS}-debug-container`}>
					 	<canvas ref={tmpCanvasRef}/>
					</div> */}
			</Modal.Body>

			<Modal.Footer>
				<IconButton secondary onClick={onClose}>
					Cancel
				</IconButton>
				<IconButton onClick={saveHandler}>Save</IconButton>
			</Modal.Footer>
		</div>
	);
}
