import ENV from '@/env';
import { ROLES } from '@/types/users';

/** @typedef {import('../reducer/index').State} State */

export const getAccessToken = (/** @type {State} */ state) => {
	return state.auth.session && state.auth.session.access_token;
};

export const getLoggedInUserId = (/** @type {State} */ state) => {
	return (state.auth.session && state.auth.session.user_id) || null;
};

export const getLoggedInCompanyId = (/** @type {State} */ state) => {
	const { session, uwd } = state.auth;
	let companyId = session && session.company_id;
	if (!companyId) {
		companyId = uwd && uwd.user && uwd.user.default_company_id;
	}
	return companyId || null;
};

export const getLoggedInCompany = (/** @type {State} */ state) => {
	const id = getLoggedInCompanyId(state);
	const cwds = state.auth.uwd && state.auth.uwd.cwds;
	if (!cwds || !Array.isArray(cwds)) {
		return null;
	}
	const cwd = cwds.find(cwd => cwd.company && cwd.company.id === id);
	return cwd || null;
};
export const getLoggedInUserWithDetails = (/** @type {State} */ state) => {
	return (state && state.auth && state.auth.uwd) || null;
};
export const getLoggedInUser = (/** @type {State} */ state) => {
	const uwd = getLoggedInUserWithDetails(state);
	return (uwd && uwd.user) || null;
};

export const isLoggedIn = (/** @type {State} */ state) => {
	return !!(state.auth.session && state.initialized);
};

const createRoleTester = (/** @type {string} */ role) => (/** @type {State} */ state) => {
	return state.auth.session ? state.auth.session.role === role : null;
};

export const allowSocialAccount = (/** @type {State} */ state, networkType) => {
	const section = ENV.accounts[networkType];
	if (!section) {
		throw new Error(`No ENV section for network type: ${networkType}`);
	}

	if (!section.limit_to_users) {
		return true;
	}

	const user = state.auth.uwd && state.auth.uwd.user;
	if (!user) {
		return false;
	}

	const allowed = Object.values(section.limit_to_users).some(value => {
		return user.username === value || user.email === value || user.id === value;
	});

	return allowed;
};

export const isAdmin = createRoleTester(ROLES.admin);
export const isManager = createRoleTester(ROLES.manager);
export const isBasicUser = createRoleTester(ROLES.basic_user);
export const isTrustedUser = createRoleTester(ROLES.trusted_user);

export const allowProducts = (/** @type {State} */ state) => isAdmin(state);
export const allowManagementReports = (/** @type {State} */ state) => isAdmin(state);
export const allowAudienceReports = (/** @type {State} */ state) => true;
export const allowCompanies = (/** @type {State} */ state) => !isBasicUser(state);
export const allowSharingCenter = (/** @type {State} */ state) => true;
export const allowSiteAnalyticsSettings = (/** @type {State} */ state) => isAdmin(state);
export const allowSiteSeeOrphans = (/** @type {State} */ state) => isAdmin(state);
export const allowSiteSeeOwnership = (/** @type {State} */ state) => !isBasicUser(state);
export const allowSiteSetNoOwner = (/** @type {State} */ state) => isAdmin(state);
export const allowSiteCreate = (/** @type {State} */ state) => isManager(state) || isAdmin(state);
export const allowEditSite = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state) || isBasicUser(state) || isTrustedUser(state);
export const allowDownloadSiteFiles = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state);
export const allowBillboardEdit = (/** @type {State} */ state) => isAdmin(state);
export const allowMetadata = (/** @type {State} */ state) => isAdmin(state);
export const allowCreateCompanies = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state);
export const allowEditCompanies = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state);
export const allowCreateTopLevelCompanies = (/** @type {State} */ state) => isAdmin(state);
export const allowEditEnquiries = (/** @type {State} */ state) => isAdmin(state);
export const allowEditEnquiryDrafts = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state);
export const allowOrderWithoutBeneficiary = (/** @type {State} */ state) => isAdmin(state);
export const allowAddStatsVersion = (/** @type {State} */ state) => isAdmin(state);
export const allowPersonalAccounts = (/** @type {State} */ state) => isAdmin(state);
export const allowManageUsers = (/** @type {State} */ state) => isAdmin(state);
export const allowEditBrochure = (/** @type {State} */ state) =>
	isAdmin(state) || isManager(state) || isBasicUser(state);
