import { Children, cloneElement, Component, createRef } from 'react';
import isEqual from 'lodash/isEqual';

import './ContextMenu.scss';

const CLASS = 'sv-ContextMenu';

class ContextMenu extends Component {
	constructor(props) {
		super(props);

		this.contextMenu = createRef();
		const position = { ...props.position };
		const style = { ...props.style };
		const { xPos, yPos } = position;

		this.state = {
			style: {
				...style,
				left: xPos,
				top: yPos,
			},
		};
	}

	componentDidMount() {
		this.calculatePosition();

		document.addEventListener('click', this.closeContextMenu, false);
		document.addEventListener('scroll', this.closeContextMenu, false);
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.position, this.props.position)) {
			this.calculatePosition();
		}
	}

	calculatePosition = () => {
		const currentContextMenu = this.contextMenu.current;
		const { position } = this.props;
		if (!currentContextMenu || !position) {
			return null;
		}

		const { height, width } = currentContextMenu.getBoundingClientRect();
		const { xPos, yPos } = position;

		const willOverlapRight =
			width + xPos > (window.innerWidth || document.documentElement.clientWidth);
		const willOverlapBottom =
			height + yPos > (window.innerHeight || document.documentElement.clientHeight);
		const xPosition = willOverlapRight ? xPos - width : xPos;
		const yPosition = willOverlapBottom ? yPos - height : yPos;

		this.setState(prevState => ({
			style: {
				...prevState.style,
				left: xPosition,
				top: yPosition,
			},
		}));
	};

	componentWillUnmount() {
		document.removeEventListener('click', this.closeContextMenu, false);
		document.removeEventListener('scroll', this.closeContextMenu, false);
	}

	closeContextMenu = e => {
		const { closeContextMenu, isOpen } = this.props;
		if (!isOpen || (e && e.defaultPrevented)) {
			return null;
		}
		closeContextMenu && closeContextMenu();
	};

	render() {
		const { children, isOpen, placement, ...props } = this.props;

		const childrenWithProps = Children.map(children, child => {
			return cloneElement(child, props);
		});

		return (
			<div ref={this.contextMenu} className={CLASS} style={this.state.style}>
				{childrenWithProps}
			</div>
		);
	}
}

export default ContextMenu;
