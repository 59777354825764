import { Component } from 'react';
import PropTypes from 'prop-types';

import FA from '@/types/font_awesome';

import TextInputWithIcon from './TextInputWithIcon';

const CLASS = 'sv-ClearableInput';

class ClearableInput extends Component {
	/**
	 * @type {TextInputWithIcon.propTypes}
	 */
	static propTypes = {
		beforeElement: PropTypes.any,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		hasError: PropTypes.bool,
		helpBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		iconOverride: PropTypes.any,
		id: PropTypes.string,
		initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		onChange: PropTypes.func,
		onIconClick: PropTypes.func,
		onKeyDown: PropTypes.func,
		optional: PropTypes.bool,
		placeholder: PropTypes.string,
		rows: PropTypes.number,
		showFeedbackIcon: PropTypes.bool,
		size: PropTypes.string,
		type: PropTypes.string,
		validator: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	};

	constructor(props) {
		super(props);

		this.state = {
			hasValue: !!props.initialValue,
		};

		this.handleIconClick = this.handleIconClick.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(value) {
		this.setState({ hasValue: !!value });
		this.props.onChange && this.props.onChange(value);
	}

	setInputValue(value) {
		if (this.input) {
			this.input.setInputValue(value);
			this.setState({ hasValue: !!value });
		}
	}

	handleIconClick(e) {
		if (!this.props.iconOverride) {
			this.setInputValue('');
		}
		if (this.props.onIconClick) {
			this.props.onIconClick('');
		}
	}

	handleKeyDown(e) {
		const keyCode = e.which || e.keyCode;

		if (keyCode === 13) {
			e.preventDefault();
		}

		if (keyCode === 27 && !this.props.iconOverride) {
			this.setInputValue('');
		}

		this.props.onKeyDown && this.props.onKeyDown(e);
	}

	render() {
		const { className, iconOverride, onChange, onIconClick, onKeyDown, ...props } = this.props;

		let icon = iconOverride;
		if (!icon && this.state.hasValue) {
			icon = FA.remove;
		}

		return (
			<TextInputWithIcon
				ref={input => (this.input = input)}
				className={`${className || ''} ${CLASS}`}
				icon={icon}
				onChange={this.handleInputChange}
				onIconClick={this.handleIconClick}
				onKeyDown={this.handleKeyDown}
				{...props}
			/>
		);
	}
}

export default ClearableInput;
