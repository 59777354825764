import * as TYPES from '@/actions/action_types';

import ReduceHelper from './reduce_helper';

const INITIAL_STATE = /** @lends State.prototype */ {
	agorityManagement: {
		libraryModal: false,
		loadError: null,
		loading: null,
		nextPage: null,
		op: null,
		selectedContent: {
			data: null,
		},
		selectedData: {
			allSelected: false,
			data: null,
		},
		surveyList: {},
		surveyResultData: [],
	},
};

const helper = new ReduceHelper('agorityManagement', 'id');

const setSelectedDevices = (state, action) => {
	return helper.updateNamespace(state, {
		selectedData: {
			allSelected: action.allSelected,
			data: action.selectedData ? { ...action.selectedData } : action.selectedData,
		},
	});
};

const openCloseLibraryModal = (state, action) => {
	return helper.updateNamespace(state, {
		libraryModal: action.data,
	});
};

const setSelectedContent = (state, action) => {
	return helper.updateNamespace(state, {
		selectedContent: {
			data: action.selectedData,
		},
	});
};

const setSurveyData = (state, action) => {
	return helper.updateNamespace(state, {
		surveyList: action.data,
	});
};

const setSurveyResultData = (state, action) => {
	return helper.updateNamespace(state, {
		surveyResultData: action.data,
	});
};

export default {
	INITIAL_STATE,
	[TYPES.AGORITY_LOAD_START]: helper.loadStart,
	[TYPES.AGORITY_LOAD_END]: helper.loadEnd,
	[TYPES.AGORITY_OP_START]: helper.opStart,
	[TYPES.AGORITY_OP_END]: helper.opEnd,
	[TYPES.AGORITY_OP_RESET]: helper.opReset,
	[TYPES.AGORITY_SELECTED_DEVICES]: setSelectedDevices,
	[TYPES.OPEN_CLOSE_LIBRARY_MODAL]: openCloseLibraryModal,
	[TYPES.AGORITY_SELECTED_CONTENT]: setSelectedContent,
	[TYPES.AGORITY_SURVEY_DATA]: setSurveyData,
	[TYPES.AGORITY_SURVEY_RESULTS_DATA]: setSurveyResultData,
};
